import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { httpCache } from "infra/axios";

export default function SelectTipoEvento({ disabled = null, value, onChange }) {
  const [items, setItems] = useState([]);
  const [, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data: tipoEventos } = await httpCache.get(
          "v2/eventos/mont/tipos-eventos"
        );

        setItems(tipoEventos);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Tipo de Evento</InputLabel>
      <Select
        value={value}
        disabled={disabled}
        onChange={(ev) => {
          onChange(items.find((x) => x.id === ev.target.value)?.id ?? null);
        }}
        label="Tipo de Evento"
      >
        {items.map((item) => (
          <MenuItem value={item.id}>{item.nome}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
