import React from "react";
import { formatMoney } from "infra/utils";
import { getValorTotalProduto } from "./utils";

function LabelTotal({ produto }) {

  if (!getValorTotalProduto(produto)) return null;

  return (
    <React.Fragment>
      R$ {formatMoney(getValorTotalProduto(produto))}
    </React.Fragment>
  );
}

export default LabelTotal;
