import React from "react";
import { formatMoney } from "infra/utils";

export default class RenderPrecoUnico extends React.Component {
  render() {
    const { item } = this.props;

    if (!item) {
      return <div />;
    }

    if (!item.precos) {
      return <div />;
    }

    if (!Array.isArray(item.precos)) {
      return <div />;
    }

    let preco = 0;

    if (item.precos.length === 0) {
      preco = 0;
    }

    if (item.precos.length === 1) {
      preco = item.precos[0].valorPreco;
    }
    
    if(!preco) return null;

    return (
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          textAlign: "center",
          display: "flex"
        }}
      >
        <div
          style={{
            top: 17,
            fontSize: 18,
            right: 10
          }}
        >
          R$
        </div>

        <div
          style={{
            fontWeight: "bold",
            fontSize: 36,
            color: "#222"
          }}
        >
          {formatMoney(preco)}
        </div>
      </div>
    );
  }
}
