import styled from "styled-components";

export const ContainerAmbientes = styled.div`
  margin-top: 120px;

  @media all and (max-width: 768px) {
    margin-top: 64px;

    * {
      text-align: center;
      justify-content: center;
    }

    button {
      margin-top: 16px;
    }
  }
`;

export const AmbienteItemContainer = styled.div`
  margin-bottom: 48px;

  h4 {
    font-size: 1.7em;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  img {
    margin-right: 32px;
    max-width: 100%;
  }

  @media all and (max-width: 768px) {
    img {
      margin-right: 0;
    }
  }
`;
