/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import bg from "../bg.png";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

import { LayoutContext } from "contexts/layout"; 
import MenuFidelidade from "./MenuFidelidade";
import { useMediaQuery } from "infra/utils";

const ContainerTopoMenu = styled.div`
  margin-bottom: 8px;
  img {
    max-width: 100%;
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 16px 8px;
`;

function TopoMenu() {
  const { layoutData } = useContext(LayoutContext);
  const isMobile = useMediaQuery("(max-width: 768 px)");

  return (
    <ContainerTopoMenu>
      <Container>
        <Row style={{ marginBottom: 32 }}>
          <Col sm={12}>
            {layoutData.cardapioImagemCabecalho ? (
              <img src={layoutData.cardapioImagemCabecalho} />
            ) : (
              <img src={bg} />
            )}
          </Col>
        </Row>

        <Row>
          {layoutData.fidelidadeId ? (
            <Col
              lg={4}
              sm={6}
              style={
                !isMobile
                  ? { marginBottom: 32, marginTop: 32 }
                  : { marginTop: 8 }
              }
            >
              <MenuFidelidade />
            </Col>
          ) : null}
        </Row>
      </Container>
    </ContainerTopoMenu>
  );
}

export default TopoMenu;
