import React from "react";
import { Row, Col, Container } from "reactstrap";
import moment from "moment";
import { TextField } from "@material-ui/core";

import { ContainerResumo } from "./styles";

import ResumoCardapio from "./ResumoCardapio";
import ResumoEspaco from "./ResumoEspaco";
// import ResumoAdicionais from "./ResumoAdicionais";

import { Ambiente, Produto } from "../../../types";
import { formatMoney } from "../../../../../../../../infra/utils";
import useTotais from "../../../hooks/useTotais";

interface ResumoProps {
  ambiente: Ambiente | null;
  update?: Function;
  produto: Produto | null;
  data: string;
  quantidadePessoas: number;
  readonly?: boolean;
  id?: number;
}

function Resumo({
  ambiente,
  id,
  update,
  produto,
  data,
  quantidadePessoas,
  readonly = false,
}: ResumoProps) {
  const { total } = useTotais();

  return (
    <ContainerResumo>
      <Container>
        <div style={{ marginBottom: 64 }}>
          <h4>Resumo da reserva {id ? `#${id}` : null}</h4>
        </div>

        <Row>
          <Col sm={6} xs={12}>
            {ambiente ? <ResumoEspaco ambiente={ambiente} /> : null}

            <br />

            {produto ? (
              <ResumoCardapio
                quantidadePessoas={quantidadePessoas}
                produto={produto}
              />
            ) : null}

            {/* <br />

            <ResumoAdicionais
              quantidadePessoas={quantidadePessoas}
              produto={produto}
            /> */}
          </Col>
          <Col sm={6} xs={12}>
            <div>
              <span style={{ color: "#666" }}>Data</span>
              <div>{moment(data).format("DD/MM/YYYY")}</div>
            </div>

            <div style={{ marginTop: 32 }}>
              <span style={{ color: "#666" }}>Quantidade de pessoas</span>
              <div>
                {readonly ? <div> {quantidadePessoas}</div> : null}
                {!readonly ? (
                  <TextField
                    variant="outlined"
                    type="number"
                    size="small"
                    style={{
                      backgroundColor: "white",
                      width: "160px",
                    }}
                    inputProps={{
                      max: ambiente?.qtdePessoas,
                      min: 1,
                    }}
                    value={quantidadePessoas}
                    onChange={({ target }) => {
                      let v: number = Number(target.value) ?? 0;

                      if (
                        ambiente?.qtdePessoas !== null ||
                        (ambiente?.qtdePessoas !== undefined &&
                          v > ambiente?.qtdePessoas)
                      ) {
                        v = ambiente?.qtdePessoas ?? 0;
                      }

                      if (typeof update === "function") {
                        update("quantidadePessoas", v);
                      }
                    }}
                  />
                ) : null}
              </div>
            </div>

            <div style={{ marginTop: 32 }}>
              <span style={{ color: "#666" }}>Espaço</span>
              <div>R$ {formatMoney(ambiente?.preco)}</div>
            </div>

            <div style={{ marginTop: 32 }}>
              <span style={{ color: "#666" }}>Cardápio</span>
              <div>
                R${" "}
                {formatMoney(
                  (produto?.valorBasePessoa ?? 0) * quantidadePessoas
                )}
              </div>
            </div>

            <div style={{ marginTop: 32 }}>
              <span style={{ color: "#666" }}>Total</span>
              <div>R$ {formatMoney(total)}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </ContainerResumo>
  );
}

export default Resumo;
