import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { formatMoney } from "infra/utils";

import { Produto as ProdutoType } from "../../../types";

import { CardapioItemContainer } from "./styles";

interface CardapioItemProps {
  item: ProdutoType;
  onClick: any;
  disabled: boolean;
  active: boolean;
  primaryColor: string;
}

function CardapioItem({
  item,
  onClick,
  disabled,
  active,
  primaryColor,
}: CardapioItemProps) {
  return (
    <CardapioItemContainer
      primaryColor={primaryColor}
      active={active}
      disabled={disabled}
    >
      <Card
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
      >
        <div>
          <img src={item.imagemUrl} alt={item.nome} />
        </div>

        <CardContent>
          <Typography variant="h5" component="h4">
            {item.nome}
          </Typography>

          <br />

          <Typography style={{ fontSize: "11px" }}>Sabores:</Typography>
          <Typography>{item.descricao}</Typography>

          <br />

          <Typography style={{ fontSize: "11px" }}>Valor por pessoa</Typography>
          <Typography>R$ {formatMoney(item.valorBasePessoa)}</Typography>
        </CardContent>
      </Card>
    </CardapioItemContainer>
  );
}

export default CardapioItem;
