import React, { useState } from "react";
import {
  Body,
  ButtonContainer,
  Card,
  Flex,
  HorizontalList,
  Title,
} from "./styles";
import Footer from "../../components/Footer";
import ScrollTop from "../../components/ScrollTop";
import Appbar from "../../components/Appbar";
import HeaderEmpresa from "../components/HeaderEmpresa";
import { Container } from "reactstrap";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import { navigate } from "infra/router/history";
import useGetPontosEntrega from "./hooks/useGetPontosEntrega";
import SearchIcon from "@material-ui/icons/Search";

export const points = [
  {
    id: 2,
    nome: "Prefeitura",
    logradouroFull: "Rua das Maravilhas, 142",
    bairroId: 376,
    latitude: null,
    longitude: null,
    horarioLimitePedido: "21:40:45",
    flgPrivado: false,
    bairroNome: "Alvorada",
    cidadeNome: "Cuiabá",
    taxaEntrega: 2.0,
  },

  {
    id: 1,
    nome: "SEDUC",
    logradouroFull: "Rua das Maravilhas, 142",
    bairroId: 376,
    latitude: null,
    longitude: null,
    horarioLimitePedido: "21:40:45",
    flgPrivado: false,
    bairroNome: "Alvorada",
    cidadeNome: "Cuiabá",
    taxaEntrega: 2.0,
  },
  {
    id: 3,
    nome: "Comper",
    logradouroFull: "Rua das Maravilhas, 142",

    bairroId: 376,
    latitude: null,
    longitude: null,
    horarioLimitePedido: "21:40:45",
    flgPrivado: false,
    bairroNome: "Alvorada",
    cidadeNome: "Cuiabá",
    taxaEntrega: 2.0,
  },
  {
    id: 4,
    nome: "Comper",
    logradouroFull: null,
    bairroId: 376,
    latitude: null,
    longitude: null,
    horarioLimitePedido: "21:40:45",
    flgPrivado: false,
    bairroNome: "Alvorada",
    cidadeNome: "Cuiabá",
    taxaEntrega: 2.0,
  },
];

export default function DeliveryPontoEntrega() {
  return (
    <Body>
      <Appbar />
      <HeaderEmpresa showCarrinho={false} />
      <Container>
        <ListagemPontoEntrega />
      </Container>

      <Footer />
      <ScrollTop />
    </Body>
  );
}

 function ListagemPontoEntrega() {
  const { data, isLoading } = useGetPontosEntrega();
  const [pontoEntrega, setPontosEntrega] = useState(
    JSON.parse(localStorage.getItem("pontoEntrega") || "{}")
  );
  const [search, setSearch] = useState(""); // novo estado para a busca
  const hasPontoEntrega = pontoEntrega.id !== undefined;

  if (isLoading) return <div>Carregando...</div>;
  if (data.length === 0) return <div>Nenhum ponto de entrega disponível</div>;

  // Filtrar os pontos de entrega com base na busca
  const filteredData = data.filter(point =>
    point.nome.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
    <div>
      <TextField
        value={search} // usar o estado da busca
        size={"medium"}
        onChange={(ev) => setSearch(ev.target.value)} // atualizar o estado da busca
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        fullWidth
        placeholder="Pesquise pelo ponto de entrega"
        variant="outlined"
      />
    </div>
      <HorizontalList>
        {filteredData.map((point) => ( // usar os dados filtrados
          <DeliveryPointCard
            key={point.id}
            point={point}
            onClick={() => setPontosEntrega(point)}
            isSelected={pontoEntrega.id === point.id}
          />
        ))}
      </HorizontalList>
      {hasPontoEntrega && (
        <ButtonContainer>
          <Button
            variant="contained"
            onClick={() => {
              localStorage.setItem(
                "pontoEntrega",
                JSON.stringify(pontoEntrega)
              );
              navigate("cardapio");
            }}
            color="primary"
          >
            Avançar - Cardápio
          </Button>
        </ButtonContainer>
      )}
    </>
  );
}
export const DeliveryPointCard = ({ point, isSelected, onClick }) => {
  const time = point.horarioLimitePedido.split(":").slice(0, 2).join(":");

  return (
    <Card isSelected={isSelected} onClick={onClick}>
      <Title>{point.nome}</Title>
      <div>
        <div>{point.logradouroFull}</div>
        <div>{point.bairroNome}</div>
      </div>
      <Flex>
        <p>
          <i
            className="fa fa-clock-o"
            style={{
              fontSize: "1.25em",
              marginRight: "8px",
            }}
          />
          <b>Limite: {time}</b>
        </p>
        <p>
          Taxa:<b> R$ {point.taxaEntrega}</b>
        </p>
      </Flex>
    </Card>
  );
};
