/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Input,
  InputGroupAddon,
  Row,
  Col,
} from "reactstrap";

import ModalPesquisarEndereco from "../ModalPesquisarEndereco";
import useCadastroEndereco from "./hook";

function ModalCadastroEndereco({ open, saveLocal, onToggle, onSaved, item }) {
  const {
    bairros,
    form,
    setForm,
    isLoading,
    isLoadingCep,
    isLoadingLocalizacao,
    isLocationGrantedOrPrompt,
    handleChange,
    handleLocation,
    handleSalvar,
    handleSearchCep,
    handleGetBairro,
    cepExistente,
  } = useCadastroEndereco({ saveLocal, onSaved, item });

  const [pesquisarEndereco, setPesquisarEndereco] = useState(false);

  const handleCloseModalPesquisa = async (endereco) => {
    setForm({
      ...endereco,
    });

    if (endereco.cidadeId) {
      await handleGetBairro(endereco.cidadeId);
    }

    setPesquisarEndereco(false);
  };

  return (
    <div>
      <ModalPesquisarEndereco
        open={pesquisarEndereco}
        onSelected={(endereco) => {
          handleCloseModalPesquisa(endereco);
        }}
        onToggle={() => {
          setPesquisarEndereco(!pesquisarEndereco);
        }}
      />

      <Modal isOpen={open} toggle={onToggle}>
        <ModalHeader toggle={onToggle}>Fazer Cadastro</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Cep</Label>
              <InputGroup>
                <Input
                  type="text"
                  value={form.cep}
                  required
                  onChange={(ev) => handleChange("cep", ev.target.value)}
                  placeholder="Cep"
                />
                <InputGroupAddon
                addonType = "append"
                >
                  <Button
                    onClick={() => handleSearchCep(form.cep)}
                    color="info"
                    type="button"
                    disabled={isLoadingCep}
                  >
                    <i className="fa fa-search" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <Row style={{ marginBottom: 16 }}>
              {isLocationGrantedOrPrompt ? (
                <Col>
                  <Button
                    disabled={isLoadingLocalizacao}
                    onClick={() => handleLocation()}
                    block
                    color="info"
                  >
                    <i className="fa fa-map-marker" />{" "}
                    {isLoadingLocalizacao
                      ? "Carregando..."
                      : "Minha localização"}
                  </Button>
                </Col>
              ) : null}

              <Col>
                <Button onClick={() => setPesquisarEndereco(true)} block>
                  <i className="fa fa-search" /> Pesquisar endereço
                </Button>
              </Col>
            </Row>

            <FormGroup>
              <Label for="exampleNome">Nome</Label>
              <Input
                type="text"
                value={form.nome}
                defaultValue={"Minha casa"}
                autoComplete="off"
                placeholder="Ex: Minha casa, trabalho, escola"
                required
                onChange={(ev) => handleChange("nome", ev.target.value)}
              />
            </FormGroup>
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Estado</Label>
                  <Input
                    type="text"
                    value={form.nomeEstado}
                    disabled={true}
                    placeholder="Nome Estado"
                    onChange={(ev) =>
                      handleChange("nomeEstado", ev.target.value)
                    }
                  />
                </FormGroup>
              </Col>

              <Col sm={8}>
                <FormGroup>
                  <Label>Cidade</Label>
                  <Input
                    type="text"
                    value={form.nomeCidade}
                    disabled={true}
                    placeholder="Nome Cidade"
                    onChange={(ev) =>
                      handleChange("nomeCidade", ev.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Bairro</Label>
              {!form.habilitaCadastro ? (
                <Input
                  type="text"
                  value={form.nomeBairro}
                  disabled
                  required
                  placeholder="Bairro"
                />
              ) : (
                <Input
                  type="select"
                  value={form.bairroId}
                  onChange={(ev) => {
                    const bairro = bairros.find(
                      (x) => Number(x.id) === Number(ev.target.value)
                    );

                    setForm({
                      ...form,
                      bairroId: ev.target.value,
                      nomeBairro: bairro ? bairro.nome : null,
                    });
                  }}
                >
                  <option value="">--selecione--</option>
                  {bairros.map((item, key) => (
                    <option key={key} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </Input>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="rua">Rua</Label>
              <Input
                type="text"
                required
                name="logradouro"
                value={form.logradouro}
                disabled={cepExistente && form.logradouro}
                onChange={(ev) => handleChange("logradouro", ev.target.value)}
                placeholder="Rua"
              />
            </FormGroup>

            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label>Número</Label>
                  <Input
                    type="text"
                    value={form.numero}
                    required
                    onChange={(ev) => handleChange("numero", ev.target.value)}
                    placeholder="Número"
                  />
                </FormGroup>
              </Col>
              <Col sm={8}>
                <FormGroup>
                  <Label>Complemento</Label>
                  <Input
                    type="text"
                    value={form.complemento}
                    required
                    onChange={(ev) =>
                      handleChange("complemento", ev.target.value)
                    }
                    placeholder="Complemento"
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label>Ponto de Referência</Label>
              <Input
                type="text"
                value={form.referencia}
                required
                onChange={(ev) => handleChange("referencia", ev.target.value)}
                placeholder="Ponto de Referência"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onToggle}>
            Cancelar
          </Button>
          <Button
            disabled={isLoading}
            color="primary"
            onClick={() => handleSalvar()}
          >
            {isLoading ? "Salvando..." : "Salvar"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalCadastroEndereco;
