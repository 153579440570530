import React from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { formatMoney } from "infra/utils";

function PagamentosList({ list }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "8px",
        }}
      >
        <div
          style={{
            padding: "2px 4px",
            background: "#d1d8e3",
            borderRadius: 8,
          }}
        >
          {list?.length > 0 ? "Pagamentos" : "Nenhum pagamento realizado"}
        </div>
      </div>
      {(list ?? []).map((pagamento) => (
        <div
          style={{
            paddingBottom: 4,
            paddingTop: 4,
          }}
          key={pagamento.id} // Adicione uma chave única aqui
        >
          <Row>
            <Col span={9}>{pagamento.nome}</Col>

            <Col style={{ textAlign: "right", color: "gray" }} span={3}>
              {moment(pagamento?.dataPagto).format("DD/MM/YY HH:mm")}
            </Col>
          </Row>{" "}
          <Row>
            <Col span={3} style={{ color: "#222" }}>
              <b>R${formatMoney(pagamento.valor)}</b>
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
}

export default PagamentosList;
