import React from 'react';
import { ButtonContainer } from '../../styles';
import { CenteredDiv, ContainerText, StyledH2, StyledH3, StyledH4, } from '../Resumo';
import useGetDetalhesReservaCriada from '../../hooks/useGetDetalhesReservaCriada';
import moment from 'moment';
import styled from 'styled-components';
import { formatMoney } from 'infra/utils';
import { navigate } from 'infra/router/history';
import { Button } from '@material-ui/core';
import 'moment/locale/pt-br';
import { Divider } from '../../../QRCodePage/QRCode';
import { QRCodeSVG } from 'qrcode.react';
import CopyToClipboard from 'react-copy-to-clipboard';

moment.locale('pt-br');

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 8px;
`;
export const StatusText = styled(StyledH3)`
  color: ${props => props.flgStatusPagamento !== 'A' ? 'green' : 'red'};
`;

export const CircleStep = styled.div`
  width: 30px;
  height: 30px;
  background-color: #D8E5F3;
  color: #397DC5;
  font-weight: 500;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  width: 100%; 
  gap: 8px;
`;

export const Text = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
  color: #397DC5; 
  width: 90%;
  font-weight: 500;
`;

const ReservaDetails = ({ detalhes }) => (
  <>
    <ContainerText>
      <StyledH3>
        Dados da reserva
      </StyledH3>
    </ContainerText>
    <div style={{ textAlign: 'center', marginTop: 16 }}>
      <StyledH3>Reserva nº {detalhes.id}</StyledH3>
      <StyledH2
        style={{
          color: '#3A7DC5',
        }}
      >
        {moment(detalhes.dataMovimento).format("DD MMMM YYYY")}
      </StyledH2>
    </div>
    <div>
      <InfoBlock style={{
        marginBottom: 0,
        marginTop: 8
      }}>
        <StyledH4>Qtde pessoas:</StyledH4>
        <StyledH4>{detalhes.qtdePessoas}</StyledH4>
      </InfoBlock>
      <InfoBlock>
        <StyledH3>Valor total:</StyledH3>
        <StyledH2>{'R$ ' + formatMoney(detalhes.valorTotal)}</StyledH2>
      </InfoBlock>
    </div>
    <Divider />
  </>
);

const PaymentStatus = ({ detalhes }) => (
  detalhes.flgTipoCobranca !== "N" && (
    <InfoBlock>
      <StyledH3>Status:</StyledH3>
      <StatusText flgStatusPagamento={detalhes?.flgStatusPagamento}>
        {detalhes?.flgStatusPagamento !== "A" ? "Pago" : "Aguardando pagamento"}
      </StatusText>
    </InfoBlock>
  )
);

const PixPayment = ({ resultado, isCopied, handleCopy, detalhes }) => (
  detalhes.flgTipoCobranca !== "N" && (
    <div>
      <span style={{ textAlign: "center" }}>
        Você pode Copiar a Chave e Colar em seu Aplicativo Bancário com PIX ou
        escanear o QrCode abaixo
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "8px 0",
        }}
      >
        <QRCodeSVG size={200} value={resultado?.pix?.qrCodeData} />
      </div>

      <br />

      <div style={{ textAlign: "center", marginBottom: 16 }}>
        <CopyToClipboard text={resultado?.pix?.qrCodeData} onCopy={handleCopy}>
          <Button color="primary" variant="contained"
            style={{
              borderRadius: 12
            }}
          >
            {isCopied ? "Copiado =)" : "Copiar PIX"}
          </Button>
        </CopyToClipboard>
      </div>
      <Text
        style={
          {
            textAlign: 'center',
          }
        }
      >
        Pagar com Pix é seguro, fácil e rápido!
      </Text>
      <Center>
        <div>
          <Flex><CircleStep>1</CircleStep><Text>Abra o aplicativo do seu banco e clique em Pix.</Text></Flex>
          <Flex><CircleStep>2</CircleStep><Text>Clique em Pix e escolha a opção Pagar por QR Code.</Text></Flex>
          <Flex><CircleStep>3</CircleStep><Text>Aponte a câmera do celular para o QR Code que será exibido após a finalização da compra.</Text></Flex>
          <Flex><CircleStep>4</CircleStep><Text>Pronto, agora é só confirmar os dados e finalizar o pagamento com o Pix.</Text></Flex>
        </div>
      </Center>
    </div>
  )
);

export function ReservaCriada({ resultado }) {
  const { data: detalhes, isLoading } = useGetDetalhesReservaCriada(resultado.id);
  const [isCopied, setIsCopied] = React.useState(false);
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <>
      <CenteredDiv>
        {
          isLoading ? (
            <div
              style={
                {
                  textAlign: 'center',
                }
              }
            >Carregando...</div>
          ) : (
            <>
              <ReservaDetails detalhes={detalhes} />
              <PaymentStatus detalhes={detalhes} />
              <Divider />
              <PixPayment resultado={resultado} isCopied={isCopied} handleCopy={handleCopy}
                detalhes={detalhes}
              />
            </>
          )
        }

      </CenteredDiv>
      <ButtonContainer>
        <Button

          variant="contained"
          onClick={
            () => {
              navigate('minhas-reservas')
            }
          }
          style={{
            backgroundColor: '#D8E5F3',
            color: '#397DC5',
            height: 50,
          }}
        >
          IR PARA MINHAS RESERVAS
        </Button>
      </ButtonContainer>
    </>
  )
}