import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import styled from "styled-components";
import { Button, TextField, Chip } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Row, Col } from "reactstrap";
import DrawerFiltro from "./DrawerFiltro";

export default function FiltroMobile({ value, onChange, fixed, isDelivery,...props }) {
  const [buscaOpen, setBuscaOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleScroll = (id) => {
    setDrawerOpen(false);

    setTimeout(() => {
      const element = window.document.querySelector(`#${id}`);

      if (!element) {
        return;
      }

      const yOffset = window.innerWidth <= 768 ? 400 : 300;

      const y =
        element.getBoundingClientRect().top + window.pageYOffset - yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }, 500);
  };

  if (buscaOpen) {
    return (
      <Row style={{ alignItems: "center" }}>
        <Col>
          <TextField
            value={value?.search}
            size={"small"}
            onChange={(ev) => onChange("search", ev.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            placeholder="Busque no cardápio"
            variant="outlined"
          />
        </Col>
        <Col xs={4}>
          <Button
            onClick={() => setBuscaOpen(false)}
            outline
            variant="contained"
            size="sm"
          >
            Fechar
          </Button>
        </Col>
      </Row>
    );
  }
  return (
    <>
      <StyledContainer>
        <StyledIconContainer onClick={() => setDrawerOpen(true)}>
          <MenuIcon />
        </StyledIconContainer>

        <div className="box-inner-scroll">
          <StyledIconContainer onClick={() => setBuscaOpen(true)}>
            <SearchIcon />
          </StyledIconContainer>
          {props?.grupos?.map((grupo, index) => (
            <Chip
              key={index}
              label={grupo.nome}
              onClick={() => handleScroll(`cat-${grupo.id}`)}
            />
          ))}
        </div>
      </StyledContainer>
      <DrawerFiltro
        {...props}
        isDelivery={isDelivery}
        handleScroll={handleScroll}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </>
  );
}
const StyledIconContainer = styled.div`
  cursor: pointer;
  padding: 0 8px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex: 1;

  .box-inner-scroll {
    & {
      display: flex;
      flex: 1;
      flex-direction: row;
      gap: 8px;
      overflow-x: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
