import React from "react";
import LayoutWebGula from "./LayoutWebGula";
import { Switch, Route } from "react-router-dom";
import RestaurantesPage from "./pages/RestaurantesPage";
import ComoFuncionaPage from "./pages/ComoFunciona";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";
import SejaNossoParceiroPage from "./pages/SejaNossoParceiro";
import GaleriaPage from "./pages/Galeria";

function WebgulaModule() {
  return (
      <LayoutWebGula>
        <Switch>
        
          <Route
            exact
            path="/webgula/como-funciona"
            component={ComoFuncionaPage}
          />
          <Route
            exact
            path="/webgula/politica-privacidade"
            component={PoliticaPrivacidade}
          />
          <Route
            exact
            path="/webgula/seja-nosso-parceiro"
            component={SejaNossoParceiroPage}
          />
          <Route
            exact
            path="/shopping/:permalink"
            component={GaleriaPage}
          />
          <Route
            exact
            path="/"
            component={RestaurantesPage}
          />
        </Switch>
      </LayoutWebGula>
  );
}

export default WebgulaModule;