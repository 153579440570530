import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import useGetDetalhesReservaCriada from "../../Reserva/hooks/useGetDetalhesReservaCriada";
import { formatMoney } from "infra/utils";
import { Center, CircleStep, InfoBlock, StatusText, Text } from "../../Reserva/components/ReservaCriada";
import { CenteredDiv, StyledH2, StyledH3, StyledH4 } from "../../Reserva/components/Resumo";
import moment from "moment";
import { Divider } from "../../QRCodePage/QRCode";
import { QRCodeSVG } from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Flex } from "../../Reserva/styles";
moment.locale('pt-br');

const ReservaInfo = ({ detalhes }) => (
  <div>
    <InfoBlock style={{ marginBottom: 0, marginTop: 8 }}>
      <StyledH4>Qtde pessoas:</StyledH4>
      <StyledH4>{detalhes.qtdePessoas}</StyledH4>
    </InfoBlock>
    {detalhes.flgTipoCobranca !== "N" && (
      <InfoBlock>
        <StyledH3>Valor total:</StyledH3>
        <StyledH2>{'R$ ' + formatMoney(detalhes.valorTotal)}</StyledH2>
      </InfoBlock>
    )}
  </div>
);

const ReservaStatus = ({ detalhes }) => (
  detalhes.flgTipoCobranca !== "N" && (
    <InfoBlock>
      <StyledH3>Status:</StyledH3>
      <StatusText flgStatusPagamento={detalhes?.flgStatusPagamento}>
        {detalhes?.flgStatusPagamento !== "A" ? "Pago" : "Aguardando pagamento"}
      </StatusText>
    </InfoBlock>
  )
);

const PixPayment = ({ detalhes, handleCopy, isCopied }) => (
  detalhes.flgTipoCobranca !== "N" && detalhes.flgStatus !== "C" && detalhes.flgStatusPagamento === "A" && (
    <div>
      <span style={{ textAlign: "center" }}>
        Você pode Copiar a Chave e Colar em seu Aplicativo Bancário com PIX ou
        escanear o QrCode abaixo
      </span>
      <div style={{ display: "flex", justifyContent: "center", margin: "8px 0" }}>
        <QRCodeSVG size={200} value={detalhes?.pagamentoInfo?.pixQrCodeData} />
      </div>
      <br />
      <div style={{ textAlign: "center", marginBottom: 16 }}>
        <CopyToClipboard text={detalhes?.pagamentoInfo?.pixQrCodeData} onCopy={handleCopy}>
          <Button color="primary" variant="contained" style={{ borderRadius: 12 }}>
            {isCopied ? "Copiado =)" : "Copiar PIX"}
          </Button>
        </CopyToClipboard>
      </div>
      <Text>Pagar com Pix é seguro, fácil e rápido!</Text>
      <Center>
        <div>
          <Flex><CircleStep>1</CircleStep><Text>Abra o aplicativo do seu banco e clique em Pix.</Text></Flex>
          <Flex><CircleStep>2</CircleStep><Text>Clique em Pix e escolha a opção Pagar por QR Code.</Text></Flex>
          <Flex><CircleStep>3</CircleStep><Text>Aponte a câmera do celular para o QR Code que será exibido após a finalização da compra.</Text></Flex>
          <Flex><CircleStep>4</CircleStep><Text>Pronto, agora é só confirmar os dados e finalizar o pagamento com o Pix.</Text></Flex>
        </div>
      </Center>
    </div>
  )
);

function ModalDetalhes({ id, open, onClose }) {
  const { data: detalhes, isLoading } = useGetDetalhesReservaCriada(id);
  const [isCopied, setIsCopied] = React.useState(false);
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };
  if (!detalhes) return null;
  return (
    <Modal isOpen={open}>
      <ModalHeader>Detalhes da Reserva #{id}</ModalHeader>
      <ModalBody>
        {isLoading ? <Spinner /> : (
          <>
            <CenteredDiv>
              <div style={{ textAlign: 'center', marginTop: 16 }}>
                <StyledH3>Reserva nº {detalhes.id}</StyledH3>
                <StyledH2 style={{ color: '#3A7DC5' }}>{moment(detalhes.dataMovimento).format("DD MMMM YYYY")}</StyledH2>
              </div>
              <ReservaInfo detalhes={detalhes} />
              <Divider />
              <ReservaStatus detalhes={detalhes} />
              <Divider />
              <PixPayment detalhes={detalhes} handleCopy={handleCopy} isCopied={isCopied} />
            </CenteredDiv>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>Fechar</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalDetalhes;