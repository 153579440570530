import React, { useCallback, useState } from "react";
import styled from "styled-components";
import axios from "infra/axios";
import { BASE_URL_API } from "infra/config";
import { showError } from "infra/alerta";

import { Body } from "./styles";
import { Container } from "reactstrap";
import Appbar from "../../components/Appbar";
import Footer from "../../components/Footer";
import ScrollTop from "../../components/ScrollTop";
import HeaderEmpresa from "../components/HeaderEmpresa";
import EscolheData from "./components/EscolheData";
import Resumo from "./components/Resumo";
import { ReservaCriada } from "./components/ReservaCriada";
import AuthClient from "infra/auth/AuthClient";
import Swal from "sweetalert2";
import { useLayoutContext } from "contexts/layout";

const INITIAL_RESERVA_STATE = {
  movimentoId: null,
  horaPrevista: {
    ticks: "12:00"
  },
  qtdePessoas: 0,
  valorTotal: 0,
  observacao: "",
};

const Reserva = () => (
  <Body>
    <Appbar />
    <HeaderEmpresa showCarrinho={false} hideDetails={true} />
    <Container>
      <ReservaPage />
    </Container>
    <Footer />
    <ScrollTop />
  </Body>
);

const ReservaPage = () => {
  const [step, setStep] = useState(0);
  const [resultadoReserva, setResultadoReserva] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reserva, setReserva] = useState(INITIAL_RESERVA_STATE);
  const {update: updateContext} = useLayoutContext()
  
  const goToStep2 = async (id) => {
    const isUserLogged = AuthClient.isUserLogged();
    if (!isUserLogged) {
      return Swal.fire({
        title: "Aviso",
        allowOutsideClick: false,
        text: "Você precisa fazer login antes de continuar.",
        icon: "warning",
        confirmButtonText: "Fazer login",
      }).then((result) => {
        if (result.value) {
          updateContext({ openLogin: true });
        }
      });
    }else{
      setReserva({ ...reserva, movimentoId: id });
      setStep(1);
    }
  }

  const solicitarReserva = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await axios.post(
        `${BASE_URL_API}/v3/reserva-mesa/criar`,
        {
          ...reserva,
          horaPrevista: reserva.horaPrevista.ticks + ":00",
        }
      );

      setResultadoReserva(data);
    setStep(2);
    } catch (error) {
      const details = error.response?.data.details;

      if (details && details.length > 0) {
        details.forEach(detail => {
          if (detail.errors && detail.errors.length > 0) {
            detail.errors.forEach(showError);
          }
        });
      } else {
        showError(error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [reserva]);

  const goToStep3 = () => solicitarReserva();

  const timeLineData = [
    { text: "DATA" },
    { text: "RESUMO" },
    { text: "RESERVA" },
  ];

  return (
    <>
      <Timeline timelineData={timeLineData} activeIndex={step} />
      {step === 0 && <EscolheData goToStep2={goToStep2} />}
      {step === 1 && <Resumo selectedDate={reserva.movimentoId} reserva={reserva} setReserva={setReserva} goToStep3={goToStep3} isLoadingCriarReserva={isLoading} />}
      {step === 2 && <ReservaCriada resultado={resultadoReserva} />}
    </>
  );
}

const Timeline = ({ timelineData, activeIndex }) => (
  <TimelineContainer>
    {timelineData.map((data, idx) => (
      <TimelineItemComponent key={idx} data={data} isActive={activeIndex === idx} />
    ))}
  </TimelineContainer>
);

const TimelineItemComponent = ({ data, isActive }) => (
  <TimelineItem>
    <Circle isActive={isActive} />
    <Text isActive={isActive}>{data.text}</Text>
  </TimelineItem>
);

// Styled components
const TimelineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 20px 0;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    height: 2px;
    background: gray;
  }
`;

const TimelineItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;

const Circle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${props => props.isActive ? 'red' : 'gray'};
  background: ${props => props.isActive ? 'red' : '#fff'};
  margin-bottom: 10px;
`;

const Text = styled.p`
  color: ${props => props.isActive ? 'red' : 'gray'};
`;

export default Reserva;