import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonContainer } from '../../../DeliveryPontoEntrega/styles';
import { Button } from "@material-ui/core";
import useGetDatasReserva from '../../hooks/useGetDatasReserva';

const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 768px;
  }
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
`;
const Day = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: ${props => props.selected ? '2px solid red' : '1px solid #ccc'};
  cursor: pointer;
  font-size: 0.8rem;
  color: ${props => props.disabled ? '#ccc' : props.selected ? 'red' : '#000'};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  @media (min-width: 768px) {
    width: 60px;
    height: 60px;
    font-size: 1rem;
  }
`;
const Weekdays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin-bottom: 10px;
`;
const Weekday = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.8rem;

  @media (min-width: 768px) {
    width: 60px;
    height: 60px;
    font-size: 1rem;
  }
`;
const MonthNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export default function EscolheData({ goToStep2}) {
  const { data, isLoading } = useGetDatasReserva();
  const [selectedId, setSelectedId] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [enabledDays, setEnabledDays] = useState([]);

  const [selectedData, setSelectedData] = useState(null);


  useEffect(() => {
    const newEnabledDays = data
      .filter(d => new Date(d.dataMovimento).getMonth() === currentMonth && new Date(d.dataMovimento).getFullYear() === currentYear)
      .map(d => new Date(d.dataMovimento).getDate());
    setEnabledDays(newEnabledDays);
    setSelectedId(null);
    setSelectedData(null);

  }, [currentMonth, currentYear, data]);

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const handleDayClick = day => {
    const selectedData = data.find(d => new Date(d.dataMovimento).getDate() === day);
    if (selectedData) {
      setSelectedId(selectedData.id);
      setSelectedData(selectedData);
    }
  };

  const handleMonthChange = (direction) => {
    if (direction === 'previous') {
      setCurrentMonth(currentMonth - 1);
      if (currentMonth - 1 < 0) {
        setCurrentMonth(11);
        setCurrentYear(currentYear - 1);
      }
    } else {
      setCurrentMonth(currentMonth + 1);
      if (currentMonth + 1 > 11) {
        setCurrentMonth(0);
        setCurrentYear(currentYear + 1);
      }
    }
  };
 if (isLoading) return <div>Carregando...</div>;
  return (
    <>
      <CalendarWrapper>
        <h1
        style={{
          color: 'gray',
          marginBottom: 24
        }}
        >Escolha a data</h1>
        <MonthNavigation>
          <button onClick={() => handleMonthChange('previous')}>{"<"}</button>
          <div>{new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })} {currentYear}</div>
          <button onClick={() => handleMonthChange('next')}>{">"}</button>
        </MonthNavigation>
        <Weekdays>
        <Weekday>D</Weekday>
        <Weekday>S</Weekday>
        <Weekday>T</Weekday>
        <Weekday>Q</Weekday>
        <Weekday>Q</Weekday>
        <Weekday>S</Weekday>
        <Weekday>S</Weekday>
      </Weekdays>
        <CalendarGrid>
          {days.map(day => {
            const dayData = data.find(d => new Date(d.dataMovimento).getDate() === day);
            return (
              <Day key={day} onClick={() => handleDayClick(day)} disabled={!enabledDays.includes(day)} selected={selectedId === dayData?.id}>
                {day}
              </Day>
            );
          })}
        </CalendarGrid>
      {selectedData && (
        <div 
        style={{
          marginTop: 20,
          padding: 10,
          border: '1px solid #ccc',
          borderRadius: 5
        }}>
          Data selecionada: {new Date(selectedData.dataMovimento).toLocaleDateString()} 
        </div>
      )}
      </CalendarWrapper>
      <ButtonContainer>
        <Button
         style={{
          height: 50,
        }}
          variant="contained"
          disabled={!selectedId}
          onClick={
            () => {
              goToStep2(selectedId)
            }
          }
          color="primary"
        >
          AVANÇAR
        </Button>
      </ButtonContainer>
    </>
  );
}