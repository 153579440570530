import React, { useContext } from "react";
import { Row, Col, Container } from "reactstrap";

import SkeletonItem from "./SkeletonItem";
import CardapioItem from "./CardapioItem";

import { LayoutContext } from "contexts/layout";

import { ContainerCardapio } from "./styles";
import useTopCardapio from "../../../hooks/useTopCardapio";
import { Button } from "@material-ui/core";
import { navigate } from "infra/router/history";

function Cardapios() {
  const { data, isLoading } = useTopCardapio();

  const { layoutData }: any = useContext(LayoutContext);

  return (
    <Container>
      <ContainerCardapio>
        <div style={{ marginBottom: 64 }}>
          <h3>Opções de Cardápio</h3>
        </div>

        <div>
          {isLoading ? (
            <Row>
              {[0, 1, 2, 3].map(() => (
                <Col md={3}>
                  <SkeletonItem />
                </Col>
              ))}
            </Row>
          ) : null}

          <Row>
            {data.map((produto, index) => (
              <Col md={4} sm={6} xs={12}>
                <CardapioItem
                  item={produto}
                  key={index}
                  primaryColor={layoutData.primaryColor}
                />
              </Col>
            ))}
          </Row>

          <div style={{marginTop: 32, marginBottom: 32, textAlign: 'center'}}>
            <Button
              onClick={() => navigate("eventos/carrinho")}
              color="primary"
              variant="contained"
            >
              Montar
            </Button>
          </div>
        </div>
      </ContainerCardapio>
    </Container>
  );
}

export default Cardapios;
