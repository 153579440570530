import React, { useContext } from "react";
import Badge from "@material-ui/core/Badge";

import AuthClient from "infra/auth/AuthClient";
import { LayoutContext } from "contexts/layout";
import { getAllCart } from "services/cart";

import { LinkText, CarrinhoBotaoMobile } from "./styles";
import useCookies from "infra/hooks/useCookies";

function CarrinhoMobile() {
  const { layoutData } = useContext(LayoutContext);
  const { getCookie } = useCookies();
  const isUserLogged = AuthClient.isUserLogged();
  const isPedidoOff =  !isUserLogged && !!getCookie("idPedidoOff") && !!getCookie("chavePedidoOff") && layoutData?.wgPedidoDeliveryOFF;
  if (!isUserLogged && !layoutData.flgMenuPedidoSimplesOk && !isPedidoOff) {
    return null;
  }

  return (
    <CarrinhoBotaoMobile>
      <LinkText
        style={{ borderRadius: 6 }}
        href={`/${layoutData.tenant}/carrinho`}
      >
        <Badge
          overlap="circle"
          badgeContent={getAllCart().length}
          color="secondary"
        >
          <i style={{ fontSize: "1.5em" }} className="fa fa-shopping-cart" />
        </Badge>
        <span style={{ paddingLeft: 16 }}> Carrinho </span>
      </LinkText>
    </CarrinhoBotaoMobile>
  );
}

export default CarrinhoMobile;
