import { createBrowserHistory } from "history";
import AuthClient from "../auth/AuthClient";

const history = createBrowserHistory({});

export function navigate(path, reload) {
  const empresa = AuthClient.getEmpresa();

  if (reload) {
    return (window.location.href = `/${empresa.tenant}/${path}`);
  }

  return history.push(`/${empresa.tenant}/${path}`);
}

export default history;
