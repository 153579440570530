import { useState, useEffect } from "react";
import { Reserva, StatusReserva } from "../types";

export default function useReservas() {
  const [items, setItems] = useState<Reserva[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      setTimeout(() => {
        setItems([
          {
            id: 1016,
            data: "2020-12-03",
            horario: "10h ás 13h",
            preco: 1240.5,
            status: StatusReserva.AguardandoAprovacao,
          },
          {
            id: 1014,
            data: "2020-12-10",
            horario: "10h ás 13h",
            preco: 1240.5,
            status: StatusReserva.Finalizada,
          },
          {
            id: 1011,
            data: "2020-12-27",
            horario: "10h ás 13h",
            preco: 1240.5,
            status: StatusReserva.Cancelada,
          },
        ]);

        setIsLoading(false);
      }, 2000);
    })();
  }, []);

  return {
    items,
    isLoading,
  };
}
