import React from 'react';
import styled from 'styled-components';
import { removeCart } from 'services/cart';
import { formatMoney } from 'infra/utils';
import Swal from 'sweetalert2'

const ProdutoItemCard = styled.div`
  border-bottom: 1px solid #eee;
  margin-bottom: 16px;
  padding: 8px 0;
  overflow: hidden;
`;

const ButtonFlat = styled.button` 
  background: none;
  padding: 8px 0;
  font-size: 12px;
  font-weight: bold;
  margin-right: 16px;
  border:none;
  outline: none;
  color: ${props => props.color || '#888'};

  &:hover {text-decoration:underline;}
  &:focus {outline:0;}
`;


function ProdutoItem({ produto, layoutData, forceUpdate, onEdit }) {

  const variacoesAdicionadas = [];

  produto.variacoesUsadas.forEach((variacao) => {

    variacao.items.forEach((item) => {
      if (item.quantidade) {
        variacoesAdicionadas.push({ quantidade: item.quantidade, nome: item.nomeCompleto })
      }
    })
  })

  return (
    <ProdutoItemCard>
      <div style={{ display: 'flex' }}>
        <p style={{ flex: 1 }}> {produto.quantidade}x {produto.nomeCompleto} </p>
        <strong>
          R$ {formatMoney(produto.valorTotal)}
        </strong>
      </div>
      <p style={{ fontSize: 12, color: '#555' }}>{variacoesAdicionadas.map(item => `${item.quantidade} x ${item.nome}`).join(', ')}</p>
      <p style={{ fontSize: 12, marginBottom: 0 }}> Obs.: {produto.observacao || 'Sem'}</p>

      <div>
        <ButtonFlat onClick={() => onEdit(produto)} color={layoutData.primaryColor}>
          Editar
        </ButtonFlat>
        <ButtonFlat onClick={() => {
          Swal.fire({
            title: 'Aviso',
            text: 'Deseja realmente excluir este item do carrinho?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Voltar'
          }).then((result) => {
            if (result.value) {

              removeCart(produto.uuid);
              forceUpdate();
            }
          });
        }}>
          Remover
        </ButtonFlat>
      </div>
    </ProdutoItemCard>
  )
}

export default ProdutoItem;