/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from 'reactstrap';
import Text from '../../../../../components/Text';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { useEffect } from 'react';
import Axios from 'axios';
import { BASE_URL_API } from 'infra/config';
import { CircularProgress } from '@material-ui/core';

const Wrapper = styled.div`
`

function PerguntasFrequentes() {

  const [openPergunta, setOpenPergunta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {

    setIsLoading(true);

    Axios.get(`${BASE_URL_API}/v1/api/dadoswgsite/perguntas-empresa`)
      .then((res) => {
        setItems(res.data);
      })
      .catch((err) => {

      })
      .then(() => {
        setIsLoading(false);
      })

  }, []);
 
  return (
    <Wrapper>

      <Container>

        <div style={{ width: '100%', marginTop: 32, marginBottom: 32, display: 'flex', justifyContent: 'flex-start' }}>
          <Text size={32} variant="fancy-title">Perguntas Frequentes</Text>
        </div>

        {isLoading ? <div> <CircularProgress /> </div> : null}

        <Row style={{ marginBottom: 32 }}>
          <Col sm={12}>

            <List component="div" disablePadding>
              {items.map((item, k) => (
                <div style={{ backgroundColor: 'white', padding: 16, marginBottom: 32 }}>
                  <ListItem onClick={() => setOpenPergunta(openPergunta === k ? null : k)} key={k} button>
                    <ListItemText primary={<h5 style={{ fontWeight: 'bold', margin: 0, padding: 0 }}>{item.pergunta}</h5>} />
                    {openPergunta === k ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <div>
                    <Collapse in={openPergunta === k} timeout="auto" unmountOnExit>
                      <p style={{ padding: 16 }}>
                        {item.resposta}
                      </p>
                    </Collapse>
                  </div>
                </div>
              ))}
            </List>

          </Col>

        </Row>

      </Container>
    </Wrapper>
  )
}

export default PerguntasFrequentes;