import { formatMoney } from "infra/utils";
import React from "react";
import { Form, FormGroup, Label } from "reactstrap";
import CurrencyInputComponent from "ui/components/Inputs/CurrencyInput";

function FormPagamento({ value, onChange, numIdentificador, valorRestante }) {
  return (
    <Form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <div># {numIdentificador && numIdentificador}</div>
          <div style={{ color: "#222" }}>
            Valor Restante: R$ {formatMoney(valorRestante)}
          </div>
        </div>
        <FormGroup>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Label>Valor que deseja pagar</Label>
            <CurrencyInputComponent onChange={onChange} value={value} />
          </div>
        </FormGroup>
      </div>
    </Form>
  );
}

export default FormPagamento;
