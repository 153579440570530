import React, { useState, useCallback } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from "reactstrap";
import AuthClient from "infra/auth/AuthClient";

function ModalEsqueciSenha({ open, onToggle, onSaved }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({});

  const handleRecuperarSenha = useCallback(async () => {

    if (!form.email) {
      setError('Informe o email!');
      return
    }

    setError('');
    setIsLoading(true);

    try {

      const result = await AuthClient.recuperarSenha(form.email);

      if (result) {
        alert(`Foi enviado um email para o email ${form.email} com a informação de recuperação de senha!`);
        onSaved();
      } else {
        setError("Não foi possível recuperar a senha do usuário!");
      }

    } catch (e) {

      let errorText = "";

      if (typeof e === "object" && e.response) {
        for (const prop in e.response.data) {
          errorText = e.response.data[prop][0];
        }
      }

      setError(errorText);
    } finally {
      setIsLoading(false);
    }
  }, [form.email, onSaved]);

  const handleChange = useCallback(
    (prop, value) => {
      setForm({
        ...form,
        [prop]: value
      });
    },
    [form]
  );

  return (
    <Modal isOpen={open} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>Fazer Cadastro</ModalHeader>
      <ModalBody>
        <Form>

          <FormGroup>
            <Label for="exampleEmail">Email</Label>
            <Input
              type="email"
              value={form.email}
              required
              onChange={ev => handleChange("email", ev.target.value)}
              placeholder="Email"
            />
          </FormGroup>

        </Form>

        {error && (
          <Alert color="danger">
            {error || "Houve um problema ao recuperar a senha do usuário!"}
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onToggle}>
          Cancelar
        </Button>
        <Button
          disabled={isLoading}
          color="primary"
          onClick={() => handleRecuperarSenha()}
        >
          {isLoading ? 'Enviando...' : 'Recuperar'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalEsqueciSenha;
