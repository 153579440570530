import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import moment from "moment";
import AuthClient from "infra/auth/AuthClient";
import { useRef } from "react";

function ModalPerfil({ open, onToggle, onSaved }) {
  const refImage = useRef();

  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [form, setForm] = useState({});

  useEffect(() => {
    (async () => {
      const data = AuthClient.getData();

      if (!data) return;

      setForm({
        email: data.email,
        nome: data.nome,
        cpf: data.cpf,
        sexo: data.sexo,
        imagemUrl: data.imagemUrl,
        dataNascimento: data.dataNascimento
          ? moment(data.dataNascimento).format("YYYY-MM-DD")
          : null,
      });

      setImage(null);
    })();
  }, []);

  const handleAtualizar = useCallback(async () => {
    setIsLoading(true);

    try {
      const model = {
        nome: form.nome,
        cpf: form.cpf,
        sexo: form.sexo,
        dataNascimento: form.dataNascimento,
      };

      if (image) {
        const imagemUrl = await AuthClient.uploadImage(image.file);

        model.imagemUrl = imagemUrl;
      }

      await AuthClient.atualizarUsuario(model);

      onSaved();
    } catch (e) {
      setIsOpenToast(true);

      let errorText = "Houve um problema";

      if (typeof e === "object" && e.response && e.response.data) {
        for (const prop in e.response.data) {
          errorText = e.response.data[prop][0];
        }
      }

      setError(errorText);
    } finally {
      setIsLoading(false);
    }
  }, [form, onSaved, image]);

  const handleChange = useCallback(
    (prop, value) => {
      setForm({
        ...form,
        [prop]: value,
      });
    },
    [form]
  );

  const handleImageChange = useCallback((e) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      setImage({
        file,
        preview: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }, []);

  return (
    <div>
      <Modal isOpen={openPreview} size="lg">
        <ModalHeader toggle={() => setOpenPreview(false)}>Imagem</ModalHeader>

        <ModalBody style={{ textAlign: "center" }}>
          <img
            src={image?.preview || form.imagemUrl}
            alt="perfil"
            style={{ maxWidth: "100%" }}
          />
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={() => setOpenPreview(false)}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={open} toggle={onToggle}>
        <ModalHeader toggle={onToggle}></ModalHeader>
        <ModalBody>
          <h4 style={{ textAlign: "center" }}>Perfil</h4>

          <input
            accept="image/*"
            ref={refImage}
            type="file"
            onChange={handleImageChange}
            style={{ zIndex: -11, position: "absolute" }}
          />

          <Form>
            <FormGroup style={{ marginBottom: 32, paddingTop: 16 }}>
              <div
                style={{
                  display: "table",
                  margin: "auto",
                  border: "1px solid #ccc",
                  textAlign: "center",
                  width: 100,
                  height: 100,
                  borderRadius: 50,
                  overflow: "hidden",
                  backgroundColor: "#f7f7f7",
                }}
              >
                {!form.imagemUrl && !image ? (
                  <i
                    className="mdi mdi-image"
                    style={{ fontSize: 24, marginTop: 16 }}
                  />
                ) : (
                  <img
                    onClick={() => setOpenPreview(true)}
                    src={image?.preview || form.imagemUrl}
                    alt={form.nome}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 50,
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>

              <div style={{ textAlign: "center", paddingTop: 8 }}>
                <Button size="sm" onClick={() => refImage.current.click()}>
                  Mudar Imagem
                </Button>
              </div>
            </FormGroup>

            <FormGroup>
              <Label for="exampleNome">Nome</Label>
              <Input
                type="text"
                value={form.nome}
                required
                onChange={(ev) => handleChange("nome", ev.target.value)}
                placeholder="Nome"
              />
            </FormGroup>
            <FormGroup>
              <Label>CPF</Label>
              <Input
                type="cpf"
                value={form.cpf}
                required
                onChange={(ev) => handleChange("cpf", ev.target.value)}
                placeholder="CPF"
              />
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                value={form.email}
                required
                disabled
                onChange={(ev) => handleChange("email", ev.target.value)}
                placeholder="Email"
              />
            </FormGroup>
            <FormGroup>
              <Label>Data de Nascimento</Label>
              <Input
                type="date"
                required
                name="dataNascimento"
                value={form.dataNascimento}
                onChange={(ev) =>
                  handleChange("dataNascimento", ev.target.value)
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Sexo</Label>
              <Input
                type="select"
                value={form.sexo}
                onChange={(ev) => handleChange("sexo", ev.target.value)}
              >
                <option value="">--selecione--</option>
                <option value="m">Masculino</option>
                <option value="f">Feminino</option>
                <option value="o">Outros</option>
              </Input>
            </FormGroup>
          </Form>

          {isOpenToast && (
            <Alert color="danger">
              {error || "Houve um problema ao atualizar o usuário!"}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onToggle}>
            Cancelar
          </Button>
          <Button
            disabled={isLoading}
            color="primary"
            onClick={() => handleAtualizar()}
          >
            Salvar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalPerfil;
