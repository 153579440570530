import React from "react";
import { Row, Col, Media } from "reactstrap";
import { Button } from "@material-ui/core";

import { Ambiente } from "../../../types";
import { AmbienteItemContainer } from "./styles";

interface AmbienteItemProps {
  item: Ambiente;
  onClick: any;
}

function AmbienteItem({ item, onClick }: AmbienteItemProps) {
  return (
    <AmbienteItemContainer>
      <Row>
        <Col md={5}>
          <img src={item.imagemUrl} alt={item.nome} />
        </Col>

        <Col md={7}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Media heading>{item.nome}</Media>

              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <Row>
                  <Col>
                    <span>{item.descricao}</span>
                  </Col>
                </Row>

                <br />

                <Row>
                  <Col>
                    <strong>CAPACIDADE MÁXIMA</strong>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <span>Até {item.qtdePessoas} pessoas</span>
                  </Col>
                </Row>
              </div>

              <div style={{ display: "flex" }}>
                <Button onClick={onClick} color="primary" variant="outlined">
                  Montar
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </AmbienteItemContainer>
  );
}

export default AmbienteItem;
