import React, { useContext, useCallback, useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Button, createMuiTheme, TextField, ThemeProvider } from "@material-ui/core";

import FormaPagamento from "./tabs/FormaPagamento";
import ReactPixel from "react-facebook-pixel";

import { LayoutContext } from "contexts/layout";
import {
  getObservacao,
  setObservacao,
  getOrigem,
  getChaveComanda,
  getNome,
  getSaida,
  setSaida,
  setNome,
  setChaveComanda
} from "services/cart";
import useFinalizarPedido from "actions/pedido/useFinalizarPedido";

import UserAddress from "../../components/UserAddress";
import ListaProdutos from "../../components/CarrinhoCard/ListaProdutos";
import ProdutoTotais from "../../components/CarrinhoCard/ProdutoTotais";
import { navigate } from "infra/router/history";
import useCookies from "infra/hooks/useCookies";
import AuthClient from "infra/auth/AuthClient";
import axios from "axios";
import { BASE_URL_API } from "infra/config";

const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        "&.Mui-disabled": {
          color: "black",
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&.Mui-disabled": {
          color: "black",
        },
      },
    },
  },
});

function Conteudo() {
  const { isLoading, produtos, finalizar } = useFinalizarPedido();
  const { layoutData, update: updateContext } = useContext(LayoutContext);
  const { getCookie } = useCookies();

  const isUserLogged = AuthClient.isUserLogged();
  const isPedidoOff = !isUserLogged && !!getCookie("idPedidoOff") && !!getCookie("chavePedidoOff") && layoutData?.wgPedidoDeliveryOFF;
  const [, setIsLoading] = useState(true);
  const userData = AuthClient.getData();
  const forceUpdate = useCallback(() => updateContext(), [updateContext]);
  const pontoEntrega = JSON.parse(localStorage.getItem("pontoEntrega"));

  const origem = getOrigem();
  const saidaId = getSaida()
  const [formMenu, setFormMenu] = useState({ nome: "", chave: "", });
  console.log(origem, saidaId, isUserLogged, layoutData)
  const isPedidoAcompanhamentoConta = origem === 'menu' && !!saidaId && isUserLogged && layoutData.opcoesMenu.pedidoComandaByAccConta
  const isPedidoComandaDeslogado = origem === 'menu' && !isUserLogged && layoutData.opcoesMenu.pedidoComandaDeslogado
  const isPedidoDireto = origem === 'menu' && !saidaId && isUserLogged && layoutData.opcoesMenu.pedidoDiretoAtivo

  useEffect(() => {
    if (!layoutData.flgPedidoPontoEntrega && pontoEntrega !== null) {
      localStorage.removeItem("pontoEntrega")
    }
  }, [layoutData.flgPedidoPontoEntrega, pontoEntrega])
  useEffect(() => {
    const chaveCns = localStorage.getItem("chaveCns");
    const conta = localStorage.getItem("conta");

    if (chaveCns) {
      onChangeFormMenu("chave", chaveCns);
    }

    if (conta) {
      onChangeFormMenu("saidaId", conta);
    }
  }, []);
  const onChangeFormMenu = (prop, value) => {
    setFormMenu((prev) => ({
      ...prev,
      [prop]: value,
    }));

    if (prop === "nome") {
      setNome(value);
    }

    if (prop === "chave") {
      setChaveComanda(value);
    }
    if (prop === "saidaId") {
      setSaida(value);
    }
  };

  useEffect(() => {
    async function hasConta() {
      if (isUserLogged) {
        try {
          setIsLoading(true);
          const { data } = await axios.get(
            `${BASE_URL_API}/v1/acompanhamento-conta/pedidomenu-conta-atual`
          );

          if (data) {
            updateContext({ ...layoutData, contaAtualMenuDto: data });
          }
        } catch (err) {
        } finally {
          setIsLoading(false);
        }
      }
    }
    hasConta();
    // eslint-disable-next-line
  }, [isUserLogged]);

  useEffect(() => {
    if (isUserLogged) {
      const value = {
        nome: getNome() || userData?.nome,
        chave: getChaveComanda() || layoutData?.contaAtualMenuDto?.chaveMobile,
      };

      setFormMenu(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutData]);


  if (layoutData.codigoPixelFacebookCart) {
    ReactPixel.init(layoutData.codigoPixelFacebookCart);
    ReactPixel.pageView();
  }

  if (!produtos.length) {
    return (
      <div
        style={{
          fontSize: "70px",
          color: "#CCCCCC",
          textAlign: "center",
          marginTop: 48,
          marginBottom: 48,
        }}
      >
        <div className="mdi mdi-cart-outline" />

        <span style={{ fontSize: "28px" }}>Nenhum produto no carrinho</span>
      </div>
    );
  }

  return (
    <Container style={{ marginBottom: 32 }}>
      <Row>
        <Col md={9}>
          <div>
            {!isPedidoAcompanhamentoConta && !isPedidoComandaDeslogado && (
              <UserAddress horizontal forceUpdate={forceUpdate} isPedidoSimples={
                isPedidoDireto
              } />
            )}

          </div>

          {origem === "delivery" ? (
            <div style={{ marginTop: 40, marginBottom: 40 }}>
              Prazo médio de entrega: {layoutData.tempoMedioEntregaNome}
            </div>
          ) : null}


          <Button
            variant="outlined"
            color="primary"
            style={{ marginTop: 16 }}
            onClick={() => {
              if (origem === "delivery") {
                navigate("cardapio");
              } else {
                navigate("menu");
              }
            }}
          >
            Adicionar mais produtos
          </Button>

          {window.innerWidth <= 768 ? (
            <div style={{ marginTop: 40, marginBottom: 40 }}>
              <h4>Produtos</h4>
              <ListaProdutos items={produtos} />

              <ProdutoTotais />
            </div>
          ) : null}

          {!isPedidoAcompanhamentoConta && !isPedidoComandaDeslogado && (
            <FormaPagamento
              forceUpdate={forceUpdate}
              isPedidoSimples={
                isPedidoDireto
              }
              isPedidoOff={isPedidoOff}
            />
          )}


          {origem === "delivery" || isPedidoDireto ? (

            <div style={{ marginTop: 32 }}>
              <TextField
                fullWidth
                variant="outlined"
                value={getObservacao()}
                onChange={({ target: { value } }) => setObservacao(value)}
                multiline
                rows={3}
                label="Alguma observação?"
                placeholder={"Observação final do pedido"}
              />
            </div>
          ) : null}

          {isPedidoAcompanhamentoConta || isPedidoComandaDeslogado ? (
            <ThemeProvider theme={theme}>
              <div style={{ marginTop: 32 }}>
                {isPedidoComandaDeslogado && (
                  <>

                    <TextField
                      style={{
                        marginTop: 16,
                        color: '#000',
                        background: "white",
                        width: "100%",
                      }}
                      value={formMenu.saidaId}
                      defaultValue={localStorage.getItem("conta")}
                      size={"medium"}
                      onChange={(ev) => onChangeFormMenu("saidaId", ev.target.value)}
                      fullWidth
                      placeholder="Informe o Codigo da Conta"
                      variant="outlined"
                      label="Código da conta  (Solicite o código da conta ao atendente)"
                    />
                    <br />
                    <br />
                  </>
                )}



                <TextField
                  style={{
                    marginTop: 16,
                    color: '#000',
                    background: "white",
                    width: "100%",
                  }}
                  disabled={isPedidoAcompanhamentoConta}
                  defaultValue={ isPedidoComandaDeslogado 
                    ?  localStorage.getItem("chaveCns") : formMenu.chave
                  }
                  value={formMenu.chave}
                  size={"medium"}
                  onChange={(ev) => onChangeFormMenu("chave", ev.target.value)}
                  fullWidth
                  placeholder={isPedidoComandaDeslogado ? "Informe a Chave de Segurança da Conta" : "Solicite a chave ao atendente"}
                  variant="outlined"
                  label={`Chave da comanda  ${isPedidoComandaDeslogado ? "(Solicite a chave ao atendente)" : ""}`}
                />

                <br />
                <br />

                <TextField
                  style={{
                    marginTop: 16,

                    background: "white",
                    color: '#000',
                    width: "100%",
                  }}

                  disabled={isPedidoAcompanhamentoConta}
                  value={formMenu.nome}
                  size={"medium"}
                  onChange={(ev) => onChangeFormMenu("nome", ev.target.value)}
                  fullWidth
                  placeholder="Seu Nome"
                  variant="outlined"
                  label="Seu Nome"

                />

                <div style={{ marginTop: 16, marginBottom: 16, width: '100%', display: 'flex', justifyContent: 'center', color: 'gray' }}>
                  <b style={{ textAlign: "center", marginTop: 16, marginBottom: 16, width: '100%' }}>Esse pedido será vinculado a sua comanda e e conforme as regras da casa poderá ser calculado com a taxa de serviço</b>
                </div>


              </div>
            </ThemeProvider>

          ) : null}

          <div style={{ marginTop: 32 }}>
            <Button
              onClick={() => finalizar()}
              disabled={isLoading}
              variant="contained"
              fullWidth
              style={{
                backgroundColor: isLoading ? "#ccc" : layoutData.primaryColor,
                color: "white",
              }}
            >
              {isLoading ? "Finalizando..." : "Finalizar Pedido"}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Conteudo;
