import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import {
  Container,
  Row,
  Col,
  Spinner,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Badge,
} from "reactstrap";
import axios from "axios";
import OwlCarousel from "react-owl-carousel2";
import { LayoutContext } from "contexts/layout";
import { BASE_URL_API } from "infra/config";

const InfosSemanalRoot = styled.div`
  width: 100%;
  clear: both;
  padding-top: 150px;
  padding-bottom: 180px;
  position: relative;
`;

const H1 = styled.h1`
  color: ${(props) => props.color};
  font-size: 40px;
  text-align: center;
`;

function InfosSemanal() {
  const [items, setItems] = useState();
  const { layoutData } = useContext(LayoutContext);

  if (items !== undefined && items.length === 0) {
    return null;
  }

  return (
    <InfosSemanalRoot id="promocao-evento">
      <Container>
        <Row>
          <Col md="12">
            <H1 color={layoutData.primaryColor}>Informações da Semana</H1>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Slides
              empresaGlobalId={layoutData.id}
              onFinishLoad={(v) => setItems(v)}
            />
          </Col>
        </Row>
      </Container>
    </InfosSemanalRoot>
  );
}

function Slides({ empresaGlobalId, onFinishLoad }) {
  const [infos, setInfos] = useState([]);
  const [loading, setLoading] = useState(false);

  const options = {
    loop: true,
    items: 4,
    nav: false,
    margin: 16,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data } = await axios.get(
        BASE_URL_API +
          "/v1/api/empresas-info-semanal/" +
          empresaGlobalId +
          "/1/lista"
      );

      const items = data.map((item) => {
        return {
          title: item.diaSemanaNome,
          caption: item.texto,
          flgMusicaAoVivo: item.flgMusicaAoVivo,
          url: item.imagemUrl,
        };
      });

      if (typeof onFinishLoad === "function") {
        onFinishLoad(items);
      }

      setLoading(false);

      setInfos(items);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaGlobalId]);

  return (
    <div style={{ marginTop: 80 }}>
      {loading && (
        <Spinner
          style={{
            width: "3rem",
            display: "table",
            margin: "auto",
            height: "3rem",
          }}
          type="grow"
        />
      )}

      <OwlCarousel options={options}>
        {infos.map((info) => (
          <Card>
            <CardImg top width="100%" src={info.url} alt={info.title} />
            <CardBody>
              <CardTitle style={{ fontWeight: "bold" }}>{info.title}</CardTitle>
              <CardSubtitle>
                {info.flgMusicaAoVivo ? (
                  <Badge color="primary">
                    <i className="fa fa-music" aria-hidden="true" /> Música
                    aovivo
                  </Badge>
                ) : null}
              </CardSubtitle>
              <CardText>{info.caption}</CardText>
            </CardBody>
          </Card>
        ))}
      </OwlCarousel>
    </div>
  );
}

export default InfosSemanal;
