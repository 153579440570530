function useCookies() {
  const getCookies = () => {
    const cookies = document.cookie.split(";");
    const cookieData = {};

    cookies.forEach((cookie) => {
      const [name, value] = cookie.split("=");
      const cookieName = name.trim();
      const cookieValue = decodeURIComponent(value.trim());
      cookieData[cookieName] = cookieValue;
    });

    return cookieData;
  };

  const getCookie = (name) => {
    const cookies = getCookies();
    return cookies[name];
  };

  const setCookie = (name, value, daysToExpire) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);

    const cookieValue =
      encodeURIComponent(value) +
      (daysToExpire ? `; expires=${expirationDate.toUTCString()}` : "");
    document.cookie = `${name}=${cookieValue}`;
  };

  return {
    getCookies,
    getCookie,
    setCookie,
  };
}

export default useCookies;
