/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from 'reactstrap';
import image1 from '../icons/image1.svg';
import image2 from '../icons/image2.png';
import image3 from '../icons/image3.png';
import image4 from '../icons/image4.png';
import Text from '../../../../../components/Text'; 

const Wrapper = styled.div` 
  margin-bottom: 32px;
  background: white;
`

const ContainerImage = styled.div`
  text-align: center;
  img {
    max-width: 100%;
  }
`

const ContainerText = styled.div`
  margin-bottom: 32px;
  clear: both;
  float: left;
`

const Title = styled.div`
  font-size: 44px;
  font-weight: bold;
`

function Corpo() {

  return (
    <Wrapper>
 
      <Container>

        <div style={{ width: '100%', paddingTop: 64, marginBottom: 64, display: 'flex', justifyContent: 'flex-start' }}>
          <Text size={32} variant="fancy-title">Vantagens</Text>
        </div>

        <Row style={{marginBottom: 50}}>
          <Col sm={6}>
            <ContainerImage>
              <img src={image1} />
            </ContainerImage>
          </Col>
          <Col sm={6} style={{alignItems: 'center', display: 'flex'}}>
            <ContainerText>
              <div>
                <Title>App exclusivo</Title>
              </div>
              <div style={{ clear: 'both', float: 'left', marginTop: 16 }}>
                <p>
                  Com essa opção podemos criar um aplicativo do WebGula personalizado para sua empresa, de forma que possa divulgar em suas redes sociais valorizando sua marca.
                </p>
              </div>
            </ContainerText>
          </Col>
        </Row>


        <Row style={{marginBottom: 50}}>
          <Col sm={6} style={{alignItems: 'center', display: 'flex'}}>
            <ContainerText>
              <div>
                <Title>Link exclusivo para sua Empresa</Title>
              </div>
              <div style={{ clear: 'both', float: 'left', marginTop: 16 }}>
                <p>
                  Tenha uma página personalizada para sua empresa com diversas informações, imagens e cardápio digital. Através do link compartilhado no WhatsApp seus clientes poderão realizar pedidos de delivery.
                </p>
              </div>
            </ContainerText>
          </Col>
          <Col sm={6}>
            <ContainerImage>
              <img src={image2} />
            </ContainerImage>
          </Col>
        </Row>

        <Row style={{marginBottom: 50}}>
          <Col sm={6}>
            <ContainerImage>
              <img src={image3} />
            </ContainerImage>
          </Col>
          <Col sm={6} style={{alignItems: 'center', display: 'flex'}}>
            <ContainerText>
              <div>
                <Title>Praça de Alimentação Digital</Title>
              </div>
              <div style={{ clear: 'both', float: 'left', marginTop: 16 }}>
                <p>
                  Fique visível dentro do aplicativo principal do WebGula, junto com as melhores empresas do segmento
                </p>
              </div>
            </ContainerText>
          </Col>
        </Row>

        <Row style={{marginBottom: 50}}>
          <Col sm={6} style={{alignItems: 'center', display: 'flex'}}>
            <ContainerText>
              <div>
                <Title>Integração</Title>
              </div>
              <div style={{ clear: 'both', float: 'left', marginTop: 16 }}>
                <p>
                  Os pedidos realizados no WebGula, link exclusivo ou app exclusivo são integrados com o nosso sistema de retaguarda, dessa forma sua empresa ganhará tempo e organização.
                </p>
              </div>
            </ContainerText>
          </Col>
          <Col sm={6}>
            <ContainerImage>
              <img src={image4} />
            </ContainerImage>
          </Col>
        </Row>

      </Container>
    </Wrapper>
  )
}

export default Corpo;