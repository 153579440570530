import Axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { BASE_URL_API } from "infra/config";
import { showError } from "infra/alerta";
import AuthClient from "infra/auth/AuthClient";
import Swal from "sweetalert2";

export default function useConta(saidaId, updateContext) {
  const [isLoading, setIsLoading] = useState(false);
  const [venda, setVenda] = useState({});

  const onLoad = useCallback(async () => {
    const isUserLogged = AuthClient.isUserLogged();

    if (!isUserLogged) {
      return Swal.fire({
        title: "Aviso",
        allowOutsideClick: false,
        text: "Você precisa fazer login antes de continuar.",
        icon: "warning",
        confirmButtonText: "Fazer login",
      }).then((result) => {
        if (result.value) {
          updateContext({ openLogin: true });
        }
      });
    } else {
      try {
        const { data } = await Axios.get(
          `${BASE_URL_API}/v1/acompanhamento-conta/venda-detalhada/${saidaId}?saidaId=${saidaId}`
        );

        setVenda(data);
      } catch (error) {
        const details = error.response?.data.details;

        if (details && details.length > 0) {
          for (const detail of details) {
            if (detail.errors && detail.errors.length > 0) {
              for (const error of detail.errors) {
                showError(error);
              }
            }
          }
        } else {
          // Caso não haja detalhes de erro específicos, você pode tratar o erro de outra forma ou simplesmente logá-lo.
          showError(error);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saidaId]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      onLoad();

      setIsLoading(false);
    })();
  }, [onLoad]);

  return {
    isLoading,
    venda,
    setIsLoading,
    refresh: onLoad,
  };
}
