import { Button } from "@material-ui/core";
import { showError, showSuccess } from "infra/alerta";
import { navigate } from "infra/router/history";
import React from "react";
import { resgatarPontosSaida } from "services/fidelidade";
import styled from "styled-components";

function FidelidadeSection({ cabecalho, conta, setLoading, onReset }) {
  const resgatar = async (saidaid) => {
    try {
      setLoading(true);
      const response = await resgatarPontosSaida(saidaid); // Renomear a função aqui
      if (response) {
        showSuccess("Pontos resgatados com sucesso!");
      }
    } catch (error) {
      const details = error.response?.data.details;

      if (details && details.length > 0) {
        for (const detail of details) {
          if (detail.errors && detail.errors.length > 0) {
            for (const error of detail.errors) {
              showError(error);
            }
          }
        }
      }
    } finally {
      setLoading(false);
      onReset();
    }
  };
  if (cabecalho.fidelidadeId > 0) {
    if (cabecalho.fidelidadeFlgParticipa) {
      if (conta.flgStatusFidelidade === "A") {
        return (
          <Container color="#3994e3">
            Essa venda irá gerar pontos após o encerramento.
          </Container>
        );
      } else if (
        conta.flgStatusFidelidade === "P" &&
        !conta.flgFidelidadeProcessada
      ) {
        return (
          <Container>
            <Button
              color="primary"
              variant="contained"
              onClick={() => resgatar(conta.id)}
            >
              Resgate {conta.fidelidadePontos} pontos dessa conta.
            </Button>
          </Container>
        );
      } else if (
        conta.flgStatusFidelidade === "P" &&
        conta.flgFidelidadeProcessada
      ) {
        return (
          <Container color="#1ea62b">
            {conta.fidelidadePontos} foram resgatados dessa conta.
          </Container>
        );
      }
    } else {
      return (
        <Container>
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("fidelidade")}
          >
            Participe do Plano de Fidelidade
          </Button>
        </Container>
      );
    }
  }
  return null;
}

export default FidelidadeSection;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 4px;
  font-size: 1.25rem;
  font-weight: 400;
  color: ${(props) => props.color};
`;
