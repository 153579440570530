import React from 'react';
import styled from 'styled-components';

const IncrementorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  margin: 10px 0; /* Adiciona uma margem vertical de 10px */
  bg-color: #f5f5f5;
`;

const Button = styled.button`
  width: 30px;
  height: 30px;
`;

const Value = styled.div`
  margin: 0 10px; /* Adiciona uma margem horizontal de 10px */
  background-color: #f5f5f5;
`;

export function Incrementor({ value, setValue, tipo }) {

  const increment = () => {
    if (tipo === 'horas') {
      const [hours, minutes] = value.split(':').map(Number);
      const newMinutes = (minutes + 30) % 60;
      const newHours = (minutes + 30 >= 60 ? hours + 1 : hours) % 24;
      setValue(`${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`);
    } else {
      setValue(value + 1);
    }
  };

  const decrement = () => {
    if (tipo === 'horas') {
      const [hours, minutes] = value.split(':').map(Number);
      const newMinutes = (minutes - 30 + 60) % 60;
      const newHours = (minutes - 30 < 0 ? hours - 1 : hours) % 24;
      setValue(`${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`);
    } else {
      setValue(value > 0 ? value - 1 : 0);
    }
  };

  return (
    <IncrementorWrapper>
      <Button onClick={decrement}>-</Button>
      <Value>{value}</Value>
      <Button onClick={increment}>+</Button>
    </IncrementorWrapper>
  );
}