import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonItem() {
  return (
    <div style={{ width: "100%" }}>
      <Skeleton width="100%" height={180} variant="rect" />
      <br />

      <Skeleton width="50%" variant="text" />
      <br />
      <Skeleton width="80%" variant="text" />

      <Skeleton width="70%" variant="text" />
    </div>
  );
}

export default SkeletonItem;
