import { useCallback, useEffect, useState } from "react";
import { BASE_URL_API } from "infra/config";
import axios from "infra/axios";

export default function usePedidoOff(idPedidoOff, chavePedidoOff) {
  const [isLoading, setIsLoading] = useState(false);
  const [venda, setVenda] = useState({});

  const onLoad = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL_API}/v3/wg-pedido-off/obter-pessoaOff?pessoaoffid=${idPedidoOff}&chave=${chavePedidoOff}`
      );
      setVenda(data);
    } catch (e) {}
  }, [idPedidoOff, chavePedidoOff]);

  useEffect(() => {
    (async () => {
      if (!idPedidoOff || !chavePedidoOff) {
        return;
      }
      setIsLoading(true);

      onLoad();

      setIsLoading(false);
    })();
  }, [onLoad, idPedidoOff, chavePedidoOff]);

  return {
    isLoading,
    venda,
  };
}
