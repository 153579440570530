import React, { useContext } from "react";
import { Badge } from "reactstrap";
import moment from "moment";
import styled from "styled-components";
import { formatMoney } from "infra/utils";
import { SITUACAO_PEDIDO } from "services/pedido";
import history from "infra/router/history";
import { LayoutContext } from "contexts/layout";

const Wrapper = styled.div`
  overflow: hidden;
  margin-bottom: 16px;
  padding: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const BoxWhite = styled.div`
  width: 80px;
  height: 80px;
  background: #f7f7f7;
  padding: 8px;
  float: left;
  margin-right: 16px;
  text-align: center;
`;

const BoxDescription = styled.div`
  float: left;
`;

function PedidoItem({ item }) {
  const { layoutData } = useContext(LayoutContext);

  return (
    <Wrapper
      onClick={() => {
        history.push(`/${layoutData.tenant}/acompanhamento/${item.id}`);
        // if (
        //   SITUACAO_PEDIDO.FINALIZADO === item.situacaoId ||
        //   SITUACAO_PEDIDO.CANCELADO === item.situacaoId
        // ) {
        //   history.push(`/${layoutData.tenant}/historico-pedidos/${item.id}`);
        // } else {
        //   history.push(`/${layoutData.tenant}/acompanhamento/${item.id}`);
        // }
      }}
    >
      <BoxWhite>
        <div style={{ fontSize: 18, fontWeight: "bold" }}>
          {" "}
          {moment(item.dataPedido).format("DD")}{" "}
        </div>
        <div style={{ fontSize: 14 }}>
          {moment(item.dataPedido).format("MM/YYYY")}
        </div>
      </BoxWhite>
      <BoxDescription>
        <div>
          <div style={{ fontSize: 18 }}>
            {item.empresaNome}{" "}
            {item.flgRetirarLocal ? " - (Retirar no local)" : null}
          </div>

          <Badge
            fill
            color={
              item.situacaoId === SITUACAO_PEDIDO.CANCELADO
                ? "danger"
                : item.situacaoId === SITUACAO_PEDIDO.FINALIZADO
                ? "success"
                : "primary"
            }
          >
            {item.situacaoNome}
          </Badge>
          {item.flgPontoEntrega && (
            <Badge
              fill
              color={"primary"}
              style={{
                marginLeft: 8,
              }}
            >
              Ponto de Entrega
            </Badge>
          )}

          {item.flgSugerirAvaliacao ? (
            <Badge fill style={{ marginLeft: 8 }} color={"success"}>
              Avalie este pedido
            </Badge>
          ) : null}
        </div>
        <div style={{ fontSize: 18, color: "green" }}>
          R$ {formatMoney(item.valorTotal)}
        </div>
      </BoxDescription>
    </Wrapper>
  );
}

export default PedidoItem;
