import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import {
  EmojiEventsOutlined as EmojiEventsOutlinedIcon,
  FavoriteOutlined as FavoriteOutlinedIcon,
  ArrowBack as ArrowBackIcon,
  ControlPointOutlined as ControlPointOutlinedIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  HighlightOutlined as HighlightOutlinedIcon,
  HourglassEmpty as HourglassEmptyIcon,
  SendOutlined as SendOutlinedIcon,
} from "@material-ui/icons";
import { findHistoricoLancamento } from "services/fidelidade";
import { LayoutContext } from "contexts/layout";
import moment from "moment";
import theme from "theme.json";
import EmptyState from "../EmptyState";

function getIconTipoMovimentacao(tipoLancamentoId: Number) {
  let icon;
  let color = theme.palette.primary.main;

  if (tipoLancamentoId === 1) {
    icon = <EmojiEventsOutlinedIcon style={{ color: "white" }} />;
  }

  if (tipoLancamentoId === 2) {
    icon = <FavoriteOutlinedIcon style={{ color: "white" }} />;
  }

  if (tipoLancamentoId === 3) {
    icon = <ArrowBackIcon style={{ color: "white" }} />;
  }

  if (tipoLancamentoId === 4) {
    icon = <ControlPointOutlinedIcon style={{ color: "white" }} />;
  }

  if (tipoLancamentoId === 5) {
    icon = <RemoveCircleOutlineIcon style={{ color: "white" }} />;
  }

  if (tipoLancamentoId === 6) {
    icon = <HighlightOutlinedIcon style={{ color: "white" }} />;
  }

  if (tipoLancamentoId === 7) {
    icon = <HourglassEmptyIcon style={{ color: "white" }} />;
  }
  if (tipoLancamentoId === 8) {
    icon = <SendOutlinedIcon style={{ color: "white" }} />;
  }

  return {
    icon,
    color,
  };
}

interface HistoricoLancamentoResult {
  items: any[];
  data: string;
}

function TabHistorico(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const { layoutData }: any = useContext(LayoutContext);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const data = await findHistoricoLancamento(
          layoutData.fidelidadeContaId
        );

        let grupo: any = {};

        data.forEach((item: any) => {
          const dataLancamento = moment(item.dataLancamento).format(
            "YYYY-MM-DD"
          );

          grupo[dataLancamento] = grupo[dataLancamento] || {
            items: [],
            data: item.dataLancamento,
          };
          grupo[dataLancamento].items.push(item);
        });

        const retorno: HistoricoLancamentoResult[] = [];

        for (const prop in grupo) {
          retorno.push(grupo[prop]);
        }

        setData(retorno);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [layoutData.fidelidadeContaId]);

  return (
    <Box style={{ padding: 16, minHeight: 400 }}>
      {isLoading ? (
        <div style={{ textAlign: "center", marginTop: 32 }}>
          <CircularProgress />
        </div>
      ) : null}

      {data.map((item, index) => (
        <List
          key={index}
          subheader={
            <ListSubheader>
              {moment(item.data).format("DD/MM/YYYY")}
            </ListSubheader>
          }
        >
          {item.items.map((lancamento: any, i: any) => {
            const status = getIconTipoMovimentacao(item.tipoLancamentoId);
            const color =
              item.flgDebitoCredto === "D"
                ? "#6992f0"
                : "#4dcf46";

            return (
              <ListItem key={i}>
                <ListItemIcon>
                  <div
                    style={{
                      backgroundColor: color,
                      width: 30,
                      height: 30,
                      borderRadius: 30,
                      textAlign: "center",
                    }}
                  >
                    {status.icon}
                  </div>
                </ListItemIcon>
                <ListItemText
                  color={status.color}
                  primary={lancamento.nomeTipoLancamento}
                  secondary={lancamento.ponto + " pontos"}
                />
              </ListItem>
            );
          })}
        </List>
      ))}

      {!data.length && !isLoading ? <EmptyState /> : null}
    </Box>
  );
}

export default TabHistorico;
