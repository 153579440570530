import React, { useState } from "react";
import { Spinner } from "reactstrap";
import MinhaFormaPagamentoItem from "./MinhaFormaPagamentoItem";
import { useMinhasFormasPagamento } from "actions/forma-pagamento";
import { Paper, List } from "@material-ui/core";
import ModalCadastrarFormaPagamento from "./ModalCadastrarFormaPagamento";
import iconAdd from "./icon-add-credit-card.png";

function ListaMinhasFormasPagamento({ onSelect, selected, origemCarrinho }) {
  const { isLoading, items, load } = useMinhasFormasPagamento();
  const [openModalCriar, setOpenModalCriar] = useState(false);

  return (
    <div style={{ paddingBottom: 32 }}>
      {isLoading ? <Spinner /> : null}

      <ModalCadastrarFormaPagamento
        open={openModalCriar}
        onClose={(saved = false) => {
          setOpenModalCriar(false);
          if (saved) {
            load();
          }
        }}
      />

      <Paper
        style={{ cursor: "pointer" }}
        onClick={() => setOpenModalCriar(true)}
      >
        <MinhaFormaPagamentoFake image={iconAdd} title="Novo cartão" />
      </Paper>
      <br />
      <List>
        {origemCarrinho ? (
          <Paper
            style={{
              marginBottom: 16,
              cursor: "pointer",
              border: `1px solid ${
                selected?.id === 101 ? "green" : "transparent"
              }`,
            }}
            onClick={() =>
              onSelect({
                id: 101,
                pix: true,
                credito: false,
              })
            }
          >
            <MinhaFormaPagamentoFake
              image="/images/bandeiras/pix.png"
              title="Pagamento com PIX"
            />
          </Paper>
        ) : null}

        {items.map((item, index) => (
          <Paper key={index} style={{ marginBottom: 16 }}>
            <MinhaFormaPagamentoItem
              item={item}
              onRefresh={load}
              selected={selected?.id === item.id}
              onClick={onSelect}
            />
          </Paper>
        ))}
      </List>
    </div>
  );
}

function MinhaFormaPagamentoFake({ title, image }) {
  return (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          width: 60,
          height: 60,
          backgroundColor: "#F0F1F3",
          textAlign: "center",
          margin: 16,
          borderRadius: "50%",
          float: "left",
        }}
      >
        <img
          src={image}
          style={{ width: 25, height: 25, marginTop: 20 }}
          alt={title}
        />
      </div>

      <div style={{ marginTop: 32 }}>{title}</div>
    </div>
  );
}

export default ListaMinhasFormasPagamento;
