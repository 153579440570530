import axios from "axios";
import { BASE_URL_API, BASE_URL_TACTO } from "../config";
import authClient from "../auth/AuthClient";
import { httpCache } from "../axios";

class LayoutProvider {
  getLayoutConfiguration = async (permaLink) => {
    if (permaLink === "webgula") {
      return {
        layout: "webgula",
      };
    }

    const url = `${BASE_URL_API}/v1/api/empresa-wg-config/${permaLink}/perma-link`;

    try {
      const responseConfig = await httpCache.get(url);

      const urlDados = `${BASE_URL_API}/v1/api/empresas/informacao-completa?empresaId=${responseConfig.data.empresaId}`;

      const { data: dados } = await axios.get(urlDados);

      const logoUrl =
        BASE_URL_TACTO +
        "/v1/empresas/" +
        dados.id +
        "/imagem?c=" +
        dados.grupoEmpresarialChave +
        "&b=" +
        dados.logoUrl;

      const data = {
        config: { ...responseConfig.data },
        ...dados,
        layout:
          responseConfig.data.modeloLayoutId === 1 ? "default" : "default",
        descricao: dados.mensagemPadrao,
        id: dados.id,
        logo: logoUrl,
        primaryColor: responseConfig.data.corBody,
      };

      data.telefone = (data.telefone || "").replace(/[^0-9]/g, "");

      authClient.setEmpresa({
        chave: dados.grupoEmpresarialChave,
        empresaGlobalId: dados.id,
        tenant: permaLink,
        empresaBaseId: dados.empresaBaseId,
      });

      return data;
    } catch (e) {
      // TODO 440
      return null;
    }
  };
}

export default new LayoutProvider();
