import React, { useState, useCallback, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import { LayoutContext } from "contexts/layout";
import AuthClient from "infra/auth/AuthClient";

import ModalEsqueciSenha from "./ModalEsqueciSenha";
import styled from "styled-components";
import { useMediaQuery } from "infra/utils";

function ModalLogin({ open, onToggle }) {
  const { update } = useContext(LayoutContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [openEsqueciSenha, setOpenEsqueciSenha] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const handleLogin = useCallback(async () => {
    setIsLoading(true);

    const res = await AuthClient.loginUser(username, password);

    setIsLoading(false);

    if (!res) {
      setIsOpenToast(true);
    } else {
      update({});
      onToggle();
      window.location.reload();
    }
  }, [onToggle, password, update, username]);

  // const handleLoginFacebook = useCallback(
  //   async (accessToken) => {
  //     setIsLoading(true);

  //     const res = await AuthClient.loginExternal({
  //       provider: "facebook",
  //       accessToken,
  //     });

  //     setIsLoading(false);

  //     if (!res) {
  //       setIsOpenToast(true);
  //     } else {
  //       update({});
  //       onToggle();
  //       window.location.reload();
  //     }
  //   },
  //   [onToggle, update]
  // );
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div>
      <Modal isOpen={open} toggle={onToggle}>
        <ModalHeader toggle={onToggle}>Fazer Login</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">Email</Label>
              <Input
                type="email"
                value={username}
                onChange={(ev) => setUsername(ev.target.value)}
                placeholder="Email"
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Senha</Label>
              <Input
                type="password"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                placeholder="Senha"
              />
            </FormGroup>

            <FormGroup>
              <span
                style={{
                  cursor: "pointer",
                  display: "block",
                  float: "left",
                  fontSize: 12,
                }}
                onClick={() => setOpenEsqueciSenha(true)}
              >
                Esqueceu sua senha ? Clique aqui.
              </span>
            </FormGroup>
          </Form>

          {isOpenToast ? (
            <div style={{ float: "left", width: "100%" }}>
              <Alert
                isOpen={isOpenToast}
                toggle={() => setIsOpenToast(false)}
                title="Aviso"
                color="danger"
              >
                <div>Usuário ou senha inválidos!</div>
              </Alert>
            </div>
          ) : null}

          {/* <div style={{ float: "left", width: "100%" }}>  ocultado a pedido do evandro
            <div 
              style={{ margin: "16px auto", marginTop: 32, display: "table" }}
            >
              <FacebookLogin
                isMobile={false}
                redirectUri="https://webgula.com.br/"
                appId={FACEBOOK_ID}
                fields="name,email,picture"
                scope="public_profile,email"
                textButton="Entrar com facebook"
                icon={<i className="fa fa-facebook" />}
                buttonStyle={{ height: 40, paddingTop: 8 }}
                callback={(r) => {
                  if (r.accessToken) {
                    handleLoginFacebook(r.accessToken);
                  }
                }}
              />
            </div>
            
          </div> */}
        </ModalBody>
        <ModalFooter>
          <StyledFlex>
            <Button color="secondary" disabled={isLoading} onClick={onToggle}>
              Cancelar
            </Button>
            {!isMobile && (
              <Button
                disabled={isLoading}
                onClick={() => {
                  onToggle(() => {
                    update({ openCadastro: true });
                  });
                }}
              >
                Cadastrar-me
              </Button>
            )}
            <Button
              disabled={isLoading}
              color="primary"
              onClick={() => handleLogin()}
            >
              {isLoading ? "Entrando..." : "Entrar"}
            </Button>
          </StyledFlex>
        </ModalFooter>
        {isMobile && (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              padding: 16,
            }}
          >
            <Button
              style={{
                width: "100%",
              }}
              disabled={isLoading}
              onClick={() => {
                onToggle(() => {
                  update({ openCadastro: true });
                });
              }}
            >
              Cadastrar-me
            </Button>
          </div>
        )}
      </Modal>

      <ModalEsqueciSenha
        open={openEsqueciSenha}
        onSaved={() => {
          setOpenEsqueciSenha(false);
        }}
        onToggle={() => {
          setOpenEsqueciSenha(false);
        }}
      />
    </div>
  );
}

export default ModalLogin;

const StyledFlex = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
