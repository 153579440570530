import React, { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import moment from "moment";

import { LayoutContext } from "contexts/layout";
import { formatPhone } from "infra/utils";
import { FooterRoot, Image } from "./styles";


function Footer() {

  const { layoutData } = useContext(LayoutContext);

  return (
    <FooterRoot>
      <Container>
        <Row>
          <Col md="5">
            <Image src={layoutData.logo} />
            {layoutData.telefone ? (
              <Row>
                <Col md="12">
                  <div
                    style={{
                      float: 'left',
                      borderRadius: '50%',
                      width: 50,
                      height: 50,
                      backgroundColor: 'white',
                      marginRight: 24,
                      textAlign: 'center',
                      paddingTop: 11
                    }}>
                    <i className="fa fa-phone" style={{ fontSize: 28, color: layoutData.primaryColor }} />
                  </div>
                  <div style={{ float: 'left', paddingTop: 13 }}>
                    {formatPhone(layoutData.telefone)}
                  </div>
                </Col>
              </Row>
            ) : null}

            {layoutData.email ? (
              <Row style={{ marginTop: 16 }}>
                <Col md="12">
                  <div
                    style={{
                      float: 'left',
                      borderRadius: '50%',
                      width: 50,
                      height: 50,
                      backgroundColor: 'white',
                      marginRight: 24,
                      textAlign: 'center',
                      paddingTop: 11
                    }}>
                    <i className="fa fa-envelope" style={{ fontSize: 20, color: layoutData.primaryColor }} />
                  </div>
                  <div style={{ float: 'left', paddingTop: 13 }}>
                    {(layoutData.email)}
                  </div>
                </Col>
              </Row>
            ) : null}

            <Endereco layoutData={layoutData} />
          </Col>
          <Col sx={12} md="1" />
          <Col md="6">
            {layoutData.urlDownloadAndroid || layoutData.urlDownloadIOS ? (
              <div>
                <h5>Baixe o App do {layoutData.nome}</h5>
                <div>
                  {layoutData.urlDownloadAndroid ? (
                    <div style={{ margin: '16px 0' }}>
                      <a href={layoutData.urlDownloadAndroid} rel="noopener noreferrer" target="_blank">
                        <img src="/images/apple-store.png" alt="Apple Store" />
                      </a>
                    </div>
                  ) : null}
                  {layoutData.urlDownloadIOS ? (
                    <div>
                      <a href={layoutData.urlDownloadIOS} rel="noopener noreferrer" target="_blank">
                        <img src="/images/google-play.png" alt="Google Play" />
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </Col>
        </Row>

        <hr style={{ marginTop: 64 }} />

        <Row>
          <Col sm="12" style={{ textAlign: 'center', paddingBottom: 16 }}>
            <a href="https://www.tacto.com.br" rel="noopener noreferrer" target="_blank">
              <img style={{ width: 50, marginRight: 16 }} src='https://www.tacto.com.br/img/logo-tacto.png' alt="Tacto Sistemas" />
            </a>
            Desenvolvido pela Tacto Sistemas {moment().format('YYYY')}©. Todos os direitos reservados
          </Col>
        </Row>
      </Container>
    </FooterRoot>
  );
}

function Endereco({ layoutData }) {
  const endereco = [];

  if (layoutData.endLogradouro) {
    endereco.push(layoutData.endLogradouro);
  }

  if (layoutData.endNumero) {
    endereco.push(layoutData.endNumero);
  }

  if (layoutData.endBairroNome) {
    endereco.push(layoutData.endBairroNome);
  }

  if (layoutData.endCep) {
    endereco.push(layoutData.endCep);
  }

  if (layoutData.endCidadeNome) {
    endereco.push(layoutData.endCidadeNome);
  }

  if (layoutData.endUFNome) {
    endereco.push(layoutData.endUFNome);
  }

  return (
    <Row style={{ marginTop: 16 }}>
      <Col md="12">
        <div
          style={{
            float: 'left',
            borderRadius: '50%',
            width: 50,
            height: 50,
            backgroundColor: 'white',
            marginRight: 24,
            textAlign: 'center',
            paddingTop: 11
          }}>
          <i className="fa fa-map-marker" style={{ fontSize: 24, color: layoutData.primaryColor }} />
        </div>
        <div style={{ float: 'left', width: 'calc(100% - 74px)', paddingTop: 13 }}>
          {endereco.join(", ")}
        </div>
      </Col>
    </Row>
  );
}

export default Footer;
