import React, { useContext } from "react";
import { Row, Col, Container } from "reactstrap";

import { EventoContext } from "../../../EventoContext";

import SkeletonItem from "./SkeletonItem";
import ItensAdicionais from "./ItensAdicionais";
import CardapioItem from "./CardapioItem";

import { LayoutContext } from "contexts/layout";

import { ContainerCardapio } from "./styles";
import { Produto } from "../../../types";
import { TextField } from "@material-ui/core";

import useCardapio from "../../../hooks/useCardapio";
import useTotais from "../../../hooks/useTotais";

import SelectTipoEvento from "../../../../../../../components/Inputs/SelectTipoEvento";
import { formatMoney } from "infra/utils";

function Cardapio() {
  const { data, isLoading } = useCardapio();

  const { total } = useTotais();

  const { layoutData }: any = useContext(LayoutContext);
  const { update, value } = useContext(EventoContext);

  const handleSelecionarProduto = (produto: Produto) => {
    const _v = value.produto?.id !== produto.id ? produto : null;

    update("produto", _v);

    if (_v) {
      update("produtoItens", [...(_v.itens ?? [])]);
    } else {
      update("produtoItens", []);
    }
  };

  return (
    <ContainerCardapio>
      <Container>
        <div style={{ marginBottom: 64 }}>
          <h3>Monte o cardápio do evento</h3>
        </div>

        <div style={{ marginBottom: 32 }}>
          <Row>
            <Col sm={3} xs={12}>
              <SelectTipoEvento
                value={value.tipoEventoId}
                onChange={(v: any) => {
                  update("tipoEventoId", v);
                }}
              />
            </Col>
          </Row>
        </div>

        <div>
          {isLoading ? (
            <Row>
              {[0, 1, 2, 3].map(() => (
                <Col md={3}>
                  <SkeletonItem />
                </Col>
              ))}
            </Row>
          ) : null}

          <Row>
            {value.tipoEventoId &&
              data.map((produto, index) => (
                <Col md={3} sm={6} xs={12}>
                  <CardapioItem
                    disabled={!value.dataEvento}
                    active={value.produto?.id === produto.id}
                    onClick={() => handleSelecionarProduto(produto)}
                    item={produto}
                    key={index}
                    primaryColor={layoutData.primaryColor}
                  />
                </Col>
              ))}
          </Row>
        </div>

        {/* <div>
          {value.produto ? (
            <ItensCardapio
              value={value.produtoItens || []}
              items={value.produto.itens ?? []}
              onChange={(v: any) => {
                update("produtoItens", v);
              }}
            />
          ) : null}
        </div> */}

        <div>
          {value.produto ? (
            <ItensAdicionais
              value={value.adicionais || []}
              items={value.produto.itens ?? []}
              onChange={(v: any) => {
                update("adicionais", v);
              }}
            />
          ) : null}
        </div>

        <div>
          <Row>
            <Col md={6} sm={12}>
              {value.produto ? (
                <TextField
                  label="Digite aqui suas observações"
                  style={{ marginTop: 32, backgroundColor: "white" }}
                  variant="outlined"
                  rows={4}
                  fullWidth
                  multiline
                  value={value.observacao}
                  onChange={({ target: { value } }) =>
                    update("obvervacao", value)
                  }
                />
              ) : null}
            </Col>
          </Row>
        </div>

        {value.produto ? (
          <div
            style={{
              clear: "both",
              marginTop: 32,
              marginBottom: 32,
              width: "100%",
              float: "left",
            }}
          >
            <div style={{ float: "right" }}>
              <div style={{ display: "flex", marginBottom: 16 }}>
                <div style={{ flex: 1, marginRight: 16, display: "flex" }}>
                  Cardápio:
                </div>
                <div>R$ {formatMoney(value.produto?.valorBasePessoa ?? 0)}/pessoa</div>
              </div>

              {/* <div style={{ display: "flex", marginBottom: 16 }}>
                <div style={{ flex: 1, marginRight: 16, display: "flex" }}>
                  Adicionais:
                </div>
                <div>R$ 0/pessoa</div>
              </div> */}

              <div style={{ display: "flex", marginBottom: 16 }}>
                <div style={{ flex: 1, marginRight: 16, display: "flex" }}>
                  Subtotal:
                </div>
                <div>
                  <strong>R$ {formatMoney(total)}/pessoa</strong>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Container>
    </ContainerCardapio>
  );
}

export default Cardapio;
