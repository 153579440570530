import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

import { LayoutContext } from "contexts/layout";

const IntroRoot = styled.div`
  width: 100%;
  clear: both;
  padding-top: 180px;
  padding-bottom: 80px;
  position: relative;
  background: #F0F1F3;
`;


const IntroItem = styled.div`
  margin-bottom: 16px;

  .fa {
    font-size: 34px;
    color: ${props => props.color};
  }
`;

const H1 = styled.h1`
  color: ${props => props.color};
  font-size: 36px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Time = styled.h1`
  font-size: 18px;

  span {
    color: #333;
    margin-left: 16px;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Paragraph = styled.p`
  color: #333;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

function Intro() {

  const { layoutData } = useContext(LayoutContext);

  return (
    <IntroRoot>
      <Container>
        <Row>
          <Col md="8">
            <H1 color={layoutData.primaryColor}>{layoutData.nome}</H1>
            <Paragraph>{layoutData.descricao}</Paragraph>
          </Col>
          <Col md="4">

            <IntroItem color={layoutData.primaryColor}>

              <Row>
                <Col md="12">
                  <Time>
                    <i className="fa fa-clock-o" />
                    <span> Tempo médio de entrega </span>
                  </Time>
                  <Paragraph>
                    Entre {layoutData.tempoMedioEntregaNome}
                  </Paragraph>
                </Col>
              </Row>

            </IntroItem>
            <IntroItem color={layoutData.primaryColor}>
              <Row>
                <Col md="12">
                  <Time>
                    <i className="fa fa-phone" />
                    <span>
                      Contato
                    </span>
                  </Time>
                  <Paragraph>
                    ({layoutData.telefone.slice(0, 2)}){" "}
                    {layoutData.telefone.slice(2)}
                  </Paragraph>
                </Col>
              </Row>
            </IntroItem>

            <IntroItem color={layoutData.primaryColor}>
              <Row>
                <Col md="12">
                  <Time>
                    <i className="fa fa-envelope-o" />
                    <span>
                      Email
                    </span>
                  </Time>
                  <Paragraph>
                    {layoutData.email}
                  </Paragraph>
                </Col>
              </Row>
            </IntroItem>

          </Col>
        </Row>
      </Container>
    </IntroRoot>
  );
}

export default Intro;
