import React from "react";
import { Button, Badge } from "reactstrap";
import styled from "styled-components";
import { Row, Col } from "reactstrap";

const CardItem = styled.div`
  background-color: #fff;
  border: 1px solid ${(props) => (props.selected ? "rgb(255, 64, 0)" : "#ccc")};
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }
`;

const AceitaEntrega = styled.div`
  margin-top: -30px;
  margin-bottom: 10px;
  width: 100%;

  @media all and (max-width: 768px) {
    margin-top: 0;
  }
`;

function getConcatAddress(endereco) {
  const retorno = [];

  if (endereco.logradouro) {
    retorno.push(endereco.logradouro);
  }

  if (endereco.nomeBairro) {
    retorno.push(endereco.nomeBairro);
  }

  if (endereco.nomeCidade) {
    retorno.push(endereco.nomeCidade);
  }

  if (endereco.nomeEstado) {
    retorno.push(endereco.nomeEstado);
  }

  return retorno.join(", ");
}

function EnderecoCard({
  item,
  isLoading,
  handleSelectPrincipal,
  handleItemEdit,
  selected,
  hiddenAceitaEntrega = false,
}) {
  return (
    <div>
      <CardItem
        selected={!!selected}
        onClick={() => {
          handleItemEdit(item);
        }}
      >
        <h6>{item.nome}</h6>
        <Row>
          <Col sm={9}>
            <div style={{ fontSize: "0.8em", color: "#666" }}>
              {getConcatAddress(item)}
            </div>
          </Col>
          <Col sm={3}>
            {!item.flgAceitaEntrega && !hiddenAceitaEntrega ? (
              <AceitaEntrega>
                <Badge
                  style={{
                    width: "100%",
                    opacity: 0.6,
                    paddingTop: 6,
                    paddingBottom: 6,
                  }}
                  color="danger"
                >
                  Não aceita entrega
                </Badge>
              </AceitaEntrega>
            ) : null}

            {!item.flgPrincipal ? (
              typeof handleSelectPrincipal === "function" &&
              item.flgAceitaEntrega ? (
                <Button
                  disabled={isLoading}
                  color="primary"
                  size="sm"
                  block
                  onClick={(ev) => {
                    ev.stopPropagation();
                    handleSelectPrincipal(item);
                  }}
                >
                  Selecionar p/ Entrega
                </Button>
              ) : null
            ) : null}
          </Col>
        </Row>
      </CardItem>
    </div>
  );
}

export default EnderecoCard;
