import React, { useCallback, useContext, useState } from "react";

import { LayoutContext } from "contexts/layout";
import { validarVoucher } from "services/pedido";
import Swal from "sweetalert2";
import { TextField } from "@material-ui/core";

function BoxDesconto({ voucher, setVoucher }) {
  const [cupom, setCupom] = useState(null);
  const [isLoadingCupom, setIsLoadingCupom] = useState(false);

  const { layoutData } = useContext(LayoutContext);

  const handleAdicionarVoucher = useCallback(async () => {
    if (voucher) {
      setVoucher(null);
      return;
    }

    if (!cupom) {
      return Swal.fire("Aviso", "Informe o cupom corretamente!");
    }

    setIsLoadingCupom(true);
    setVoucher(null);

    try {
      const data = await validarVoucher(cupom);

      if (data.ativo) {
        const { voucher } = data;

        setVoucher(voucher);
      } else {
        Swal.fire("Aviso", data.mensagem, "error");
      }
    } catch (e) {
      let error = "Cupom não encontrado!";

      if (e.response.data?.details) {
        error = e.response.data.details[0].errors[0];
      }

      Swal.fire("Aviso", error, "error");
    } finally {
      setIsLoadingCupom(false);
    }
  }, [voucher, setVoucher, cupom]);

  return (
    <div
      style={{
        marginTop: 16,
        marginBottom: 16,
        display: "flex",
        flex: 1,
        clear: "both",
      }}
    >
      <div style={{ flex: 1 }}>
        {!voucher ? (
          <TextField
            size="small"
            label="Cupom"
            variant="outlined"
            value={cupom}
            defaultValue={cupom}
            disabled={isLoadingCupom || voucher}
            onChange={(ev) => setCupom(ev.target.value)}
          />
        ) : (
          <div>
            <div style={{ fontSize: 11 }}>voucher: </div>
            {voucher.nome}
          </div>
        )}
      </div>
      <div
        onClick={() => (!isLoadingCupom ? handleAdicionarVoucher() : null)}
        style={{
          paddingTop: 12,
          cursor: "pointer",
          color: layoutData.primaryColor,
          paddingLeft: 8,
        }}
      >
        {isLoadingCupom ? "Aplicando..." : voucher ? "Remover" : "Aplicar"}
      </div>
    </div>
  );
}

export default BoxDesconto;
