import axios from "axios";
import { BASE_URL_API } from "infra/config";
import AuthClient from "infra/auth/AuthClient";
import { httpCache } from "infra/axios";

export async function getEspecialidades() {
  const { data } = await httpCache.get(`${BASE_URL_API}/v1/api/especialidades`);

  return data;
}

export async function getCardapioCompleto(pdvId = 3, guiaId) {
  let grupos;
  let cardapio;

  if (guiaId) {
    grupos = await getGruposGuias(pdvId, Number(guiaId));
    cardapio = await getCardapioGuias(pdvId, Number(guiaId));
    const { produtos, variacoes, variacaoItens, produtoTamanhoVariacoes } =
      cardapio;

    const groupedItems = {};

    produtos.forEach((produto) => {
      produto.pdvId = pdvId;

      produto.variacoes = produtoTamanhoVariacoes.filter(
        (pvi) => pvi.itemId === produto.id
      );

      if (produto.imagem) {
        produto.imagem = produto.imagem[0] ? produto.imagem[0].url : null;
      }

      if (!produto.imagem) {
        /*produto.imagem =
          "https://www.witneyandmason.com/wp-content/themes/witneyandmason/images/product-placeholder.gif";*/
      }

      produto.variacoes = produto.variacoes
        .map((variacaoProduto) => {
          const variacao = variacoes.find(
            (v) => v.id === variacaoProduto.variacaoId
          );

          const produtosDaVariacao = variacaoItens
            .filter((variacaoItem) => variacaoItem.variacaoId === variacao.id)
            .map((variacaoItem) => {
              const produtoVariacao = produtos.find(
                (pro) => pro.id === variacaoItem.itemId
              );

              return {
                ...produtoVariacao,
                preco: variacaoItem.valor,
                tamanhoId: variacaoItem.tamanhoId,
                tamanhoVariacaoProdutoId: variacaoProduto.tamanhoId,
                variacaoId: variacao.id,
              };
            });

          return {
            nome: variacao.nome,
            nomeMostra: variacao.nomeMostra,
            qtdeMax: variacao.qtdeMax,
            qtdeMin: variacao.qtdeMin,
            flgTipo: variacao.flgTipo,
            flgMaiorPrecoM : variacao.flgMaiorPrecoM,
            id: variacao.id,
            items: produtosDaVariacao.sort(function (a, b) {
              return ("" + a.nomeCompleto).localeCompare(b.nomeCompleto);
            }),
            ordem: variacaoProduto.ordem || 0,
          };
        })
        .sort((a, b) => (b.ordem > a.ordem ? -1 : 1));
    });

    const sugestaoDia = produtos.filter((produto) => !!produto.flgSugestao);

    grupos.forEach((grupo) => {
      grupo.pdvId = pdvId;

      if (!groupedItems[grupo.categoriaNome]) {
        groupedItems[grupo.categoriaNome] = [];
      }

      grupo.produtos = cardapio.produtos
        .filter((produto) => produto.grupoId === grupo.id)
        .sort((a, b) => (b.ordemLista > a.ordemLista ? -1 : 1));

      groupedItems[grupo.categoriaNome].push(grupo);
    });

    let items = [];

    for (let prop of Object.keys(groupedItems)) {
      items.push({
        id: groupedItems[prop][0].categoriaId,
        nome: prop,
        items: groupedItems[prop],
      });
    }

    window.items = items;

    return { result: items, sugestaoDia };
  } else {
    grupos = await getAllGrupos(pdvId);

    cardapio = await getAllCardapio(pdvId);
    const { produtos, variacoes, variacaoItens, produtoTamanhoVariacoes } =
      cardapio;

    const groupedItems = {};

    produtos.forEach((produto) => {
      produto.pdvId = pdvId;

      produto.variacoes = produtoTamanhoVariacoes.filter(
        (pvi) => pvi.itemId === produto.id
      );

      if (produto.imagem) {
        produto.imagem = produto.imagem[0] ? produto.imagem[0].url : null;
      }

      if (!produto.imagem) {
        /*produto.imagem =
          "https://www.witneyandmason.com/wp-content/themes/witneyandmason/images/product-placeholder.gif";*/
      }

      produto.variacoes = produto.variacoes
        .map((variacaoProduto) => {
          const variacao = variacoes.find(
            (v) => v.id === variacaoProduto.variacaoId
          );

          const produtosDaVariacao = variacaoItens
            .filter((variacaoItem) => variacaoItem.variacaoId === variacao.id)
            .map((variacaoItem) => {
              const produtoVariacao = produtos.find(
                (pro) => pro.id === variacaoItem.itemId
              );

              return {
                ...produtoVariacao,
                preco: variacaoItem.valor,
                tamanhoId: variacaoItem.tamanhoId,
                tamanhoVariacaoProdutoId: variacaoProduto.tamanhoId,
                variacaoId: variacao.id,
              };
            });

          return {
            nome: variacao.nome,
            nomeMostra: variacao.nomeMostra,
            qtdeMax: variacao.qtdeMax,
            qtdeMin: variacao.qtdeMin,
            flgTipo: variacao.flgTipo,
            flgMaiorPrecoM : variacao.flgMaiorPrecoM,
            id: variacao.id,
            items: produtosDaVariacao.sort(function (a, b) {
              return ("" + a.nomeCompleto).localeCompare(b.nomeCompleto);
            }),
            ordem: variacaoProduto.ordem || 0,
          };
        })
        .sort((a, b) => (b.ordem > a.ordem ? -1 : 1));
    });

    const sugestaoDia = produtos.filter((produto) => !!produto.flgSugestao);

    grupos.forEach((grupo) => {
      grupo.pdvId = pdvId;

      if (!groupedItems[grupo.categoriaNome]) {
        groupedItems[grupo.categoriaNome] = [];
      }

      grupo.produtos = cardapio.produtos
        .filter((produto) => produto.grupoId === grupo.id)
        .sort((a, b) => (b.ordemLista > a.ordemLista ? -1 : 1));

      groupedItems[grupo.categoriaNome].push(grupo);
    });

    let items = [];

    for (let prop of Object.keys(groupedItems)) {
      items.push({
        id: groupedItems[prop][0].categoriaId,
        nome: prop,
        items: groupedItems[prop],
      });
    }

    window.items = items;

    return { result: items, sugestaoDia, grupos };
  }
}

export async function getAllCardapio(pdvId = 3) {
  const { data } = await httpCache.get(
    `${BASE_URL_API}/v3/cardapio/completo`,
    {
      params: {
        pdvId,
      },
    }
  );

  return data;
}
export async function getCardapioGuias(pdvId = 3, guiaId = 0) {
  const { data } = await httpCache.get(`${BASE_URL_API}/v3/cardapio/completo`, {
    params: {
      pdvId,
      guiaId,
    },
  });

  return data;
}

export async function getProduto(itemId, pdvId = 3) {
  const { data } = await axios.get(
    `${BASE_URL_API}/v1/cardapios/item/${itemId}?pdvId=${pdvId}`
  );

  return data;
}

export async function getAllGrupos(pdvId = 3) {
  const response = await httpCache.get(`${BASE_URL_API}/v3/cardapio/grupos`, {
    params: {
      pdvId,
    },
  });

  const { data } = response;
  return data;
}
export async function getGruposGuias(pdvId = 3, guiaId = 0) {
  const { data } = await httpCache.get(`${BASE_URL_API}/v3/cardapio/grupos`, {
    params: {
      pdvId,
      guiaId,
    },
  });

  return data;
}

export async function getModalidadePagamento() {
  const empresa = AuthClient.getEmpresa();

  const { data } = await axios.get(
    `${BASE_URL_API}/v1/api/modalidades-caixas/${empresa.empresaGlobalId}`
  );

  return data;
}
