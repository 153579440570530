import styled from "styled-components";

export const ContainerCardapio = styled.div`
  margin-top: 120px;

  @media all and (max-width: 768px) {
    margin-top: 64px;
  }
`;

export const CardapioItemContainer = styled.div<any>`
  margin-bottom: 32px;

  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  h4 {
    font-size: 1.2em;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  .MuiCard-root {
    position: relative;

    &:before {
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      background-color: ${(props) => props.primaryColor}19;
      display: ${(props) => (props.active ? "block" : "none")};
    }
  }
`;
