import styled from "styled-components";

export const ContainerResumo = styled.div`
  margin-top: 120px;

  @media all and (max-width: 768px) {
    margin-top: 64px;
  }
`;

export const BoxResumo = styled.div`
  border: 1px solid #efefef;

  img {
    max-width: 100%;
    width: 100%;
  }
`;


export const BoxHeaderResumo = styled.div`
  background-color: #efefef;
  padding: 8px 15px;
  display: flex;
  flex: 1;
`;