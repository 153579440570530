import axios from "axios";
import { setup } from "axios-cache-adapter";
import authClient from "./auth/AuthClient";
import { BASE_URL_API } from "./config";

// TODO, implementar cache no futuro
export const httpCache = setup({
  baseURL: BASE_URL_API,
  cache: {
    maxAge: 1 * 60 * 1000,
  },
});

function prepareRequest(config) {
  const tokenData = authClient.getToken() || {};
  const empresaData = authClient.getEmpresa() || {};
  if (tokenData.access_token && !config?.url?.includes("googleapis")) {
    config.headers.common["Authorization"] = `Bearer ${tokenData.access_token}`;

    if (!config.url.includes("accounts.tactonuvem.com.br")) {
      if (empresaData.chave && !config.headers["tacto-grupo-empresarial"]) {
        config.headers["tacto-grupo-empresarial"] = empresaData.chave;
      }
      if (empresaData.empresaBaseId && !config.headers.grupoEmpresaId) {
        config.headers.grupoEmpresaId = empresaData.empresaBaseId;
      }
      if (empresaData.empresaBaseId && !config.headers.empresaId) {
        config.headers.empresaId = empresaData.empresaBaseId;
      }
    }
  }

  return config;
}

axios.interceptors.request.use(prepareRequest, (error) =>
  Promise.reject(error)
);

httpCache.interceptors.request.use(prepareRequest, (error) =>
  Promise.reject(error)
);

axios.interceptors.response.use((response) => {
  return response;
}, responseFailed);

httpCache.interceptors.response.use((response) => {
  return response;
}, responseFailed);

async function responseFailed(error) {
  const originalRequest = error.config;

  if (
    error?.response?.status === 401 &&
    originalRequest.url.includes("/connect/token")
  ) {
    authClient.logout();
    window.location.reload();
    return Promise.reject(error);
  }

  if (error?.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    authClient.logout();
    await authClient.loginClient();

    prepareRequest(originalRequest);

    return axios(originalRequest);
  }
  return Promise.reject(error);
}

// Create `axios` instance passing the newly created `cache.adapter`

export default axios;
