/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from 'reactstrap';
import bg from './bg.png';
import icon1 from './icons/icon1.png';
import icon2 from './icons/icon2.png';
import icon3 from './icons/icon3.png';
import icon4 from './icons/icon4.png';
import icon5 from './icons/icon5.png';
import icon6 from './icons/icon6.png';
import Text from '../../../../components/Text';

const Wrapper = styled.div`
  margin-top: 150px;
  margin-bottom: 32px;
`

const BackgroundComoFunciona = styled.div`
  img {
    max-width: 100%;
  }
`

const IconTitle = styled.div` 
  float: left;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: ${props => props.active ? '#ED958F' : 'white'};
  text-align: center;
  margin-right: 16px;
  border: 4px solid white;
  padding: 4px;

  img {
    max-width: 100%;
  }
`

const Title = styled.div` 
  float: left;
  font-size: 26px;
`

function ComoFuncionaPage() {

  return (
    <Wrapper>
      <Container>

        <div style={{ width: '100%', marginTop: 32, marginBottom: 32, display: 'flex', justifyContent: 'flex-start' }}>
          <Text size={32} variant="fancy-title">Como funciona</Text>
        </div>

        <Row>
          <Col sm={6}>
            <BackgroundComoFunciona>
              <img src={bg} />
            </BackgroundComoFunciona>
          </Col>
          <Col sm={6}>
            <div style={{marginBottom: 32, clear: 'both', float: 'left'}}>
              <div>
                <IconTitle active> <img src={icon1} /> </IconTitle>
                <Title>Guia gastronômico</Title>
              </div>
              <div style={{ clear: 'both', float: 'left', marginTop: 16 }}>
                <p>
                  Através de sua localização mostramos os estabelecimentos, que são parceiros do webgula, mais próximos de você. Com algumas opções de filtros.
                </p>
              </div>
            </div>


            <div style={{marginBottom: 32, clear: 'both', float: 'left'}}>
              <div>
                <IconTitle> <img src={icon2} /> </IconTitle>
                <Title>Delivery</Title>
              </div>
              <div style={{ clear: 'both', float: 'left', marginTop: 16 }}>
                <p>
                Tenha o melhor do WebGula em qualquer lugar! Escolha seus pratos favoritos e receba onde quiser.
                </p>
              </div>
            </div>


            <div style={{marginBottom: 32, clear: 'both', float: 'left'}}>
              <div>
                <IconTitle> <img src={icon3} /> </IconTitle>
                <Title>Menu digital</Title>
              </div>
              <div style={{ clear: 'both', float: 'left', marginTop: 16 }}>
                <p>
                Quer ir ao seu restaurante favorito? Verifique se ele possui o menu digital para consumo no local.
                </p>
              </div>
            </div>


            <div style={{marginBottom: 32, clear: 'both', float: 'left'}}>
              <div>
                <IconTitle> <img src={icon4} /> </IconTitle>
                <Title>Acompanhamento de conta</Title>
              </div>
              <div style={{ clear: 'both', float: 'left', marginTop: 16 }}>
                <p>
                Sente-se na mesa do estabelecimento e solicite ao garçom o seu codigo de acompanhamento, assim poderá acompanhar tudo que é lançado em sua conta.
                </p>
              </div>
            </div>


            <div style={{marginBottom: 32, clear: 'both', float: 'left',}}>
              <div>
                <IconTitle> <img src={icon5} /> </IconTitle>
                <Title>Fidelidade</Title>
              </div>
              <div style={{ clear: 'both', float: 'left', marginTop: 16 }}>
                <p>
                  Disponibilizamos para alguns de nossos parceiros um plano de fidelidade, onde você ganha pontos consumindo normalmente e posteriormente poderá trocar por prêmios do proprio estabelecimento.
                </p>
              </div>
            </div>

            <div style={{marginBottom: 32, clear: 'both', float: 'left',}}>
              <div>
                <IconTitle> <img src={icon6} /> </IconTitle>
                <Title>Avaliação</Title>
              </div>
              <div style={{ clear: 'both', float: 'left', marginTop: 16 }}>
                <p>
                Envie uma avaliação do Estabelecimento que você frequentou, pode ser uma critica, uma sugestão ou um elogio.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default ComoFuncionaPage;