import React from 'react';
import SelectCidade from '../../../../components/Inputs/SelectCidade';
import SelectEspecialidades from '../../../../components/Inputs/SelectEspecialidades';
import styled from 'styled-components';
import { InputBase } from '@material-ui/core';
import { Row, Col } from 'reactstrap';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';

const Linha = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
`

const Coluna = styled.div` 
    display: flex;
`
const SearchIconContainer = styled(SearchIcon)`
 
`;

const TagFiltro = styled.div`
    background-color: ${props => props.active ? '#E2392E' : 'white'};
    border: 1px solid #E2392E;
    color: ${props => props.active ? 'white' : '#E2392E'};
    padding: 6px 16px ;
    border-radius: 6px;
    margin-right: 16px;
    cursor: pointer;
   
    &:hover {

    }
`

const TextFieldContainer = styled(InputBase)`
    input {
        font-size: 16px; 
        border: 1px solid #aaa;
        padding: 10px 20px;
        padding-left: 44px;
        border-radius: 6px;
        height: 25px;

        &:focus {
            border-color: #333;
        }
    }
`;

const ContainerSearch = styled.div`
    position: relative;

    ${SearchIconContainer} {
        position: absolute;
        left: 14px;
        top: 12px;
    } 
`

function FiltroRestaurantes({ filtros, setFiltro }) {

    return (
        <Row>
            <Col sm={9}>
                <Row>
                    <Col sm={4}>
                        <Linha>
                            <Coluna style={{ paddingTop: 8, marginRight: 8 }}>Filtre por</Coluna>
                            <Coluna style={{ flex: 1 }}>
                                <SelectCidade value={filtros.cidadeId} onChange={(value) => setFiltro('cidadeId', value)} />
                            </Coluna>
                        </Linha>
                    </Col>

                    <Col sm={3}>
                        <Linha>
                            <SelectEspecialidades value={filtros.especialidadeId} onChange={(value) => setFiltro('especialidadeId', value)} />
                        </Linha>
                    </Col>
                    <Col sm={5}>
                        <Linha>
                            <Coluna>
                                <TagFiltro
                                    active={filtros.tipoEmpresa === 2}
                                    onClick={() => setFiltro('tipoEmpresa', 2)}>
                                    <CheckIcon />
                                    <span> Delivery </span>
                                </TagFiltro>
                            </Coluna>
                            <Coluna>
                                <TagFiltro
                                    active={filtros.tipoEmpresa === 1}
                                    onClick={() => setFiltro('tipoEmpresa', 1)}>
                                    <CheckIcon />
                                    <span> Menu </span>
                                </TagFiltro>
                            </Coluna>
                        </Linha>
                    </Col>

                </Row>
            </Col>
            <Col sm={3}>
                <Linha>
                    <ContainerSearch>
                        <SearchIconContainer />
                        <TextFieldContainer
                            fullWidth
                            size="small"
                            placeholder="Busque por nome"
                            value={filtros.nome}
                            onChange={(ev) => setFiltro('nome', ev.target.value)}
                            variant="outlined"
                        />
                    </ContainerSearch>
                </Linha>
            </Col>
        </Row>
    )
}

export default FiltroRestaurantes;