import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { findCidadesAtivas } from 'services/estado';
import { TextField } from '@material-ui/core';

export default function SelectCidade({ disabled, value, onChange }) {

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {

    (() => {
      const item = items.find(x => x.id === value);
      setInputValue(item?.nomeFull)
    })();

  }, [items, value]);

  useEffect(() => {

    (async () => {

      try {
        setIsLoading(true);

        const data = await findCidadesAtivas();

        setItems(data);
      } catch (e) {

      } finally {
        setIsLoading(false);
      }

    })();
  }, []);

  return (
    <Autocomplete
      value={value}
      inputValue={inputValue || ''}
      onInputChange={(ev) => setInputValue(ev.target.value)}
      disabled={disabled}
      onChange={(ev, newValue) => {
        onChange(newValue?.id || null);
      }}
      options={items}
      loading={isLoading}
      fullWidth
      loadingText="Carregando..."
      getOptionLabel={(option) => option.nomeFull}
      renderInput={(params) => <TextField disabled={disabled} {...params} size="small" fullWidth label="Cidade" variant="outlined" />}
    />
  )
}