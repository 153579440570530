import React from "react";
import { formatMoney } from "infra/utils";
import RangeQuantidade from "./RangeQuantidade";
import { Row, Col } from "reactstrap";

class RenderVariacaoItem extends React.PureComponent {
  state = {
    maximoDisponivelParaVariacao: this.props.item.qtdeMax
  };

  componentDidMount = () => {
    this.updateMaximoDisponivelParaVariacao();
    // if (this.props.onRef) {
    //   this.props.onRef(this);
    // }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.item !== this.props.item) {
      this.updateMaximoDisponivelParaVariacao();
    }
  }

  calculeQuantidadeMaxima = variacao => {
    let quantidadeJaUsada = 0;

    variacao.items.forEach(item => {
      quantidadeJaUsada += item.quantidade || 0;
    });

    return variacao.qtdeMax - quantidadeJaUsada;
  };

  onChangeQuantidadeMultiplaESoma = (value, item) => {
    const { item: variacao } = this.props;

    let quantidadeJaSelecionada = 0;

    variacao.items.forEach(itemFor => {
      quantidadeJaSelecionada += itemFor.quantidade;

      if (itemFor.id === item.id) {
        itemFor.quantidade = value;
      }
    });

    variacao.quantidadeJaSelecionada = quantidadeJaSelecionada;

    this.props.onChange(variacao);

    this.updateMaximoDisponivelParaVariacao();

    this.forceUpdate();
  };

  updateMaximoDisponivelParaVariacao = () => {
    const variacao = this.props.item;

    this.setState({
      maximoDisponivelParaVariacao: this.calculeQuantidadeMaxima(variacao)
    });
  };

  onChangeQuantidadeRadio = item => {

    if (this.props.readonly) {
      return;
    }

    const variacao = this.props.item;

    const isAdicao = !item.quantidade;

    let quantidadeJaSelecionada = 0;

    variacao.items.forEach(itemFor => {
      itemFor.quantidade = 0;

      if (itemFor.id === item.id) {
        itemFor.quantidade = isAdicao ? 1 : 0;
      }

      quantidadeJaSelecionada += itemFor.quantidade;
    });

    variacao.quantidadeJaSelecionada = quantidadeJaSelecionada;

    this.props.onChange(variacao);
  };

  renderMultiple = item => {

    if (this.props.readonly) return null;

    const { maximoDisponivelParaVariacao } = this.state;

    item.quantidade = item.quantidade || 0;

    const max = item.quantidade + maximoDisponivelParaVariacao;

    return (
      <RangeQuantidade
        min={0}
        max={max}
        value={item.quantidade}
        onChange={value => this.onChangeQuantidadeMultiplaESoma(value, item)}
      />
    );
  };

  render = () => {
    const { item, tamanhoVariacaoProdutoId, readonly } = this.props;

    const items = (item.items || []).filter(x => x.tamanhoVariacaoProdutoId === tamanhoVariacaoProdutoId);

    if (items.length === 0) {
      return <div />;
    }

    return (
      <Col sm={6}>
        <div
          style={{
            backgroundColor: "#f7f7f7",
            marginTop: 20,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 16,
            borderRadius: 4
          }}
        >
          <div
            style={{
              flexDirection: "column",
              marginBottom: 6,
              marginTop: 6,
              paddingLeft: 0,
              paddingRight: 16,
              display: "flex"
            }}
          >
            <div>
              <div style={{ fontSize: 18, fontWeight: "bold" }}>
                {item.nomeMostra}
              </div>
            </div>
            <div>
              {item.qtdeMax > 1 && item.flgTipo !== "S" ? (
                <div style={{ fontSize: 12, color: "#999" }}>
                  Escolha as {item.qtdeMax} partes deste produto.
                </div>
              ) : null}
              {item.flgTipo === "S" ? (
                <div style={{ fontSize: 12, color: "#999" }}>
                  Escolha até {item.qtdeMax}{" "}
                  {item.qtdeMax > 1 ? "opções" : "opção"}.
                </div>
              ) : null}
            </div>
          </div>

          {items.map((itemFor, key) => (
            <div
              style={{
                marginLeft: 0,
                marginRight: 0,
                paddingRight: 0,
                borderBottom: "1px solid #ddd",
                paddingTop: 8,
                paddingBottom: 8,
                cursor: item.flgTipo === 'U' && !readonly ? 'pointer' : null
              }}
              onClick={() =>
                item.flgTipo === "U"
                  ? this.onChangeQuantidadeRadio(itemFor)
                  : undefined
              }
              key={key}
            >
              <div>
                <Row>
                  <Col xs={8}>
                    <div style={{ fontSize: 14 }}>{itemFor.nomeCompleto}</div>

                    {!!itemFor.textoInformativo ? (
                      <Row style={{ marginTop: 10 }}>
                        <Col>
                          <div
                            style={{
                              fontSize: 12,
                              color: "#999"
                            }}
                          >
                            {itemFor.textoInformativo}
                          </div>
                        </Col>
                      </Row>
                    ) : null}

                    <div>
                      <div
                        style={{
                          fontWeight: item.selected && "bold",
                          marginTop: 5,
                          fontSize: 14
                        }}
                      >
                        R$ {formatMoney(itemFor.preco)}
                      </div>
                    </div>
                  </Col>

                  <Col xs={4}>
                    <div>
                      <div style={{ textAlign: "right" }}>
                        {item.flgTipo === "U" ? (
                          itemFor.quantidade === 1 ? (
                            <span className="fa fa-check-circle" />
                          ) : null
                        ) : (
                            this.renderMultiple(itemFor)
                          )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ))}
        </div>
      </Col>
    );
  };
}

export default RenderVariacaoItem;
