import React, { useRef } from "react";
import Appbar from "../../../components/Appbar";
import styled from "styled-components";
import ScrollTop from "../../../components/ScrollTop";
import Footer from "../../../components/Footer";
import ListaCardapio from "./components/ListaCardapio";
import TopoMenu from "./components/TopoMenu";

const Body = styled.div`
  padding-top: 90px;

  @media all and (max-width: 768px) {
    & {
      padding-top: 30px;
    }
  }
`;

function MenuPage(props) {
  const ref = useRef(null);

  return (
    <Body>
      <div ref={ref}>
        <Appbar />

        <TopoMenu />

        <ListaCardapio refParent={ref} {...props}/>

        <Footer />
        <ScrollTop />
      </div>
    </Body>
  );
}

export default MenuPage;
