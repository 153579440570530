import React, { useContext, useState } from "react";
import { getRetirarNoLocal } from "services/cart";

import { formatMoney } from "infra/utils";
import AuthClient from "infra/auth/AuthClient";

import { LayoutContext } from "contexts/layout";

import ModalEnderecos from "../../ModalEnderecos";
import useCookies from "infra/hooks/useCookies";

function BoxEndereco({ taxaEntrega }) {
  const { layoutData } = useContext(LayoutContext);

  const [openModalEndereco, setOpenModalEndereco] = useState(false);
  const { getCookie } = useCookies();

  const authData = AuthClient.getData();
  const isUserLogged = AuthClient.isUserLogged();
const isPedidoOff = !isUserLogged && !!getCookie("idPedidoOff") && !!getCookie("chavePedidoOff") && layoutData?.wgPedidoDeliveryOFF;
  const endereco =authData?.endereco || {}
  const retirarNoLocal = getRetirarNoLocal();
  const isPontoEntrega = !!localStorage.getItem("pontoEntrega");

  let textoEntrega = "Endereço não informado";
  if (isPontoEntrega) {
    textoEntrega = `R$ ${formatMoney(taxaEntrega)}`;
  } else if (retirarNoLocal) {
    textoEntrega = "Retirar no local";
  } else if (endereco?.flgAceitaEntrega || endereco?.chave || isPedidoOff) {
    if (taxaEntrega) {
      textoEntrega = `R$ ${formatMoney(taxaEntrega)}`;
    } else {
      textoEntrega = "Grátis";
    }
  } else {
    textoEntrega = "Não entrega neste endereço";
  }

  return (
    <>
      <ModalEnderecos
        open={openModalEndereco}
        layoutData={layoutData}
        onToggle={() => {
          setOpenModalEndereco(false);
        }}
        onCallbackAfterSelectedPrincipal={() => {
          setOpenModalEndereco(false);
        }}
      />

      <div style={{ display: "flex", marginBottom: 8 }}>
        <div style={{ flex: 1 }}>Taxa de Entrega</div>

        <div
          style={{
            color:
              textoEntrega === "Não entrega neste endereço" ? "red" : "#333",
          }}
        >
          {textoEntrega}

          {textoEntrega === "Não entrega neste endereço" ? (
            <div
              onClick={() => setOpenModalEndereco(true)}
              style={{ color: "#0293fd", cursor: "pointer" }}
            >
              Alterar endereço
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default BoxEndereco;