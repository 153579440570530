/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { getAvaliacoesDelivery } from "services/empresa";
import { LayoutContext } from "contexts/layout";
import styled from 'styled-components';
import moment from "moment";

const AvaliacaoContainer = styled.div`
  width: 100%;
  float: left;
  overflow: hidden;
  margin-bottom: 32px;
`;

const AvaliacaoLeft = styled.div`
  float: left;

  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;

const AvaliacaoBody = styled.div`
  float: left;
  margin-left: 16px;
  width: calc(100% - 70px);
`;

const AvaliacaoBodyNome = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const AvaliacaoBodyMensagem = styled.div`
  font-size: 18px;
  float: left;
`;

const AvaliacaoBodyMensagemResposta = styled.div`
  background: #f7f7f7;
  padding: 8px 16px;
  margin-top: 16px;
  border-radius: 6px;
  font-size: 13px; 
  float: left;
  width: 100%;
`;

function ModalListaAvaliacao({ open, onClose, onAvaliar }) {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const { layoutData } = useContext(LayoutContext);

  useEffect(() => {

    (async () => {
      if (!open) return;

      try {

        setIsLoading(true);

        const avaliacoes = await getAvaliacoesDelivery(layoutData.id);

        setItems(avaliacoes);

      } catch (e) {

      } finally {
        setIsLoading(false);
      }
    })();

  }, [layoutData.id, open]);

  return (
    <div>
      <Modal isOpen={open} toggle={onClose}>
        <ModalHeader toggle={onClose}>Avaliações</ModalHeader>

        <ModalBody>

          <div>
            {isLoading ? <Spinner style={{ margin: '32px auto', display: 'table' }} /> : null}
          </div>

          <div>

            {items.map((item, index) => (
              <AvaliacaoContainer key={index}>
                <AvaliacaoLeft>
                  <img src={item.pessoaImagemUrl} alt={item.pessoaNome} />
                </AvaliacaoLeft>
                <AvaliacaoBody>
                  <div style={{ width: '100%' }}>
                    <AvaliacaoBodyNome>{item.pessoaNome}</AvaliacaoBodyNome>
                    <AvaliacaoBodyMensagem>
                      <div style={{ marginTop: -10 }}>
                        <span>
                          {[...Array(item.avaliacao)].map((x, i) => <i key={i} className="fa fa-star" style={{ color: layoutData.primaryColor, marginRight: 6, fontSize: 13 }} />)}
                        </span>
                        <small style={{ fontSize: 12, color: '#888' }}>
                          {moment(item.dataHoraAvaliacao).format('DD/MM/YYYY')}
                        </small>
                      </div>
                      <p style={{fontSize: 14}}>{item.mensagem}</p>
                    </AvaliacaoBodyMensagem>
                    {item.mensagemResposta ? (
                      <AvaliacaoBodyMensagemResposta>
                        <p style={{fontSize: 11}}>{moment(item.dataHoraResposta).format('DD/MM/YYYY')}</p>
                        <p>{item.mensagemResposta}</p>
                      </AvaliacaoBodyMensagemResposta>
                    ) : null}
                  </div>
                </AvaliacaoBody>
              </AvaliacaoContainer>
            ))}

          </div>

        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            block
            onClick={() => onAvaliar()}>
            Avaliar Restaurante
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
}

export default ModalListaAvaliacao;
