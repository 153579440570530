import React from "react";
import { Row, Col } from "reactstrap";
import { BoxResumo, BoxHeaderResumo } from "./styles";
import { formatMoney } from "../../../../../../../../infra/utils";
import { Ambiente } from "../../../types";

interface ResumoEspacoProps {
  ambiente: Ambiente;
}

function ResumoEspaco({ ambiente }: ResumoEspacoProps) {
  return (
    <BoxResumo>
      <BoxHeaderResumo>
        <div style={{ flex: 1 }}>Espaço</div>
        <div>Valor</div>
      </BoxHeaderResumo>

      <div style={{ padding: 15 }}>
        <Row>
          <Col sm={3}>
            <img src={ambiente?.imagemUrl} alt={ambiente?.nome} />
          </Col>
          <Col
            md={7}
            style={{
              paddingLeft: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <h5>{ambiente?.nome}</h5>
              <span>Até {ambiente?.qtdePessoas} pessoas</span>
            </div>
          </Col>
          <Col
            md={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            R$ {formatMoney(ambiente?.preco)}
          </Col>
        </Row>
      </div>
    </BoxResumo>
  );
}

export default ResumoEspaco;
