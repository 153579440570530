import React, { useState, useEffect } from "react";
import { findPedido } from "services/pedido";
import { Container, Spinner } from "reactstrap";
import PedidoDetalheItem from "../../HistoricoPedidosPage/components/PedidoItem";
import PedidoItem from "./PedidoItem";
import { List } from "@material-ui/core";

function ListaPedidos(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [pedido, setPedido] = useState({
    pedidoItem: []
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const data = await findPedido(props.match.params.id);
 
        setPedido(data);
      } catch (e) { 
      } finally {
        setIsLoading(false);
      }
    })();
  }, [props.match.params.id]);

  return (
    <Container>
      {isLoading && <Spinner />}

      <h2>Pedido #{props.match.params.id}</h2>

      <br />

      <PedidoDetalheItem item={pedido} />

      <div>
        <List>
          {pedido.pedidoItem.map(item => (
            <PedidoItem item={item} />
          ))}
        </List>
      </div>
      <br />
    </Container>
  );
}

export default ListaPedidos;
