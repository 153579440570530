import React, { useState } from "react";
import {
  CircularProgress,
  Menu,
  MenuItem,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import CreditCardImage, { getBandeira } from "./CreditCardImage";
import MenuIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
import { useEffect } from "react";
import Swal from "sweetalert2";
import {
  useExcluirFormaPagamento,
  useMudarPrincipalFormaPagamento,
} from "actions/forma-pagamento";

function MinhaFormaPagamentoItem({ item, onClick, selected, onRefresh }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    excluir,
    success: successExcluir,
    isLoading: isLoadingExcluir,
  } = useExcluirFormaPagamento(item?.id);
  const {
    mudarPrincipal,
    success: successAlterar,
    isLoading: isLoadingAlterar,
  } = useMudarPrincipalFormaPagamento(item?.id);

  const handleClick = (event) => {
    if (isLoadingExcluir || isLoadingAlterar) return;

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (isLoadingExcluir || isLoadingAlterar) return;

    setAnchorEl(null);
  };

  const handleExcluir = () => {
    if (isLoadingExcluir || isLoadingAlterar) return;

    Swal.fire({
      title: "Aviso",
      text: "Deseja realmente excluir?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Voltar",
    }).then((result) => {
      if (result.value) {
        excluir(item);
      }
    });
  };

  const handleMudarPrincipal = () => {
    if (isLoadingExcluir || isLoadingAlterar) return;

    mudarPrincipal();
  };

  useEffect(() => {
    if (successExcluir) {
      onRefresh(item);
    }
  }, [successExcluir, onRefresh, item]);

  useEffect(() => {
    if (successAlterar) {
      onRefresh(item);
      setAnchorEl(null);
    }
  }, [successAlterar, setAnchorEl, onRefresh, item]);
  return (
    <ListItem
      style={{
        cursor: typeof onClick === "function" ? "pointer" : "inherit",
        border: `1px solid ${selected ? "green" : "transparent"}`,
      }}
      onClick={() => (typeof onClick === "function" ? onClick(item) : null)}
    >
      <ListItemAvatar>
        <div
          style={{
            width: 60,
            height: 60,
            paddingTop: 16,
            marginRight: 16,
            backgroundColor: "#F0F1F3",
            textAlign: "center",
            borderRadius: "50%",
          }}
        >
          <CreditCardImage bandeira={item.bandeira} />
        </div>
      </ListItemAvatar>
      <ListItemText
        primary={item.nomePortador + (item.principal ? ` (Principal)` : "")}
        secondary={`${getBandeira(item?.bandeira)?.name} **** ${
          item.numerosFinais
        }`}
      />
      {typeof onClick !== "function" ? (
        <ListItemSecondaryAction>
          <IconButton onClick={handleClick} aria-label="delete">
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {!item?.principal ? (
              <MenuItem onClick={handleMudarPrincipal}>
                {" "}
                {isLoadingAlterar ? (
                  <CircularProgress size="small" />
                ) : (
                  <ReplayOutlinedIcon />
                )}{" "}
                Mudar para principal
              </MenuItem>
            ) : null}
            <MenuItem onClick={handleExcluir}>
              {" "}
              {isLoadingExcluir ? (
                <CircularProgress size="small" />
              ) : (
                <DeleteIcon />
              )}{" "}
              Excluir cartão
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
}

export default MinhaFormaPagamentoItem;
