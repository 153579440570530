import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";

import { Produto as ProdutoType } from "../../../types";

import { CardapioImage, CardapioItemContainer } from "./styles";

interface CardapioItemProps {
  item: ProdutoType;
  onClick?: any;
  primaryColor: string;
}

function CardapioItem({
  item,
  onClick = null,
  primaryColor,
}: CardapioItemProps) {
  return (
    <CardapioItemContainer primaryColor={primaryColor}>
      <Card onClick={onClick}>
        <CardapioImage>
          <img src={item.imagemUrl} alt={item.nome} />
        </CardapioImage>

        <CardContent>
          <Typography variant="h5" component="h4">
            {item.nome}
          </Typography>

          <br />

          {item.descricao ? (
            <div>
              <Typography style={{ fontSize: "11px" }}>Sabores:</Typography>
              <Typography>{item.descricao}</Typography>
            </div>
          ) : null}
        </CardContent>
      </Card>
    </CardapioItemContainer>
  );
}

export default CardapioItem;
