import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { Container, Spinner } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { findPedido } from "services/pedido";

import Appbar from "../../components/Appbar";
import ScrollTop from "../../components/ScrollTop";
import Footer from "../../components/Footer";
import { QRCodeSVG } from "qrcode.react";

const Body = styled.div`
  min-height: 100vh;
  padding-top: 120px;

  @media all and (max-width: 768px) {
    & {
      padding-top: 64px;
    }
  }
`;

function PagamentoPixPage(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [pedido, setPedido] = useState<any>({});
  const [isCopiado, setIsCopiado] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    // TODO, verificar se o pedido ja foi pago para mandar pra tela de detalhe
    findPedido(props.match.params.id)
      .then(setPedido)
      .finally(() => setIsLoading(false));
  }, [props]);
  return (
    <Body>
      <Appbar />

      <Container style={{ maxWidth: 768, minHeight: "80vh" }}>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner />
          </div>
        ) : null}

        {!isLoading && pedido ? (
          <div style={{ marginTop: 32 }}>
            <h3 style={{ textAlign: "center" }}>
              Copie a chave PIX e entre em seu banco para efetuar o pagamento
            </h3>

            <div style={{ textAlign: "center" }}>
            
        <QRCodeSVG size={200} value={pedido?.pix?.qrCodeData} />

            </div>
            <br />

            <div style={{ textAlign: "center", margin: "32px 0" }}>
              <CopyToClipboard
                text={pedido?.pix?.qrCodeData as any}
                onCopy={() => setIsCopiado(true)}
              >
                <Button color="primary" variant="contained">
                  {isCopiado ? "Copiado =)" : "Copiar PIX"}
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        ) : null}
      </Container>

      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default PagamentoPixPage;
