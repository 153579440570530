import React, { useEffect } from 'react';
import useGetDetalhesReserva from '../../hooks/useGetDetalhesReserva';
import styled from 'styled-components';
import { Incrementor } from 'ui/components/Inputs/Incrementor';
import { formatMoney } from 'infra/utils';
import { ButtonContainer } from '../../styles';
import { Button } from '@material-ui/core';

export const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  maxWidth: 700px;
`;

export const StyledH4 = styled.h4`
  font-size: 18px;
  margin-bottom: 16px;
`;

export const StyledH3 = styled.h3`
  font-size: 22px;
  text-align: center;
`;

export const StyledH2 = styled.h2`
  color: #666;
`;

export const ContainerText = styled.div`
  display: flex;
  background-color: #dae1ed;
  padding: 4px;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const Loading = () => <div>Carregando...</div>;

const Resumo = ({ selectedDate, reserva, setReserva, goToStep3, isLoadingCriarReserva }) => {
  const { data: detalhes, isLoading } = useGetDetalhesReserva(selectedDate);

  const total = detalhes?.flgReservaTipoCobranca === "P" ? detalhes?.flgReservaValorUnitario * reserva.qtdePessoas : detalhes?.flgReservaValorUnitario;

  useEffect(() => {
    setReserva({
      ...reserva,
      valorTotal: total
    })
  }, [reserva, setReserva, total])

  if (isLoading) return <Loading />

  return (
    <>
      <CenteredDiv>
        <StyledH4 style={{ textAlign: "center" }}>{detalhes?.reservaTextoPadrao}</StyledH4>
        <ContainerText>
          <StyledH3>Horário previsto de chegada</StyledH3>
        </ContainerText>
        <IncrementorContainer value={reserva.horaPrevista.ticks} setValue={(value) => setReserva({ ...reserva, horaPrevista: { ticks: value } })} tipo="horas" />
        <StyledH4>Custo por pessoa: R$ {formatMoney(detalhes?.flgReservaValorUnitario)}</StyledH4>
        <ContainerText>
          <StyledH3 style={{ textAlign: "center" }}>Informe a quantidade de pessoas</StyledH3>
        </ContainerText>
        <IncrementorContainer value={reserva.qtdePessoas} setValue={(value) => setReserva({ ...reserva, qtdePessoas: value })} tipo="quantidade" />
        {detalhes?.flgReservaTipoCobranca !== "N" && (
          <>

            <TotalContainer total={total} />
            <Instructions />
          </>
        )}
      </CenteredDiv>
      <ButtonContainer>
        <ReservaButton disabled={reserva.qtdePessoas === 0 || isLoadingCriarReserva} onClick={goToStep3} isLoadingCriarReserva={isLoadingCriarReserva} />
      </ButtonContainer>
    </>
  )
}

const IncrementorContainer = ({ value, setValue, tipo }) => (
  <div style={{ marginBottom: 16 }}>
    <Incrementor value={value} setValue={setValue} tipo={tipo} />
  </div>
);

const TotalContainer = ({ total }) => (
  <div>
    <StyledH2>Total: <b>R$ {formatMoney(total)}</b></StyledH2>
  </div>
);

const Instructions = () => (
  <div style={{ fontSize: 16, marginTop: 16, textAlign: "center" }}>
    <p> Será gerado um QRCODE PIX com o valor total da reserva.</p>
    <p> Para confirmar a reserva efetue o pagamento em até 30 min.</p>
  </div>
);

const ReservaButton = ({ disabled, onClick, isLoadingCriarReserva }) => (
  <Button variant="contained" disabled={disabled} onClick={onClick} color="primary" style={{
    height: 50,
  }}>
    {isLoadingCriarReserva ? 'Carregando...' : 'SOLICITAR RESERVA'}
  </Button>
);

export default Resumo;