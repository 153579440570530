import { useState, useCallback } from 'react';
import { excluirFormaPagamento } from 'services/pedido';

export default function useExcluirFormaPagamento(id) {

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const excluir = useCallback(async () => {

    try {

      setIsLoading(true);

      await excluirFormaPagamento(id);

      setSuccess(true);

      setTimeout(() => setSuccess(false), 100);

    } catch (error) {

      let errorText = '';

      if (typeof error === 'object') {
        errorText = error.response?.data?.details[0]?.errors[0];
      }

      alert(errorText || "Houve um problema ao excluir a forma de pagamento.");
 
    } finally {
      setIsLoading(false)
    }

  }, [id]);

  return {
    excluir,
    isLoading,
    success
  }
}
