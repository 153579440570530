/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Button as ButtonMaterial } from "@material-ui/core";
import InputMask from "react-input-mask";

import { useCriarFormaPagamento } from "actions/forma-pagamento";
import ModalCadastroEndereco from "../../components/ModalCadastroEndereco";
import EnderecoCard from "../../components/EnderecoCard";

function ModalCadastrarFormaPagamento({ open, onClose }) {
  const [openModalCadastroEndereco, setOpenModalCadastroEndereco] =
    useState(false);
  const { form, change, salvar, isLoading, success } = useCriarFormaPagamento();

  useEffect(() => {
    if (success) {
      onClose(true);
    }
  }, [success, onClose]);

  const onChangeEndereco = (value) => {
    change("nomeCidade", value.nomeCidade);
    change("nomeEstado", value.nomeEstado);
    change("nomeBairro", value.nomeBairro);
    change("logradouro", value.logradouro);
    change("numero", value.numero);
    change("bairroId", value.bairroId);
    change("complemento", value.complemento);
    change("referencia", value.referencia);
    change("cep", value.cep);
  };

  return (
    <div>
      <Modal isOpen={open} toggle={() => onClose()}>
        <ModalHeader toggle={() => onClose()}>Fazer Cadastro</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Dados do cartão</Label>
            <InputMask
              required
              mask="9999 9999 9999 9999"
              value={form.numeroCartao}
              onChange={(ev) => change("numeroCartao", ev.target.value)}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  name="cartao"
                  placeholder="N.º do Cartão"
                />
              )}
            </InputMask>

            <Row style={{ marginTop: 16 }}>
              <Col>
                <InputMask
                  required
                  mask="99/99"
                  value={form.expiracao}
                  onChange={(ev) => change("expiracao", ev.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      name="expiracao"
                      placeholder="Validade"
                    />
                  )}
                </InputMask>
              </Col>
              <Col>
                <InputMask
                  required
                  mask="999"
                  value={form.codigoSeguranca}
                  onChange={(ev) => change("codigoSeguranca", ev.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      name="codigoSeguranca"
                      placeholder="CVV"
                    />
                  )}
                </InputMask>
              </Col>
            </Row>
          </FormGroup>

          <br />

          <FormGroup>
            <Label>Nome do responsável do cartão</Label>
            <Input
              value={form.nomePortador}
              required
              onChange={(ev) => change("nomePortador", ev.target.value)}
              placeholder="Nome"
            />
          </FormGroup>

          <FormGroup>
            <Label>CPF</Label>
            <Input
              type="cpf"
              value={form.cpf}
              required
              onChange={(ev) => change("cpf", ev.target.value)}
              placeholder="CPF/CNPJ"
            />
          </FormGroup>

          {form.logradouro ? (
            <EnderecoCard
              item={form}
              hiddenAceitaEntrega={true}
              handleItemEdit={() => {
                setOpenModalCadastroEndereco(true);
              }}
            />
          ) : null}

          {!form.logradouro ? (
            <div style={{ marginTop: 8 }}>
              <ButtonMaterial
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => setOpenModalCadastroEndereco(true)}
              >
                Informar endereço
              </ButtonMaterial>
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => onClose()}>
            Cancelar
          </Button>
          <Button disabled={isLoading} color="primary" onClick={() => salvar()}>
            {isLoading ? "Salvando..." : "Salvar"}
          </Button>
        </ModalFooter>
      </Modal>

      <ModalCadastroEndereco
        open={openModalCadastroEndereco}
        onToggle={() => {
          setOpenModalCadastroEndereco(!openModalCadastroEndereco);
        }}
        item={{ ...form, id: true }}
        saveLocal
        onSaved={(endereco) => {
          onChangeEndereco(endereco);
          setOpenModalCadastroEndereco(false);
        }}
      />
    </div>
  );
}

export default ModalCadastrarFormaPagamento;
