import { useState, useCallback } from "react";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { criarFormaPagamento } from "services/pedido";
import valid from "card-validator";
import { showError } from "infra/alerta";

export default function useCriarFormaPagamento() {
  const [form, setForm] = useState({});
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const salvar = useCallback(async () => {
    try {
      const expiracao = form.expiracao?.replace(/ /g, "").split("/");

      form.mesExpiracao = expiracao ? expiracao[0] : null;
      form.anoExpiracao = expiracao ? expiracao[1] : null;

      if (!form.nomePortador) {
        return alert("Informe o nome do responsável do cartão.");
      }

      if (!form.numeroCartao) {
        return alert("Informe o número do cartão.");
      }

      if (!valid.number(form.numeroCartao).isValid) {
        return alert("Número do cartão é invalido.");
      }

      if (!form.mesExpiracao) {
        return alert("Informe o mês de validade do cartão.");
      }

      if (!form.anoExpiracao) {
        return alert("Informe o ano de validade do cartão.");
      }

      if (!form.codigoSeguranca) {
        return alert("Informe o código de segurança do cartão.");
      }

      if (
        form.cpf?.length > 0 &&
        form.cpf?.length <= 11 &&
        !cpf.isValid(form.cpf)
      ) {
        return alert("O CPF está inválido!");
      }

      if (form.cpf?.length > 11 && !cnpj.isValid(form.cpf)) {
        return alert("O CNPJ está inválido!");
      }

      setIsLoading(true);

      form.anoExpiracao = `20${form.anoExpiracao}`;
      form.numeroCartao = form.numeroCartao.replace(/ /g, "");
      if (form.id) {
        await criarFormaPagamento(form);
      } else {
        const res = await criarFormaPagamento(form);
        change("id", res.id);
      }

      setSuccess(true);

      setTimeout(() => setSuccess(false), 100);
    } catch (error) {
      const details = error.response?.data.details;

      if (details && details.length > 0) {
        for (const detail of details) {
          if (detail.errors && detail.errors.length > 0) {
            for (const error of detail.errors) {
              showError(error);
            }
          }
        }
      } else {
        // Caso não haja detalhes de erro específicos, você pode tratar o erro de outra forma ou simplesmente logá-lo.
        showError(error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [form]);

  const change = (prop, value) => setForm((f) => ({ ...f, [prop]: value }));

  return {
    form,
    change,
    salvar,
    isLoading,
    success,
  };
}
