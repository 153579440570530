/* eslint-disable no-useless-escape */
import axios from "axios";

import { MAPS_KEY, PLACE_KEY } from "infra/config";
import { getValueEndereco } from "infra/utils";
import { BASE_URL_API } from "infra/config";

import { procurarCep } from "./pessoa";
import { findAll as findAllEstados } from "./estado";

export async function requestPermissionLocation() {
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition((result) => {
      const { coords } = result;
      resolve(coords);
    }, reject);
  });
}

export async function checkPermissionLocation() {
  if (!navigator?.permissions) {
    return;
  }

  const result = await navigator.permissions.query({ name: "geolocation" });

  return result.state;
}

export async function buscarCidadesPorNome(nome, uf) {
  const { data } = await axios.get(
    `${BASE_URL_API}/v1/api/cidades/obter-cidade-por-nome?nome=${nome}&sigla=${uf.toLowerCase()}`
  );

  return data;
}

export async function validarBairroPorNome({
  nomeBairro,
  nomeCidade,
  siglaUf,
}) {
  const { data } = await axios.post(
    `${BASE_URL_API}/v1/api/bairros/validar-bairro`,
    {
      nomeBairro,
      nomeCidade,
      siglaUf,
    }
  );

  return data;
}

export async function getAddressByPostalCodeGoogleMapsApi(params = {}) {
  let address = [];
  let endereco = {};

  if (params.logradouro) {
    address.push(params.logradouro);
  }

  if (params.nomeBairro) {
    address.push(params.nomeBairro);
  }

  if (params.nomeCidade) {
    address.push(params.nomeCidade);
  }

  if (params.nomeEstado) {
    address.push(params.nomeEstado);
  }

  if (params.cep) {
    address.push(params.cep);
  }

  const uri = `https://maps.googleapis.com/maps/api/geocode/json?address=${address.join(
    ","
  )}&sensor=false&key=${MAPS_KEY}`;

  const { data } = await axios.get(uri);

  const result = data;

  if (result.results && result.results.length) {
    const address_components = result.results[0].address_components;
    const geometry = result.results[0].geometry;

    const numero = getValueEndereco("street_number", address_components);
    const logradouro = getValueEndereco("route", address_components);
    const bairro = getValueEndereco("sublocality_level_1", address_components);
    const cidade = getValueEndereco(
      "administrative_area_level_2",
      address_components
    );
    const estado = getValueEndereco(
      "administrative_area_level_1",
      address_components
    );
    const pais = getValueEndereco("country", address_components);

    endereco = {
      latitude: geometry.location.lat,
      longitude: geometry.location.lng,
      numero,
      logradouro,
      nomeBairro: bairro,
      nomeCidade: cidade,
      nomeEstado: estado,
      pais,
    };
  }

  return endereco;
}

export async function getCompleteAddressToLocation() {
  try {
    const { latitude, longitude } = await requestPermissionLocation();

    const endereco = await getEnderecoByLatLng(latitude, longitude);

    return endereco;
  } catch (e) {
    return {};
  }
}

export async function getEnderecoByGooglePlace(search) {
  const url = "https://maps.googleapis.com/maps/api/place/autocomplete/json";

  const params = {
    key: PLACE_KEY,
    language: "pt-BR",
    types: "address",
    input: search,
  };

  // const autocomplete = new google.maps.places.Autocomplete(search);

  try {
    const { latitude, longitude } = await requestPermissionLocation();
    params.locationbias = `circle:2000@${latitude},${longitude}`;
  } catch (e) {}

  const { data } = await axios.get(
    `${url}?key=${PLACE_KEY}&types=address&input=${search}`
  );

  return data;
}

export async function getDetalhesEnderecoGooglePlace(data) {
  const address = data.address_components;
  const location = data.geometry.location;

  let endereco = {
    latitude: location.lat(),
    longitude: location.lng(),
  };

  endereco.numero = getValueEndereco("street_number", address);
  endereco.logradouro = getValueEndereco("route", address);
  endereco.nomeBairro = getValueEndereco("sublocality_level_1", address);
  endereco.nomeCidade = getValueEndereco(
    "administrative_area_level_2",
    address
  );
  endereco.nomeEstado = getValueEndereco(
    "administrative_area_level_1",
    address
  );
  const estado = findAllEstados().find((v) => v.nome === endereco.nomeEstado);

  endereco.uf = estado ? estado.sigla : null;

  endereco.cep = getValueEndereco("postal_code", address) || "";
  endereco.cep = clearCep(endereco.cep);

  if ((endereco.cep || "").length === 8) {
    const enderecoCep = await getEnderecoByCep(endereco.cep);

    endereco.cepId = enderecoCep.cepId;
    endereco.ufId = enderecoCep.ufId;
    endereco.bairroId = enderecoCep.bairroId;
    endereco.cidadeId = enderecoCep.cidadeId;
    endereco.complemento = enderecoCep.complemento;
    endereco.habilitaCadastro = enderecoCep.habilitaCadastro;
  } else {
    // Caso nao tenha encontrado cep, eu busco o cep pela localizacao
    const enderecoByLatLng = await getEnderecoByLatLng(
      endereco.latitude,
      endereco.longitude
    );

    endereco.cep = enderecoByLatLng?.cep;
    endereco.cepId = enderecoByLatLng?.cepId;
    endereco.numero = endereco.numero || enderecoByLatLng?.numero;
    endereco.ufId = endereco.ufId || enderecoByLatLng?.ufId;
  }

  return endereco;
}

export async function getEnderecoByCep(cep) {
  const endereco = {};

  try {
    const res = await procurarCep(cep);

    endereco.logradouro = res.logradouro;
    endereco.complemento = res.complemento;
    endereco.referencia = res.referencia;
    endereco.bairroId = res.bairroId;
    endereco.cidadeId = res.cidadeId;
    endereco.ufId = res.ufId;
    endereco.cepId = res.id;
    endereco.habilitaCadastro = res.habilitaCadastro;
    endereco.nomeBairro = res.nomeBairro;
    endereco.nomeEstado = res.nomeEstado;
    endereco.nomeCidade = res.nomeCidade;
  } catch (e) {}

  return endereco;
}

export async function getEnderecoByLatLng(latitude, longitude) {
  try {
    // busca na api do google o endereço
    const uri = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${MAPS_KEY}`;

    const { data } = await axios.get(uri);

    const result = data;

    let endereco = {};

    if (result && result.results && result.results.length) {
      const address_components = result.results[0].address_components;

      const numero = getValueEndereco("street_number", address_components);
      const logradouro = getValueEndereco("route", address_components);
      const bairro = getValueEndereco(
        "sublocality_level_1",
        address_components
      );
      const cidade = getValueEndereco(
        "administrative_area_level_2",
        address_components
      );
      const estado = getValueEndereco(
        "administrative_area_level_1",
        address_components
      );
      const pais = getValueEndereco("country", address_components);
      const cep = clearCep(
        getValueEndereco("postal_code", address_components).replace("-", "")
      );

      const estadoUf = findAllEstados().find(
        (v) => v.nome === endereco.nomeEstado
      );

      endereco = {
        latitude: latitude,
        longitude: longitude,
        numero,
        logradouro,
        nomeBairro: bairro,
        nomeCidade: cidade || "",
        nomeEstado: estado || "",
        pais,
        cep: cep,
        uf: (estadoUf && estadoUf.sigla) || "",
        ufId: estadoUf && estadoUf.id,
        nome: "",
      };

      if ((cep || "").length === 8) {
        const enderecoCep = await getEnderecoByCep(cep);

        if (
          enderecoCep &&
          enderecoCep.nomeCidade &&
          enderecoCep.nomeCidade.toLowerCase() ===
            endereco.nomeCidade.toLowerCase() &&
          ((enderecoCep.ufSigla && enderecoCep.ufSigla.toLowerCase()) ===
            endereco.uf.toLowerCase() ||
            (enderecoCep.nomeEstado && enderecoCep.nomeEstado.toLowerCase()) ===
              endereco.nomeEstado.toLowerCase())
        ) {
          endereco.ufId = enderecoCep.ufId;
          endereco.cidadeId = enderecoCep.cidadeId;
          endereco.habilitaCadastro = enderecoCep.habilitaCadastro;
          endereco.cepId = enderecoCep.cepId;
          endereco.bairroId = enderecoCep.bairroId;
        }
      }

      if (!endereco.cidadeId && endereco.nomeBairro) {
        const enderecoCidadeBairroUf = await getEnderecoByCidadeNomeBairroNomeUF(
          {
            bairro: endereco.nomeBairro,
            cidade: endereco.nomeCidade,
            uf: endereco.uf,
          }
        );

        endereco.bairroId =
          endereco.bairroId || enderecoCidadeBairroUf.bairroId;
        endereco.cidadeId = enderecoCidadeBairroUf.cidadeId;
        endereco.ufId = endereco.ufId || enderecoCidadeBairroUf.ufId;
      }
    }

    return endereco;
  } catch (e) {
    return null;
  }
}

export async function getEnderecoByCidadeNomeBairroNomeUF({
  cidade,
  bairro,
  uf,
}) {
  let retorno = {};

  try {
    const validarBairro = await validarBairroPorNome({
      nomeBairro: bairro,
      nomeCidade: cidade,
      siglaUf: (uf || "").toLowerCase(),
    });

    if (validarBairro) {
      retorno.bairroId = validarBairro.id;
      retorno.cidadeId = validarBairro.cidadeId;
      retorno.ufId = validarBairro.ufId;
    }
  } catch (e) {}

  try {
    if (!retorno.cidadeId && cidade && uf) {
      const cidades = await buscarCidadesPorNome(cidade, uf);

      if (cidades.length) {
        retorno.cidadeId = cidades[0].id;
        retorno.ufId = cidades[0].ufId;
      }
    }
  } catch (e) {}

  return retorno;
}

export function clearCep(cep) {
  if (!cep) return null;

  return (
    cep.toString().replace(/\-/g, "").replace(/\./g, "").replace(/ /g, "") || ""
  );
}
