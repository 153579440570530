import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Spinner } from "reactstrap";
import useGetPontosEntrega from "../DeliveryPontoEntrega/hooks/useGetPontosEntrega";
import { DeliveryPointCard } from "../DeliveryPontoEntrega";
import { useLayoutContext } from "contexts/layout";

function ModalPontoEntrega({ open, onToggle }) {
  const { data, isLoading } = useGetPontosEntrega();
  const pontoEntrega = JSON.parse(localStorage.getItem("pontoEntrega")) ?? {};
  const {update:updateContext}= useLayoutContext();
  return (
    <div>
      <Modal size="lg" isOpen={open} toggle={onToggle}>
        <ModalHeader toggle={onToggle}>Endereço para entrega</ModalHeader>
        <ModalBody>
          <div style={{ marginBottom: 16, overflow: "hidden" }}>
            {isLoading && <Spinner color="primary" />}
          </div>

          <div style={{ clear: "both" }}>
            {data.map((item, key) => (
              <DeliveryPointCard
                key={item.id}
                point={item}
                onClick={() => {
                  localStorage.setItem("pontoEntrega", JSON.stringify(item));
                  onToggle();
                  updateContext();
                }}
                isSelected={pontoEntrega?.id === item.id} // Usa cadeia opcional para evitar erro se pontoEntrega for nulo
              />
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onToggle}>
            Sair
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalPontoEntrega;