import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  List,
  Typography,
} from "@material-ui/core";
import { findPremiacoes } from "services/fidelidade";
import { LayoutContext } from "contexts/layout";

import ModalRecompensa from "./ModalRecompensa";

export interface RecompensaResult {
  fidelidadeId: Number;
  fidelidadeContaId: Number;
  flgDisponivelAgora: boolean;
  id: Number;
  imagemUrlCompleta: string;
  nome: string;
  pontoCusto: Number;
  qtdeDisponivel: Number;
  texto: string;
}

interface DataResult {
  items: RecompensaResult[];
  grupo: Number;
}

function TabRecompensas() {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [previewPremio, setPreviewPremio] = useState<RecompensaResult | null>(
    null
  );
  const [data, setData] = useState<DataResult[]>([]);
  const { layoutData }: any = useContext(LayoutContext);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const data = await findPremiacoes(layoutData.fidelidadeId);

        let grupos: any = {};

        data.forEach((item: any) => {
          grupos[item.pontoCusto] = grupos[item.pontoCusto] || {
            grupo: item.pontoCusto,
            items: [],
          };
          grupos[item.pontoCusto].items.push(item);
        });

        const items: DataResult[] = [];

        for (const grupo in grupos) {
          items.push(grupos[grupo]);
        }

        setData(items);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [layoutData.fidelidadeId]);

  const onPreviewPremio = (premio: RecompensaResult) => {
    premio.fidelidadeContaId = layoutData.fidelidadeContaId as any;

    setOpenModal(true);

    setPreviewPremio(premio);
  };

  return (
    <Box style={{ padding: 16, minHeight: 400 }}>
      {isLoading ? (
        <div style={{ textAlign: "center", marginTop: 32 }}>
          <CircularProgress />
        </div>
      ) : null}

      <ModalRecompensa
        onClose={() => {
          setOpenModal(false);
        }}
        premio={previewPremio as RecompensaResult}
        visible={openModal}
      />

      <List>
        {data.map((item, index) => (
          <div style={{ marginBottom: 32 }}>
            <Typography
              style={{
                fontSize: 18,
                backgroundColor: "#f7f7f7",
                padding: "4px 8px",
                marginBottom: 32,
              }}
            >
              {item.grupo} pontos
            </Typography>
            <Row>
              {item.items.map((premio: RecompensaResult) => (
                <Col lg={4} md={4} sm={6} xs={12}>
                  <ListItemRecompensa
                    premio={premio}
                    onClick={() => onPreviewPremio(premio)}
                  />
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </List>
    </Box>
  );
}

function ListItemRecompensa({
  premio,
  onClick,
}: {
  premio: RecompensaResult;
  onClick: any;
}) {
  return (
    <div onClick={onClick}>
      <Card>
        <CardActionArea>
          <Typography style={{ padding: 16 }}>{premio.nome}</Typography>
          <img
            style={{ width: "100%" }}
            title={premio.nome}
            src={premio.imagemUrlCompleta}
            alt={premio.nome}
          />
        </CardActionArea>
        <CardContent>
          <Chip
            variant="outlined"
            color="primary"
            label={`${premio.pontoCusto} ponto(s)`}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default TabRecompensas;
