import React from "react";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import QRCode from "./QRCode";

function QRCodePage() {
  return <QRCode />;
}

export default QRCodePage;
