/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Container, Spinner } from 'reactstrap';
import { findPedidoMenu } from 'services/pedido';
import Resumo from '../../AcompanhamentoPage/components/Resumo';
import { QRCodeSVG } from 'qrcode.react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from '@material-ui/core';
import { Center, CircleStep, Flex, Text } from '../../Reserva/components/ReservaCriada';

const ContainerItem = styled.div`
  width: 100%;
  clear: both;
  border-bottom: 1px solid #ccc;
  padding-top: 16px;
  padding-bottom: 16px;
  float: left;
`;


const Label = styled.div`
  font-size: 14px;
`;

const LabelDescription = styled.div`
  font-size: 18px;
`;

function ResumoPedido(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [pedido, setPedido] = useState({});
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  useEffect(() => {

    (async () => {
      setIsLoading(true);

      try {
        const data = await findPedidoMenu(props.match.params.id);
        setPedido(data);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();


  }, [props.match.params.id]);


  return (
    <Container style={{ maxWidth: 768 }}>
      {isLoading && <Spinner />}
      <div style={{ clear: 'both', float: 'left', width: '100%' }}>
        <h2 style={{ textAlign: 'center', marginTop: 32 }}>Pedido #{props.match.params.id}</h2>
      </div>
      <br />
      <div style={{ clear: 'both', float: 'left', width: '100%' }}>
        <h5>Status do pedido</h5>
        <ContainerItem>
          <span>- {pedido.situacaoNome}</span>
        </ContainerItem>
        <ContainerItem>
          <Label>Data do pedido</Label>
          <LabelDescription>
            {moment(pedido.dataHoraPedido).format('DD/MM/YYYY [•] HH:mm')}
          </LabelDescription>
        </ContainerItem>
        <ContainerItem>
          <Label>{pedido.localizacaoNome ? 'Receber em' : 'Retirar no balcão'}</Label>
          {pedido.localizacaoNome && <LabelDescription>{pedido.localizacaoNome}</LabelDescription>}
        </ContainerItem>
        <ContainerItem>
          <Label>Resumo</Label>
          <Resumo items={pedido.pedidoItem} pedido={pedido} />
        </ContainerItem>
        <ContainerItem>
          <Label style={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: pedido.flgPago ? 'green' : 'red',
            marginBottom: 8,
          }}>{pedido.flgPago ? 'Pedido Pago' : 'Pagamento pendente'}</Label>
          {!pedido.flgPago && (
            <div style={{ textAlign: 'center' }}>
              <span style={{marginBottom:8}}>
                Você pode Copiar a Chave e Colar em seu Aplicativo Bancário com PIX ou escanear o QrCode abaixo
              </span>
              <div style={{ display: 'flex', justifyContent: 'center', margin: '8px 0' }}>
                <QRCodeSVG size={200} value={pedido?.pagamentoInfo?.pixQrCodeData} />
              </div>
              <br />
              <div style={{ textAlign: 'center', marginBottom: 16 }}>
                <CopyToClipboard text={pedido?.pagamentoInfo?.pixQrCodeData} onCopy={handleCopy}>
                  <Button color="primary" variant="contained" style={{ borderRadius: 12 }}>
                    {isCopied ? 'Copiado =)' : 'Copiar PIX'}
                  </Button>
                </CopyToClipboard>
              </div>
              <Text style={{ textAlign: 'center' }}>Pagar com Pix é seguro, fácil e rápido!</Text>
              <Center>
                <div>
                  <Flex>
                    <CircleStep>1</CircleStep>
                    <Text>Abra o aplicativo do seu banco e clique em Pix.</Text>
                  </Flex>
                  <Flex>
                    <CircleStep>2</CircleStep>
                    <Text>Clique em Pix e escolha a opção Pagar por QR Code.</Text>
                  </Flex>
                  <Flex>
                    <CircleStep>3</CircleStep>
                    <Text>Aponte a câmera do celular para o QR Code que será exibido após a finalização da compra.</Text>
                  </Flex>
                  <Flex>
                    <CircleStep>4</CircleStep>
                    <Text>Pronto, agora é só confirmar os dados e finalizar o pagamento com o Pix.</Text>
                  </Flex>
                </div>
              </Center>
            </div>
          )}
        </ContainerItem>
      </div>
      <br />
    </Container>
  );
}

export default ResumoPedido;