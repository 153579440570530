/* eslint-disable */
/* eslint-disable no-redeclare */
import { useState, useEffect } from "react";

import { BASE_URL_TACTO } from "./config";

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

export function formatMoney(value, c, d, t) {
  var n = value,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d === undefined ? "," : d,
    t = t === undefined ? "." : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}

export function formatPhone(value) {
  if (!value) {
    return null;
  }

  value = String(value);

  let ddd = null;
  let telefone = null;

  if (value.length === 10) {
    ddd = value.substr(0, 2);
    telefone = value.substr(2);
  } else if (value.length === 11) {
    ddd = value.substr(0, 2);
    telefone = value.substr(2);
  } else {
    telefone = value;
  }

  const twoPart = telefone.substr(-4);
  const firstPart = telefone.replace(twoPart, "");

  if (!ddd) {
    return `${firstPart}-${twoPart}`;
  }

  return `(${ddd}) ${firstPart}-${twoPart}`;
}

export function parseDoubleCustom(value, decimals = 3) {
  value = Number(value || 0);

  if (!value) {
    return 0;
  }

  return parseFloat(value.toFixed(decimals));
}

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function trim(string) {
  string = string || "";

  return string.trim();
}

export function removeAccents(value) {
  if (!value) {
    return null;
  }

  return value
    .replace(/[áàãâä]/gi, "a")
    .replace(/[éè¨ê]/gi, "e")
    .replace(/[íìïî]/gi, "i")
    .replace(/[óòöôõ]/gi, "o")
    .replace(/[úùüû]/gi, "u")
    .replace(/[ç]/gi, "c")
    .replace(/[ñ]/gi, "n")
    .replace(/[^a-zA-Z0-9]/g, " ");
}

export function getValueEndereco(type, address_components) {
  const address =
    address_components.find((x) => x.types.indexOf(type) > -1) || {};
  return address.long_name;
}

export function getEnderecoString(item) {
  const endereco = [];

  if (item.endBairroNome) {
    endereco.push(item.endBairroNome);
  }

  if (item.endCidadeNome) {
    endereco.push(item.endCidadeNome);
  }

  if (item.endUFSigla) {
    endereco.push(item.endUFSigla);
  }

  return endereco.join(", ");
}

export function createImage(chaveEmpresarial, empresaId, name) {
  return (
    BASE_URL_TACTO +
    "/v1/empresas/" +
    empresaId +
    "/imagem?c=" +
    chaveEmpresarial +
    "&b=" +
    name
  );
}

export function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => ++value); // update the state to force render
}
