import React from "react";
import { Row, Col } from "reactstrap";
import { BoxResumo, BoxHeaderResumo } from "./styles";
import { formatMoney } from "../../../../../../../../infra/utils";
import { Produto } from "../../../types";


interface ResumoCardapioProps {
  produto: Produto,
  quantidadePessoas: number;
} 


function ResumoCardapio({ produto, quantidadePessoas } : ResumoCardapioProps) {
  return (
    <BoxResumo>
      <BoxHeaderResumo>
        <div style={{ flex: 1 }}>Cardápio</div>
        <div>Valor</div>
      </BoxHeaderResumo>

      <div style={{ padding: 15 }}>
        <Row>
          <Col sm={3}>
            <img src={produto?.imagemUrl} alt={produto?.nome} />
          </Col>
          <Col
            md={7}
            style={{
              paddingLeft: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <h5>{produto?.nome}</h5>
              <span>R$ {formatMoney(produto?.valorBasePessoa)}/pessoa</span>
            </div>
          </Col>
          <Col
            md={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            R$
            {formatMoney(produto?.valorBasePessoa * quantidadePessoas)}
          </Col>
        </Row>
      </div>
    </BoxResumo>
  );
}

export default ResumoCardapio;
