import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { httpCache } from 'infra/axios';

const Wrapper = styled.div` 
  margin-bottom: 32px;
  background: white;
`

const Container = styled.div`
  max-width: 768px;
  margin: auto;
  display: flex;
  padding-top:32px;
  min-height: 100vh;

  flex-direction: column;
  background-color: ${(props) => (props.theme === 2 ? "#EBEBEB" : "#ffffff")};
  * {
    text-align: center;
  }
  p {
    color: ${(props) => (props.theme === 1 ? "#333333" : "#ffffff")};
  }
`;
const Image = styled.img`
  max-width: 150px;
  margin-bottom: 32px;
  @media screen and (max-width: 768px) {
    max-width: 120px;
  }
`;

function Corpo({ permalink }) {
  const [dados, setDados] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onLoad = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await httpCache.get(
        `/v3/api/empresas/shopping/${permalink}`
      );

      setDados(data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [permalink]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);



  if (isLoading) {
    return <p>Carregando...</p>;
  }
  return (
    <Wrapper>
      <Container theme={1}>
        <div>
          <Image src={dados.imagemUrl} />
          <h1 style={{
            color: '#727272'
          }}>{dados.nome}</h1>
          <p>{dados.mensagem}</p>
        </div>
        <div
          style={{
            width: '100%',
            backgroundColor: '#D8D9DB',
            color: '#727272',
            fontWeight: 'bold',
            padding: '8px',
          }}
        >
          LOJAS
        </div>
        {
          dados.empresas && dados.empresas.map((empresa, k) => (

            <div key={k}
              style={{
                cursor: 'pointer',
                marginTop: '8px',
                width: '100%',
                color: '#727272',
                padding: '8px',
                borderBottom: '1px solid #D8D9DB',
              }}
              onClick={() => {
                window.location.href = `/${empresa.permaLink}/qrcode`;
                }
              }
            >
              <Image src={empresa.logoUrl} />

              <p style={{
                fontSize: 24
              }}><b>{empresa.nome}</b></p>
              <p
                style={{
                  color: '#4F4F4F'
                }}
              > {empresa.mensagemPadrao}</p>   <p
              style={{
                color: '#4F4F4F'
              }}
            > {empresa.especialidades}</p>
            </div>
          ))

        }
      </Container>
    </Wrapper>
  )
}

export default Corpo;