import styled from "styled-components";

export const Body = styled.div`
  padding-top: 110px;

  @media all and (max-width: 768px) {
    & {
      padding-top: 30px;
    }
  }
`;