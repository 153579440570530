import React, { useCallback, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, 
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { MOTIVOS_CANCELAMENTO } from "services/pedido";

function ModalMotivoCancelamento({ open, isLoading, onClose, onOk }) {
  const [motivo, setMotivo] = useState(null); 

  const { addToast } = useToasts();

  const validar = useCallback(() => {
    if (!motivo) {
      return addToast("Informe o motivo do cancelamento", {
        appearance: "error",
        autoDismiss: true
      });
    }

    onOk(motivo);
  }, [addToast, motivo, onOk]);
 
  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Aviso</DialogTitle>
        <DialogContent>
          <h4> Por qual motivo deseja cancelar o pedido ?</h4>

          <p>
            Após o estabelecimento aceitar e iniciar a produção o pedido só
            poderá ser cancelado por telefone
          </p>

          <div>

            <RadioGroup name="motivo" value={Number(motivo)} onChange={(ev) => { 
              setMotivo(Number(ev.target.value))
            }}>
              {MOTIVOS_CANCELAMENTO.map(item => (
                <FormControlLabel value={Number(item.id)} control={<Radio />} label={item.nome} />
              ))}
            </RadioGroup>
 
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            Cancelar
          </Button>
          <Button disabled={isLoading} variant="contained" onClick={() => validar()} color="primary">
            Confirmar Cancelamento
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalMotivoCancelamento;
