import axios, { AxiosResponse } from "axios";
import { BASE_URL_API } from "infra/config";
import AuthClient, { Endereco } from "infra/auth/AuthClient";

export async function findBairros(cidadeId: number) {
  const { data } = await axios.get(
    `${BASE_URL_API}/v1/api/bairros/por-cidade?cidadeId=${cidadeId}`
  );

  return data;
}

export async function procurarCep(cep: string) {
  const { data } = await axios.get(`${BASE_URL_API}/v1/api/ceps/${cep}`);

  return data;
}

export async function getEnderecoPrincipal(
  params = {}
): Promise<Endereco | null> {
  const enderecos = await getEnderecosPessoa(params);

  const endereco = enderecos.find((x: Endereco) => x.flgPrincipal) || null;

  return endereco;
}

export async function getEnderecosPessoa(
  params: any = {}
): Promise<Endereco[]> {
  const { pessoaId } = params;

  let { data } = await axios.get(
    `${BASE_URL_API}/v1/api/pessoas-enderecos/${pessoaId}/PessoaId?pessoaId=${pessoaId}&empresaGlobalId=${
      params.empresaId || 0
    }`
  );

  data = data.sort((x: Endereco) => (x.flgPrincipal ? -1 : 1));

  const authData = AuthClient.getData();

  if (authData) {
    setAuthEnderecoPrincipal(data.find((x: Endereco) => x.flgPrincipal));
  }

  return data;
}

export function setAuthEnderecoPrincipal(endereco: any = null) {
  const authData = AuthClient.getData();

  if (authData) {
    authData.endereco = endereco;

    AuthClient.setData({
      ...authData,
    });
  }
}

export async function saveEnderecosPessoa(endereco: Endereco) {
  const auth = AuthClient.getData();

  if (!auth) {
    return null;
  }

  const { pessoaId } = auth;

  endereco.pessoaId = pessoaId;

  let response: AxiosResponse;

  if (!endereco.id) {
    response = await axios.post(
      `${BASE_URL_API}/v1/api/pessoas-enderecos/`,
      endereco
    );
  } else {
    response = await axios.put(
      `${BASE_URL_API}/v1/api/pessoas-enderecos/${endereco.id}`,
      endereco
    );
  }

  return response.data;
}

export async function deleteEnderecosPessoa(endereco: Endereco) {
  const { data } = await axios.delete(
    `${BASE_URL_API}/v1/api/pessoas-enderecos/${endereco.id}`
  );

  return data;
}

export async function setPrincipalEnderecosPessoa(enderecoId: number) {
  const { data } = await axios.put(
    `${BASE_URL_API}/v1/api/pessoas-enderecos/${enderecoId}/ativar`
  );

  return data;
}

export async function getTelefones() {
  const auth = AuthClient.getData();

  if (!auth) {
    return null;
  }

  const { pessoaId } = auth;

  const { data } = await axios.get(
    `${BASE_URL_API}/v1/pessoas-telefones/${pessoaId}/PessoaId?pessoaId=` +
      pessoaId
  );

  return data;
}

export async function saveTelefonePessoa(telefone: any) {
  const auth = AuthClient.getData();

  if (!auth) {
    return null;
  }

  const { pessoaId } = auth;

  telefone.pessoaId = pessoaId;
  telefone.tipo = 1;
  telefone.codPais = 55;
  telefone.codDdd = Number(telefone.codDdd);

  let response: AxiosResponse;

  if (!telefone.id) {
    response = await axios.post(
      `${BASE_URL_API}/v1/pessoas-telefones/`,
      telefone
    );
  } else {
    response = await axios.put(
      `${BASE_URL_API}/v1/pessoas-telefones/${telefone.id}`,
      telefone
    );
  }

  return response.data;
}

export async function deleteTelefonePessoa(telefone: any) {
  const { data } = await axios.delete(
    `${BASE_URL_API}/v1/pessoas-telefones/${telefone.id}`
  );

  return data;
}
