import React from 'react';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import Appbar from '../../components/Appbar';
import styled from 'styled-components';
import SliderMain from './components/SliderMain';
import Intro from './components/Intro';
import ScrollTop from '../../components/ScrollTop';
import Footer from '../../components/Footer';
import Contato from '../../components/Contato';
import InfosSemanal from './components/InfosSemanal';
import MenuSection from './components/MenuSection';

const Body = styled.div` 
  min-height: 100vh;
  padding-top: 135px;
`;

function IndexPage() {
  return (
    <Body>
      <Appbar />
      <SliderMain />
      <Intro />
      <MenuSection />
      <InfosSemanal />
      <Contato />
      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default IndexPage;
