import React from "react";
import { Router } from "react-router-dom";
import history from "./infra/router/history";
import Layouts from "./ui/layouts";
import { ToastProvider } from "react-toast-notifications";
import LayoutProvider from "./contexts/layout";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {
  return (
    <LayoutProvider>
      <Router history={history}>
        <ToastProvider>
          <Layouts />
        </ToastProvider>
      </Router>
    </LayoutProvider>
  );
}

export default App;
