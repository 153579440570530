/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Spinner } from "reactstrap";
import useGetMesas from "../Menu/MenuPage/hooks/useGetMesas";
import { getLocalizacaoMesaId, setLocalizacaoMesaId, setRetirarNoLocal } from "services/cart";

function ModalMesas({ open, onToggle, updateContext }) {
  const { data, isLoading } = useGetMesas();

  const mesaSelecionadaId = getLocalizacaoMesaId()
  return (
    <div>
      <Modal size="lg" isOpen={open} toggle={onToggle}>
        <ModalHeader toggle={onToggle}>Mesa</ModalHeader>
        <ModalBody>
          <div style={{ marginBottom: 16, overflow: "hidden" }}>
            {isLoading && <Spinner color="primary" />}
          </div>

          <div style={{ clear: "both" }}>
            {data.map((item, key) => (
              <div key={key}
              style={{
                border: item.id === mesaSelecionadaId?.id ? "2px solid red" : "1px solid #ddd", 
                padding: "10px",
                margin: "10px 0",
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                cursor: "pointer",
              }}
              onClick={
                () => {
                  setRetirarNoLocal(false)
                  setLocalizacaoMesaId(item) 
                  updateContext()
                  onToggle()
                }
              }
              > 
              Localização: {item?.nome}
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onToggle}>
            Sair
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalMesas;
