import React from "react";
import Appbar from "../../components/Appbar";
import styled from "styled-components";
import ScrollTop from "../../components/ScrollTop";
import Footer from "../../components/Footer";
import ListaPedidos from "./components/ListaPedidos";

const Body = styled.div` 
  min-height: 100vh;
  padding-top: 90px;
`;

function HistoricoPedidos(props) {
  return (
    <Body>
      <Appbar />
 
      <ListaPedidos />
      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default HistoricoPedidos;
