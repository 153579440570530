import React, { useContext, useEffect, useState } from "react";

import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

import layoutProvider from "infra/provider/layout-provider";
import authClient from "infra/auth/AuthClient";
import menu from "infra/router/menu";

import PreLoader from "ui/components/PreLoader";

import { refreshCart } from "services/cart";
import { GA_KEY } from "infra/config";

export const LayoutContext = React.createContext({
  layoutData: {},
  update: (_value: any): any => { },
});

export function useLayoutContext() {
  return useContext(LayoutContext);
}

function LayoutProvider({ children }: any): any {
  const [isLoading, setIsLoading] = useState(false);
  const [layoutData, setLayoutData] = useState({ menu });

  useEffect(() => {
    (async () => {
      const { document, location } = window;
      const { pathname, search } = location;
      const titleElement: HTMLTitleElement | any =
        document.querySelector("title");

      try {
        if (!pathname.includes("localhost")) {
          ReactGA.initialize(GA_KEY);
          ReactGA.pageview(pathname + search);
        }

        const isUserLogged = authClient.isUserLogged();

        setIsLoading(true);

        if (!isUserLogged) {
          authClient.logout();
          await authClient.loginClient();
        }

        let [tenant, tenant2] = pathname.split("/");

        if (tenant === "") {
          tenant = tenant2;
        }

        if (!tenant || tenant === "shopping" || tenant === "webgula") {
          tenant = "webgula";
        }
        if (isUserLogged) {
          const res = await authClient.getUserInfo();

          if (!res) {
            authClient.logout();
            await authClient.loginClient();
          }
        }

        let data = await layoutProvider.getLayoutConfiguration(tenant);

        if (!data) {
          location.href = location.origin;
          return;
        }

        data.tenant = tenant;

        data.menu = menu.filter(({ display }) => {
          if (typeof display === "function") {
            return display(data);
          }

          return display;
        });

        data.menu.forEach((item: any) => {
          if (item.action.includes(":path")) {
            item.action = item.action.replace(":path", data.tenant);
          }
        });

        setLayoutData({ ...data });

        if (data.codigoPixelFacebook) {
          ReactPixel.init(data.codigoPixelFacebook);
          ReactPixel.pageView();
        }

        titleElement.innerText = data.nome || "WebGula";

        refreshCart();
      } catch (e) {
        authClient.logout();
        location.reload();
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const update = (value: any) => {
    setLayoutData({
      ...layoutData,
      ...value,
    });
  };

  return (
    <LayoutContext.Provider
      value={{
        layoutData: layoutData,
        update: update,
      }}
    >
      <PreLoader isLoading={isLoading}>{children}</PreLoader>
    </LayoutContext.Provider>
  );
}

export default LayoutProvider;
