import styled from "styled-components";

export const Container = styled.div<any>`
  position: ${(props) => (props.fixed ? "fixed" : "relative")};
  box-shadow: ${(props) =>
    props.fixed ? "0 3px 3px rgba(0, 0, 0, .3)" : "none"};
  top: ${(props) => (props.fixed ? props.offsetTop : 0)}px;
  left: 0px;
  right: 0px;
  z-index: 1;
  background-color: white;
  width: ${(props) => (props.fixed ? "auto" : "100%")};
  padding: 16px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: ${(props) => (props.fixed ? 0 : 8)}px;

  @media all and (max-width: 768px) {
    & {
      margin-top: ${(props) => (props.fixed ? -40 : 0)}px;
      margin-bottom: 16px;
    }
  }
`;
