import React from "react";
import Appbar from "../../components/Appbar";
import styled from "styled-components";
import ScrollTop from "../../components/ScrollTop";
import Footer from "../../components/Footer";
import HeaderEmpresa from "../components/HeaderEmpresa";
import Conteudo from "./components/Conteudo";

const Body = styled.div` 
  padding-top: 90px;

  @media all and (max-width: 768px) {
    & {
      padding-top: 30px;
    }
  }
`;

function CarrinhoPage() {

 
  return (
    <Body>
      <Appbar />

      <HeaderEmpresa hideBackground hideDetails />

      <Conteudo />
 
      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default CarrinhoPage;
