import { useEffect, useState, useCallback } from 'react';
import { getMinhasFormasPagamento } from 'services/pedido';

export default function useMinhasFormasPagamento() {

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const load = useCallback(async () => {

    try {

      setIsLoading(true);

      const data = await getMinhasFormasPagamento();

      setItems(data);

    } catch (e) {
    } finally {
      setIsLoading(false)
    }

  }, []);


  useEffect(() => {

    load();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    items,
    load,
    isLoading
  }
}
