import React from "react";
import { Row, Col } from "reactstrap";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonItem() {
  return (
    <Row style={{ marginBottom: 16 }}>
      <Col md={5}>
        <Skeleton height={250} variant="rect" />
      </Col>

      <Col md={7}>
        <Skeleton height={50} variant="text" />
        <br />
        <Skeleton height={100} variant="text" />
      </Col>
    </Row>
  );
}

export default SkeletonItem;
