import axios from "axios";
import { getEnderecoPrincipal } from "services/pessoa";
import moment from "moment";

import { ACCOUNTS_URL, BASE_URL_API } from "../config";

export const KEY_AUTH = "auth-webgula";

export interface AuthType {
  sexo: string;
  imagemUrl: string | null;
  nome: string;
  cpf: string | null;
  telefone: string | null;
  pessoaId: number;
  dataNascimento: string | null;
  id: string;
  usuarioAccid: string;
  email: string;
  sub: string;
  "phone-confirmed": string;
  endereco?: Endereco | null;
}

export interface Endereco {
  bairroId: number;
  cep: string;
  cepId: number;
  cepInfo: any | null;
  cidadeId: number;
  complemento: string;
  flgAceitaEntrega: boolean;
  flgAtivo: boolean;
  flgPrincipal: boolean;
  id: number;
  latitude: string;
  logradouro: string;
  longitude: string;
  nome: string;
  nomeBairro: string;
  nomeCidade: string;
  nomeEstado: string;
  numero: string;
  pessoaId: number;
  referencia: string | null;
  taxaEntrega: number;
}

class AuthClient {
  async loginClient() {
    let body: any = {
      grant_type: "client_credentials",
      audience: "tacto-webgula-api",
      client_id: "webgula",
      client_secret: "secret",
      scope: "tacto-webgula-api IdentityServerApi",
    };

    const url = `${ACCOUNTS_URL}/connect/token`;

    body = Object.keys(body)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(body[key]);
      })
      .join("&");

    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    this.setToken({
      ...response.data,
    });

    return response;
  }

  async loginUser(username: string, password: any) {
    let body: any = {
      grant_type: "password",
      audience: "tacto-webgula-api profile openid",
      client_id: "webgula_mobile",
      client_secret: "secret",
      username,
      password,
    };

    const url = `${ACCOUNTS_URL}/connect/token`;

    body = Object.keys(body)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(body[key]);
      })
      .join("&");

    try {
      const response = await axios.post(url, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      this.setToken({
        ...response.data,
      });

      await this.getUserInfo();

      return response.data;
    } catch (e) {
      return false;
    }
  }

  async loginExternal(config: any) {
    let body: any = {
      grant_type: "external",
      audience: "tacto-webgula-api profile openid",
      client_id: "webgula_mobile",
      client_secret: "secret",
      provider: config.provider,
      external_token: config.accessToken,
    };

    body = Object.keys(body)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(body[key]);
      })
      .join("&");

    const url = `${ACCOUNTS_URL}/connect/token`;

    try {
      const response = await axios.post(url, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      this.setToken({
        ...response.data,
      });

      await this.getUserInfo();

      return response.data;
    } catch (e) {
      return false;
    }
  }

  async recuperarSenha(email: string) {
    const url = `${ACCOUNTS_URL}/api/usuario-logado/recuperar-senha`;

    try {
      const response = await axios.post(url, {
        email,
      });

      return response.data;
    } catch (e) {
      return false;
    }
  }

  async uploadImage(file: string) {
    try {
      const url = `${BASE_URL_API}/v1/pessoas/imagem`;

      const imagesData = new FormData();

      imagesData.append("file", file);

      const { data } = await axios.post(url, imagesData, {
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      });

      return data.imagemUrl;
    } catch (e) {
      return null;
    }
  }

  async getUserInfo() {
    const url = `${ACCOUNTS_URL}/connect/userinfo`;

    try {
      const response = await axios.post(url);

      const { data } = response;

      const empresa = this.getEmpresa();

      const auth: AuthType = {
        sexo: data.sex,
        imagemUrl: data.image,
        nome: data.name,
        cpf: data.cpf,
        telefone: data.phone,
        pessoaId: Number(data.pessoaglobalid),
        dataNascimento: data.dateofbirth
          ? moment(data.dateofbirth).format("YYYY-MM-DD")
          : null,
        id: data.id,
        usuarioAccid: data.id,
        email: data.email,
        sub: data.sub,
        "phone-confirmed": data["phone-confirmed"],
      };

      auth.endereco = await getEnderecoPrincipal({
        pessoaId: auth.pessoaId,
        empresaId: empresa.empresaGlobalId || 0,
      });

      this.setData({
        ...auth,
      });

      return response;
    } catch (e) { }
  }

  async registrarUsuario(body: any) {
    const url = `${ACCOUNTS_URL}/api/usuarios`;

    try {
      const { data } = await axios.post(url, body);

      return data;
    } catch (e) {
      throw e;
    }
  }

  async confirmarSMS(body: any) {
    const url = `${ACCOUNTS_URL}/api/usuario-logado/telefone/confirmacao-sms`;

    try {
      const { data } = await axios.put(url, body);

      return data;
    } catch (e) {
      throw e;
    }
  }

  async trocarTelefone(telefone: string) {
    const url =  'https://webgula.tactonuvem.com.br/v3/usuario-logado/alterarTelefone';

    try {
      const { data } = await axios.put(url, {
        novoTelefone: telefone,
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  async validarSMSTrocandoTelefone(body: any) {
    const url = `${ACCOUNTS_URL}/api/usuario-logado/novo-telefone`;

    try {
      const { data } = await axios.put(url, {
        ...body,
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  async reenviarSMS(body: any) {
    const url = `${ACCOUNTS_URL}/api/usuario-logado/telefone/confirmacao-sms`;

    try {
      const { data } = await axios.post(url, body);

      return data;
    } catch (e) {
      throw e;
    }
  }

  async atualizarUsuario(body: AuthType) {
    const url = `${ACCOUNTS_URL}/api/usuario-logado`;

    try {
      const { data } = await axios.put(url, body);

      if (data.success) {
        this.setData({
          ...this.getData(),
          ...body,
        });
      }

      return data;
    } catch (e) {
      throw e;
    }
  }

  isClientLogged() {
    const authData = this.getData();
    return !!(!authData && this.getToken() && this.getToken().access_token);
  }

  isUserLogged() {
    const authData = this.getData();

    return !!authData;
  }

  isSmsConfirmated() {
    const authData = this.getData();

    return !authData || authData["phone-confirmed"] === "true";
  }


  setData(data: AuthType) {
    localStorage.setItem(
      KEY_AUTH,
      JSON.stringify({
        ...data,
      })
    );
  }

  getData(): AuthType | null {

    if (!KEY_AUTH) {
      return null;
    }

    try {
      const storage = localStorage.getItem(KEY_AUTH);

      if (!storage) {
        return null;
      }

      const result = JSON.parse(storage);

      if (!result) {
        return null;
      }

      return result;
    } catch (e) {
      return null;
    }
  }

  setToken(tokenData: any) {
    localStorage.setItem(
      `${KEY_AUTH}-token`,
      JSON.stringify({
        ...tokenData,
      })
    );
  }

  getToken(): any {
    try {
      const storage = localStorage.getItem(`${KEY_AUTH}-token`);

      if (!storage) {
        return null;
      }

      const result = JSON.parse(storage) || {};

      return result;
    } catch (e) {
      return null;
    }
  }

  setEmpresa(empresa: any) {
    localStorage.setItem(
      `${KEY_AUTH}-empresa`,
      JSON.stringify({
        ...empresa,
      })
    );
  }

  getEmpresa(): any {
    const key = `${KEY_AUTH}-empresa`;

    try {
      const storage = localStorage.getItem(key);

      if (!storage) {
        return null;
      }

      const result = JSON.parse(storage) || {};

      return result;
    } catch (e) {
      return null;
    }
  }

  logout(): void {
    localStorage.removeItem(KEY_AUTH);
    localStorage.removeItem(`${KEY_AUTH}-token`);
  }
}

export default new AuthClient();
