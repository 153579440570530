import React from "react";
import styled from "styled-components";
import Text from "../../../../components/Text";

const Wrapper = styled.div`
  margin-top: 150px;
  margin-bottom: 32px;
  padding: 0 32px;
`;

export default function PoliticaPrivacidade() {
  return (
    <Wrapper>
      <div
        style={{
          width: "100%",
          marginTop: 32,
          marginBottom: 32,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Text size={32} variant="fancy-title">
          Politica de privacidade
        </Text>
      </div>
      <>
        <p>
          A sua privacidade é importante para nós. É política do WebGula
          respeitar a sua privacidade em relação a qualquer informação sua que
          possamos coletar no site <a href="webgula.com.br">WebGula</a>, e
          outros sites que possuímos e operamos.
        </p>
        <p>
          Solicitamos informações pessoais apenas quando realmente precisamos
          delas para lhe fornecer um serviço. Fazemos por meios justos e legais,
          com o seu conhecimento e consentimento. Também informamos por que
          estamos coletando e como será usado.{" "}
        </p>
        <p>
          Nossa lista de serviços, as quais é necessário algum tipo de
          informação, inclue:{" "}
        </p>

        <ul>
          <li>
            Pedidos de Delivery, onde precisamos que seja um usuário e que
            cadastre o endereço de entrega.{" "}
          </li>
          <li>
            Controle de Fidelidade, onde precisamos que seja um usuário para que
            possamos mostrar seus pontos e beneficios.
          </li>
          <li>
            Acompanhamento de contas ou Pedidos de Mesa, onde precisamos que
            seja um usuário cadastrado.{" "}
          </li>{" "}
        </ul>
        <p>
          Apenas retemos as informações coletadas pelo tempo necessário para
          fornecer o serviço solicitado. Quando armazenamos dados, protegemos
          dentro de meios comercialmente aceitáveis ​​para evitar perdas e
          roubos, bem como acesso, divulgação, cópia, uso ou modificação não
          autorizados.
        </p>
        <p>
          As Informações que coletamos, além de serem criptografadas, ficam
          hospedados em um dos melhores serviços de hospedagem do mundo(Azure).
        </p>
        <p>Não armazenamos informações bancárias em nosso banco de dados.</p>
        <p>
          Não compartilhamos informações de identificação pessoal publicamente
          ou com terceiros, exceto quando exigido por lei.
        </p>
        <p>
          O nosso site pode ter links para sites externos que não são operados
          por nós. Esteja ciente de que não temos controle sobre o conteúdo e
          práticas desses sites e não podemos aceitar responsabilidade por suas
          respectivas políticas de privacidade{" "}
        </p>
        <p>
          Você é livre para recusar a nossa solicitação de informações pessoais,
          entendendo que talvez não possamos fornecer alguns dos serviços
          desejados.
        </p>
        <p>
          O uso continuado de nosso site será considerado como aceitação de
          nossas práticas em torno de privacidade e informações pessoais. Se
          você tiver alguma dúvida sobre como lidamos com dados do usuário e
          informações pessoais, entre em contacto connosco.
        </p>

        <div
          style={{
            width: "100%",
            marginTop: 32,
            marginBottom: 32,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Text size={32} variant="fancy-title">
            Compromisso do Usuario
          </Text>
        </div>
        <p>
          O usuário se compromete a fazer uso adequado dos conteúdos e da
          informação que o WebGula oferece no site e com caráter enunciativo,
          mas não limitativo:
        </p>
        <ul>
          <li>
            A) Não se envolver em atividades que sejam ilegais ou contrárias à
            boa fé a à ordem pública;
          </li>
          <li>
            B) Não difundir propaganda ou conteúdo de natureza racista,
            xenofóbica, ou casas de apostas, jogos de sorte e azar, qualquer
            tipo de pornografia ilegal, de apologia ao terrorismo ou contra os
            direitos humanos;
          </li>
          <li>
            C) Não causar danos aos sistemas físicos (hardwares) e lógicos
            (softwares) do WebGula, de seus fornecedores ou terceiros, para
            introduzir ou disseminar vírus informáticos ou quaisquer outros
            sistemas de hardware ou software que sejam capazes de causar danos
            anteriormente mencionados.
          </li>
        </ul>

        <div
          style={{
            width: "100%",
            marginTop: 32,
            marginBottom: 32,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Text size={32} variant="fancy-title">
            Login com Facebook ou Google
          </Text>
        </div>
        <p>
          A opção de fazer login através do Facebook ou Google não gera nenhum
          risco de vazamento de informações da redes sociais, pois nós não temos
          controle sobre a sua conta cadastrada no google ou facebook.
        </p>
        <p>
          Apenas fazemos a autenticação facilitada, o Login é feito
          exclusivamente no facebook ou google, após isso eles nos confirmam que
          é um usuário válido. dessa forma você não precise memorizar mais uma
          senha.
        </p>

        <div
          style={{
            width: "100%",
            marginTop: 32,
            marginBottom: 32,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Text size={32} variant="fancy-title">
            Mais informaçoes
          </Text>
        </div>
        <p>
          Esperemos que esteja esclarecido e, como mencionado anteriormente, se
          houver algo que você não tem certeza se precisa ou não, geralmente é
          mais seguro deixar os cookies ativados, caso interaja com um dos
          recursos que você usa em nosso site.
        </p>
        <p>
          Esta política é efetiva a partir de <strong>Agosto</strong>/
          <strong>2021</strong>.
        </p>
      </>
    </Wrapper>
  );
}
