import Axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { BASE_URL_API } from "../../infra/config";
import { ReservaDetalhes } from "../../infra/types/reserva";

export default function useReserva(eventoReservaId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ReservaDetalhes>();

  const onLoad = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await Axios.get(
        `${BASE_URL_API}/v2/eventos/acomp/detalhado/${eventoReservaId}`
      );

      setData(data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, [eventoReservaId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return {
    isLoading,
    data,
  };
}
