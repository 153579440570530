import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { Row, Col } from "reactstrap";
import moment from "moment";
import QRCode from "qrcode.react";
function ModalResgate({
  visible,
  premio,
  onClose,
}: {
  visible: boolean;
  premio: any;
  onClose: any;
}) {
  if (!premio) {
    return null;
  }
  const data = moment(premio.dataHoraLanc).format("DD/MM/YYYY HH:mm");

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: 700,
          width: "100%",
        },
      }}
    >
      <DialogContent>

        <Row >
          <Col lg={9} md={9} sm={7} xs={12}>
            <div>
              <Typography> {premio.premioNome}</Typography>
            </div>
            <span style={{ color: "#aaa", marginRight: 16 }}>{data}</span>{" "}

            <div style={{ fontWeight: "bold", marginTop: 32, fontSize: 18 }}>
              {premio.premioPontoCusto} pontos x {premio.qtdePremio}
            </div>
          </Col>
          <Col lg={3} md={3} sm={5} xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "16px 0",
              }}
            >
              <QRCode size={150} value={premio.chavePagto} />
            </div>

          </Col>
        </Row>
      </DialogContent>


      <DialogActions>
        <div style={{ marginRight: 8 }}>
          <Button
            onClick={() => {
              onClose();
            }}
            color="primary"
          >
            FECHAR
          </Button>
        </div>
      </DialogActions>
    </Dialog >
  );
}

export default ModalResgate;
