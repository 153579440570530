import React from "react";
import Appbar from "../../components/Appbar";
import styled from "styled-components";
import ScrollTop from "../../components/ScrollTop";
import Footer from "../../components/Footer";
import ListaPedidos from "./components/ListaPedidos";
import HeaderEmpresa from "../components/HeaderEmpresa";

const Body = styled.div` 
  min-height: 100vh;
  padding-top: 90px;
`;

function HistoricoPedidosDetalhes(props) {
  return (
    <Body>
      <Appbar />

      <HeaderEmpresa />

      <ListaPedidos {...props} />
      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default HistoricoPedidosDetalhes;
