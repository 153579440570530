import styled from "styled-components";

export const FooterRoot = styled.div`
  width: 100%;
  clear: both;
  padding-top: 80px;
  position: relative;
  background: #f7f7f9;
`;

export const Image = styled.img`
  max-width: 175px;
  margin-bottom: 32px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
