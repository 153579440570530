import { useState, useCallback } from 'react';
import { mudarPrincipalFormaPagamento } from 'services/pedido';
 

export default function useMudarPrincipalFormaPagamento(id) {

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const mudarPrincipal = useCallback(async () => {

    try {

      setIsLoading(true);

      await mudarPrincipalFormaPagamento(id);

      setSuccess(true);

      setTimeout(() => setSuccess(false), 0);

    } catch (error) {

      let errorText = '';

      if (typeof error === 'object') {
        errorText = error.response?.data?.details[0]?.errors[0];
      }
      
      alert(errorText || `Houve um problema ao realizar está operação.`);

    } finally {
      setIsLoading(false)
    }

  }, [id]);

  return {
    mudarPrincipal,
    isLoading,
    success
  }
}
