import { useEffect, useState } from "react";
import { Produto } from "../types";
import { httpCache } from "infra/axios";

export default function useTopCardapio() {
  const [data, setData] = useState<Produto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { data: items } = await httpCache.get(
          "v2/eventos/top-lista-cardapio"
        );


        setData(items);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    data,
    isLoading,
  };
}
