export function calcularTotalVariacao(variacao) {
  const divisaoMultipla = variacao.flgTipo === "M";
  const itemsSelecionados = variacao.items.filter(x => x.quantidade);
  const flgMaiorPrecoM = variacao.flgMaiorPrecoM;
  
  let totalQuantidadesSelecionada = 0;
  let totalItemsVariacao = 0;

  itemsSelecionados.forEach(v => (totalQuantidadesSelecionada += v.quantidade));

  if (flgMaiorPrecoM  && divisaoMultipla) {
    const itemMaiorPreco = itemsSelecionados.reduce((prev, current) => {
      return (prev.preco > current.preco) ? prev : current
    }, { preco: 0 });
    totalItemsVariacao = itemMaiorPreco.preco;

  } else {
    
    itemsSelecionados.forEach(variacaoitem => {
      let { total } = calcularTotalVariacaoItem(
        variacaoitem,
        divisaoMultipla,
        totalQuantidadesSelecionada
      );

      totalItemsVariacao += total;
    });
  }

  return totalItemsVariacao;
}

export function calcularTotalVariacaoItem(
  variacaoitem,
  divisaoMultipla,
  totalQuantidadesSelecionada
) {
  let quantidadeSelecionada = variacaoitem.quantidade;

  let preco = quantidadeSelecionada * variacaoitem.preco;

  if (divisaoMultipla) {
    const qtdeMultiplaBase = 1 / totalQuantidadesSelecionada;
    quantidadeSelecionada = quantidadeSelecionada * qtdeMultiplaBase;
    preco = variacaoitem.preco * quantidadeSelecionada;
  }
  return { total: preco, quantidade: quantidadeSelecionada };
}

export function getValorTotalProduto(produto) {
  let valorTotal = 0;
 
  const precoSelecionado = (produto.precos || []).find(x => x.selected);

  if (!precoSelecionado) {
    return 0;
  }

  let possuiVariacaoMultiplaSelecionada = false;

  produto.variacoes.forEach(variacao => {
    if (variacao.flgTipo === "M") {
      const totalQuantidadesSelecionada = variacao.items
        .filter(x => x.quantidade)
        .reduce((acc, current) => acc + current.quantidade, 0);

      if (!possuiVariacaoMultiplaSelecionada) {
        possuiVariacaoMultiplaSelecionada = totalQuantidadesSelecionada > 0;
      }
    }

    const totalItemsVariacao = calcularTotalVariacao(variacao);

    valorTotal += totalItemsVariacao * (produto.quantidade || 1);
  });

  if (!possuiVariacaoMultiplaSelecionada) {
    valorTotal += precoSelecionado.valorPreco * produto.quantidade;
  }

  valorTotal -= produto.valorDesconto || 0;

  return valorTotal;
}
