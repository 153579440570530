import styled from "styled-components";

export const Description = styled.div`
  float: left;
  width: calc(100% - 200px);
  padding-right: 16px;

  .quantidade {
    width: 80px;
  }

  @media all and (max-width: 768px) {
    &,
    .preco-display,
    .quantidade {
      width: 100% !important;
    }

    .quantidade {
      > div {
        margin: 10px auto;
        display: table !important;
      }

      > div > div {
        float: left;
      }
    }
  }
`;

export const ImagemStyled = styled.div`
  & {
    float: right;
    max-width: 200px;
    width: 100%;
  }

  img {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ccc;
  }

  @media all and (max-width: 768px) {
    & {
      max-width: 100% !important;
    }
  }
`;
