import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

import { LayoutContext } from "contexts/layout";
import { Button } from "@material-ui/core";
import { navigate } from "infra/router/history";

const MenuSectionRoot = styled.div`
  width: 100%;
  clear: both;
  padding-top: 20px;
  padding-bottom: 80px;
  position: relative;
`;

const H1 = styled.h1`
  color: #333;
  font-style: italic;
  font-size: 32px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Paragraph = styled.p`
  color: #333;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const ContainerCenter = styled.div`
  max-width: 350px;
  height: 100%;
  margin: auto;
  display: table;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

function MenuSection() {
  const { layoutData } = useContext(LayoutContext);

  if (!layoutData.flgWGAtivoMenuSeguranca) {
    return null;
  }

  return (
    <MenuSectionRoot>
      <Container>
        <Row>
          <Col md="4">
            <img src={"/images/menu.png"} alt="menu" />
            <img
              style={{ position: "absolute", top: 16, left: 32 }}
              src={"/images/menu-outline.png"}
              alt="menu"
            />
          </Col>
          <Col md="8">
            <ContainerCenter>
              <strong
                style={{
                  fontStyle: "italic",
                  fontSize: 12,
                  color: layoutData.primaryColor,
                }}
              >
                Conheça nosso
              </strong>
              <H1 color={layoutData.primaryColor}>Menu</H1>
              <Paragraph>
                Quer sair de casa? Venha nos visitar! Aproveite e conheça o
                nosso menu para consumo no local
              </Paragraph>
              <Button
                color="primary"
                variant="contained"
                onClick={() => navigate("menu")}
              >
                Conheça nosso menu
              </Button>
            </ContainerCenter>
          </Col>
        </Row>
      </Container>
    </MenuSectionRoot>
  );
}

export default MenuSection;
