import React from "react";

import { Button } from "@material-ui/core";

function StepControl({
  steps,
  isLoading,
  activeStep,
  handleBack,
  handleNext,
}: any) {
  return (
    <div
      style={{ padding: 16, float: "left", width: "100%", marginBottom: 32 }}
    >
      <div style={{ float: "right" }}>
        {activeStep ? (
          <Button
            variant="outlined"
            color="primary"
            size="large"
            disabled={isLoading}
            onClick={handleBack}
            style={{
              marginRight: 16,
              textTransform: "none",
              paddingLeft: 64,
              paddingRight: 64,
            }}
          >
            Voltar
          </Button>
        ) : null}
        <Button
          style={{
            paddingLeft: 64,
            textTransform: "none",
            paddingRight: 64,
          }}
          disabled={isLoading}
          variant="contained"
          size="large"
          color="primary"
          onClick={handleNext}
        >
          {activeStep === steps.length - 1 ? "Solicitar reserva" : "Continuar"}
        </Button>
      </div>
    </div>
  );
}

export default StepControl;
