import { Empresa } from 'infra/types/empresa';

const menu = [
  // {
  //   text: "Inicio",
  //   action: "/inicio"
  // },
  {
    text: "Delivery",
    action: "/:path/cardapio",
    display: (empresa: Empresa) => !empresa.flgDeliveryIndisponivel,
  },
  {
    text: "Menu",
    action: "/:path/menu",
    display: (empresa: Empresa) => empresa.flgMostraCardapioMenu,
  },
  {
    text: "Eventos",
    action: "/:path/eventos",
    display: (empresa: Empresa) => empresa.flgReservaEventoAtivo,
  },
  {
    text: "Reservas",
    action: "/:path/minhas-reservas",
    display: (empresa: Empresa) => empresa.flgStatusReservaMesaAtiva === "A" || 
      empresa.flgStatusReservaMesaAtiva === "P",
  },
];

export default menu;
