import React from "react";
import styled from 'styled-components'; 
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import themeJson from '../../../theme.json'
const theme = createMuiTheme(themeJson);


const Body = styled.div` 
	height: 100vh ; 
`;

function LayoutDefault({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Body>
        {children}
      </Body>
    </ThemeProvider>
  );
}

export default LayoutDefault;
