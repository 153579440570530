import { NavLink } from "reactstrap";
import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
   
   .btn-primary {
     background: ${(props) => props.primaryColor} !important;
     border-color: ${(props) => props.primaryColor} !important;
   }

   .modal-header {
     border: none;
   }
   .modal-title {
     display: block;
     text-align: center;
     width: 100%;
   }
   .modal-footer {
     background: #FAFAFC;
   }
`;

export const AppbarRoot = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

  .navbar {
    background-color: white;
    height: 90px;
    padding: 16px 0;
  }

  .user-menu {
    margin-top: 8px;
  }

  @media screen and (max-width: 768px) {
    & {
      padding-bottom: 0px;
    }

    .navbar {
      height: 50px;
    }

    .navbar-toggler {
      left: 6px;
      top: 8px;
      position: absolute;
      border: none;
      color: black;
    }

    .navbar-brand {
      margin-left: 50px;
      margin-top: -10px;
    }

    .navbar-nav {
      .nav-item,
      .user-menu {
        text-align: center;
      }
      * {
        float: none !important;
      }
    }
  }
`;

export const LinkText = styled(NavLink)`
  font-weight: 500;
  color: #444 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;

  &:hover {
    color: #222 !important;
  }
`;

export const CarrinhoBotaoMobile = styled.div`
  display: none;

  @media all and (max-width: 768px) {
    display: block;

    margin-top: -10px;

    .MuiBadge-root + span {
      display: none;
    }
  }
`;

export const ImageLogo = styled.img`
  max-width: 100%;
  max-height: 60px;

  @media screen and (max-width: 768px) {
    max-height: 30px;
    margin-left: 16px;
  }
`;
