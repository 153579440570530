import React from "react";

import { Row, Col, Container } from "reactstrap";

import Appbar from "../../../components/Appbar";
import ScrollTop from "../../../components/ScrollTop";
import Footer from "../../../components/Footer";

import { Body } from "./styles";

import useReservas from "./hooks";
import { Card, CardContent, Chip } from "@material-ui/core";

import SkeletonItem from "./SkeletonItem";

import { Reserva, StatusReserva } from "../types";
import { formatMoney } from "infra/utils";
import { navigate } from "infra/router/history";

function ReservasPage() {
  const { isLoading, items } = useReservas();

  return (
    <Body>
      <Appbar />

      <Container>
        <h2>Reservas</h2>

        <br />
        <br />
        <br />

        {isLoading ? (
          <>
            <Row style={{ marginBottom: 32 }}>
              <Col sm={6}>
                <SkeletonItem />
              </Col>
              <Col sm={6}>
                <SkeletonItem />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <SkeletonItem />
              </Col>
              <Col sm={6}>
                <SkeletonItem />
              </Col>
            </Row>
          </>
        ) : null}

        <Row>
          {items.map((item: Reserva) => (
            <Col sm={6} style={{ marginBottom: 32 }}>
              <Card
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`eventos/${item.id}/status`)}
              >
                <CardContent>
                  <Row>
                    <Col sm={12}>
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                          <h5>Reserva #{item.id}</h5>
                        </div>
                        <div>
                          <Chip
                            color={
                              item.status === StatusReserva.AguardandoAprovacao
                                ? "primary"
                                : "default"
                            }
                            variant="outlined"
                            label={item.status}
                            size="small"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12}>
                      <span style={{ fontSize: 12 }}>
                        R$ {formatMoney(item.preco)}
                      </span>
                    </Col>
                  </Row>
                </CardContent>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default ReservasPage;
