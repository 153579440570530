import { useEffect, useState } from "react";
import { httpCache } from "infra/axios";

export default function useGetDatasReserva() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { data: reservas } = await httpCache.get(
          "v3/reserva-mesa/calendario-disponivel"
        );

        setData(reservas);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    data,
    isLoading,
  };
}
