import React, { useEffect, useState, useContext } from "react";
import {
  findFidelidadeCampanhaAtiva,
} from "services/fidelidade";
import { LayoutContext } from "contexts/layout";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import { Typography } from "@material-ui/core";
import AuthClient from "infra/auth/AuthClient";
import { navigate } from "infra/router/history";

export const displayTexto = (texto: string) => {
  if (!texto) return null;

  const splites = texto.split("*");

  return (
    <div>
      {splites.map((item) => (
        <Typography>{item}</Typography>
      ))}
    </div>
  );
};

function ModalDetalhesFidelidade({ onClose, open }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const { layoutData, update: updateContext }: any = useContext(LayoutContext);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const result = await findFidelidadeCampanhaAtiva(layoutData.id);

        setData(result);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [layoutData.id]);


  const isUserLogged = AuthClient.isUserLogged();

  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Fidelidade</ModalHeader>
      <ModalBody>
        {isLoading ? <Spinner /> : null}
        {data?.imagemUrl ? (
          <img
            alt="imagem-fidelidade"
            src={data.imagemUrl}
            style={{ maxWidth: 250, margin: "auto", width: "100%" }}
          />
        ) : null}

        <div>{displayTexto(data?.textoCliente)}</div>

        {data?.pontosAdesao ? (
          <div style={{ margin: "16px 0", fontWeight: "bold" }}>
            Pontos para Adesão: {data.pontosAdesao} pontos
          </div>
        ) : null}

        <div style={{ margin: "16px 0" }}>Serviço Gratuito</div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isLoading} onClick={onClose}>
          Fechar
        </Button>

        {isUserLogged ? (
          <Button
            disabled={isLoading}
            color="primary"
            onClick={() => navigate('fidelidade')}
          >
            Saber mais
          </Button>
        ) : null}

        {!isUserLogged ? (
          <Button
            color="primary"
            onClick={() => updateContext({ openLogin: true })}
          >
            Fazer Login para continuar
          </Button>
        ) : null}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDetalhesFidelidade;
