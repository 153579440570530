import Axios from "axios";
import { BASE_URL_API, BASE_URL_TACTO } from "infra/config";

export async function getAvaliacoesDelivery(empresaId) {
  try {
    const { data } = await Axios.get(
      `${BASE_URL_API}/v1/avaliacoes-empresas/${empresaId}/ultimas-avaliacoes-delivery`
    );

    return data;
  } catch (e) {}
}

export async function getAvaliacoesMenu(empresaId) {
  try {
    const { data } = await Axios.get(
      `${BASE_URL_API}/v1/avaliacoes-empresas/${empresaId}/ultimas-avaliacoes-menu`
    );

    return data;
  } catch (e) {}
}

export async function faleConosco({ nome, email, telefone, mensagem }) {
  try {
    const { data } = await Axios.post(
      `${BASE_URL_API}/v1/fale-conosco`,
      {
        empresaId: 1, // default webgula
        nome: nome,
        email: email,
        telefone: telefone,
        mensagem: mensagem,
      },
      {
        headers: {
          empresaId: 1,
          "tacto-grupo-empresarial": "71043125-2E2E-4A80-A690-933E025D64DA",
        },
      }
    );

    return data;
  } catch (e) {}
}

export async function getEmpresasGeral(params) {
  let { data } = await Axios.get(
    `${BASE_URL_API}/v1/api/empresas/geral-cidade`,
    {
      params,
    }
  );

  data = data.map((item) => {
    if (!!item.logoUrl && item.logoUrl?.indexOf("http") === -1) {
      item.logoUrl = createImage(
        item.chaveGrupoEmpresarial,
        item.id,
        item.logoUrl
      );
    }

    return item;
  });

  return data;
}

export function createImage(chaveEmpresarial, empresaId, name) {
  return (
    BASE_URL_TACTO +
    "/v1/empresas/" +
    empresaId +
    "/imagem?c=" +
    chaveEmpresarial +
    "&b=" +
    name
  );
}
