import Swal from "sweetalert2";

export function showSuccess(
  message = "Operação realizada com sucesso",
  title = "Sucesso"
) {
  return Swal.fire(title, message, "success");
}

export function showError(
  message = "Houve um problema ao realizar esta operação",
  title = "Erro"
) {
  return Swal.fire(title, message, "error");
}

export function showConfirm(message = "Deseja realmente realizar esta operação?") {
  return Swal.fire({
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    title: "Aviso",
    icon: "info",
    text: message,
  });
}
