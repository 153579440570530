import moment from "moment";
import AuthClient from "infra/auth/AuthClient";
import { parseDoubleCustom } from "infra/utils";

export const CART_KEY = "webgula-cart";

export function getCartKey() {
  const empresa = AuthClient.getEmpresa();

  return `${CART_KEY}-${empresa.tenant}`;
}

export function refreshCart() {
  if (isExpireCart()) {
    checkoutCart();
  }
}

export function isExpireCart() {
  const createdAt = getCreatedAtCart();

  if (createdAt && moment(moment().utc()).diff(createdAt, "h") >= 1) {
    return true;
  }

  return false;
}

export function getOriginalCart() {
  try {
    const cart = JSON.parse(localStorage.getItem(getCartKey()));

    if (cart) {
      return cart;
    }
    return {};
  } catch (e) {
    return {};
  }
}

export function getCreatedAtCart() {
  const cart = getOriginalCart();

  return cart.createdAt;
}

export function addCart(produto) {
  const items = getAllCart();
  let createdAt = getCreatedAtCart() || moment().format();

  const existe = !!items.find((x) => x.uuid === produto.uuid);

  if (existe) {
    return updateCart(produto);
  }

  produto.uuid = +new Date();
  produto.quantidade = produto.quantidade || 1;
  items.push(produto);

  updateStorageCart({ createdAt, items });
}

export function updateStorageCart(value) {
  let cart = getOriginalCart();

  cart = {
    ...cart,
    ...value,
  };

  return localStorage.setItem(getCartKey(), JSON.stringify(cart));
}

export function getAllCart() {
  try {
    const cart = JSON.parse(localStorage.getItem(getCartKey()));

    return (cart && cart.items) || [];
  } catch (e) {
    return [];
  }
}

export function getItemCart(uuid) {
  const items = getAllCart();

  return items.find((item) => item.uuid === uuid);
}

export function removeCart(uuid) {
  const items = getAllCart();

  const newItems = items.filter((item) => item.uuid !== uuid);

  return updateAllCart(newItems);
}

export function updateAllCart(items) {
  const createdAt = getCreatedAtCart();

  return updateStorageCart({ createdAt, items: items });
}

export function setOrigem(origem) {
  const createdAt = getCreatedAtCart();

  return updateStorageCart({ createdAt, origem });
}

export function getOrigem() {
  const cart = getOriginalCart();

  return cart.origem;
}

export function getObservacao() {
  const cart = getOriginalCart();

  return cart.observacao;
}

export function setObservacao(value) {
  return updateStorageCart({ observacao: value });
}

export function getVoucher() {
  const cart = getOriginalCart();

  return !!cart.voucher ? cart.voucher : null;
}

export function setVoucher(value) {
  return updateStorageCart({ voucher: value });
}

export function getDesconto() {
  const cart = getOriginalCart();

  return Number(cart.desconto ?? 0);
}

export function setDesconto(value) {
  return updateStorageCart({ desconto: value });
}

export function setValorPagamento(value) {
  return updateStorageCart({ valorPagamento: value });
}

export function getValorPagamento() {
  const cart = getOriginalCart();
  return cart.valorPagamento ? parseDoubleCustom(cart.valorPagamento) : null;
}

// Remover este metood
export function getFormaPagamento() {
  const cart = getOriginalCart();
  return cart.modalidade;
}

export function getFormaPagamentoOnline() {
  const cart = getOriginalCart();
  return cart.pagamentoOnline;
}

export function getRetirarNoLocal() {
  const cart = getOriginalCart();
  return cart.retirarNoLocal === "true";
}

export function getChaveComanda() {
  const cart = getOriginalCart();
  return cart.chaveComanda;
}

export function getNome() {
  const cart = getOriginalCart();
  return cart.nome;
}

export function getTaxaEntrega() {
  const cart = getOriginalCart();
  return Number(cart.taxaEntrega ?? 0);
}

export function setTaxaEntrega(value) {
  return updateStorageCart({ taxaEntrega: value });
}

export function getSaida() {
  const cart = getOriginalCart();
  return cart.saidaId;
}

// Remover este metodo
export function setSaida(value) {
  return updateStorageCart({ saidaId: value });
}

// Remover este metodo
export function setFormaPagamento(value) {
  return updateStorageCart({ modalidade: value });
}

export function setFormaPagamentoOnline(value) {
  return updateStorageCart({ pagamentoOnline: value });
}

export function setRetirarNoLocal(value) {
  return updateStorageCart({ retirarNoLocal: String(value) });
}

export function setChaveComanda(value) {
  return updateStorageCart({ chaveComanda: String(value) });
}

export function setNome(value) {
  return updateStorageCart({ nome: String(value) });
}



export function getLocalizacaoMesaId() {
  const cart = getOriginalCart();
  return cart.localizacaoMesaId;
}


export function setLocalizacaoMesaId(value) {
  return updateStorageCart({ localizacaoMesaId: value });
}

export function calcularValorTotal() {
  const items = getAllCart();

  const valorTotal = items.reduce(
    (acumulador, current) => current.valorTotal + acumulador,
    0
  );

  return valorTotal;
}

export function updateCart(produto) {
  const items = getAllCart();
  const createdAt = getCreatedAtCart();

  items.forEach((item, key) => {
    if (item.uuid === produto.uuid) {
      items[key] = {
        ...produto,
      };
    }
  });

  updateStorageCart({ createdAt, items });
}

export function checkoutCart() {
  localStorage.removeItem(getCartKey());
}
