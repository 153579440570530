import React from "react";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { Row, Col } from "reactstrap";
import { formatMoney } from "infra/utils";

interface ItensAdicionaisProps {
  value: any[];
  onChange: Function;
  items: any[];
}

function ItensAdicionais({ value, onChange, items }: ItensAdicionaisProps) {
  const isChecked = (item: any) => {
    return value.find((x: any) => x.id === item.id);
  };

  const handleChange = (item: any) => {
    if (isChecked(item)) {
      onChange(value.filter((x) => x.id !== item.id));
    } else {
      onChange([...value, item]);
    }
  };

  return (
    <div>
      <br />
      <br />

      <FormControl>
        <h5>Adicionais e observações</h5>
        <FormGroup>
          <Row>
            {items.map((item, index) => (
              <Col sm={4}>
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={isChecked(item)}
                      onChange={() => handleChange(item)}
                      name="gilad"
                    />
                  }
                  label={`${item.nome} • R$ ${formatMoney(item.preco)}/pessoa`}
                />
              </Col>
            ))}
          </Row>
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default ItensAdicionais;
