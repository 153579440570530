import React from "react";
import styled from "styled-components";

import Appbar from "../../../components/Appbar";
import ScrollTop from "../../../components/ScrollTop";
import Footer from "../../../components/Footer";

import TopoMenu from "./components/TopoMenu";
import Ambientes from './components/Ambientes';
import Cardapios from "./components/Cardapios";

const Body = styled.div`
  padding-top: 90px;

  @media all and (max-width: 768px) {
    & {
      padding-top: 30px;
    }
  }
`;

function PrincipalPage() {
  return (
    <Body>
      <Appbar />

      <TopoMenu />
      <Ambientes />

      <Cardapios />

      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default PrincipalPage;
