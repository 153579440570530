/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import moment from "moment";
import styled from "styled-components";
import { Container, Spinner } from "reactstrap";
import { Button } from "@material-ui/core";

import ReactPixel from "react-facebook-pixel";
import {
  getAcompanhamento,
  SITUACAO_PEDIDO,
  cancelar as cancelarPedido,
  findPedidoOff,
} from "services/pedido";
import { LayoutContext } from "contexts/layout";
import ModalAvaliacao from "../../components/ModalAvaliacao";
import { findPedido } from "services/pedido";

import ModalMotivoCancelamento from "./ModalMotivoCancelamento";
import EnderecoDetalhes from "./EnderecoDetalhes";
import Resumo from "./Resumo";
import { showError, showSuccess } from "infra/alerta";
import useCookies from "infra/hooks/useCookies";
import AuthClient from "infra/auth/AuthClient";

const ContainerItem = styled.div`
  width: 100%;
  clear: both;
  border-bottom: 1px solid #ccc;
  padding-top: 16px;
  padding-bottom: 16px;
  float: left;
`;

const StatusItem = styled.div`
  width: 100%;
  clear: both;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    border-left: 1px solid #aaa;
    width: 1px;
    position: absolute;
    left: 4px;
    top: 16px;
    bottom: 10px;
    display: ${(props) => (props.display ? "block" : "none")};
  }
`;

const StatusItemLeft = styled.div`
  float: left;
`;

const StatusItemBody = styled.div`
  float: left;
  padding-bottom: 32px;
  margin-top: -5px;
  margin-left: 8px;
`;

const StatusItemBadge = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.color};
`;

const Label = styled.div`
  font-size: 14px;
`;

const LabelDescription = styled.div`
  font-size: 18px;
`;

function TimelineComponent(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalAvaliar, setOpenModalAvaliar] = useState(false);
  const [pedido, setPedido] = useState({});
  const [items, setItems] = useState([]);
  const { layoutData } = useContext(LayoutContext);
  const { getCookie } = useCookies();
  if (layoutData.CodigoPixelFacebookCompra) {
    ReactPixel.init(layoutData.CodigoPixelFacebookCompra);
    ReactPixel.pageView();
  }
  
 const isUserLogged = AuthClient.isUserLogged();
 const isPedidoOff = !isUserLogged && !!getCookie("idPedidoOff") && !!getCookie("chavePedidoOff") && layoutData?.wgPedidoDeliveryOFF;
  // MOTIVOS_CANCELAMENTO
  const handleAll = useCallback(
    async (interval) => {
      const data = await getAcompanhamento(
        props.match.params.id,
      );

      setPedido((state) => ({ ...state, situacaoId: data[0].situacaoId }));
      setItems(data);

      if (
        data.find(
          (item) =>
            item.situacaoId === SITUACAO_PEDIDO.CANCELADO ||
            item.situacaoId === SITUACAO_PEDIDO.FINALIZADO
        )
      ) {
        if (interval) {
          clearInterval(interval);
        }
      }
    },
    [props.match.params.id, setPedido]
  );

  const onCancelarPedido = useCallback(
    async (motivoId) => {
      setIsLoading(true);

      try {
        await cancelarPedido(props.match.params.id, motivoId);
        showSuccess("Pedido cancelado com sucesso");

        setPedido((pedido) => ({
          ...pedido,
          situacaoId: SITUACAO_PEDIDO.CANCELADO,
        }));

        await handleAll();
      } catch (error) {
        showError(error);
      } finally {
        setOpenModal(false);
        setIsLoading(false);
      }
    },
    [handleAll, setPedido, props.match.params.id]
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const data = isPedidoOff ? await findPedidoOff(
          getCookie("idPedidoOff"),
          props.match.params.id
        ) : await findPedido(props.match.params.id);

        setPedido(data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [props.match.params.id,isPedidoOff]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        await handleAll();

        const interval = setInterval(async () => {
          await handleAll(interval);
        }, 10000);

        return () => {
          clearInterval(interval);
        };
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [props.match.params.id]);

  const podeAvaliarPedido =
    pedido.situacaoId === SITUACAO_PEDIDO.FINALIZADO ||
    pedido.situacaoId === SITUACAO_PEDIDO.SAIU_PARA_ENTREGA;

  const podeCancelar =
    pedido.situacaoId === SITUACAO_PEDIDO.EM_ABERTO ||
    pedido.situacaoId === SITUACAO_PEDIDO.AGUARDANDO_PAGAMENTO;
  

  return (
    <Container style={{ maxWidth: 768 }}>
      {isLoading && <Spinner />}

      <ModalMotivoCancelamento
        open={openModal}
        isLoading={isLoading}
        onClose={() => setOpenModal(false)}
        onOk={(motivoId) => onCancelarPedido(motivoId)}
      />

      <ModalAvaliacao
        open={openModalAvaliar}
        pedido={pedido}
        onClose={() => setOpenModalAvaliar(false)}
        onSaved={() => setOpenModalAvaliar(false)}
      />

      <div
        style={{
          clear: "both",
          float: "left",
          width: "100%",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Pedido #{props.match.params.id}</h2>

        <div style={{ display: "table", margin: "auto", marginBottom: 16 }}>
          {podeAvaliarPedido ? (
            <div style={{ float: "left", marginRight: 8 }}>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => setOpenModalAvaliar(true)}
              >
                Avaliar
              </Button>
            </div>
          ) : null}

          {podeCancelar ? (
            <div style={{ float: "left" }}>
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={() => setOpenModal(true)}
              >
                Cancelar Pedido
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      <br />
      <div style={{ clear: "both", float: "left", width: "100%" }}>
        <h5>Status do pedido</h5>

        <ContainerItem>
          {items.map((item, index) => (
            <StatusItem display={index !== items.length - 1}>
              <StatusItemLeft>
                <StatusItemBadge
                  color={index === 0 ? layoutData.primaryColor : "#333"}
                />
              </StatusItemLeft>
              <StatusItemBody>
                <span>
                  {moment(item.data).format("HH:mm")} - {item.situacaoPedido}
                </span>
              </StatusItemBody>
            </StatusItem>
          ))}
        </ContainerItem>

        <ContainerItem>
          <div>
            <EnderecoDetalhes
            isPedidoOff={isPedidoOff}
              endereco={pedido}
              retirarNoLocal={pedido.flgRetirarLocal}
            />
          </div>
        </ContainerItem>

        <ContainerItem>
          <Label>Data do pedido</Label>
          <LabelDescription>
            {moment(pedido.data).format("DD/MM/YYYY [•] HH:mm")}
          </LabelDescription>
        </ContainerItem>

          <ContainerItem>
          <Label>Forma de pagamento</Label>
          <LabelDescription>{pedido.modalidadeCaixaApiNome}</LabelDescription>
        </ContainerItem>

        <ContainerItem>
          <Label>Resumo</Label>
          <Resumo items={isPedidoOff ? pedido.itens : pedido.pedidoItem} pedido={pedido} isPedidoOff={isPedidoOff} />
        </ContainerItem>
      </div>
      <br />
    </Container>
  );
}

export default TimelineComponent;
