import Axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { BASE_URL_API } from "infra/config";

export default function usePagamentosParciais(saidaId, canSearch) {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const onLoad = useCallback(async () => {
    try {
      const { data } = await Axios.get(
        `${BASE_URL_API}/v3/acompconta/venda-pagamentos`,
        {
          params: {
            saidaId,
          },
        }
      );
      setList(data);
    } catch (e) {}
  }, [saidaId]);

  useEffect(() => {
    if (!canSearch) return;
    (async () => {
      setIsLoading(true);

      onLoad();

      setIsLoading(false);
    })();
  }, [onLoad, canSearch]);

  return {
    isLoading,
    list,
    refresh: onLoad,
  };
}
