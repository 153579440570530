import React from "react";
import Appbar from "./components/Appbar";
import styled from "styled-components";
import ScrollTop from "./components/ScrollTop";
import Footer from "./components/Footer";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import themeJson from "../../../theme.json";
const theme = createMuiTheme(themeJson);

const Body = styled.div`
  height: 100vh;
`;

function LayoutWebGula({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Appbar />
      <Body>
        {children}
        <Footer />
        <ScrollTop />
      </Body>
    </ThemeProvider>
  );
}

export default LayoutWebGula;
