import { useState, useEffect } from 'react';
import { getEmpresasGeral } from 'services/empresa';
import { useMemo } from 'react';
import { removeAccents } from 'infra/utils';

function useRestaurantesApi() {
    const [payload, setPayload] = useState([]);
    const [filtros, setFiltros] = useState({
        cidadeId: 1489, // Cuiaba
        tipoEmpresa: 2
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        (async () => {

            if (!filtros.cidadeId) {
                return setPayload([]);
            }

            setLoading(true);

            try {
                const data = await getEmpresasGeral({
                    tipoEmpresa: filtros.tipoEmpresa,
                    especialidadeId: filtros.especialidadeId, 
                    cidadeId: filtros.cidadeId
                });

                setPayload(data);

            } catch (e) {

                // TODOO: mostrar erro

            } finally {
                setLoading(false);
            }
        })();

    }, [filtros.cidadeId, filtros.tipoEmpresa, filtros.especialidadeId]);

    const items = useMemo(() => {

        if (!filtros.nome) {
            return payload;
        }

        let result = payload.filter(x => { 
            return removeAccents(x.nome.toLowerCase()).includes(removeAccents(filtros.nome.toLowerCase()))
        });

        return result;

    }, [payload, filtros.nome]);

    return [items, loading, filtros, (key, value) => setFiltros(f => ({ ...f, [key]: value }))];
}


export default useRestaurantesApi;