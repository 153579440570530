import React from "react";
import styled from "styled-components";
import { formatMoney } from "infra/utils"; 

import {
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography
} from "@material-ui/core";

const Wrapper = styled.div`
  padding: 16px;
  background: #f7f7f7;
  width: 100%;
  float: left;
  margin-bottom: 16px;

  &:hover {
    background: #efefef;
    cursor: pointer;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  }
`;

function PedidoItem({ item }) {
  return (
    <Wrapper>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar src={item.imagem} alt={item.nomeCompleto} />
        </ListItemAvatar>
        <ListItemText
          primary={item.itemNomeCompleto}
          secondary={
            <React.Fragment>
              <Typography component="span" variant="body2" color="textPrimary">
                {item.qtde} x R${" "}
                {formatMoney(item.valorTotal / item.qtde)} ={" R$ "}
                <strong style={{ fontSize: 20 }}>
                  {" "}
                  {formatMoney(item.valorTotal)}{" "}
                </strong>
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </Wrapper>
  );
}

export default PedidoItem;
