import { useEffect, useState } from "react";
import { BASE_URL_API } from "infra/config";
import Axios from "axios";

// import { Container } from './styles';

function useGuias() {
  const [guias, setGuias] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await Axios.get(`${BASE_URL_API}/v3/cardapio/guias`);
        setGuias(data);
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    guias,
    loading,
  };
}

export default useGuias;
