import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { solicitarResgatePremio } from "services/fidelidade";
import { showError, showSuccess } from "infra/alerta";
import { RecompensaResult } from "./index";
import RangeQuantidade from "ui/layouts/default/pages/components/variacao/RangeQuantidade";

function ModalRecompensa({
  visible,
  premio,
  onClose,
}: {
  visible: boolean;
  premio: RecompensaResult;
  onClose: any;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<{ quantidade: Number; observacao: string }>({
    quantidade: 1,
    observacao: "",
  });

  const handleSolicitarResgate = useCallback(async () => {
    if (!premio.flgDisponivelAgora) {
      return showError("O premio se encontra Indisponível para resgate");
    }

    try {
      setIsLoading(true);

      await solicitarResgatePremio({
        fidelidadeContaId: premio.fidelidadeContaId,
        premioId: premio.id,
        qtdePremio: form.quantidade,
        observacao: form.observacao,
      });

      showSuccess("Solicitação de resgate feita com sucesso!");
    } catch (error) {

      const details = (error as any).response?.data.details

      if (details && details.length > 0) {
        for (const detail of details) {
          if (detail.errors && detail.errors.length > 0) {
            for (const error of detail.errors) {
              showError(error);
            }
          }
        }
      }
    } finally {
      setIsLoading(false);
      onClose()
    }
  }, [form, premio, onClose]);

  useEffect(() => {
    if (visible) {
      setForm({ quantidade: 1, observacao: "" });
    }
  }, [visible]);

  if (!premio) {
    return null;
  }

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: 700,
          width: "100%",
        },
      }}
    >
      <DialogTitle>{premio.nome}</DialogTitle>
      <DialogContent>
        {!premio.flgDisponivelAgora ? (
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <Chip color="secondary" label="Indisponível" />
          </div>
        ) : null}

        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <Typography style={{ fontSize: 20 }}>
            <Chip
              variant="outlined"
              color="primary"
              label={`${premio.pontoCusto} ponto(s)`}
            />
          </Typography>
        </div>

        <DialogContentText>{premio.texto}</DialogContentText>

        <div>
          <div style={{ marginTop: 32, marginBottom: 32 }}>
            <RangeQuantidade
              min={1}
              max={9999}
              value={form.quantidade}
              onChange={(v: any) =>
                setForm((prev) => ({
                  ...prev,
                  quantidade: v,
                }))
              }
            />
          </div>
          <div style={{ marginTop: 32, marginBottom: 32 }}>
            <TextField
              value={form.observacao}
              fullWidth
              rows={4}
              multiline
              onChange={(ev) =>
                setForm((prev) => ({
                  ...prev,
                  observacao: ev.target?.value,
                }))
              }
              label="Observação (máximo 50 caracteres)"
              variant="filled"
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ marginRight: 8 }}>
          <Button
            onClick={() => {
              onClose();
            }}
            color="primary"
            autoFocus
            disabled={isLoading}
          >
            Sair
          </Button>
          <Button
            onClick={() => {
              handleSolicitarResgate();
            }}
            color="primary"
            variant="contained"
            autoFocus
            disabled={isLoading}
          >
            {isLoading ? "Aguarde..." : "Solicitar Resgate"}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ModalRecompensa;
