import React from "react";
import styled from "styled-components";
import { formatMoney } from "infra/utils";

const ContainerContent = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;

const ProdutoItemCard = styled.div`
  border-bottom: 1px solid #eee;
  margin-bottom: 16px;
  padding: 8px 0;
  overflow: hidden;
`;

// export function calcularValorTotal(items) {

//   const valorTotal = items.reduce(
//     (acumulador, current) => current.valorTotal + acumulador,
//     0
//   );

//   return valorTotal;
// }

export default function Resumo({ items = [], pedido,isPedidoOff }) {
  return (
    <React.Fragment>
      <ContainerContent>
        {items.map((produto) => (
          <ProdutoItemCard>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                {produto.qtde}x {isPedidoOff ? produto.produtoNome : produto.itemNomeCompleto}{" "}
              </p>
              <strong>R$ {formatMoney(produto.valorTotal)}</strong>
            </div>
            <p style={{ fontSize: 12, color: "#555" }}>
              {produto?.pedidoItemVariacao
                ?.map((item) => `${item.qtde} x ${item.itemNome}`)
                ?.join(", ")}
            </p>
            <p style={{ fontSize: 12, marginBottom: 0 }}>
              {" "}
              Obs.: {produto.observacao || "Sem"}
            </p>
          </ProdutoItemCard>
        ))}

        <div style={{ margin: "16px 0" }}>
          <div style={{ display: "flex", marginBottom: 8 }}>
            <div style={{ flex: 1 }}> SubTotal </div>
            <div>R$ {formatMoney(pedido.valorProduto)}</div>
          </div>
          <div style={{ display: "flex", marginBottom: 8 }}>
            <div style={{ flex: 1 }}> Taxa de Entrega </div>
            <div>
              {pedido.flgRetirarLocal
                ? "Retirar no local"
                : pedido.valorTaxaEntrega || pedido.valorTxEntrega > 0
                ? `R$ ${formatMoney(isPedidoOff ? pedido.valorTxEntrega : pedido.valorTaxaEntrega)}`
                : `Grátis`}
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 8 }}>
            <div style={{ flex: 1 }}>
              <strong> Total </strong>
            </div>
            <div>
              <strong>R$ {formatMoney(pedido.valorTotal)}</strong>
            </div>
          </div>
        </div>
      </ContainerContent>
    </React.Fragment>
  );
}
