import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { Container, Row, Col, Badge, Spinner } from "reactstrap";
import { getCardapioCompleto } from "services/cardapio";
import ModalProduto from "../../../components/ModalProduto";
import Produto from "./Produto";
import { ContainerProduto, GrupoItem, FundoCardapio } from "./styles";
import { LayoutContext } from "contexts/layout";
import axios from "axios";
import AuthClient from "infra/auth/AuthClient";
import { BASE_URL_API } from "infra/config";
import NavbarSticky from "../../../CardapioPage/components/NavbarSticky";
import { removeAccents } from "infra/utils";
import clonedeep from "lodash.clonedeep";
import useGuias from "actions/guias/useGuias";
import { checkoutCart, getAllCart, getOrigem } from "services/cart";
import { Button } from "@material-ui/core";
import { navigate } from "infra/router/history";

function ListaCardapio(props) {
  const [grupos, setGrupos] = useState([]);
  const [gruposFiltro, setGruposFiltro] = useState([]);
  const [filtros, setFiltros] = useState({
    guiaId: props.match.params.guiaId || undefined,
  });
  const [produto, setProduto] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenProduto, setIsOpenProduto] = useState(false);
  const { layoutData, update: updateContext } = useContext(LayoutContext);
  const isUserLogged = AuthClient.isUserLogged();
  const { guias } = useGuias();
  const origem = getOrigem();
  useEffect(() => {
    async function hasConta() {
      if (isUserLogged) {
        try {
          setIsLoading(true);
          const { data } = await axios.get(
            `${BASE_URL_API}/v1/acompanhamento-conta/pedidomenu-conta-atual`
          );

          if (data) {
            updateContext({ ...layoutData, contaAtualMenuDto: data });
          }
        } catch (err) {
        } finally {
          setIsLoading(false);
        }
      }
    }
    hasConta();
    // eslint-disable-next-line
  }, [isUserLogged]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      const { result, grupos } = await getCardapioCompleto(1, filtros?.guiaId); // pdvId 1 cardapio do menu
      setGrupos(result);
      setGruposFiltro(grupos);
      setIsLoading(false);
    }
    fetchData();
  }, [filtros]);

  const handleClick = useCallback((item) => {
    setProduto(item);
    setIsOpenProduto(true);
  }, []);

  const handleChange = useCallback(
    (prop, value) => {
      setFiltros((prev) => ({ ...prev, [prop]: value }));
    },
    [setFiltros]
  );

  const result = useMemo(() => {
    let retorno = clonedeep([...grupos]);

    if (filtros.categoria) {
      retorno = retorno.filter((x) => x.nome === filtros.categoria);
    }

    if (filtros.search) {
      retorno.forEach((x) => {
        x.items.forEach((grupo) => {
          grupo.produtos = grupo.produtos.filter((produto) => {
            return removeAccents(produto.nomeCompleto.toLowerCase()).includes(
              removeAccents(filtros.search.toLowerCase())
            );
          });
        });

        x.items = x.items.filter((grupo) => grupo.produtos.length);
      });

      retorno = retorno.filter((x) => x.items.length);
    }

    return retorno;
  }, [grupos, filtros]);

  useEffect(() => {
    if (origem === 'delivery') {
      checkoutCart();
      updateContext();
    }
  }, [layoutData, origem, updateContext]);

  const pontoEntrega = JSON.parse(localStorage.getItem("pontoEntrega"));

  useEffect(() => {
    if (!layoutData.flgPedidoPontoEntrega || pontoEntrega !== null) {
      localStorage.removeItem("pontoEntrega")
    }
  }, [layoutData.flgPedidoPontoEntrega, pontoEntrega]);

  return (
    <div>
      <Container style={{ marginBottom: 32 }}>
        <h2 style={{ marginBottom: 32 }}>Menu Principal</h2>

        <NavbarSticky
          grupos={gruposFiltro}
          indice={grupos}
          guias={guias}
          value={filtros}
          onChange={handleChange}
        />
      </Container>

      <FundoCardapio color={layoutData?.cardapioCorFundo}>
        <Container>
          {isLoading && (
            <Spinner style={{ margin: "32px auto", display: "table" }} />
          )}

          <Row>
            <Col md={12}>
              <ModalProduto
                open={isOpenProduto}
                item={produto}
                menu={true}
                onToggle={() => setIsOpenProduto(!isOpenProduto)}
                onSaved={() => setIsOpenProduto(false)}
              />

              <ContainerProduto>
                <div>
                  <Row>
                    <Col sm={12}>
                      <div>
                        {result.map((grupo, key) =>
                          grupo.items.length ? (
                            <div key={key} id={`gru-${grupo.id}`}>
                              <GrupoItem key={key}>
                                <h4>{grupo.nome}</h4>
                                <div>
                                  {grupo.items.map((categoria, k) =>
                                    categoria.produtos.length ? (
                                      <div key={k} id={`cat-${categoria.id}`}>
                                        <Row>
                                          <Col xs={12}>
                                            <h5
                                              style={{
                                                float: "left",
                                                padding: "2px 4px",
                                              }}
                                            >
                                              {categoria.nome}
                                            </h5>{" "}
                                            <Badge>
                                              <span>
                                                {categoria.produtos.length}{" "}
                                                produtos
                                              </span>
                                            </Badge>
                                          </Col>
                                        </Row>
                                        <Row>
                                          {categoria.produtos.map((produto) => (
                                            <Col
                                              key={produto.id}
                                              md={6}
                                              xs={12}
                                            >
                                              <div style={{ marginBottom: 16 }}>
                                                <Produto
                                                  onClick={() =>
                                                    handleClick(produto)
                                                  }
                                                  item={produto}
                                                />
                                              </div>
                                            </Col>
                                          ))}
                                        </Row>
                                      </div>
                                    ) : null
                                  )}
                                </div>
                              </GrupoItem>
                            </div>
                          ) : null
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </ContainerProduto>
            </Col>
          </Row>

          {window.innerWidth <= 768 && getAllCart().length > 0 && ( 
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("carrinho")}
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                zIndex: 1000,
                backgroundColor: layoutData.primaryColor,
                color: "white",
                padding: "16px 0",
                fontWeight: 'bold',
                borderRadius: 6 
              }}
            >
              {`Ir para o carrinho ${getAllCart().length > 0 ? `(${getAllCart().length} ${getAllCart().length > 1 ? "itens" : "item"})` : ""}`}
            </Button>
          )}
        </Container>

      </FundoCardapio>
    </div>
  );
}

export default ListaCardapio;
