import React, { useState, useContext } from "react";

import { Step, Stepper, StepLabel } from "@material-ui/core";
import { Container } from "reactstrap";
import Swal from "sweetalert2";

import Appbar from "../../../components/Appbar";
import ScrollTop from "../../../components/ScrollTop";
import Footer from "../../../components/Footer";

import { EventoContext } from "../EventoContext";

import StepControl from "./components/StepControl";
import Ambiente from "./components/Ambiente";
import Cardapio from "./components/Cardapio";
import Resumo from "./components/Resumo";

import { Body } from "./styles";

import useFinalizarReserva from "actions/reserva/useFinalizarReserva";

import { Ambiente as AmbienteType, Produto as ProdutoType } from "../types";

const steps = ["DATA E ESPAÇO", "CARDÁPIO", "INFORMAÇÕES FINAIS"];

function CarrinhoPage() {
  const [activeStep, setActiveStep] = useState(0);
  const { value, update } = useContext(EventoContext);

  const { isLoading, handleReservar } = useFinalizarReserva();

  const handleNext = () => {
    if (activeStep === 0) {
      if (!value.dataEvento) {
        return Swal.fire("Aviso", "Informe a data!", "error");
      }

      if (!value.ambiente) {
        return Swal.fire("Aviso", "Informe o ambiente!", "error");
      }
    }

    if (activeStep === 1) {
      if (!value.tipoEventoId) {
        return Swal.fire("Aviso", "Informe o tipo de evento!", "error");
      }

      if (!value.produto) {
        return Swal.fire("Aviso", "Informe o cardápio!", "error");
      }
    }

    if (activeStep === 2) {
      if (!value.quantidadePessoas) {
        return Swal.fire("Aviso", "Informe a quantidade de pessoas", "error");
      }

      return handleReservar();
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <Ambiente />;
      case 1:
        return <Cardapio />;
      default:
        return (
          <Resumo
            update={update}
            data={value.dataEvento}
            quantidadePessoas={value.quantidadePessoas}
            ambiente={value.ambiente as AmbienteType}
            produto={value.produto as ProdutoType}
          />
        );
    }
  }

  return (
    <Body>
      <Appbar />

      <Container>
        <Stepper
          style={{ backgroundColor: "#F0F1F3" }}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div style={{ marginBottom: 32 }}>
          {getStepContent(activeStep)}

          <div>
            <StepControl
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
              isLoading={isLoading}
              steps={steps}
            />
          </div>
        </div>
      </Container>

      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default CarrinhoPage;
