import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Spinner, Row, Col, Container } from "reactstrap";

import Appbar from "../../../components/Appbar";
import ScrollTop from "../../../components/ScrollTop";
import Footer from "../../../components/Footer";

import useConta from "actions/menu/useConta";
import { formatMoney } from "infra/utils";
import moment from "moment";
import { Button } from "@material-ui/core";
import { navigate } from "infra/router/history";
import usePagamentosParciais from "actions/menu/usePagamentosParciais";
import { LayoutContext } from "contexts/layout";
import ProdutosList from "./components/ProdutosList";
import PagamentosList from "./components/PagamentosList";
import ModalPagamentoParcialPix from "./components/ModalPagamentoParcialPix";

import FidelidadeSection from "./components/FidelidadeSection";

const Body = styled.div`
  min-height: 100vh;
  padding-top: 120px;

  @media all and (max-width: 768px) {
    & {
      padding-top: 64px;
    }
  }
`;

function ContaPage(props) {
  const { update: updateContext, layoutData } = useContext(LayoutContext);
  const [showPagamentosList, setShowPagamentosList] = React.useState(false);
  const [modalPagamentoParcial, setModalPagamentoParcial] =
    React.useState(false);
  const { isLoading, venda, refresh, setIsLoading } = useConta(
    props.match.params.saidaId,
    updateContext
  );
  const {
    isLoading: isLoadingPagamentos,
    list,
    refresh: refreshPagamentos,
  } = usePagamentosParciais(props.match.params.saidaId, showPagamentosList);

  useEffect(() => {
    refreshPagamentos();
    refresh();
  }, [props.match.params.saidaId, refreshPagamentos, refresh]);

  const MemoizedProdutosList = React.memo(ProdutosList);
  const MemoizedPagamentosList = React.memo(PagamentosList);

  const renderProdutos = !showPagamentosList && (
    <MemoizedProdutosList venda={venda} />
  );

  const renderPagamentos = showPagamentosList && (
    <MemoizedPagamentosList list={list} />
  );

  return (
    <Body>
      <ModalPagamentoParcialPix
        open={modalPagamentoParcial}
        numIdentificador={venda?.numIdentificadorCompleto}
        saidaId={props.match.params.saidaId}
        valorRestante={venda?.valorPagarRestante}
        onCancel={() => setModalPagamentoParcial(false)}
      />

      <Appbar />

      <Container>
        {(isLoading || isLoadingPagamentos) && (
          <Spinner style={{ margin: "32px auto", display: "table" }} />
        )}

        {!isLoading && (
          <div
            style={{ maxWidth: "600px", width: "100%", margin: "auto auto" }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  padding: "2px 4px",
                  background: "#d1d8e3",
                  borderRadius: 8,
                }}
              >
                Minha Conta
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 16,
              }}
            >
              <span span={9}># {venda.id}</span>

              <span style={{ textAlign: "right", spanor: "#222" }} span={3}>
                {venda?.numIdentificadorCompleto}
              </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Atendente:</span>

              <span>{venda?.vendedorApelido}</span>
              <span>{moment(venda?.dataSaida).format("DD/MM/YY HH:mm")}</span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
                marginTop: 8,
                padding: "16px 0",
                borderTop: "1px solid gray",
              }}
            >
              {venda.flgAceitaWGComanda ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => navigate("menu", true)}
                >
                  Novo Pedido
                </Button>
              ) : null}
              <div
                style={{
                  display: "flex",
                  gap: 4,
                  justifyContent: "space-between",
                  marginTop: 8,
                }}
              >
                <Button
                  style={{
                    width: "100%",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    refreshPagamentos();
                    refresh();
                  }}
                >
                  Atualizar
                </Button>
                {venda.flgStatus === "A" && (
                  <Button
                    style={{
                      width: "100%",
                    }}
                    color="primary"
                    variant="contained"
                    onClick={() => setShowPagamentosList(!showPagamentosList)}
                  >
                    {showPagamentosList ? "Produtos" : "Pagamentos"}
                  </Button>
                )}
              </div>
            </div>

            <div style={{ borderTop: "1px solid gray", padding: 8 }}>
              {!showPagamentosList ? (
                <>{renderProdutos}</>
              ) : (
                <>
                  {renderPagamentos}
                </>
              )}
            </div>

            <div
              style={{
                padding: "8px 0",
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                fontWeight: "bold",
              }}
            >
              <Row>
                <Col span={12}>Valor de Gorjeta</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  R$: {formatMoney(venda?.valorComissao)}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Valor de Desconto</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {" "}
                  R$: {formatMoney(venda?.valorDescontoTotal)}
                </Col>
              </Row>
              {venda?.valorPagoParcial > 0 && (
                <Row>
                  <Col span={12}>Valor Pago Parcial</Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    {" "}
                    R$: {formatMoney(venda?.valorPagoParcial)}
                  </Col>
                </Row>
              )}
              {venda?.valorPagarRestante > 0 && (
                <Row>
                  <Col span={12}>Valor Restante</Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    {" "}
                    R$: {formatMoney(venda?.valorPagarRestante)}
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={12}>Couvert/Ingresso</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {" "}
                  R$: {formatMoney(venda?.valorIngressoTotal)}
                </Col>
              </Row>
              <Row>
                <Col span={12}> Total</Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  {" "}
                  R$: {formatMoney(venda?.valorTotal)}
                </Col>
              </Row>
            </div>

            <FidelidadeSection
              cabecalho={layoutData}
              conta={venda}
              onReset={refresh}
              setLoading={setIsLoading}
            />
          </div>
        )}
      </Container>

      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default ContaPage;
