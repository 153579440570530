import React, { useContext, useState, useEffect, useCallback } from "react";

import { Drawer } from "@material-ui/core";
import styled from "styled-components";
import { Typography, TextField, Button, Collapse } from "@material-ui/core";
import AuthClient from "infra/auth/AuthClient";
import { LayoutContext } from "contexts/layout";
import Axios from "axios";
import { BASE_URL_API } from "infra/config";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Row, Col } from "reactstrap";
import ListaContas from "../../../Menu/MenuPage/components/ListaContas";
import MenuFidelidade from "../../../Menu/MenuPage/components/MenuFidelidade";

export default function DrawerFiltro(props) {
  return (
    <StyledDrawer
      closable="true"
      anchor={"left"}
      open={props.drawerOpen}
      onClose={() => props.setDrawerOpen(false)}
    >
      <div>
        <Button
          style={{
            color: "white",
            background: "#6c757d",
            width: "100%",
            margin: "16px 0",
          }}
          onClick={() => props.setDrawerOpen(false)}
        >
          Fechar
        </Button>
      </div>
      {
        !props.isDelivery && (
          <AcompanhamentoConta {...props} />
        )
      }

      <div style={{ padding: "8px" }}>
        {props.indice?.map((grupo) => (
          <div key={grupo.id}>
            <span
              onClick={() => props.handleScroll(`gru-${grupo.id}`)}
              style={{
                fontSize: "0.8em",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              <Typography variant="body2">{grupo.nome}</Typography>
            </span>

            <ul>
              {grupo.items?.map((categoria) =>
                categoria.produtos.length ? (
                  <li
                    key={categoria.id}
                    onClick={() => props.handleScroll(`cat-${categoria.id}`)}
                    style={{
                      marginBottom: 8,
                      cursor: "pointer",
                      fontSize: "0.8em",
                    }}
                  >
                    <Typography variant="body2">{categoria.nome}</Typography>
                  </li>
                ) : null
              )}
            </ul>
          </div>
        ))}
      </div>
    </StyledDrawer>
  );
}

export function AcompanhamentoConta(props) {
  const { layoutData, update: updateContext } = useContext(LayoutContext);

  const [openAcompanhamento, setOpenAcompanhamento] = useState(false);
  const [openAcompanhamentoCollapse, setOpenAcompanhamentoCollapse] =
    useState(false);
  const [chave, setChave] = useState(null);
  const [inputChave, setInputChave] = useState("");
  const isUserLogged = AuthClient.isUserLogged();
  const [contas, setContas] = useState([]);

  const onLoad = useCallback(async () => {
    try {
      const { data } = await Axios.get(
        BASE_URL_API + "/v1/acompanhamento-conta/lista-contas"
      );

      setContas(data);
    } catch (e) {
    } finally {
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleAcessarConta = () => {
    if (isUserLogged) {
      setChave(inputChave);
      setOpenAcompanhamento(true);
    } else {
      props.setDrawerOpen(false);
      updateContext({ openLogin: true });
    }
  };

  return (
    <>
      <div
        style={{
          background: "white",
          boxShadow: "0 0 3px rgba(0,0,0,.2)",
          paddingTop: "16px",
          borderRadius: "4px",
        }}
      >
        <div style={{ paddingBottom: 16, paddingLeft: 16, paddingRight: 16 }}>
          <Typography
            onClick={() => setOpenAcompanhamentoCollapse((prev) => !prev)}
            gutterBottom
            variant="h5"
            component="h2"
            style={{
              margin: 0,
              cursor: "pointer",
              fontWeight: "500",
              fontSize: "1em",
            }}
          >
            {openAcompanhamentoCollapse ? <ExpandMore /> : <ChevronRight />}{" "}
            Acompanhe sua conta
          </Typography>

          <Collapse in={openAcompanhamentoCollapse}>
            <Typography variant="body2" color="textSecondary" component="p">
              Está em nosso estabelecimento? Peça ao atendente o código da sua
              conta e insira no campo a baixo.
            </Typography>

            <div style={{ marginTop: 32 }}>
              <Row>
                <Col sm={8} style={{ marginBottom: 16 }}>
                  <TextField
                    value={inputChave}
                    defaultValue=""
                    onChange={(ev) => setInputChave(ev.target.value)}
                    fullWidth
                    variant="outlined"
                    label="Código da conta"
                  />
                </Col>
                <Col sm={4}>
                  <Button
                    style={{ height: 55 }}
                    disabled={!inputChave}
                    onClick={handleAcessarConta}
                    variant="contained"
                    fullWidth
                    color="primary"
                  >
                    Acessar Conta
                  </Button>
                </Col>
              </Row>
            </div>
            <Row>
              {isUserLogged && (
                <ListaContas
                  openAcompanhamento={openAcompanhamento}
                  setOpenAcompanhamento={setOpenAcompanhamento}
                  chave={chave}
                  setChave={setChave}
                  items={contas}
                  onRefresh={() => {
                    setInputChave("");
                    onLoad();
                  }}
                />
              )}
            </Row>
          </Collapse>
        </div>
      </div>
      <Row>
        {layoutData.fidelidadeId ? (
          <Col lg={4} sm={6} style={{ marginBottom: 32, marginTop: 32 }}>
            <MenuFidelidade />
          </Col>
        ) : null}
      </Row>
    </>
  );
}
const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 95vw;
    height: 100vh;
    padding: 8px;
  }
`;
