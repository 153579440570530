import React, { useContext } from "react";
import { Container } from "reactstrap";
import { navigate } from "infra/router/history";

import { Ambiente } from "../../../types";
import useAmbientesEvento from "../../../hooks/useAmbientesEvento";
import { EventoContext } from "../../../EventoContext";
import { ContainerAmbientes } from "./styles";

import SkeletonItem from "./SkeletonItem";
import AmbienteItem from "./AmbienteItem";

function Ambientes() {
  const { data, isLoading } = useAmbientesEvento();
  const { update } = useContext(EventoContext);

  const montar = (ambiente: Ambiente) => {
    update("ambiente", ambiente);

    return navigate("eventos/carrinho");
  };

  return (
    <ContainerAmbientes>
      <Container>
        <div>
          {isLoading ? (
            <div>
              <SkeletonItem />
              <SkeletonItem />
              <SkeletonItem />
            </div>
          ) : null}

          {data.map((ambiente, index) => (
            <AmbienteItem
              onClick={() => montar(ambiente)}
              item={ambiente}
              key={index}
            />
          ))}
        </div>
      </Container>
    </ContainerAmbientes>
  );
}

export default Ambientes;
