import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Container, Row, Col, Badge, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import clonedeep from "lodash.clonedeep";

import { getCardapioCompleto } from "services/cardapio";
import { removeAccents } from "infra/utils";
import AuthClient from "infra/auth/AuthClient";
import { useLayoutContext } from "contexts/layout";

import ModalProduto from "../../components/ModalProduto";
import { ContainerProduto, GrupoItem } from "./styles";
import Produto from "./Produto";

import NavbarSticky from "./NavbarSticky";
import ModalPedidoOff from "../../components/ModalPedidoOff";
import useCookies from "infra/hooks/useCookies";
import { FundoCardapio } from "../../Menu/MenuPage/components/styles";
import { checkoutCart, getAllCart, getOrigem } from "services/cart";
import { Button } from "@material-ui/core";
import { navigate } from "infra/router/history";

function ListaCardapio() {
  const { layoutData, update: updateContext } = useLayoutContext();
  const [filtros, setFiltros] = useState({});
  const [grupos, setGrupos] = useState([]);
  const [sugestao, setSugestao] = useState([]);
  const [produto, setProduto] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [gruposFiltro, setGruposFiltro] = useState([]);
  const [isOpenProduto, setIsOpenProduto] = useState(false);
  const [openModalCadastro, setOpenModalCadastro] = useState(false);
  const { getCookie, setCookie } = useCookies();

  const pedidoOffCookie =
    !!getCookie("idPedidoOff") && !!getCookie("chavePedidoOff");

  const flagPedidoOff = !!layoutData?.wgPedidoDeliveryOFF;

  const pontoEntrega = JSON.parse(localStorage.getItem("pontoEntrega"));
  const origem = getOrigem()
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      const { result, grupos, sugestaoDia } = await getCardapioCompleto(3);
      setGrupos(result);
      setGruposFiltro(grupos)
      setSugestao(sugestaoDia);

      setIsLoading(false);
    }
    fetchData();

    return () => {
      setGrupos([]);
      setGruposFiltro([]);
      setSugestao([]);
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (!layoutData.flgPedidoPontoEntrega && pontoEntrega !== null) {
      localStorage.removeItem("pontoEntrega")
    }
  }, [layoutData.flgPedidoPontoEntrega, pontoEntrega]);


  const handleClick = useCallback(
    (item) => {
      const isUserLogged = AuthClient.isUserLogged();
      if (!isUserLogged && !pedidoOffCookie) {
        return Swal.fire({
          title: "Aviso",
          text: "Você precisa fazer login antes de continuar.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Fazer login",
          cancelButtonText: flagPedidoOff
            ? "Continuar sem cadastro"
            : "Cancelar",
        }).then((result) => {
          if (result.value) {
            updateContext({ openLogin: true });
          }
          if (flagPedidoOff && result.isDismissed) {
            setOpenModalCadastro(true);
          }
        });
      }

      if (item.flgEmFalta) {
        return Swal.fire("Aviso", "Produto em falta!", "error");
      }

      setProduto(item);
      setIsOpenProduto(true);
    },
    [updateContext, pedidoOffCookie, flagPedidoOff]
  );

  const handleChange = useCallback(
    (prop, value) => {
      setFiltros((prev) => ({ ...prev, [prop]: value }));
    },
    [setFiltros]
  );
  useEffect(() => {
    if (origem === 'menu') {
      checkoutCart();
      updateContext();
    }
  }, [layoutData, origem, updateContext]);
  const result = useMemo(() => {
    let retorno = clonedeep([...grupos]);

    if (filtros.categoria) {
      retorno = retorno.filter((x) => x.nome === filtros.categoria);
    }

    if (filtros.search) {
      retorno.forEach((x) => {
        x.items.forEach((grupo) => {
          grupo.produtos = grupo.produtos.filter((produto) => {
            return removeAccents(produto.nomeCompleto.toLowerCase()).includes(
              removeAccents(filtros.search.toLowerCase())
            );
          });
        });

        x.items = x.items.filter((grupo) => grupo.produtos.length);
      });

      retorno = retorno.filter((x) => x.items.length);
    }

    return retorno;
  }, [grupos, filtros]);

  return (
    <div
      style={{ backgroundColor: "#fafafc", overflow: "hidden", paddingTop: 16 }}
    >
      <FundoCardapio color={layoutData?.cardapioCorFundo}>
        <Container>
          {isLoading && (
            <Spinner style={{ margin: "32px auto", display: "table" }} />
          )}

          <Row>
            <Col md={9}>
              {isOpenProduto ? (
                <ModalProduto
                  open={isOpenProduto}
                  item={produto}
                  onToggle={() => setIsOpenProduto(!isOpenProduto)}
                  onSaved={() => setIsOpenProduto(false)}
                />
              ) : null}

              <ContainerProduto>
                <NavbarSticky
                  isDelivery={true}
                  grupos={gruposFiltro}
                  indice={grupos}
                  value={filtros}
                  onChange={handleChange}
                />

                <div>
                  <br />

                  <Row>
                    <Col sm={12}>
                      {sugestao.length ? (
                        <div>
                          <GrupoItem>
                            {sugestao.length > 1 ? (
                              <h4>Sugestões do dia </h4>
                            ) : (
                              <h4>Sugestão do dia</h4>
                            )}
                            <Row>
                              {sugestao.map((produto, key) => (
                                <Col md={6} xs={12} key={key}>
                                  <div style={{ marginBottom: 16 }}>
                                    <Produto
                                      onClick={() => handleClick(produto)}
                                      item={produto}
                                    />
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </GrupoItem>
                        </div>
                      ) : null}
                      <div>
                        {result.map((grupo, key) =>
                          grupo.items?.length ? (
                            <div key={key} id={`gru-${grupo.id}`}>
                              <GrupoItem key={key}>
                                <h3>{grupo.nome}</h3>
                                <div>
                                  {grupo.items.map((categoria, k) =>
                                    categoria.produtos.length ? (
                                      <div key={k} id={`cat-${categoria.id}`}>
                                        <Row>
                                          <Col xs={12}>
                                            <h4 style={{ float: "left" }}>
                                              {categoria.nome}
                                            </h4>{" "}
                                            <Badge>
                                              {categoria.produtos.length} produtos
                                            </Badge>
                                          </Col>
                                        </Row>
                                        <Row>
                                          {categoria.produtos.map((produto, key) => (
                                            <Col md={6} xs={12} key={key}>
                                              <div style={{ marginBottom: 16 }}>
                                                <Produto
                                                  onClick={() =>
                                                    handleClick(produto)
                                                  }
                                                  item={produto}
                                                />
                                              </div>
                                            </Col>
                                          ))}
                                        </Row>
                                      </div>
                                    ) : null
                                  )}
                                </div>
                              </GrupoItem>
                            </div>
                          ) : null
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </ContainerProduto>
            </Col>
          </Row>
          {window.innerWidth <= 768 && getAllCart().length > 0 && (
            <Button
              variant="contained"
              color="primary.main"
              onClick={() => navigate("carrinho")}
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                zIndex: 1000,
                backgroundColor: layoutData.primaryColor,
                color: "white",
                padding: "16px 0",
                fontWeight: 'bold',
                borderRadius: 6 
              }}
            >
              {`Ir para o carrinho ${getAllCart().length > 0 ? `(${getAllCart().length} ${getAllCart().length > 1 ? "itens" : "item"})` : ""}`}
            </Button>
          )}
        </Container>
        <ModalPedidoOff
          open={openModalCadastro}
          onToggle={() => {
            setOpenModalCadastro(!openModalCadastro);
          }}
          onSaved={(values) => {
            setOpenModalCadastro(!openModalCadastro);
            setCookie("idPedidoOff", values?.id, 7);
            setCookie("chavePedidoOff", values?.chave, 7);
            updateContext();
          }}
        />
      </FundoCardapio>
    </div>
  );
}

export default ListaCardapio;
