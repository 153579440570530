import React from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { formatMoney } from "infra/utils";

function ProdutosList({ venda }) {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            padding: "2px 4px",
            background: "#d1d8e3",
            borderRadius: 8,
          }}
        >
          {venda.saidaItem?.length > 0 ? "Produtos" : "Nenhum produto lançado"}
        </div>
      </div>
      {(venda.saidaItem ?? []).map((produto) => (
        <div
          style={{
            paddingBottom: 16,
            paddingTop: 8,
          }}
          key={produto.id} // Adicione uma chave única aqui
        >
          <Row>
            <Col span={9}>{produto.produtoNomeCompleto}</Col>

            <Col style={{ textAlign: "right", color: "gray" }} span={3}>
              {moment(produto?.dataHoraVenda).format("DD/MM/YY HH:mm")}
            </Col>
          </Row>

          <Row style={{ marginTop: 8 }}>
            <Col span={3}>Qtde.</Col>
            <Col span={3} style={{ textAlign: "right" }}>
              V.Unitário
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              V.Total
            </Col>
          </Row>
          <Row>
            <Col span={3} style={{ color: "#222" }}>
              <b>x{produto.qtde}</b>
            </Col>
            <Col span={3} style={{ textAlign: "right", color: "#222" }}>
              <b>R${formatMoney(produto.valorUnitario)}</b>
            </Col>
            <Col span={3} style={{ textAlign: "right", color: "#222" }}>
              <b>R${formatMoney(produto.valorTotal)}</b>
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
}

export default ProdutosList;
