/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import { TextField, Button } from '@material-ui/core'
import { useState } from 'react';
import InputMask from 'react-input-mask';
import { useCallback } from 'react';
import { faleConosco } from 'services/empresa';
import { useToasts } from 'react-toast-notifications';

const BaseGradient = styled.div`
	margin-top: 80px;
	padding-top: 20px; 
	padding-bottom: 32px;
	display: flex;
	justify-content: center;
	background: linear-gradient(360deg, #880A0A 22.25%, #E3382D 100%);
	overflow: hidden;
`;

const ImagesContainer = styled.div`  
	display: flex;
	flex: 1;
	flex-direction: row; 
	
	@media all and (max-width: 768px) {
		flex-direction: column;
		display: block;
	} 
`

const HeaderText = styled.h1` 

	font-family: Cabin;
	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	line-height: 64px; 

	color: #FFFFFF; 
	
	@media all and (max-width: 768px) {
		font-size: 24px;
		line-height: 24px; 
		text-align: center;
	}
`

const ContainerText = styled.div`
	
	width: 60%; 

	@media all and (max-width: 768px) {
		display: block;
		width: 100%; 
		text-align: center;

		img {
			margin-bottom: 16px;
			margin-right: 0 !important;
		}
	}
`;

const Formulario = styled.div`
	background: white;
	padding: 32px;
	width: 40%;

	img {
		margin-top: 48px;
	}
	@media all and (max-width: 768px) {
		display: block;
		width: 100%; 
		text-align: center;

		img {
			margin-top: 0px;
			max-height: 250px
		}
	}

`;

function HeaderTopo() {

	const { addToast } = useToasts()
	const [form, setForm] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});

	const handleEnviar = useCallback(async () => {

		const stateErrors = {};

		if (!form.nome) {
			stateErrors.nome = true;
		}
		if (!form.email) {
			stateErrors.email = true;
		}
		if (!form.telefone) {
			stateErrors.telefone = true;
		}

		if (!form.mensagem) {
			stateErrors.mensagem = true;
		}

		setErrors(stateErrors);

		if (Object.keys(stateErrors).length > 0) {
			return;
		}

		try {

			setIsLoading(true);

			await faleConosco({
				nome: form.nome,
				email: form.email,
				telefone: form.telefone,
				mensagem: form.mensagem
			});

			alert('Seu contato foi realizado com sucesso, em breve retornaremos.');
			
			setForm({});

		} catch (e) {
			addToast('Houve um problema ao enviar o contato', { appearance: 'error' })
		} finally {
			setIsLoading(false);
		}

	}, [addToast, form.email, form.mensagem, form.nome, form.telefone]);

	return (
		<BaseGradient>
			<Container>
				<ImagesContainer>
					<ContainerText style={{ display: 'flex', alignItems: 'center' }}>
						<HeaderText style={{ color: 'white', margin: 16, }}>
							Solicite nosso contato para saber como podemos te ajudar a{' '}
							<span style={{ color: '#F6B215' }}>aumentar o seu faturamento</span>
						</HeaderText>
					</ContainerText>

					<Formulario>
						<h2>Solicitar contato</h2>

						<div style={{ marginBottom: 32 }}>
							<TextField error={errors.nome} variant="outlined" fullWidth label="Nome completo" value={form.nome} onChange={(ev) => {
								setForm({ ...form, nome: ev.target.value });
								setErrors(err => ({ ...err, nome: false }));
							}} />
						</div>

						<div style={{ marginBottom: 32 }}>
							<TextField error={errors.email} variant="outlined" fullWidth type="email" label="E-mail" value={form.email}
								onChange={(ev) => {
									setForm({ ...form, email: ev.target.value });
									setErrors(err => ({ ...err, email: false }));
								}} />
						</div>


						<div style={{ marginBottom: 32 }}>
							<InputMask error={errors.telefone} label="Celular" variant="outlined" fullWidth mask="(99) 9 9999-9999" value={form.telefone} onChange={(ev) => {
								setForm({ ...form, telefone: ev.target.value });
								setErrors(err => ({ ...err, telefone: false }));
							}}
							>
								{(inputProps) => <TextField
									{...inputProps}
								/>}
							</InputMask>
						</div>

						<div style={{ marginBottom: 32 }}>
							<TextField as="textarea" variant="outlined" fullWidth rows={4} type="textarea" label="Mensagem" value={form.mensagem}
								onChange={(ev) => {
									setForm({ ...form, mensagem: ev.target.value });
									setErrors(err => ({ ...err, mensagem: false }));
								}} />
						</div>

						<p style={{ fontSize: 12 }}>
							Ao clicar em "Enviar", você aceito que um representante do WebGula entre em contato contigo por telefone, e-mail ou WhatsApp (incluindo mensagens automáticas para fins comerciais).
						</p>

						<Button disabled={isLoading} onClick={() => handleEnviar()} fullWidth color="primary" variant="contained">{isLoading ? 'Enviando...' : 'Enviar'}</Button>

					</Formulario>
				</ImagesContainer>
			</Container>
		</BaseGradient>
	)
}

export default HeaderTopo;
