import React from "react";
import {
  Body,
} from "./styles";
import Footer from "../../components/Footer";
import ScrollTop from "../../components/ScrollTop";
import Appbar from "../../components/Appbar";
import HeaderEmpresa from "../components/HeaderEmpresa";
import { Container, Spinner, Row, Col } from "reactstrap";

import { Paper } from "@material-ui/core";
import ReservaItem from "./components/ReservaItem";
import useGetMinhasReservas from "./hooks/useGetMinhasReservas";


function MinhasReservas() {
  return (
    <Body>
      <Appbar />
      <HeaderEmpresa showCarrinho={false} />
      <Container>
        <MinhasReservasPage />
      </Container>

      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default MinhasReservas;

function MinhasReservasPage() {

  const {data:minhasReservas, isLoading } = useGetMinhasReservas();
 
 
  return (
  
    <Container>
      {isLoading && <Spinner />}

      <h2 style={{marginTop: 16}}>Reservas</h2>

      <br />

      <Row>
        {minhasReservas.map(item => (
          <Col sm={6}>
            <Paper>
              <ReservaItem item={item} />
            </Paper>
          </Col>
        ))}
      </Row>
      <br />
    </Container>
  );
}
