import AuthClient from "infra/auth/AuthClient";
import {
  getAllCart,
  checkoutCart,
  getFormaPagamento,
  getObservacao,
  getValorPagamento,
  getFormaPagamentoOnline,
  getSaida,
  getVoucher,
  getDesconto,
  getTaxaEntrega,
  getNome,
  getChaveComanda,
  setChaveComanda,
  setNome,
  getLocalizacaoMesaId,
  getRetirarNoLocal,
} from "./cart";
import moment from "moment";
import { calcularTotalVariacaoItem } from "../ui/components/Produto/utils";
import { parseDoubleCustom, createImage } from "infra/utils";
import Axios from "axios";
import { BASE_URL_API } from "infra/config";

export const SITUACAO_PEDIDO = {
  EM_ABERTO: 1,
  CANCELADO: 2,
  EM_PRODUCAO: 3,
  SAIU_PARA_ENTREGA: 4,
  FINALIZADO: 5,
  PRONTO_RETIRAR_LOCAL: 6,
  AGUARDANDO_PAGAMENTO: 7,
};

export const MOTIVOS_CANCELAMENTO = [
  {
    id: 4,
    nome: "Fiz o Pedido Errado",
  },
  {
    id: 5,
    nome: "Meu Endereço estava Errado",
  },
  {
    id: 6,
    nome: "Desisti de Pedir",
  },
  {
    id: 7,
    nome: "Outros Motivos",
  },
];

export async function validarVoucher(cupom) {
  const { data } = await Axios.get(`${BASE_URL_API}/v1/vouchers/${cupom}`);

  return data;
}

export async function getAcompanhamento(pedidoId, pessoaOffId) {
  const { data } = !!pessoaOffId
    ? await Axios.get(
      `${BASE_URL_API}/v3/wg-pedido-off/obter-status?pessoaoffid=${pessoaOffId}&pedidoId=${pedidoId}`
    )
    : await Axios.get(
      `${BASE_URL_API}/v1/wg-pedido/${pedidoId}/acompanhamento`
    );

  return data;
}

export async function getMinhasFormasPagamento() {
  const { data } = await Axios.get(`${BASE_URL_API}/v1/cartoes`);
  return data;
}

export async function getFormaPagamentoPrincipal() {
  const { data } = await Axios.get(`${BASE_URL_API}/v1/cartoes/principal`);
  return data;
}

export async function atualizarFormaPagamento(body) {
  const { data } = await Axios.put(
    `${BASE_URL_API}/v1/cartoes/${body.id}`,
    body
  );
  return data;
}

export async function mudarPrincipalFormaPagamento(id) {
  const { data } = await Axios.put(
    `${BASE_URL_API}/v1/cartoes/${id}/alterar-principal`
  );
  return data;
}

export async function criarFormaPagamento(body) {
  const { data } = await Axios.post(`${BASE_URL_API}/v1/cartoes`, body);
  return data;
}

export async function excluirFormaPagamento(id) {
  const { data } = await Axios.delete(`${BASE_URL_API}/v1/cartoes/${id}`);
  return data;
}

export async function getModalidadesPagamento(empresaId) {
  const { data } = await Axios.get(
    `${BASE_URL_API}/v1/api/modalidades-caixas/${empresaId}`
  );
  return data;
}

export async function findPedidoMenu(id) {
  const { data } = await Axios.get(
    `${BASE_URL_API}/v3/wg-pedido-consumo/direto/meus-pedidos/${id}/detalhado`
  );

  return data;
}

export async function findPedidoOff(
  pessoaOffId,
  pedidoId,
) {
  const { data } = await Axios.get(
    `${BASE_URL_API}/v3/wg-pedido-off/${pedidoId}/detalhado`,
    {
      params: {
        pessoaoffid: pessoaOffId,
        pedidoid: pedidoId,
      },
    }
  );

  return data;
}

export async function findPedido(id) {
  const { data } = await Axios.get(
    `${BASE_URL_API}/v1/wg-pedido/${id}/detalhes/completo`
  );

  if (!!data.logoUrl && data.logoUrl.indexOf("http") === -1) {
    data.logoUrl = createImage(
      data.grupoEmpresarialChave,
      data.empresaId,
      data.logoUrl
    );
  }

  const response = {};

  response.id = id;
  response.empresaId = data.empresaId;
  response.situacaoId = data.situacaoId;
  response.modalidadeCaixaApiNome = data.modalidadeCaixaApiNome;
  response.dataPedido = data.dataHoraPedido;
  response.valorTotal = data.valorTotal;
  response.valorProduto = data.valorProduto;
  response.valorTaxaEntrega = data.valorTxEntrega;
  response.bairro = data.endBairroNome;
  response.endCidadeNome = data.endCidadeNome;
  response.endEstadoNome = data.endEstadoNome;
  response.endLogradouro = data.endLogradouro;
  response.endNumero = data.endNumero;
  response.situacaoNome = data.situacaoPedidoNome;
  response.pedidoItem = data.pedidoItem;
  response.flgRetirarLocal = data.flgRetirarLocal;
  response.pix = data.pix;
  response.flgPontoEntrega = data.flgPontoEntrega;
  response.logradouroFull = data.logradouroFull;
  response.pontoEntregaNome = data.pontoEntregaNome;

  return response;
}
export async function getAll(params = { limite: 30 }) {
  let { data } = await Axios.get(
    `${BASE_URL_API}/v1/api/PedidoApi/ultimos/empresa-atual`,
    {
      params,
    }
  );

  data = data.map((item) => {
    if (!!item.logoUrl && item.logoUrl.indexOf("http") === -1) {
      item.logoUrl = createImage(
        item.grupoEmpresarialChave,
        item.empresaId,
        item.logoUrl
      );
    }

    return {
      id: item.pedidoBaseId,
      empresaId: item.empresaId,
      situacaoId: item.situacaoId,
      dataPedido: item.dataPedido,
      valorTotal: item.valorTotal,
      valorTaxaEntrega: item.valorTaxaEntrega,
      bairro: item.bairro,
      empresaNome: item.empresaNome,
      empresaBaseId: item.empresaBaseId,
      grupoEmpresarialChave: item.grupoEmpresarialChave,
      situacaoNome: item.wG_SituacaoNome,
      logoUrl: item.logoUrl,
      origemWGId: 4,
    };
  });

  return data;
} export async function getAllPedidosMenu(params = { limite: 30 }) {
  let { data } = await Axios.get(
    `${BASE_URL_API}/v3/wg-pedido-consumo/direto/meus-pedidos
`,

  );

  // data = data.map((item) => {
  //   if (!!item.logoUrl && item.logoUrl.indexOf("http") === -1) {
  //     item.logoUrl = createImage(
  //       item.grupoEmpresarialChave,
  //       item.empresaId,
  //       item.logoUrl
  //     );
  //   }

  //   return {
  //     id: item.pedidoBaseId,
  //     empresaId: item.empresaId,
  //     situacaoId: item.situacaoId,
  //     dataPedido: item.dataPedido,
  //     valorTotal: item.valorTotal,
  //     valorTaxaEntrega: item.valorTaxaEntrega,
  //     bairro: item.bairro,
  //     empresaNome: item.empresaNome,
  //     empresaBaseId: item.empresaBaseId,
  //     grupoEmpresarialChave: item.grupoEmpresarialChave,
  //     situacaoNome: item.wG_SituacaoNome,
  //     logoUrl: item.logoUrl,
  //     origemWGId: 4,
  //   };
  // });

  return data;
}

export async function getTaxaEntregaEndereco(
  enderecoId,
  valorTotalProduto,
  pessoaOffId = null,
) {
  const { data: regiao } = !!pessoaOffId
    ? await Axios.get(
      `${BASE_URL_API}/v3/wg-pedido-off/validar-empresa?pessoaoffid=${pessoaOffId}&valorTotalProduto=${valorTotalProduto ?? 0
      }`
    )
    : await Axios.get(
      `${BASE_URL_API}/v2/api/pessoa-endereco/validar-empresa`,
      {
        params: {
          valorTotalProduto: valorTotalProduto ?? 0,
          pessoaEnderecoId: enderecoId,
        },
      }
    );
  return regiao;
}
export async function checkout(
  form,
  retirarNoLocal = false,
  isPedidoOff = false,
  idPedidoOff = null,
  chavePedidoOff = null,
  pontoEntregaId = null,
  enderecoPedidoOff = null
) {
  const authData = AuthClient.getData();
  const empresa = form.empresa;
  const endereco = authData?.endereco || enderecoPedidoOff || null;
  const pagamentoOnline = getFormaPagamentoOnline();
  const formaPagamento = getFormaPagamento();
  const modalidadeId = formaPagamento?.id;
  const modalidadeNome = formaPagamento?.nome;
  const valorPagamento = getValorPagamento();
  const observacao = getObservacao();
  const items = getAllCart();
  const taxaEntrega = retirarNoLocal ? 0 : getTaxaEntrega();
  const voucher = getVoucher();
  const valorDesconto = getDesconto();
  const isPontoEntrega = !!pontoEntregaId;
  let valorTroco = 0;
  let valorTotal = 0;

  items.forEach((item) => {
    valorTotal += item.valorTotal;
    item.pedidoItemVariacao = [];

    item.variacoesUsadas.forEach((variacao) => {
      const divisaoMultipla = variacao.flgTipo === "M";
      const itemsSelecionados = variacao.items.filter((x) => x.quantidade);
      const totalQuantidadesSelecionada = itemsSelecionados.reduce(
        (acc, current) => acc + current.quantidade,
        0
      );

      itemsSelecionados.forEach((variacaoitem) => {
        const { total, quantidade } = calcularTotalVariacaoItem(
          variacaoitem,
          divisaoMultipla,
          totalQuantidadesSelecionada
        );

        item.pedidoItemVariacao.push({
          tamanhoId: variacaoitem.tamanhoId,
          itemId: variacaoitem.id,
          variacaoId: variacao.id,
          qtde: parseDoubleCustom(quantidade),
          valorUnitario: parseDoubleCustom(variacaoitem.preco),
          valorTotal: parseDoubleCustom(total),
          observacao: variacaoitem.observacao,
        });
      });
    });
  });

  valorTotal = parseDoubleCustom(valorTotal);
  const valorTotalComTudo = parseDoubleCustom(
    valorTotal - valorDesconto + taxaEntrega
  );

  if (modalidadeNome?.toLowerCase()?.includes("dinheiro")) {
    valorTroco = valorPagamento - (valorTotal - valorDesconto + taxaEntrega);

    if (valorPagamento < valorTotalComTudo) {
      return {
        error: "O valor de pagamento deve ser maior ou igual ao valor total!",
      };
    }
  }

  let modalidadeCaixaApiId = null;

  if (pagamentoOnline?.pix) {
    modalidadeCaixaApiId = 101;
  } else if (pagamentoOnline?.id) {
    modalidadeCaixaApiId = 99;
  } else {
    modalidadeCaixaApiId = modalidadeId;
  }

  const pessoaCarteiraId = !pagamentoOnline?.pix ? pagamentoOnline?.id : null;

  let modelPedidoOff = null;

  if (isPedidoOff) {
    modelPedidoOff = {
      pessoaOFFId: idPedidoOff,
      pessoaOFFChave: chavePedidoOff,
      voucherId: voucher?.id || null,
      empresaGlobalId: empresa.id,
      empresaId: empresa.empresaBaseId,
      pessoaEnderecoId: retirarNoLocal ? null : 1,
      dataHoraPedido: moment().format(),
      situacaoId: SITUACAO_PEDIDO.EM_ABERTO,
      modalidadeCaixaApiId: modalidadeCaixaApiId,
      pagamentoOnline: formaPagamento?.pix || !!pagamentoOnline?.id,
      flgRetirarLocal: retirarNoLocal ? true : false,
      endBairroId: retirarNoLocal ? empresa.endBairroId : endereco?.bairroId,
      endLogradouro: retirarNoLocal ? empresa.endLogradouro : endereco?.logradouro,
      endNumero: retirarNoLocal ? empresa.endNumero : endereco?.numero,
      endComplemento: retirarNoLocal ? empresa.endComplemento : endereco?.complemento,
      endReferencia: retirarNoLocal ? empresa.endReferencia : endereco?.referencia ?? "",
      endCep: retirarNoLocal ? empresa.endCep : endereco?.cep,
      endBairroNome: retirarNoLocal ? empresa.endBairroNome : endereco?.bairroNome,
      endCidadeId: retirarNoLocal ? empresa.endCidadeId : endereco?.cidadeId,
      valorDesconto: valorDesconto || 0,
      valorProduto: parseDoubleCustom(valorTotal),
      valorTotal: valorTotalComTudo,
      valorTroco: parseDoubleCustom(valorTroco),
      valorTxEntrega: parseDoubleCustom(taxaEntrega),
      observacao: observacao,
      origemWGId: 4,
      wgPedidoItem: items.map((item) => {
        const precoSelecionado = item.precos.find((x) => x.selected);

        return {
          itemId: item.id,
          valorUnitario: parseDoubleCustom(item.valorTotal / item.quantidade),
          qtde: parseDoubleCustom(item.quantidade),
          valorNormal: parseDoubleCustom(item.valorTotal / item.quantidade),
          valorTotal: parseDoubleCustom(item.valorTotal),
          observacao: item.observacao || "",
          tamanhoId: precoSelecionado.tamanhoId,
          wgPedidoItemVariacao: item.pedidoItemVariacao,
          OrigemWGId: 4,
        };
      }),
      pedidoItemOpcional: [],
    };
  }
  const model = {
    voucherId: voucher?.id || null,
    pessoaGlobalId: authData?.pessoaId ?? 0,
    empresaGlobalId: empresa.id,
    empresaId: empresa.empresaBaseId,
    pessoaEnderecoId: retirarNoLocal ? null : endereco?.id,
    dataHoraPedido: moment().format(),
    modalidadeCaixaApiId: modalidadeCaixaApiId,
    pessoaCarteiraId: pessoaCarteiraId,
    pagamentoOnline: formaPagamento?.pix || !!pagamentoOnline?.id,
    flgRetirarLocal: retirarNoLocal ? true : false,
    endBairroId: retirarNoLocal ? empresa.endBairroId : endereco?.bairroId,
    endLogradouro: retirarNoLocal ? empresa.endLogradouro : endereco?.logradouro,
    endNumero: retirarNoLocal ? empresa.endNumero : endereco?.numero,
    endComplemento: retirarNoLocal ? empresa.endComplemento : endereco?.complemento,
    endReferencia: retirarNoLocal ? empresa.endReferencia : endereco?.referencia,
    endCep: retirarNoLocal ? empresa.endCep : endereco?.cep,
    endBairroNome: retirarNoLocal ? empresa.endBairroNome : endereco?.nomeBairro,
    endCidadeId: retirarNoLocal ? empresa.endCidadeId : endereco?.cidadeId,
    endLatitude: retirarNoLocal ? empresa.endLatitude : endereco?.latitude,
    endLongitude: retirarNoLocal ? empresa.endLongitude : endereco?.longitude,
    valorDesconto: valorDesconto || 0,
    valorProduto: parseDoubleCustom(valorTotal),
    valorTotal: valorTotalComTudo,
    valorTroco: parseDoubleCustom(valorTroco),
    valorTxEntrega: parseDoubleCustom(taxaEntrega),
    situacaoId: SITUACAO_PEDIDO.EM_ABERTO,
    observacao: observacao,
    origemWGId: 4,
    pontoEntregaId: pontoEntregaId || undefined,
    wgPedidoItem: items.map((item) => {
      const precoSelecionado = item.precos.find((x) => x.selected);

      return {
        itemId: item.id,
        valorUnitario: parseDoubleCustom(item.valorTotal / item.quantidade),
        qtde: parseDoubleCustom(item.quantidade),
        valorNormal: parseDoubleCustom(item.valorTotal / item.quantidade),
        valorTotal: parseDoubleCustom(item.valorTotal),
        observacao: item.observacao || "",
        tamanhoId: precoSelecionado.tamanhoId,
        wgPedidoItemVariacao: item.pedidoItemVariacao,
        OrigemWGId: 4,
      };
    }),
    pedidoItemOpcional: [],
  };

  try {
    let url;
    let modeloEscolhido;

    if (pagamentoOnline?.pix) {
      isPontoEntrega ? url = `${BASE_URL_API}/v3/wg-pedido/ponto-entrega` : url = `${BASE_URL_API}/v3/wg-pedido`;
      modeloEscolhido = model;
    } else if (isPedidoOff) {
      url = `${BASE_URL_API}/v3/wg-pedido-off/novo-pedido`;
      modeloEscolhido = modelPedidoOff;
    } else if (isPontoEntrega) {
      url = `${BASE_URL_API}/v3/wg-pedido/ponto-entrega`;
      modeloEscolhido = model;
    } else {
      url = `${BASE_URL_API}/v3/wg-pedido`;
      modeloEscolhido = model;
    }

    const { data } = await Axios.post(url, modeloEscolhido);

    if (isPedidoOff) {
      modelPedidoOff.id = data.id;
      modelPedidoOff.empresa = { ...empresa };
      modelPedidoOff.items = items;
    } else {
      model.id = data.id;
      model.empresa = { ...empresa };
      model.items = items;
    }

    // Limpa o carrinho
    checkoutCart();
    localStorage.removeItem("pontoEntrega");

    return {
      success: true,
      data: {
        id: isPedidoOff ? modelPedidoOff.id : model.id,
        pix: data.pix,
        empresaId: empresa.id,
        situacaoId: model.situacaoId,
        dataPedido: isPedidoOff
          ? modelPedidoOff.dataHoraPedido
          : model.dataHoraPedido,
        valorTotal: isPedidoOff ? modelPedidoOff.valorTotal : model.valorTotal,
        bairro: isPedidoOff
          ? modelPedidoOff.endBairroNome
          : model.endBairroNome,
        empresaNome: empresa.nome,
        empresaBaseId: empresa.empresaBaseId,
        grupoEmpresarialChave: empresa.chaveGrupoEmpresarial,
        situacaoNome: "Em aberto",
        logoUrl: empresa.logoUrl,
      },
    };
  } catch (exception) {
    let errorMsg = "Houve um problema ao realizar esta operação.";

    if (exception?.response?.data?.details) {
      errorMsg = exception.response.data.details[0].errors.join(" ");
    }

    console.log("algo de errado nao ta certo no fim do pedido", exception);

    return {
      success: false,
      error: errorMsg,
    };
  }
}

export async function checkoutMenuAcompanhamentoConta(
  form,
  nome,
  chaveComanda
) {
  const empresa = form.empresa;

  const observacao = getObservacao();

  const saidaId = getSaida();

  let { items, valorTotal, valorTotalVariacao } = formatProdutosMenu(
    getAllCart()
  );
  valorTotal = parseDoubleCustom(valorTotal);

  const model = {
    aplicativoId: 1,
    saidaId,
    nomeApoio: nome,
    chavePedidoMobile: chaveComanda,
    empresaId: empresa.empresaBaseId,
    valorDesconto: 0,
    valorAcrescimo: 0,
    valorProduto: parseDoubleCustom(valorTotal),
    valorTotal: parseDoubleCustom(valorTotal),
    observacao: observacao,
    wgPedidoItem: items.map((item) => {
      const precoSelecionado = item.precos.find((x) => x.selected);

      return {
        itemId: item.id,
        valorUnitario: parseDoubleCustom(item.valorTotal / item.quantidade),
        qtde: parseDoubleCustom(item.quantidade),
        valorNormal: parseDoubleCustom(item.valorTotal / item.quantidade),
        valorTotal: parseDoubleCustom(item.valorTotal),
        valorDesconto: 0,
        valorTotalVariacao: valorTotalVariacao,
        observacao: item.observacao || "",
        tamanhoId: precoSelecionado.tamanhoId,
        wgPedidoItemVariacao: item.pedidoItemVariacao,
        flgVariacao: valorTotalVariacao > 0,
      };
    }),
  };

  try {
    await Axios.post(`${BASE_URL_API}/v3/wg-pedido-comanda/direto`, model);

    // Limpa o carrinho
    checkoutCart();

    return {
      success: true,
      data: {
        saidaId,
      },
    };
  } catch (exception) {
    let errorMsg = "Houve um problema ao realizar esta operação.";

    if (
      exception.response &&
      exception.response.data &&
      exception.response.data.details
    ) {
      errorMsg = exception.response.data.details[0].errors.join(" ");
    }

    return {
      success: false,
      error: errorMsg,
    };
  }
}


export async function checkoutMenuAcompanhamentoContaDeslogado(
  form,
  nome,
  chaveComanda
) {
  const empresa = form.empresa;

  const observacao = getObservacao();

  const saidaId = getSaida();

  let { items, valorTotal, valorTotalVariacao } = formatProdutosMenu(
    getAllCart()
  );
  valorTotal = parseDoubleCustom(valorTotal);

  const model = {
    aplicativoId: 1,
    saidaId,
    nomeApoio: nome,
    empresaId: empresa.empresaBaseId,
    valorDesconto: 0,
    valorAcrescimo: 0,
    chaveClienteCurta: chaveComanda,
    valorProduto: parseDoubleCustom(valorTotal),
    valorTotal: parseDoubleCustom(valorTotal),
    observacao: observacao,
    wgPedidoItem: items.map((item) => {
      const precoSelecionado = item.precos.find((x) => x.selected);

      return {
        itemId: item.id,
        valorUnitario: parseDoubleCustom(item.valorTotal / item.quantidade),
        qtde: parseDoubleCustom(item.quantidade),
        valorNormal: parseDoubleCustom(item.valorTotal / item.quantidade),
        valorTotal: parseDoubleCustom(item.valorTotal),
        valorDesconto: 0,
        valorTotalVariacao: valorTotalVariacao,
        observacao: item.observacao || "",
        tamanhoId: precoSelecionado.tamanhoId,
        wgPedidoItemVariacao: item.pedidoItemVariacao,
        flgVariacao: valorTotalVariacao > 0,
      };
    }),
  };

  try {
    await Axios.post(`${BASE_URL_API}/v3/wg-pedido-comanda/direto-off`, model);

    // Limpa o carrinho
    checkoutCart();

    return {
      success: true,
      data: {
        saidaId,
      },
    };
  } catch (exception) {
    let errorMsg = "Houve um problema ao realizar esta operação.";

    if (
      exception.response &&
      exception.response.data &&
      exception.response.data.details
    ) {
      errorMsg = exception.response.data.details[0].errors.join(" ");
    }

    return {
      success: false,
      error: errorMsg,
    };
  }
}



export async function checkoutPedidoDireto(form, isPedidoComTaxaDeServico) {
  const empresa = form.empresa;
  const observacao = getObservacao();

  const mesa = getLocalizacaoMesaId();
  const pagamentoOnline = getFormaPagamentoOnline();
  const formaPagamento = getFormaPagamento();
  const modalidadeId = formaPagamento?.id;
  let { items, valorTotal, valorTotalVariacao } = formatProdutosMenu(
    getAllCart()
  );
  const retirarNoLocal = getRetirarNoLocal();
  valorTotal = parseDoubleCustom(valorTotal);
  let modalidadeCaixaApiId = null;

  if (pagamentoOnline?.pix) {
    modalidadeCaixaApiId = 101;
  } else if (pagamentoOnline?.id) {
    modalidadeCaixaApiId = 99;
  } else {
    modalidadeCaixaApiId = modalidadeId;
  }

  const pessoaCarteiraId = !pagamentoOnline?.pix ? pagamentoOnline?.id : null;

  const valorTotalComTaxaDeServico = isPedidoComTaxaDeServico ? valorTotal * 1.1 : valorTotal;
  const model = {
    localizacaoId: !retirarNoLocal ? mesa?.id : 0,
    flgRetirarLocal: retirarNoLocal,
    empresaId: empresa.empresaBaseId,
    valorDesconto: 0,
    valorAcrescimo: 0,
    modalidadeCaixaApiId: modalidadeCaixaApiId,
    pessoaCarteiraId: pessoaCarteiraId,
    valorProduto: parseDoubleCustom(valorTotal),
    valorTotal: parseDoubleCustom(valorTotalComTaxaDeServico),
    valorTaxaServico: parseDoubleCustom(isPedidoComTaxaDeServico ? valorTotal * 0.1 : 0),
    observacao: observacao,
    wgPedidoItem: items.map((item) => {
      const precoSelecionado = item.precos.find((x) => x.selected);

      return {
        itemId: item.id,
        valorUnitario: parseDoubleCustom(item.valorTotal / item.quantidade),
        qtde: parseDoubleCustom(item.quantidade),
        valorNormal: parseDoubleCustom(item.valorTotal / item.quantidade),
        valorTotal: parseDoubleCustom(item.valorTotal),
        valorComissao: parseDoubleCustom(isPedidoComTaxaDeServico ? item.valorTotal * 0.1 : 0),
        percComissao: isPedidoComTaxaDeServico ? 10 : 0,
        valorDesconto: 0,
        valorTotalVariacao: valorTotalVariacao,
        observacao: item.observacao || "",
        tamanhoId: precoSelecionado.tamanhoId,
        wgPedidoItemVariacao: item.pedidoItemVariacao,
        flgVariacao: valorTotalVariacao > 0,
      };
    }),
  };


  try {
    const { data } = await Axios.post(`${BASE_URL_API}/v3/wg-pedido-consumo/direto`, model);

    // Limpa o carrinho
    checkoutCart();


    return {
      success: true,
      data
    };
  } catch (exception) {
    let errorMsg = "Houve um problema ao realizar esta operação.";

    if (
      exception.response &&
      exception.response.data &&
      exception.response.data.details
    ) {
      errorMsg = exception.response.data.details[0].errors.join(" ");
    }

    return {
      success: false,
      error: errorMsg,
    };
  }
}


export async function checkoutMenu(form) {
  const empresa = form.empresa;

  const observacao = getObservacao();

  const nome = getNome();
  const chaveComanda = getChaveComanda();

  let { items, valorTotal, valorTotalVariacao } = formatProdutosMenu(
    getAllCart()
  );

  valorTotal = parseDoubleCustom(valorTotal);

  const model = {
    nomeApoio: nome,
    chavePedidoMobile: chaveComanda,
    aplicativoId: 1,
    empresaId: empresa.empresaBaseId,
    valorDesconto: 0,
    valorAcrescimo: 0,
    valorProduto: parseDoubleCustom(valorTotal),
    valorTotal: parseDoubleCustom(valorTotal),
    observacao: observacao,
    wgPedidoItem: items.map((item) => {
      const precoSelecionado = item.precos.find((x) => x.selected);

      return {
        itemId: item.id,
        valorUnitario: parseDoubleCustom(item.valorTotal / item.quantidade),
        qtde: parseDoubleCustom(item.quantidade),
        valorNormal: parseDoubleCustom(item.valorTotal / item.quantidade),
        valorTotal: parseDoubleCustom(item.valorTotal),
        valorDesconto: 0,
        valorTotalVariacao: valorTotalVariacao,
        observacao: item.observacao || "",
        tamanhoId: precoSelecionado.tamanhoId,
        wgPedidoItemVariacao: item.pedidoItemVariacao,
        flgVariacao: valorTotalVariacao > 0,
      };
    }),
  };

  try {
    await Axios.post(`${BASE_URL_API}/v3/wg-pedido-comanda/direto`, model);

    // Limpa o carrinho
    checkoutCart();

    setNome(nome);
    setChaveComanda(chaveComanda);

    return {
      success: true,
    };
  } catch (exception) {
    let errorMsg = "Houve um problema ao realizar esta operação.";

    if (
      exception.response &&
      exception.response.data &&
      exception.response.data.details
    ) {
      errorMsg = exception.response.data.details[0].errors.join(" ");
    }

    return {
      success: false,
      error: errorMsg,
    };
  }
}

export function formatProdutosMenu(items) {
  let valorTotal = 0;
  let valorTotalVariacao = 0;

  // considerar a flag flgTipo
  // flgTipo == "M" multiplo
  // flgTipo == "U" unico
  // flgTipo == "S" somavel

  items.forEach((item) => {
    valorTotal += item.valorTotal;

    item.pedidoItemVariacao = [];

    item.variacoesUsadas.forEach((variacao) => {
      const divisaoMultipla = variacao.flgTipo === "M";

      const itemsSelecionados = variacao.items.filter((x) => x.quantidade);

      const totalQuantidadesSelecionada = itemsSelecionados.reduce(
        (acc, current) => acc + current.quantidade,
        0
      );

      itemsSelecionados.forEach((variacaoitem) => {
        const { total, quantidade } = calcularTotalVariacaoItem(
          variacaoitem,
          divisaoMultipla,
          totalQuantidadesSelecionada
        );

        const valorTotalVariacaoItem = parseDoubleCustom(total);

        valorTotalVariacao += valorTotalVariacaoItem;

        item.pedidoItemVariacao.push({
          tamanhoId: variacaoitem.tamanhoId,
          itemId: variacaoitem.id,
          variacaoId: variacao.id,
          qtde: parseDoubleCustom(quantidade),
          valorUnitario: parseDoubleCustom(variacaoitem.preco),
          valorTotal: valorTotalVariacaoItem,
          observacao: variacaoitem.observacao,
        });
      });
    });
  });

  return { items, valorTotalVariacao, valorTotal };
}

export async function cancelar(pedidoId, motivoCancelamentoId) {
  const body = {
    id: pedidoId,
    motivoCancelamentoId,
    dataHoraCancelamento: new Date(),
  };

  try {
    const { data } = await Axios.put(
      `${BASE_URL_API}/v1/wg-pedido/cancelar`,
      body
    );

    return data;
  } catch (e) {
    let error = "Houve um problema ao cancelar o pedido";
    if (e.response) {
      error = e.response.data.details[0].errors[0];
    }

    throw error;
  }
}

export async function avaliar({ avaliacao, pedidoId, mensagem, empresaId }) {
  const body = {
    mensagem,
    avaliacao,
    pedidoDelliveryId: pedidoId,
    flgStatus: "A",
    empresaId,
    usuarioClienteEmail: AuthClient.getData().email || "avaliar@tacto.com.br",
  };

  try {
    let data;

    if (pedidoId) {
      const { data: result } = await Axios.post(
        `${BASE_URL_API}/v1/avaliacoes-empresas/pedido-delivery`,
        body
      );

      data = result;
    } else {
      const { data: result } = await Axios.post(
        `${BASE_URL_API}/v1/avaliacoes-empresas`,
        body
      );

      data = result;
    }

    return data;
  } catch (e) {
    let error = "Houve um problema ao cancelar o pedido";
    if (e.response) {
      error = e.response.data.details[0].errors[0];
    }

    throw error;
  }
}
