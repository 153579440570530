/* eslint-disable react-native/no-inline-styles */
import React from "react";
import { Button } from "reactstrap";
import { parseDoubleCustom } from "infra/utils";

class RangeQuantidade extends React.PureComponent {
  onChange = (add = true) => {
    let { value, step, onChange, min, max } = this.props;

    step = step || 1;
    min = min === null || min === undefined ? 0 : min;
    max = max === null || max === undefined ? 9999 : max;

    if (add) {
      value = value - step;
    } else {
      value = value + step;
    }

    value = parseDoubleCustom(value);

    if (value > max) {
      return;
    }

    if (value < min) {
      return;
    }

    onChange(value);
  };

  render = () => {
    let { value, max, min = 0, textSize = 20 } = this.props;

    min = min === null || min === undefined ? 0 : min;
    max = max === null || max === undefined ? 9999 : max;

    value = value || 0;

    return (
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          background: "#fff",
          padding: 4,
          borderRadius: 4
        }}
      >
        <div style={{ width: 25, textAlign: "center" }}>
          <div>
            <Button
              color="danger"
              size="sm"
              onClick={() => this.onChange(true)}
            >
              <span
                className="fa fa-minus"
                style={{
                  color: "#fff",
                  opacity: value === min ? 0.3 : 1,
                  textAlign: "center"
                }}
              />
            </Button>
          </div>
        </div>
        <div style={{ width: 35 }}>
          <div
            style={{ fontSize: textSize, textAlign: "center", color: "#333" }}
          >
            {parseDoubleCustom(value, 2)}
          </div>
        </div>
        <div style={{ width: 25 }}>
          <div>
            <Button
              color="success"
              size="sm"
              onClick={() => this.onChange(false)}
            >
              <span
                className="fa fa-plus"
                style={{
                  color: "#fff",
                  opacity: value === max ? 0.3 : 1,
                  textAlign: "center"
                }}
              />
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

export default RangeQuantidade;
