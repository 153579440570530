import { useState, useCallback, useEffect, useContext } from "react";
import cloneDeep from "lodash.clonedeep";
import { calcularTotalVariacao } from "ui/components/Produto/utils";
import AuthClient from "infra/auth/AuthClient";
import { LayoutContext } from "contexts/layout";
import { getProduto } from "services/cardapio";
import {
  addCart,
  getOrigem,
  getAllCart,
  checkoutCart,
  setOrigem,
  getSaida,
  setSaida,
} from "services/cart";
import Swal from "sweetalert2";
import { showError } from "infra/alerta";
import useCookies from "infra/hooks/useCookies";

export default function useProduto(open, onSaved, menu, item) {
  const [produto, setProduto] = useState({
    variacoes: [],
    precos: [],
  });

  const [, updateState] = useState();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { layoutData, update: updateContext } = useContext(LayoutContext);
  const { getCookie } = useCookies();
  const isUserLogged = AuthClient.isUserLogged();
  // Quando esta no menu e esta acompanhando alguma conta 
  const isPedidoMenuDeslogado =  (menu  && !isUserLogged && layoutData.opcoesMenu.pedidoComandaDeslogado);
  const isPedidoMenu = (menu && layoutData.contaAtualMenuDto) || (menu  && isUserLogged && layoutData.opcoesMenu.pedidoDiretoAtivo);
  const isPedidoOff = !menu &&  !isUserLogged && !!getCookie("idPedidoOff") && !!getCookie("chavePedidoOff") && layoutData?.wgPedidoDeliveryOFF;
  const podeAdicionarOUAtualizarProduto = !menu || isPedidoMenu || isPedidoMenuDeslogado;
console.log(isPedidoMenuDeslogado)
  const forceUpdate = useCallback(() => updateState({}), []);

  const selecionarPreco = useCallback(
    (preco, pro) => {
      if (!preco) return;

      (pro.precos || []).forEach((p) => (p.selected = p === preco));

      pro.quantidade = 1;

      pro.variacoes.forEach((variacao) => {
        variacao.quantidadeJaSelecionada = 0;

        variacao.items.forEach((item) => {
          item.quantidade = 0;
        });
      });

      setProduto({ ...pro });

      forceUpdate();
    },
    [forceUpdate]
  );

  useEffect(() => {
    if (!open) {
      return;
    }

    async function load() {
      setError("");

      setIsLoading(true);

      setProduto({});

      const _produto = cloneDeep(item);

      // Caso nao esteja no carrinho busca do servidor mais dados
      if (!item.jaEstaNoCarrinho) {
        const response = await getProduto(item.id, menu ? 1 : 3);

        _produto.precos = response.valorPrecoItem || [];
      }

      setIsLoading(false);

      _produto.precos = _produto.precos.sort(
        (a, b) => a.valorPreco - b.valorPreco
      );

      const precoJaSelecionado = !!_produto.precos.find((x) => x.selected);

      if (!precoJaSelecionado && _produto.precos.length) {
        _produto.precos[0].selected = true;
      }

      _produto.quantidade = _produto.quantidade ?? 1;
      _produto.variacoes = _produto.variacoes ?? [];

      setProduto(_produto);

      setTimeout(
        () =>
          selecionarPreco(
            _produto.precos.find((x) => x.selected),
            _produto
          ),
        500
      );
    }

    load();
  }, [item, open, menu, selecionarPreco]);

  const handleChange = useCallback(
    (prop, value) => {
      setProduto({
        ...produto,
        [prop]: value,
      });
    },
    [produto]
  );

  const changeVariacao = useCallback(
    (variacao) => {
      produto.variacoes.forEach((item) => {
        if (item.id === variacao.id) {
          item = {
            ...variacao,
          };
        }
      });

      setProduto({ ...produto });
    },
    [produto]
  );

  const getValorTotalProduto = useCallback((item) => {
    let valorTotal = 0;

    let precoSelecionado = item.precos.find((x) => x.selected);

    if (!precoSelecionado) {
      return 0;
    }

    let possuiVariacaoMultiplaSelecionada = false;

    item.variacoes.forEach((variacao) => {
      if (variacao.flgTipo === "M") {
        const totalQuantidadesSelecionada = variacao.items
          .filter((x) => x.quantidade)
          .reduce((acc, current) => acc + current.quantidade, 0);

        if (!possuiVariacaoMultiplaSelecionada) {
          possuiVariacaoMultiplaSelecionada = totalQuantidadesSelecionada > 0;
        }
      }

      const totalItemsVariacao = calcularTotalVariacao(variacao);

      valorTotal += totalItemsVariacao * (item.quantidade || 1);
    });

    if (!possuiVariacaoMultiplaSelecionada) {
      valorTotal += precoSelecionado.valorPreco * item.quantidade;
    }

    valorTotal -= item.valorDesconto || 0;

    return valorTotal;
  }, []);

  const handleAdicionar = useCallback(async () => {
    setError("");

    if (!isPedidoOff && !isUserLogged && !isPedidoMenuDeslogado ) {
      return updateContext({ openLogin: true });
    }

    if (
      (isPedidoMenu && !layoutData.aberto) ||
      (!isPedidoMenu && !layoutData.aberto)
    ) {
      return showError("A empresa se encontra fechada nesse momento!");
    }

    if (await naoPodeAdicionarCarrinho(produto)) {
      return;
    }

    const saida = getSaida();

    // Caso nao tenha saida selecionada entao assume a ultima
    if (!saida && produto.pdvId === 1) {
      setSaida(layoutData.contaAtualMenuDto?.saidaId);
    }

    let precoSelecionado = null;

    if (produto.precos && produto.precos.length) {
      precoSelecionado = produto.precos.find((x) => x.selected) || {};

      produto.tamanhoId = precoSelecionado.tamanhoId;
    }

    if (!produto.tamanhoId) {
      return setError("Informe o tamanho do produto!");
    }

    const variacoesUsadas = produto.variacoes.filter((variacao) => {
      return !!variacao.items.find(
        (item) => item.tamanhoVariacaoProdutoId === precoSelecionado.tamanhoId
      );
    });

    for (const variacao of variacoesUsadas) {
      const quantidadeTotalSelecionada = variacao.items.reduce(
        (acumulador, current) => (acumulador += current.quantidade),
        0
      );

      if (variacao.flgTipo === "U") {
        if (variacao.qtdeMin && quantidadeTotalSelecionada === 0) {
          return setError(
            "Ops. Você precisa informar algum(a) " + variacao.nomeMostra
          );
        }
      } else {
        if (quantidadeTotalSelecionada > variacao.qtdeMax) {
          return setError(
            "Ops. Você só pode informar " +
              variacao.qtdeMax +
              " quantidade(s) de " +
              variacao.nomeMostra
          );
        }

        if (variacao.qtdeMin && quantidadeTotalSelecionada < variacao.qtdeMin) {
          return setError(
            `Ops. Você precisa informar pelo menos ${variacao.qtdeMin} ${variacao.nomeMostra}`
          );
        }
      }
    }

    const valorTotal = getValorTotalProduto(produto);

    if (!Number(valorTotal)) {
      return setError("Neste momento não é possível adicionar este produto", {
        appearance: "error",
        autoDismiss: true,
      });
    }

    const produtoCart = {
      ...produto,
      valorPreco: precoSelecionado.valorPreco,
      valorDesconto: produto.valorDesconto || 0,
      jaEstaNoCarrinho: true,
      valorTotal: valorTotal,
      variacoesUsadas: variacoesUsadas,
      empresaId: layoutData.id,
    };

    addCart(produtoCart);

    onSaved();

    updateContext();
  }, [isPedidoOff, isUserLogged, isPedidoMenuDeslogado, isPedidoMenu, layoutData.aberto, layoutData.id, layoutData.contaAtualMenuDto, produto, getValorTotalProduto, onSaved, updateContext]);

  const naoPodeAdicionarCarrinho = (produto) => {
    return new Promise(async (resolve) => {
      const origemCarrinho = getOrigem();
      const produtos = getAllCart();

      // Caso nao tenha origem, que no caso vai ser sempre na primeira
      // adicao de produto, entao adiciona origem e retorna
      if (!origemCarrinho) {
        setOrigem(produto.pdvId === 1 ? "menu" : "delivery");
      } else if (origemCarrinho === "menu" && produto.pdvId === 3) {
        // caso exista produtos atualmente,
        // perguntar se desenja substituir
        if (produtos.length) {
          return Swal.fire({
            title: "Aviso",
            text:
              "Ja existe um pedido em andamento pelo delivery, deseja limpar o pedido atual iniciar um novo ?",
            showCancelButton: true,
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Não`,
          }).then((result) => {
            if (result.isConfirmed) {
              checkoutCart();
              setOrigem("menu");
              resolve(false);
            } else {
              resolve(true);
            }
          });
        }
      } else if (origemCarrinho === "delivery" && produto.pdvId === 1) {
        // caso exista produtos atualmente,
        // perguntar se desenja substituir
        if (produtos.length) {
          return Swal.fire({
            title: "Aviso",
            text:
              "Ja existe um pedido em andamento pelo menu na mesa, deseja limpar o pedido atual iniciar um novo ?",
            showCancelButton: true,
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Não`,
          }).then((result) => {
            if (result.isConfirmed) {
              checkoutCart();
              setOrigem("delivery");
              resolve(false);
            } else {
              resolve(true);
            }
          });
        }
      }

      resolve(false);
    });
  };

  return {
    handleAdicionar,
    changeVariacao,
    handleChange,
    podeAdicionarOUAtualizarProduto,
    error,
    isLoading,
    produto,
    selecionarPreco,
  };
}
