/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styled from 'styled-components';
import Corpo from './components/Corpo';

const Wrapper = styled.div` 
  margin-bottom: 32px;
  margin-top: 64px;

`
function GaleriaPage({ match }) {
  const permalink = match.params.permalink;
  
  return (
    <Wrapper>
      <Corpo permalink={permalink} />
    </Wrapper>
  )
}

export default GaleriaPage;