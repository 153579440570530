/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import logo from '../images/logo.png';
import appStoreIcon from '../images/appstore-btn-icon.png';
import googlePlayIcon from '../images/googleplay-button-icono.png';
import appStoreIconBlack from '../images/appstore-button-icon-black.png';
import googlePlayIconBlack from '../images/googleplay-button-icon-black.png';
import appDeliveryImage from '../images/appdeliver.png';
import logoVertical from '../images/LogoVertical.png';
import querVenderMais from '../images/quervendermais.png';
import bg from '../images/bg.svg';
import Text from '../../../components/Text';
import { useHistory } from 'react-router-dom';


const FooterRoot = styled.div`
	width: 100%;
	clear: both; 
	padding-bottom: 0px;
	position: relative;
	background: white;
`;

const FooterWrapper = styled.div`
	text-align: center;
	margin-top: 10px;
	font-size: 0.9em;
	padding: 12px 0; 
	border-top: 1px solid #888;
`;
const FooterContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`

const CenterMobile = styled.div`
	text-align: center;
	align-items: center;
	justify-content: center;
`

function Footer() {

	const history = useHistory();

	return (
		<FooterRoot id="fale-conosco">
			<div
				style={{
					background: '#F0F1F3',
					height: 168,
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column'
				}}>
				<Container>
					<FooterContainer
						style={{
							flexDirection: 'column',
							justifyContent: 'space-evenly',
							alignItems: 'center',
							paddingTop: 32
						}}>
						<Text variant="h5" style={{ textAlign: 'center', marginBottom: 16 }}>Baixe o app e tenha e tenha o melhor do WebGula em suas mãos</Text>
						<div style={{
							marginBottom: 16
						}}>

							<a target="_blank" href="https://apps.apple.com/us/app/webgula/id1462601021?l=pt&ls=1">
								<img src={appStoreIcon} style={{ width: 151, height: 56, marginRight: 8 }} />
							</a>

							<a target="_blank" href="https://play.google.com/store/apps/details?id=com.tactonuvem.webgula">
								<img src={googlePlayIcon} style={{ width: 151, height: 56, marginLeft: 8 }} />
							</a>
						</div>
					</FooterContainer>
				</Container>
			</div>
			<div
				style={{
					backgroundImage: `url(${bg})`,
					paddingTop: 32,
					paddingBottom: 32
				}}>
				<Container>
					<Row>
						<Col sm={4}>
							<div style={{ textAlign: 'center' }}>
								<img src={logoVertical} style={{ height: 128, marginTop: 32 }} />
							</div>
						</Col>
						<Col sm={4}>
							<div
								style={{
									flexDirection: 'column',
									display: 'flex',
									justifyContent: 'space-evenly',
									alignItems: 'center',
									paddingBottom: 8
								}}>
								<img src={querVenderMais} style={{ marginBottom: 8, height: 132, width: 275 }} />
								<button
									onClick={() => history.push('/webgula/seja-nosso-parceiro')}
									style={{
										background: 'white',
										color: '#CC271D',
										borderRadius: 4,
										border: 0,
										paddingLeft: 32,
										paddingRight: 32,
										height: 48,
										justifyContent: 'space-evenly',
									}}>SEJA NOSSO PARCEIRO</button>

							</div>
						</Col>
						<Col sm={4}>
							<div style={{ textAlign: 'center' }}>
								<img src={appDeliveryImage} className="img-responsive" style={{ height: 204 }} />
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div>
				<Container>
					<Row style={{ paddingTop: 32 }}>
						<Col sm={6}>
							<div>

								<CenterMobile> <img src={logo} /> </CenterMobile>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										marginTop: 20
									}}>
									<span
										style={{
											background: 'white',
											display: 'flex',
											height: 40,
											width: 40,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 20,
											marginRight: 8
										}}>
										<i
											className="mdi mdi-phone"
											style={{
												padding: 8,
												color: 'red',
												fontSize: 24
											}} />
									</span>
									(65) 3028-7728 e (65) 9 8106-1839
						</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										marginTop: 20
									}}>
									<span
										style={{
											background: 'white',
											display: 'flex',
											height: 40,
											width: 40,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 20,
											marginRight: 8
										}}>
										<i className="mdi mdi-email" style={{
											padding: 8,
											color: 'red',
											fontSize: 24
										}} />
									</span>
									comercial@webgula.com.br
						</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center'
									}}>
									<span
										style={{
											background: 'white',
											display: 'flex',
											height: 40,
											width: 40,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 20,
											marginRight: 8
										}}>
										<i className="mdi mdi-map-marker" style={{
											padding: 8,
											color: 'red',
											fontSize: 24
										}} />
									</span>
									<p style={{ paddingTop: 16 }}>
										{'Rua Voluntários da Pátria, 350, Centro-Norte, Cuiabá - MT'}
									</p>
								</div>
							</div>
						</Col>
						<Col sm={6}>
							<CenterMobile>
								<div
									style={{
										display: 'flex',
										flex: 1,
										flexDirection: 'column',
										alignItems: 'flex-start'
									}}>
									<p style={{ fontWeight: 600 }}> Baixe o app WebGula </p>
									<a target="_blank" href="https://apps.apple.com/us/app/webgula/id1462601021?l=pt&ls=1">
										<img style={{ marginTop: 16, width: 151, height: 56 }} src={appStoreIconBlack} />
									</a>
									<a target="_blank" href="https://play.google.com/store/apps/details?id=com.tactonuvem.webgula">
										<img style={{ marginTop: 16, width: 151, height: 56 }} src={googlePlayIconBlack} />
									</a>
								</div>
							</CenterMobile>
						</Col>
					</Row>
				</Container>
			</div>
			<Container>
				<FooterWrapper>
					Desenvolvido pela Tacto Sistemas 2024©. Todos os direitos reservados.
				</FooterWrapper>
			</Container>
		</FooterRoot>
	);
}

export default Footer;
