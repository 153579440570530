import React from "react";
import { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import { useEffect } from "react";
import Axios from "axios";
import { BASE_URL_API } from "infra/config";
import AuthClient from "infra/auth/AuthClient";
import { navigate } from "infra/router/history";

function ModalAcompanhamento({ onClose, chave, open }) {
  const [saida, setSaida] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (open) {
        setIsLoading(true);

        try {
          const auth = AuthClient.getData();

          const { data } = await Axios.get(
            BASE_URL_API +
              "/v1/acompanhamento-conta/solicitar-saida?chave=" +
              chave +
              "&nomeUsuario=" +
              auth.nome
          );

          setSaida(data);

          if (data.saidaId) {
            if (data.retornoId !== -3 && data.retornoId !== -1) {
              return navigate(`menu/conta/${data.saidaId}`, true);
            }
          }
        } catch (e) {
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [chave, open]);

  return (
    <Modal style={{ zIndex: 999999 }} isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Minha Conta</ModalHeader>
      <ModalBody>
        {isLoading && (
          <Spinner style={{ margin: "32px auto", display: "table" }} />
        )}

        <div>
          {" "}
          <h4 style={{ textAlign: "center" }}>
            {" "}
            Código da conta: {chave}{" "}
          </h4>{" "}
        </div>

        {saida.retornoId !== -3 &&
        saida.retornoId !== -1 &&
        !isLoading ? null : !isLoading ? (
          <div style={{ color: "red", textAlign: "center" }}>
            {saida.mensagem || "Conta não encontrada, tente novamente"}
          </div>
        ) : !isLoading && saida.retornoId === -1 ? (
          <div style={{ color: "red", textAlign: "center" }}>
            {saida.mensagem || "Quantidade de acompanhamento excedida"}
          </div>
        ) : null}
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAcompanhamento;
