import React, { useContext } from "react";
import { LayoutContext } from "contexts/layout";
import StoreIcon from "@material-ui/icons/Store";

export default function EnderecoDetalhes({ endereco, retirarNoLocal, isPedidoOff }) {
  const { layoutData } = useContext(LayoutContext);
  const isPontoEntrega = !!endereco.flgPontoEntrega;
  return (
    <div>
      {isPontoEntrega ? (
        <>
          <h5>Retirar no ponto de entrega </h5>
        </>
      ) : null}
      {retirarNoLocal && !isPontoEntrega ? <h5>Retirar em</h5> : null}
      {!retirarNoLocal && !isPontoEntrega ? <h5>Entregar em</h5> : null}

      <div style={{ display: "flex" }}>
        <div>
          <div style={{ display: "flex", marginTop: 8 }}>
            <div style={{ paddingRight: 16 }}>
              {retirarNoLocal ? (
                <StoreIcon style={{ color: layoutData.primaryColor }} />
              ) : null}
              {!retirarNoLocal ? (
                <i
                  className="fa fa-map-marker fa-2x"
                  style={{ color: layoutData.primaryColor }}
                />
              ) : null}
            </div>
            <div style={{ flex: 1 }}>
         
                <>
                { 
                  isPontoEntrega && (
                    <p style={{ fontWeight: "bold", fontSize: 13, margin: 0 }}>
                      {endereco.pontoEntregaNome}
                    </p>
                  )
                }
                  <p style={{ fontWeight: "bold", fontSize: 13, margin: 0 }}>
                    {endereco.endLogradouro}, {endereco.endNumero}
                  </p>
                  <p style={{ color: "#888", fontSize: 12 }}>
                    {isPedidoOff ? endereco.endBairroNome : endereco.bairro} , {isPedidoOff ? endereco.cidadeNome : endereco.endCidadeNome} -{" "}
                    {endereco.endEstadoNome}
                  </p>
                </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
