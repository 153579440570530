import { useEffect, useState } from "react";
import { httpCache } from "infra/axios";

export default function useGetDetalhesReservaCriada(id) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { data: detalhes } = await httpCache.get(
          `v3/reserva-mesa/minhas-reservas/${id}/detalhe`
        );

        setData(detalhes);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  return {
    data,
    isLoading,
  };
}
