const {
  REACT_APP_BASE_URL_API,
  REACT_APP_ACCOUNTS_URL,
  REACT_APP_BASE_URL_TACTO,
  REACT_APP_GA_KEY,
  REACT_APP_PLACE_KEY,
  REACT_APP_FACEBOOK_ID,
} = window.env || process.env;

export const BASE_URL_API =
  REACT_APP_BASE_URL_API || "https://webgula2.tactonuvem.com.br";
export const FACEBOOK_ID = REACT_APP_FACEBOOK_ID || "247146066644031";
export const ACCOUNTS_URL =
  REACT_APP_ACCOUNTS_URL || "https://accounts.tactonuvem.com.br";
export const BASE_URL_TACTO =
  REACT_APP_BASE_URL_TACTO || "https://api.tactonuvem.com.br";
export const MAPS_KEY =
  REACT_APP_GA_KEY || "AIzaSyCQe3MXSxhHANjKCoXIJxv8NeglD6ubuRI";
export const PLACE_KEY =
  REACT_APP_PLACE_KEY || "AIzaSyCGz3SFsrV1g2DtsESw-idV9oi3DvbzaXY";
export const GA_KEY = "UA-77760610-2";
