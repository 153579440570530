/* eslint-disable no-useless-escape */
import { useState, useCallback, useEffect } from "react";
import {
  checkPermissionLocation,
  clearCep,
  getAddressByPostalCodeGoogleMapsApi,
  getCompleteAddressToLocation,
} from "services/location";
import { procurarCep, saveEnderecosPessoa, findBairros } from "services/pessoa";
import { showError, showSuccess } from "infra/alerta";

function useCadastroEndereco({ saveLocal, onSaved, item }) {
  const [bairros, setBairros] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCep, setIsLoadingCep] = useState(false);
  const [cepExistente, setCepExistente] = useState(false);
  const [isLoadingLocalizacao, setIsLoadingLocalizacao] = useState(false);

  const [form, setForm] = useState({
    nome: "Minha casa",
  });
  const [isLocationGrantedOrPrompt, setIsLocationGrantedOrPrompt] = useState(
    true
  );

  useEffect(() => {
    async function init() {
      const result = await checkPermissionLocation();

      setIsLocationGrantedOrPrompt(result !== "denied");
    }

    init();
  }, []);

  useEffect(() => {
    async function init() {
      setForm({});
      if (item?.id) {
        setForm(item);
      }
    }

    init();
  }, [item]);

  const handleSalvar = useCallback(async () => {
    if (!form.nome) {
      form.nome = "Minha casa";
    }

    if (!form.bairroId) {
      showError(
        "Informe o bairro! Você precisa buscar o endereço pelo CEP ou fazer uma pesquisa!"
      );
      return;
    }

    if (!form.logradouro) {
      showError("Informe a rua!");
      return;
    }

    if (!form.numero) {
      showError("Informe o numero!");
      return;
    }

    form.cepId = form.cepId || null;

    if (saveLocal) {
      return onSaved(form);
    }

    setIsLoading(true);

    try {
      await saveEnderecosPessoa(form);

      showSuccess().then(onSaved);
    } catch (error) {
      let errorText = "Houve um problema ao salvar o endereço.";

      if (typeof error === "object") {
        if (error.response.data?.statusCode !== 500) {
          for (const prop in error.response.data) {
            errorText = error.response.data[prop][0];
          }
        }
      }

      showError(errorText);
    } finally {
      setIsLoading(false);
    }
  }, [form, onSaved, saveLocal]);

  const handleChange = useCallback(
    (prop, value) => {
      setForm({
        ...form,
        [prop]: value,
      });
    },
    [form]
  );

  const handleLocation = useCallback(async () => {
    try {
      setIsLoadingLocalizacao(true);

      const endereco = await getCompleteAddressToLocation();

      await getBairros(endereco?.cidadeId);

      setForm({
        ...endereco,
        habilitaCadastro: true,
        nome: form.nome,
        id: form.id,
      });
    } catch (e) {
    } finally {
      setIsLoadingLocalizacao(false);
    }
  }, [form.id, form.nome]);

  const handleSearchCep = useCallback(
    async (cep) => {
      const cepLimpo = clearCep(cep);

      if (cepLimpo.toString().length !== 8) {
        return showError("CEP em formato inválido!");
      }

      setIsLoadingCep(true);
      setCepExistente(false);
      setBairros([]);

      try {
        const dados = await procurarCep(cepLimpo);

        if (!!dados) {
          form.complemento = dados.complemento;
          form.cepId = dados.id;
          form.cidadeId = dados.cidadeId;
          form.nomeBairro = dados.nomeBairro;
          form.nomeEstado = dados.nomeEstado;
          form.nomeCidade = dados.nomeCidade;
          form.logradouro = dados.logradouro;
          form.bairroId = dados.bairroId;
          form.habilitaCadastro = dados.habilitaCadastro;

          await getBairros(dados.cidadeId);

          const enderecoGoogle = await getAddressByPostalCodeGoogleMapsApi(
            form
          );

          if (enderecoGoogle) {
            form.latitude = enderecoGoogle.latitude;
            form.longitude = enderecoGoogle.longitude;
          }

          form.pais = "Brasil";
          setForm({
            ...form,
          });

          if (dados.logradouro) {
            setCepExistente(true);
          }
        } else {
          form.cepId = "";
          form.nomeBairro = "";
          form.nomeEstado = "";
          form.nomeCidade = "";
          form.logradouro = "";
          form.estadoId = "";
          form.bairroId = "";
          form.cidadeId = "";
          form.numero = "";
          form.habilitaCadastro = null;

          setForm({
            ...form,
          });

          showError("Este cep não existe na base dos correios!");
        }
      } catch (e) {
        console.log("isso quebrou busca de cep", e);
      } finally {
        setIsLoadingCep(false);
      }
    },
    [setForm, form]
  );

  async function getBairros(cidadeId) {
    if (!cidadeId) {
      return;
    }

    try {
      let resultBairros = await findBairros(cidadeId);
      resultBairros = resultBairros.filter((x) => x.nome);
      setBairros(resultBairros);
    } catch (error) {}
  }

  const handleGetBairro = useCallback(async (cidadeId) => {
    setIsLoading(true);

    try {
      await getBairros(cidadeId);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    handleSearchCep,
    handleLocation,
    handleSalvar,
    handleChange,
    handleGetBairro,
    isLocationGrantedOrPrompt,
    bairros,
    isLoading,
    isLoadingCep,
    isLoadingLocalizacao,
    cepExistente,
    form,
    setForm,
  };
}

export default useCadastroEndereco;
