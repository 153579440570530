import React, { useEffect, useState } from "react";

import { Step, Stepper, StepLabel } from "@material-ui/core";
import { Container } from "reactstrap";

import Appbar from "../../../components/Appbar";
import ScrollTop from "../../../components/ScrollTop";
import Footer from "../../../components/Footer";

import { Body } from "./styles";
import Resumo from "../CarrinhoPage/components/Resumo";

import useReserva from "actions/reserva/useReserva";
import { Ambiente, Produto } from "../types";
import { SituacaoReserva } from "infra/types/reserva";

const steps = ["APROVAÇÃO", "PAGAMENTO", "CONFIRMADA"];

function StatusPage({ match }: any) {
  const [activeStep, setActiveStep] = useState(0);

  const { data } = useReserva(match.params.eventoId);

  useEffect(() => {
    if (data?.situacaoNome === SituacaoReserva.Aberta) {
      setActiveStep(0);
    }
  }, [data]);

  return (
    <Body>
      <Appbar />

      <Container>
        <Stepper
          style={{ backgroundColor: "transparent" }}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div style={{ marginBottom: 32 }}>
          <Resumo
            id={match.params.eventoId}
            readonly
            quantidadePessoas={data?.qtdePessoasPrevista as number}
            ambiente={
              {
                nome: data?.ambienteNome,
                id: data?.ambienteId,
                preco: data?.valorAmbiente,
                // TODO, esta faltando imagem do ambiente
              } as Ambiente
            }
            data={data?.dataEvento as string}
            produto={
              {
                nome: data?.cardapioNome,
                id: data?.cardapioId,
                imagemUrl: data?.cardapioImagemUrl,
                valorBasePessoa: data?.valorCardapio,
              } as Produto
            }
          />

          {activeStep === 1 ? (
            <div style={{ marginTop: 32, paddingLeft: 15, paddingRight: 15 }}>
              <h4>Forma de pagamento</h4>
            </div>
          ) : null}
        </div>
      </Container>

      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default StatusPage;
