import React, { useState, useCallback } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import InputMask from "react-input-mask";
import AuthClient from "infra/auth/AuthClient";

function ModalTelefone({ open, onSaved, onClose }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const auth = AuthClient.getData();
  const [form, setForm] = useState({
    telefone: auth?.telefone?.substring(2) || "",
  });

  const handleSalvar = useCallback(async () => {
    const body = { ...form };

    body.telefone = (body.telefone || "").replace(/[\D]/g, "");

    if (!body.telefone) {
      setError("Informe o telefone!");
      return;
    }

    setError("");
    setIsLoading(true);

    try {
      if (body.telefone.length === 11) {
        body.telefone = "55" + body.telefone;
      }

      await AuthClient.trocarTelefone(body.telefone);

      // await AuthClient.reenviarSMS(body.telefone);

      onSaved(body);
    } catch (e) {
      let errorText = "";

      if (typeof e === "object" && e.response) {
        for (const prop in e.response.data) {
          errorText = e.response.data[prop][0];
        }
      }

      setError(errorText ?? "Houve um problema ao cadastrar o usuário!");
    } finally {
      setIsLoading(false);
    }
  }, [form, onSaved]);

  const handleChange = useCallback(
    (prop, value) => {
      setForm({
        ...form,
        [prop]: value,
      });
    },
    [form]
  );
  // useEffect (() => {
  //   if (auth) {
  //     setForm({
  //       telefone: auth.telefone.substring(2),
  //     });
  //   }
  // }, [auth]);

  return (
    <Modal isOpen={open} >
      <ModalHeader>Informe o telefone</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="exampleTelefone">Telefone</Label>
            <InputMask
              required
              mask="(99) 9 9999-9999"
              value={form.telefone}
              onChange={(ev) => handleChange("telefone", ev.target.value)}
            >
              {(inputProps) => (
                <Input {...inputProps} name="telefone" 
                placeholder="Telefone" />
              )}
            </InputMask>
          </FormGroup>
        </Form>

        {error && (
          <Alert color="danger">
            {error || "Houve um problema ao cadastrar o usuário!"}
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={ 
          onClose
        }>
          Fechar
        </Button>
        <Button
          disabled={isLoading}
          color="primary"
          block
          onClick={() => handleSalvar()}
        >
          {isLoading ? "Salvando..." : "Salvar"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalTelefone;
