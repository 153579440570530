export interface Reserva {
  id: number;
  data: string;
  horario: string;
  preco: number;
  status: StatusReserva;
}

export interface Ambiente {
  id: number;
  nome: string;
  descricao: string;
  preco: number;
  imagemUrl: any;
  qtdePessoas: number;
}

export interface Produto {
  id: number;
  nome: string;
  descricao: string;
  valorBasePessoa: number;
  imagemUrl: any;
  qtdeMinPessoas: number;
  itens?: ProdutoItem[];
}

export interface ProdutoItem {
  id: number;
  preco: number;
  nome: string;
}

export enum StatusReserva {
  AguardandoAprovacao = "Aguardando aprovação",
  Finalizada = "Finalizada",
  Cancelada = "Cancelada",
}
