import React from "react";
import { createContext, useEffect, useState } from "react";
import { CART_EVENTO_KEY } from "../../../../../services/cart-evento";
import useLocalStorage from "../../../../../infra/hooks/useLocalStorage";
import { Ambiente, Produto } from "./types";

type Value = {
  ambiente: Ambiente | null;
  dataEvento: any;
  tipoEventoId: any;
  produto: Produto | null;
  observacao: any;
  quantidadePessoas: number;
  adicionais: any[];
  produtoItens: any[];
};

const defaultValue: Value = {
  ambiente: null,
  tipoEventoId: null,
  dataEvento: null,
  produto: null,
  observacao: null,
  quantidadePessoas: 0,
  adicionais: [],
  produtoItens: [],
};

export const EventoContext = createContext({
  value: defaultValue,
  update: (_prop: any, _value: any) => {},
  reset: () => {},
});

function EventoProvider({ children }: any): any {
  const [state, setState] = useState(defaultValue);
  const [storage, setStorage] = useLocalStorage(CART_EVENTO_KEY, defaultValue);

  useEffect(() => {
    setState(storage);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setStorage(state);
  }, [state, setStorage]);

  return (
    <EventoContext.Provider
      value={{
        value: { ...state },
        update: (prop: any, value: any) => {
          setState((prev) => ({ ...prev, [prop]: value }));
        },
        reset: () => {
          setState(defaultValue);
        },
      }}
    >
      {children}
    </EventoContext.Provider>
  );
}

export default EventoProvider;
