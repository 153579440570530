import React, { useState } from "react";
import { Badge } from "reactstrap";
import moment from "moment";
import styled from "styled-components";
import ModalDetalhes from "./ModalDetalhes";

const Wrapper = styled.div`
  overflow: hidden;
  margin-bottom: 16px;
  padding: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const BoxWhite = styled.div`
  width: 80px;
  height: 80px;
  background: #f7f7f7;
  padding: 8px;
  float: left;
  margin-right: 16px;
  text-align: center;
`;

const BoxDescription = styled.div`
  float: left;
`;

function ReservaItem({ item }) {
    const [openModal, setOpenModal] = useState(false);
    const [idReserva, setIdReserva] = useState(null);

    const handleClickDetalhes = (id) => {
        setIdReserva(id);
        setOpenModal(true);
    }

    return (
        <Wrapper
            onClick={() => {
                handleClickDetalhes(item.id)
            }}
        >
            <BoxWhite>
                <div style={{ fontSize: 18, fontWeight: "bold" }}>
                    {" "}
                    {moment(item.dataMovimento).format("DD")}{" "}
                </div>
                <div style={{ fontSize: 14 }}>
                    {moment(item.dataMovimento).format("MM/YYYY")}
                </div>
            </BoxWhite>
            <BoxDescription>
                <div>
                    <Badge
                        fill
                        color={
                            item.flgStatusPagamento === "A"
                                ? "danger" :
                                "success"
                        }
                    >
                        {item.flgStatusPagamento === "A" ? "Pendente" : "Pago"}
                    </Badge>
                </div>
                <div>
                    <strong>Reserva nº {item.id} </strong>

                </div>
                <div>
                    <strong>Horário : {item.horaPrevista.slice(0, -3)}</strong>
                </div>
                <strong style={{ fontSize: 18 }}>
              Qtde Pessoas: {item.qtdePessoas}
                </strong>
            </BoxDescription>
            {
                idReserva && openModal && (
                    <ModalDetalhes id={idReserva} open={openModal} onClose={
                        () => {
                            setOpenModal(false)
                        }
                    } />
                )
            }
        </Wrapper>
    );
}

export default ReservaItem;
