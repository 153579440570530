import React from "react";

const americanExpress = "/images/bandeiras/american-express.png";
const visa = "/images/bandeiras/visa.png";
const creditCard = "/images/bandeiras /credit-card.png";
const mastercard = "/images/bandeiras/mastercard.png";

export const getBandeira = (bandeira) => {
  const bandeiras = [
    {
      id: 1,
      name: "Visa",
    },
    {
      id: 2,
      name: "Master",
    },
    {
      id: 3,
      name: "American",
    },
    {
      id: 4,
      name: "Sorocred",
    },
    {
      id: 99,
      name: "Outros",
    },
    {
      id: 6,
      name: "Amex",
    },
    {
      id: 7,
      name: "Elo",
    },
    {
      id: 8,
      name: "Aura",
    },
    {
      id: 9,
      name: "JCB",
    },
    {
      id: 10,
      name: "Diners",
    },
    {
      id: 11,
      name: "Discover",
    },
    {
      id: 12,
      name: "Hipercard",
    },
    {
      id: 13,
      name: "Sodexo",
    },
  ];

  return bandeiras.find((x) => x.name === bandeira);
};

function CreditCardImage({ bandeira }) {
  const bandeiras = [
    {
      id: 1,
      name: "Visa",
      image: visa,
    },
    {
      id: 2,

      image: mastercard,
      name: "Master",
    },
    {
      id: 3,

      image: creditCard,
      name: "American",
    },
    {
      id: 4,

      image: creditCard,
      name: "Sorocred",
    },
    {
      id: 99,
      image: creditCard,

      name: "Outros",
    },
    {
      id: 6,

      image: americanExpress,
      name: "Amex",
    },
    {
      id: 7,

      image: creditCard,
      name: "Elo",
    },
    {
      id: 8,

      image: creditCard,
      name: "Aura",
    },
    {
      id: 9,

      image: creditCard,
      name: "JCB",
    },
    {
      id: 10,
      image: creditCard,

      name: "Diners",
    },
    {
      id: 11,
      image: creditCard,

      name: "Discover",
    },
    {
      id: 12,
      image: creditCard,

      name: "Hipercard",
    },
    {
      id: 13,
      image: creditCard,

      name: "Sodexo",
    },
  ];

  const item = bandeiras.find((x) => x.name === bandeira)?.image;

  return (
    <img
      src={item ?? creditCard}
      alt="cartao"
      style={{ width: 30, height: 20 }}
    />
  );
}

export default CreditCardImage;
