import styled from "styled-components";
import { Button } from "reactstrap";


export const UserAddressDetails = styled.div`
  text-align: right;


  @media screen and (max-width: 768px) {
    & {
      text-align: left;
      margin-top: 6px;
    }
  }
`;


export const ButtonStyled = styled(Button)`
  & {
    border: none;
    color: #fff;
    /* text-transform: uppercase; */
    background: rgba(255, 255, 255, 0.1);
    float: ${props => props.float || "right"};
    margin-left: 10px;

    &:hover {
      text-decoration: none;
      color: #fff;
      background: rgba(255, 255, 255, 0.4);
    }
 
  }

  @media screen and (max-width: 768px) {
    & {
      margin-top: 0px;
      background: none;
      padding: 0;
    }
  }
`;

export const BodyStyled = styled.div`
  width: 100%;
  padding-top: 32px;

  h1 {
    font-size: 32px;
    display: inline;
  }

  i.fa-star {
    color: ${props => props.color};
    margin-right: 10px;
  }
  
  .lista-caracteristicas {
    padding: 0;
    list-style: circle;
    margin: 8px 0;
    margin-bottom: 32px;
    li { 
      display:inline;
      font-weight: bold;
      color: #555;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 768px) {
  
    .badge {
      position: absolute;
      right: 4px;
      top: 22px;
    }

    p {
      margin: 0;
    }

    .user-menu {
      margin-top: 0;
      top: -132px;
      right: 16px;
      position: absolute;

      .nav-link {
        border-radius: 25px;
        span {
          display: none;
        }
      }
    }

    .mensagem-padrao {
      display: none;
    }
  }
`;
