/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback } from "react";
import styled from "styled-components";
import { Row, Col } from "reactstrap";
import { CardContent, Card } from "@material-ui/core";

import ModalAcompanhamento from "./ModalAcompanhamento";
import moment from "moment";
import { formatMoney } from "infra/utils";


export const Content = styled.div`
  width: 100%;
  padding: 16px 8px;
`;

function ListaContas({
  items,
  openAcompanhamento,
  setOpenAcompanhamento,
  onRefresh,
  chave,
  setChave,
}) {
  const detalharConta = useCallback(
    (conta) => {
      setChave(conta?.chaveMobile);
      setOpenAcompanhamento(true);
    },
    [setChave, setOpenAcompanhamento]
  );

  return (
    <div style={{ width: "100%" }}>
      <ModalAcompanhamento
        chave={chave}
        open={openAcompanhamento}
        onClose={() => {
          setOpenAcompanhamento(false);
          setChave(null);
          onRefresh();
        }}
      />

      <h3 style={{ paddingLeft: 16, paddingTop: 16 }}>
        Acompanhamento de contas
      </h3>

      <Content>
        <Row>
          {items.map((conta) => {
            return (
              <Col
                sm={4}
                style={{
                  marginBottom: 16,
                }}
              >
                <Card
                  onClick={() => detalharConta(conta)}
                  style={{
                    margin: 4,
                    borderRadius: 8,
                    cursor: "pointer",
                  }}
                  key={conta?.saidaId}
                >
                  <CardContent>
                    <Row>
                      <Col>
                        <div style={{ padding: 4 }}>
                          <b>Chave: </b>
                          {conta?.chaveMobile.toString()}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div style={{ padding: 4 }}>
                          <b>Data: </b>
                          {moment(conta?.dataSaida).format("DD/MM/YY HH:mm")}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div style={{ padding: 4 }}>
                          <b>Valor total: </b>
                          R$ {formatMoney(conta?.valorTotal)}
                        </div>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Content>
    </div>
  );
}

export default ListaContas;
