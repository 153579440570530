import React, { useContext } from "react";
import styled from "styled-components";

import { LayoutContext } from "contexts/layout";
import { MAPS_KEY } from "infra/config";

const Container = styled.div`
  width: 100%;
  clear: both;
  position: relative;
`;

//layoutData
function Contato() {
  const { layoutData } = useContext(LayoutContext);

  return (
    <Container id="contato">
      <iframe
        title="Mapa"
        src={`https://www.google.com/maps?q=${layoutData.endLatitude},${layoutData.endLongitude}&output=embed&key=${MAPS_KEY}`}
        width={"100%"}
        height={"680"}
        style={{ border: 0 }}
        allowfullscreen
      />
    </Container>
  );
}

export default Contato;
