import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import InputMask from "react-input-mask";
import AuthClient from "infra/auth/AuthClient";

function ModalTrocarTelefone({ open, onSaved, onCancel }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [form, setForm] = useState({});

  useEffect(() => {
    setForm({});
    setError("");
  }, [open]);

  const handleConfirmarTrocarTelefone = useCallback(async () => {
    const body = { ...form };
    body.telefone = (body.telefone || "").replace(/[\D]/g, "");

    if (!body.telefone) {
      setError("Informe o novo telefone corretamente!");
      return;
    }

    setIsLoading(true);

    try {
      let telefone = String(body.telefone);

      if (telefone.length === 11) {
        telefone = "55" + telefone;
      }

      const result = await AuthClient.trocarTelefone(telefone);

      onSaved({
        ...result,
        telefone: telefone,
      });
    } catch (e) {
      let errorText = "";

      if (typeof e === "object" && e.response) {
        errorText = e.response.message;
      }

      setError(errorText);
    } finally {
      setIsLoading(false);
    }
  }, [form, onSaved]);

  const handleChange = useCallback(
    (prop, value) => {
      setForm({
        ...form,
        [prop]: value,
      });
    },
    [form]
  );

  return (
    <div>
      <Modal isOpen={open}>
        <ModalHeader>Trocar Telefone</ModalHeader>

        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Telefone</Label>

              <InputMask
                required
                mask="(99) 9 9999-9999"
                value={form.telefone}
                onChange={(ev) => handleChange("telefone", ev.target.value)}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    name="telefone"
                    placeholder="Telefone"
                  />
                )}
              </InputMask>
            </FormGroup>
          </Form>

          {error && (
            <Alert color="danger">
              {error ||
                "Houve um problema ao trocar o telefone, tente mais tarde"}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => onCancel()}>Voltar</Button>
          <Button
            disabled={isLoading}
            color="primary"
            onClick={() => handleConfirmarTrocarTelefone()}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalTrocarTelefone;
