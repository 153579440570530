/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from "reactstrap";
import { avaliar } from "services/pedido";
import { useContext } from "react";
import { LayoutContext } from "contexts/layout";

function ModalAvaliacao({ open, pedido, onSaved, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenToast, setIsOpenToast] = useState(false);
  const { layoutData } = useContext(LayoutContext);
  const [error, setError] = useState();
  const [form, setForm] = useState({});

  const handleAvaliar = useCallback(async () => {
    if (!form.avaliacao) return alert("Informe a avaliação!");

    setIsLoading(true);

    try {

      await avaliar({
        avaliacao: form.avaliacao,
        pedidoId: pedido?.id,
        mensagem: form.comentario,
        empresaId: pedido?.empresaId ?? layoutData.id,
      });

      setIsLoading(false);

      onSaved();

    } catch (e) {
      setIsOpenToast(true);
      let errorText = e;

      if (typeof e === "object" && e.response) {
        errorText = e.response.message;
      }

      setError(errorText);
    } finally {
      setIsLoading(false);
    }
  }, [form.avaliacao, form.comentario, pedido, layoutData.id, onSaved]);


  const handleChange = useCallback(
    (prop, value) => {
      setForm({
        ...form,
        [prop]: value
      });
    },
    [form]
  );

  return (
    <div>
      <Modal isOpen={open} toggle={onClose}>
        <ModalHeader toggle={onClose}>Sua avaliação</ModalHeader>

        <ModalBody>
          {isOpenToast ? (
            <Alert toggle={() => setIsOpenToast(false)} color="danger">
              {error}
            </Alert>
          ) : null}

          <Form>
            <FormGroup>

              <Label>Como foi sua experiência?</Label>

              <div>
                <i
                  className={`fa fa-2x fa-star${form.avaliacao >= 1 ? '' : '-o'}`}
                  onClick={() => handleChange('avaliacao', 1)}
                  style={{ marginRight: 12 }}
                />
                <i
                  className={`fa fa-2x fa-star${form.avaliacao >= 2 ? '' : '-o'}`}
                  onClick={() => handleChange('avaliacao', 2)}
                  style={{ marginRight: 12 }}
                />
                <i
                  className={`fa fa-2x fa-star${form.avaliacao >= 3 ? '' : '-o'}`}
                  onClick={() => handleChange('avaliacao', 3)}
                  style={{ marginRight: 12 }}
                />
                <i
                  className={`fa fa-2x fa-star${form.avaliacao >= 4 ? '' : '-o'}`}
                  onClick={() => handleChange('avaliacao', 4)}
                  style={{ marginRight: 12 }}
                />
                <i
                  onClick={() => handleChange('avaliacao', 5)}
                  className={`fa fa-2x fa-star${form.avaliacao >= 5 ? '' : '-o'}`}
                />
              </div>
            </FormGroup>

            <FormGroup>
              <Label>Deixe seu comentário</Label>
              <Input
                type="textarea"
                value={form.comentario}
                rows={4}
                onChange={ev => handleChange("comentario", ev.target.value)}
                placeholder="Escreva aqui seu comentário"
              />
            </FormGroup>

          </Form>

        </ModalBody>
        <ModalFooter>
          <Button
            disabled={isLoading}
            color="primary"
            block
            onClick={() => handleAvaliar()}>
            Enviar Avaliação
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
}

export default ModalAvaliacao;
