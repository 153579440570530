import React from "react";
import styled from "styled-components";

const H5 = styled.h5`
  font-family: Cabin;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const H4 = styled.h4`
  font-family: Cabin;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Text / 01 */

  color: #232735;
`;

const Caption = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;

const Body2 = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`;

export default function Text({ variant, ...props }) {
  if (variant === "fancy-title") {
    return <FancyTitle {...props} />;
  }

  if (variant === "h5") {
    return <H5 {...props} />;
  }

  if (variant === "h4") {
    return <H4 {...props} />;
  }

  if (variant === "body2") {
    return <Body2 {...props} />;
  }

  if (variant === "caption") {
    return <Caption {...props} />;
  }
}

function FancyTitle({ children, size, style }) {
  return (
    <span
      style={{
        fontSize: size,
        fontFamily: "dirty_headlineregular",
        position: "relative",
        display: "inline-block",
        paddingRight: 8,
        paddingLeft: 8,
        ...style,
      }}
    >
      <div
        style={{
          height: 12,
          background: "red",
          display: "inline-block",
          position: "absolute",
          bottom: 6,
          left: 0,
          right: 0,
          zIndex: 0,
          backgroundColor: "#F6B215",
        }}
      />

      <div
        style={{
          display: "inline-block",
          position: "relative",
          zIndex: 1,
        }}
      >
        {" "}
        {children}
      </div>
    </span>
  );
}
