import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import {
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  List,
  Typography,
} from "@material-ui/core";
import { findHistoricoResgate } from "services/fidelidade";
import { LayoutContext } from "contexts/layout";
import EmptyState from "../EmptyState";
import moment from "moment";
import ModalResgate from "./ModalResgate";

export interface ResgateResult {
  flgStatus: "C" | "P";
  dataHoraLanc: string;
  premioNome: string;
  premioPontoCusto: Number;
  qtdePremio: Number;
  imagemUrlCompleta: string;
}

function TabResgates() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ResgateResult[]>([]);
  const [selectedItem, setSelectedItem] = useState<ResgateResult | null>(null); // State para o item selecionado
  const { layoutData }: any = useContext(LayoutContext);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const data = await findHistoricoResgate(layoutData.fidelidadeId);

        setData(data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [layoutData.fidelidadeId]);

  const handleOpenModal = (item: ResgateResult) => {
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  return (
    <Box style={{ padding: 16, minHeight: 400 }}>
      {isLoading ? (
        <div style={{ textAlign: "center", marginTop: 32 }}>
          <CircularProgress />
        </div>
      ) : null}

      <List>
        {data.map((item: ResgateResult, index: Number) => (
          <div key={String(index)} style={{ marginBottom: 32 }}>
            <ListItemResgate item={item} onOpenModal={handleOpenModal} />
          </div>
        ))}
      </List>

      {!data.length && !isLoading ? <EmptyState /> : null}

      {/* Renderize a modal se um item estiver selecionado */}
      {selectedItem && (
        <ModalResgate
          onClose={handleCloseModal}
          premio={selectedItem}
          visible={true}
        />
      )}
    </Box>
  );
}
function ListItemResgate({
  item,
  onOpenModal,
}: {
  item: ResgateResult;
  onOpenModal: (item: ResgateResult) => void;
}) {
  const data = moment(item.dataHoraLanc).format("DD/MM/YYYY HH:mm");
  return (
    <div>

      <Card onClick={
        () => {
          onOpenModal(item);
        }
      } style={{ cursor: "pointer" }}
      >
        <CardContent>
          <Row>
            <Col lg={9} md={9} sm={7} xs={12}>
              <Typography>
                <span style={{ color: "#aaa", marginRight: 16 }}>{data}</span>{" "}
                {item.flgStatus === "C" ? (
                  <Chip label="Cancelado" variant="outlined" />
                ) : item.flgStatus === "P" ? (
                  <Chip label="Pendente" color="secondary" variant="outlined" />
                ) : (
                  <Chip label="Entregue" />
                )}
              </Typography>
              <div>
                <Typography> {item.premioNome}</Typography>
              </div>

              <div style={{ fontWeight: "bold", marginTop: 32, fontSize: 18 }}>
                {item.premioPontoCusto} pontos x {item.qtdePremio}
              </div>
            </Col>
            <Col lg={3} md={3} sm={5} xs={12}>
              <img
                src={item.imagemUrlCompleta}
                alt={item.premioNome}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </CardContent>
      </Card>
    </div >
  );
}

export default TabResgates;
