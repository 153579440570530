import React, { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { Row, Col, Container as ContainerBootstrap } from "reactstrap";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Container } from "./styles";
import FiltroMobile from "../FiltroMobile";
import { useMediaQuery } from "infra/utils";

let stickyOffsetTop: number = 0;
let stickyOffsetLeft: number = 0;
let stickyOffsetRight: number = 0;

const APP_BAR_HEIGHT = 90;

function NavbarSticky({
  indice,
  grupos,
  guia,
  guias = [],
  value,
  onChange,
  isDelivery,
}: any) {
  const ref = useRef<any>(null);
  const [fixed, setFixed] = useState(false);
  const [hasGuias, setHasGuias] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleScroll = useCallback(() => {
    setFixed(window.pageYOffset > stickyOffsetTop - APP_BAR_HEIGHT);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (ref?.current) {
      if (!stickyOffsetTop || !stickyOffsetLeft || !stickyOffsetRight) {
        const client = ref.current.getBoundingClientRect();

        stickyOffsetTop = client.top || 0;
        stickyOffsetLeft = client.left || 0;
        stickyOffsetRight = client.right - client.width || 0;
      }
    }
  }, [ref]);

  useEffect(() => {
    if (guias.length > 0) {
      setHasGuias(true);
    }
  }, [guias]);

  return (
    <Container
      offsetTop={90}
      offsetLeft={stickyOffsetLeft}
      offsetRight={stickyOffsetRight}
      fixed={fixed}
      ref={ref}
    >
      <ContainerBootstrap>
        {isMobile ? (
          <FiltroMobile
            isDelivery={isDelivery}
            value={value}
            indice={indice}
            onChange={onChange}
            fixed={fixed}
            grupos={grupos}
          />
        ) : (
          <Row>
            {hasGuias ? (
              <Col sm={4}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  size={fixed ? "small" : "medium"}
                  style={{ margin: "0 8" }}
                  SelectProps={{
                    value: value?.guiaId,
                    native: true,
                    onChange: (event) => onChange("guiaId", event.target.value),
                  }}
                >
                  <option value={""}>Cardápio Completo</option>
                  {guias?.map((guia: any) => {
                    return (
                      <option key={guia.id} value={guia?.id}>
                        {guia?.nome}
                      </option>
                    );
                  })}
                </TextField>
              </Col>
            ) : null}

            <Col sm={hasGuias ? 4 : 6}>
              <Autocomplete
                id="combo-box-demo"
                value={value?.categoria}
                onSelect={(selected: any) =>
                  onChange("categoria", selected.target.value)
                }
                options={grupos}
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size={fixed ? "small" : "medium"}
                    fullWidth
                    label="Filtre por categoria"
                    variant="outlined"
                  />
                )}
              />
            </Col>
            <Col sm={hasGuias ? 4 : 6}>
              <TextField
                value={value?.search}
                size={fixed ? "small" : "medium"}
                onChange={(ev) => onChange("search", ev.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                placeholder="Pesquise no cardápio"
                variant="outlined"
              />
            </Col>
          </Row>
        )}
      </ContainerBootstrap>
    </Container>
  );
}

export default NavbarSticky;
