import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { formatMoney } from "infra/utils";

import { Ambiente as AmbienteType } from "../../../types";
import { AmbienteItemContainer } from "./styles";

interface AmbienteItemProps {
  item: AmbienteType;
  onClick: any;
  disabled: boolean;
  active: boolean;
  primaryColor: string;
}

function AmbienteItem({
  item,
  onClick,
  disabled,
  active,
  primaryColor,
}: AmbienteItemProps) {
  return (
    <AmbienteItemContainer
      primaryColor={primaryColor}
      active={active}
      disabled={disabled}
    >
      <Card
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
      >
        <div>
          <img src={item.imagemUrl} alt={item.nome} title={item.nome} />
        </div>

        <CardContent>
          <Typography variant="h5" component="h4">
            {item.nome}
          </Typography>

          <Typography style={{ fontSize: "11px" }}>{item.descricao}</Typography>

          <br />

          <Typography style={{ fontSize: "11px" }}>
            Capacidade máxima
          </Typography>
          <Typography>{item.qtdePessoas}</Typography>

          <br />

          <Typography style={{ fontSize: "11px" }}>Valor</Typography>
          <Typography>R$ {formatMoney(item.preco)}</Typography>
        </CardContent>
      </Card>
    </AmbienteItemContainer>
  );
}

export default AmbienteItem;
