import React, { useState } from "react";
import CurrencyInput from "react-currency-input";

function CurrencyInputComponent({ onChange }) {
  const [formattedValue, setFormattedValue] = useState("0.00");
  const [, setNumericValue] = useState(0);

  const handleInputChange = (event, maskedvalue, floatvalue) => {
    setFormattedValue(maskedvalue);
    setNumericValue(floatvalue);

    // Chame a função onChange com o valor numérico atual
    if (onChange) {
      onChange(floatvalue);
    }
  };

  return (
    <CurrencyInput
      value={formattedValue}
      onChangeEvent={handleInputChange}
      prefix="R$ "
      decimalSeparator=","
      thousandSeparator="."
      precision={2}
      allowNegative={false}
    />
  );
}

export default CurrencyInputComponent;
