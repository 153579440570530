import React, { useState, useCallback } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import InputMask from "react-input-mask";
import AuthClient from "infra/auth/AuthClient";

function ModalCadastro({ open, onToggle, onSaved }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({});

  const handleCadastro = useCallback(async () => {
    const body = { ...form };

    if (!body.nome) {
      setError("Informe o nome!");
      return;
    }

    if (body.nome.split(" ").length <= 1) {
      setError("Informe o nome com o sobrenome!");
      return;
    }

    body.telefone = (body.telefone || "").replace(/[\D]/g, "");

    if (!body.telefone) {
      setError("Informe o telefone!");
      return;
    }

    if (!body.email) {
      setError("Informe o email!");
      return;
    }

    if (!body.senha) {
      setError("Informe a senha!");
      return;
    }

    if (body.senha.length < 6) {
      setError("Informe pelo menos 6 caracteres na senha!");
      return;
    }

    setError("");
    setIsLoading(true);

    try {
      if (body.telefone.length === 11) {
        body.telefone = "55" + body.telefone;
      }

      await AuthClient.registrarUsuario(body);

      await AuthClient.loginUser(body.email, body.senha);

      onSaved(body);
    } catch (e) {
      let errorText = "";

      if (typeof e === "object" && e.response) {
        for (const prop in e.response.data) {
          errorText = e.response.data[prop][0];
        }
      }

      setError(errorText || "Houve um problema ao realizar o cadastro.");
    } finally {
      setIsLoading(false);
    }
  }, [form, onSaved]);

  const handleChange = useCallback(
    (prop, value) => {
      setForm({
        ...form,
        [prop]: value,
      });
    },
    [form]
  );

  return (
    <div>
      <Modal isOpen={open} toggle={onToggle} fullscreen={"lg"}>
        <ModalHeader toggle={onToggle}>Fazer Cadastro</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleNome">Nome</Label>
              <Input
                bsSize="lg"
                type="text"
                value={form.nome}
                required
                onChange={(ev) => handleChange("nome", ev.target.value)}
                placeholder="Nome"
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Email</Label>
              <Input
                bsSize="lg"
                type="email"
                value={form.email}
                required
                onChange={(ev) => handleChange("email", ev.target.value)}
                placeholder="Email"
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleTelefone">Telefone</Label>
              <InputMask
                required
                mask="(99) 9 9999-9999"
                value={form.telefone}
                onChange={(ev) => handleChange("telefone", ev.target.value)}
              >
                {(inputProps) => (
                  <Input
                    bsSize="lg"
                    {...inputProps}
                    name="telefone"
                    placeholder="Telefone"
                  />
                )}
              </InputMask>
            </FormGroup>
            <FormGroup>
              <Label for="exampleSexo">Sexo</Label>
              <Input
                bsSize="lg"
                type="select"
                value={form.sexo}
                onChange={(ev) => handleChange("sexo", ev.target.value)}
              >
                <option value="">Selecione</option>
                <option value="m">Masculino</option>
                <option value="f">Feminino</option>
                <option value="o">Outros</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="exampleSenha">Senha (mínimo 6 caracteres)</Label>
              <Input
                bsSize="lg"
                type="password"
                value={form.senha}
                required
                onChange={(ev) => handleChange("senha", ev.target.value)}
                placeholder="Senha"
              />
            </FormGroup>
          </Form>

          {error && (
            <Alert color="danger">
              {error || "Houve um problema ao cadastrar o usuário!"}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onToggle}>
            Cancelar
          </Button>
          <Button
            disabled={isLoading}
            color="primary"
            onClick={() => handleCadastro()}
          >
            {isLoading ? "Cadastrando..." : "Cadastrar"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalCadastro;
