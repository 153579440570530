import React from "react";
import styled from "styled-components";
import { Container } from "reactstrap";
import { Button } from "@material-ui/core";
import { navigate } from 'infra/router/history'

import bg from "../header.png";

const ContainerTopoMenu = styled.div`
  background: url(${bg}) no-repeat;
  background-size: 100% 180px;
  margin-bottom: 64px;
  padding-top: 260px;
  text-align: center;

  h3 {
    font-style: italic;
    font-size: 2.5em;
  }

  p {
    width: 80%;
    margin: 32px auto;
  }

  @media all and (max-width: 768px) {
    p {
      width: 100%;
    }
  }
`;

function TopoMenu() {
  return (
    <ContainerTopoMenu>
      <Container>
        <h3> Eventos </h3>

        <p>
          Conheça nossos espaços e nossas opções de cardápio para eventos e faça
          a sua reserva
        </p>

        <Button
          onClick={() => navigate("eventos/carrinho")}
          color="primary"
          variant="contained"
        >
          Montar
        </Button>
      </Container>
    </ContainerTopoMenu>
  );
}

export default TopoMenu;
