import React from "react";
import { Button } from "reactstrap";
import { QRCodeSVG } from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";

function PagamentoParcialPix({ qrCodeData, isCopied, onCopy }) {
  return (
    <div>
      <span style={{ textAlign: "center" }}>
        Você pode Copiar a Chave e Colar em seu Aplicativo Bancário com PIX ou
        escanear o QrCode abaixo
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "8px 0",
        }}
      >
        <QRCodeSVG size={200} value={qrCodeData} />
      </div>

      <br />

      <div style={{ textAlign: "center", marginBottom:8 }}>
        <CopyToClipboard text={qrCodeData} onCopy={onCopy}>
          <Button color="primary" variant="contained">
            {isCopied ? "Copiado =)" : "Copiar PIX"}
          </Button>
        </CopyToClipboard>
      </div>
      <span style={{ textAlign: "center" , marginTop:8}}>
        O Processamento completo do PIX pode demorar até 10 minutos após o
        pagamento.
      </span>
    </div>
  );
}

export default PagamentoParcialPix;
