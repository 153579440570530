/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Container,
} from 'reactstrap';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Mobile from './Mobile';

import styled from 'styled-components';
import logo from '../../images/logo.png';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

const LogoContainer = styled.div`
 	width: 200px;
	@media screen and (max-width: 768px) { 
		display: flex;
		flex: 1;
	}
`;

const ImageLogo = styled.img`
 	max-height: 48px;
   height: 48px;
   cursor: pointer;

	@media screen and (max-width: 768px) {
		max-height: 60px;
		margin-top: 16px;
		margin-left: 16px; 
	}
`;

const ContainerMenuDesktop = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const ContainerMenuMobile = styled.div`
	display: none;
	@media screen and (max-width: 768px) { 
		display: block;
		float: right;
		font-size: 24px;
	}
`;

const useStyles = makeStyles(() => ({
  menuButton: {
    textTransform: 'inherit',
    padding: '12px 32px',
    boxShadow: 'none',
    fontWeight: 'bold',
    fontSize: 14
  },
  toolbar: {
    height: 80,
  },
}));

function Appbar() {
  const history = useHistory();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  return (
    <AppBar color="white" position="fixed">

      <Mobile open={openModal} onClose={() => setOpenModal(false)} />

      <Container style={{ padding: 0 }}>
        <Toolbar
          classes={{
            root: classes.toolbar,
          }}>

          <LogoContainer>
            <a onClick={() => history.push('/')}>
              <ImageLogo title={'Webgula'} alt={'Webgula'} src={logo} />
            </a>
          </LogoContainer>

          <ContainerMenuMobile>
            <IconButton onClick={() => setOpenModal(true)} edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
          </ContainerMenuMobile>

          <ContainerMenuDesktop>
            <Button onClick={() => history.push("/")} className={classes.menuButton} color="primary">Encontra seu restaurante</Button>
            <Button onClick={() => history.push("/webgula/como-funciona")} className={classes.menuButton} color="inherit">Como funciona</Button>
            <Button onClick={() => history.push("/webgula/seja-nosso-parceiro")} className={classes.menuButton} variant="contained" color="primary">Seja nosso parceiro</Button>
          </ContainerMenuDesktop>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Appbar;
