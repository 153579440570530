import { useState, useContext } from "react";

import { EventoContext } from "../../ui/layouts/default/pages/Eventos/EventoContext";
import useTotais from "../../ui/layouts/default/pages/Eventos/hooks/useTotais";
import { useLayoutContext } from "../../contexts/layout";
import AuthClient from "../../infra/auth/AuthClient";
import { navigate } from "../../infra/router/history";
import { realizarReserva } from "../../services/evento";

import Swal from "sweetalert2";

export default function useFinalizarReserva() {
  const { value, reset: resetReserva } = useContext(EventoContext);
  const [isLoading, setIsLoading] = useState(false);

  const { total } = useTotais();

  const { update: updateContext } = useLayoutContext();

  const auth = AuthClient.getData();

  const handleReservar = async () => {
    if (!value) {
      return Swal.fire("Você precisa preencher todos os dados corretamente!");
    }

    if (!auth) {
      return Swal.fire("Você precisa se autenticar antes de continuar").then(
        () => {
          updateContext({ openLogin: true });
        }
      );
    }

    setIsLoading(true);

    try {
      const result = await realizarReserva({
        ambienteId: value.ambiente?.id,
        pessoaGlobalId: auth.pessoaId, // TODO, deveria estar no backend isso e nao aqui
        cardapioId: value.produto?.id,
        tipoEventoId: value.tipoEventoId,
        dataEvento: value.dataEvento,
        valorAmbiente: value.ambiente?.preco, // TODO, deveria estar no backend isso e nao aqui
        valorCardapio: value.produto?.valorBasePessoa, // TODO, deveria estar no backend isso e nao aqui
        valorServExtras: 0, // TODO, deveria estar no backend isso e nao aqui
        valorDesconto: 0, // TODO, deveria estar no backend isso e nao aqui
        valorTotal: total, // TODO, deveria estar no backend isso e nao aqui
        nome: value.ambiente?.nome, // TODO, deveria estar no backend isso e nao aqui
        qtdePessoasPrevista: value.quantidadePessoas,
      });

      resetReserva();

      Swal.fire(
        "Aviso",
        "Pedido de reserva realizado com sucesso, aguarde a aprovação do estabelecimento.",
        "success"
      ).then(() => {
        navigate(`eventos/${result.id}/status`);
      });
    } catch (e) {
      Swal.fire("Aviso", "Houve um problema ao fazer a reserva", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleReservar,
    isLoading,
  };
}
