import React from "react";
import { DescriptionOutlined as DescriptionOutlinedIcon } from "@material-ui/icons";

function EmptyState() {
  return (
    <div
      style={{
        height: 300,
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <DescriptionOutlinedIcon style={{ color: "#ccc", fontSize: 64 }} />
        <div style={{ color: "#bbb", marginTop: 16 }}>
          Ainda não há lançamentos realizados
        </div>
      </div>
    </div>
  );
}

export default EmptyState;
