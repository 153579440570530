import React, { useContext, useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import Axios from "axios";
import { BASE_URL_API } from "infra/config";
import AuthClient from "infra/auth/AuthClient";
import { navigate } from "infra/router/history";
import { LayoutContext } from "contexts/layout";
import Appbar from "ui/layouts/default/components/Appbar";
import Footer from "ui/layouts/default/components/Footer";
import ScrollTop from "ui/layouts/default/components/ScrollTop";
import styled from "styled-components";
import TopoMenu from "../MenuPage/components/TopoMenu";
import { Dialog, Box, Typography } from "@material-ui/core";
import WarningAmberIcon from "@material-ui/icons/WarningRounded"

const Body = styled.div`
  padding-top: 90px;

  @media all and (max-width: 768px) {
    & {
      padding-top: 30px;
    }
  }
`;

const ModalContent = styled(Box)`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 32px 16px;
  text-align: center;
`;

const WarningIcon = styled(WarningAmberIcon)`
  height: 100px;
  width: 100px;
  color: #ffa000;
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: bold;
  color: #4e4e4e;
  margin-bottom: 8px;
`;

const Description = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: #7d7d7d;
  margin-bottom: 24px;
`;

const LoginButton = styled.button`
  background-color: #4e4e4e;
  color: white;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 24px;
  &:hover {
    background-color: #383838;
  }
`;

const AccessMenuButton = styled.button`
  background-color: #ef2b2d;
  color: white;
  border-color: #ef2b2d;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 24px;
  &:hover {
    background-color: #d0282a;
  }
`;

const OrText = styled(Typography)`
  font-size: 16px;
  color: #7d7d7d;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ddd;
    margin: 16px;
  }
`;

function RedirectQRCode(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { update: updateContext } = useContext(LayoutContext);
  const [userNotLogged, setUserNotLogged] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  useEffect(() => {
    const checkUserLoggedIn = async () => {
      setIsLoading(true);

      try {
        const auth = AuthClient.getData();

        // Verifique se o usuário está logado
        if (!auth) {
          setUserNotLogged(true);
          setIsLoading(false);
          handleOpenLoginModal();
          return;
        }

        const { data } = await Axios.get(
          `${BASE_URL_API}/v1/acompanhamento-conta/solicitar-saida?chave=${props.match.params.chaveCns}&nomeUsuario=${auth.nome}`
        );

        if (data.saidaId) {
          if (data.retornoId !== -3 && data.retornoId !== -1) {
            return navigate(`menu/conta/${data.saidaId}`, true);
          }
        }
      } catch (error) {
        // Tratar erros
      } finally {
        setIsLoading(false);
      }
    };

    checkUserLoggedIn();
  }, [props.match.params.chaveCns, props.match.params.saidaId]);

  const handleLoginClick = () => {
    handleCloseLoginModal();
    updateContext({ openLogin: true });
  };

  const handleAccessMenuClick = () => {
    handleCloseLoginModal();
    const chaveCns = props.match.params.chaveCns;
    const conta = props.match.params.saidaId;
    localStorage.setItem("chaveCns", chaveCns);
    localStorage.setItem("conta", conta);
    navigate("menu", true);
  };

  return (
    <Body>
      <Appbar />
      <TopoMenu />
      <>
        {isLoading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3>Consultando...</h3>
            <div>
              <Spinner style={{ margin: "32px auto" }} />
            </div>
          </div>
        )}
        {userNotLogged && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 4,
            }}
          >
            <h3>Por favor, faça login para continuar</h3>
          </div>
        )}
      </>

      {/* Modal de Login */}
      <Dialog
        open={openLoginModal}
        onClose={handleCloseLoginModal}
        aria-labelledby="modal-login-title"
        aria-describedby="modal-login-description"
      >
        <ModalContent>
          <WarningIcon fontSize="large" style={{ fontSize: 80 }}  />
          <Title id="modal-login-title">Aviso</Title>
          <Description id="modal-login-description">
            Efetue o Login para um melhor aproveitamento do serviço.
          </Description>
          <LoginButton
            variant="contained"
            onClick={handleLoginClick}
          >
            Fazer Login
          </LoginButton>
          <OrText variant="body2">
            OU
          </OrText>
          <AccessMenuButton
            variant="contained"
            onClick={handleAccessMenuClick}
          >
            Acessar o Cardápio Diretamente
          </AccessMenuButton>
        </ModalContent>
      </Dialog>

      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default RedirectQRCode;
