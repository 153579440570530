import { useContext, useMemo } from "react";
import { EventoContext } from "../EventoContext";

export default function useTotais() {
  const { value } = useContext(EventoContext);

  const total = useMemo(() => {
    
    let subtotal =
      (value.produto?.valorBasePessoa ?? 0) * value.quantidadePessoas;

    subtotal += value.ambiente?.preco ?? 0;

    return subtotal;
  }, [value.produto, value.ambiente, value.quantidadePessoas]);

  return {
    total,
  };
}
