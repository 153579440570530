export interface ReservaDetalhes {
  id: number;
  empresaId: number;
  ambienteId: number;
  pessoaGlobalId: number;
  cardapioId: number;
  tipoEventoId: number;
  dataHoraEnvio: string;
  dataEvento: string;
  valorAmbiente: number;
  valorCardapio: number;
  valorServExtras: number;
  valorDesconto: number;
  valorTotal: number;
  situacaoId: number;
  dataUltimaAlteracao: string;
  nome: string;
  qtdePessoasPrevista: number;
  situacaoNome: SituacaoReserva;
  tipoNome: string;
  ambienteNome: string;
  cardapioNome: string;
  cardapioImagemUrl: string;
  mensagens: any[];
}

export enum SituacaoReserva {
  Aberta = "Aberta",
}
