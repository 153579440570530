import React, { useContext, useState } from "react";
import styled from "styled-components";
import { LayoutContext } from "contexts/layout";

const ScrollTopStyle = styled.div`
  color: #333;
  background: ${(props) => props.color};
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 80px;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
  color: white;
  z-index: 999;
  cursor: pointer;
`;

function ScrollTop() {
  const [showScroll, setShowScroll] = useState(false);
  const { layoutData } = useContext(LayoutContext);

  const height = document.documentElement.clientHeight;

  document.addEventListener(
    "scroll",
    (e) => {
      if (showScroll && document.documentElement.scrollTop <= height) {
        setShowScroll(false);
      }

      if (!showScroll && document.documentElement.scrollTop > height) {
        setShowScroll(true);
      }
    },
    true
  );

  if (!showScroll) {
    return null;
  }

  return (
    <div>
      <ScrollTopStyle
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        color={layoutData.primaryColor}
      >
        <i className="fa fa-arrow-up" />
      </ScrollTopStyle>
    </div>
  );
}

export default ScrollTop;
