import styled from "styled-components";

export const ContainerCardapio = styled.div`
  margin-top: 160px;
  max-width: 80%;
  margin: auto;

  @media all and (max-width: 768px) {
    margin-top: 64px;
    max-width: 100%;
  }

  h3 {
    font-style: italic;
    font-size: 2.5em;
    text-align: center;
  }
`;

export const CardapioImage = styled.div`
  height: 200px;
  overflow: hidden;

  img {
    min-height: 200px;
  }
`;

export const CardapioItemContainer = styled.div<any>`
  margin-bottom: 32px;

  opacity: 1;
  cursor: pointer;

  h4 {
    font-size: 1.2em;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  img {
    width: 100%;
    max-width: 100%;
  }

  .MuiCard-root {
    position: relative;

    &:before {
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      background-color: ${(props) => props.primaryColor}19;
      display: none;
    }
  }
`;
