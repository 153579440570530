import React, { useContext } from "react";
import styled from "styled-components";
import { CardImg, CardTitle, CardSubtitle } from "reactstrap";
import { formatMoney, useMediaQuery } from "infra/utils";
import { LayoutContext } from "contexts/layout";

const CardStyled = styled.div`
  float: left;
  width: 100%;
  clear: both;
  background: white;
  height: 150px;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
`;

const CardBodyStyled = styled.div`
  float: left;
  flex: 1;
  .preco {
    float: left;
    font-size: 18px;

    .desconto {
      text-decoration: line-through;
    }
  }
  .button {
    float: right;
  }
`;

const CardImgStyled = styled.div`
  float: right;
  margin-left: 16px;
  img {
    border-radius: 6px;
    width: 150px;
    height: 118px;
  }
`;

const CardTitleStyled = styled(CardTitle)`
  & {
    margin-bottom: 16px;
    color: ${(props) => props.color};
    font-weight: bold;
    line-height: 16px;
  }
`;

const TextoInfo = styled.div`
  padding: 8px 0;
  color: ${(props) => props.color || "#212529"};
`;

function Produto({ item, onClick }) {
  const { layoutData } = useContext(LayoutContext);

  const isMobile = useMediaQuery("(max-width: 768px)");
  
  return (
    <React.Fragment>
      <CardStyled
        onClick={(ev) => {
          if (item.flgDisponivel) {
            onClick(ev);
          } else {
            alert("Produto não está disponível no momento");
          }
        }}
      >
        <CardBodyStyled style={{ opacity: !item.flgDisponivel ? 0.6 : 1 }}>
        <CardTitleStyled color={layoutData.cardapioCorNomeProduto}>
            <p>{item.nomeCompleto}</p>
          </CardTitleStyled>
          {!!item.textoInformativo && !isMobile ? (
            <TextoInfo color={layoutData?.cardapioCorTextoInfo}>
              <p className="text-overflow">{item.textoInformativo}</p>
            </TextoInfo>
          ) : null}
          {!!item.preco && item.flgDisponivel ? (
            <CardSubtitle>
              <div className="preco">
                {item.flgEmFalta ? (
                  "Em Falta!"
                ) : (
                  <div>
                    <TextDesconto item={item} />
                    {item.flgPrecoVariavel ? `A partir de` : null} R${" "}
                    {formatMoney(item.preco)}
                  </div>
                )}
              </div>
            </CardSubtitle>
          ) : null}
        </CardBodyStyled>

        {item.imagem ? (
          <CardImgStyled>
            <CardImg
              style={{ opacity: !item.flgDisponivel ? 0.6 : 1 }}
              top
              src={item.imagem}
              alt={item.nomeCompleto}
            />
          </CardImgStyled>
        ) : null}

        {!item.flgDisponivel ? (
          <div
            style={{
              backgroundColor: "#999",
              color: "white",
              padding: 4,
              borderRadius: 4,
              position: "absolute",
              right: 32,
              boxShadow: "0 3px 3px rgba(0,0,0,.05)",
              bottom: 32,
              fontSize: 13,
            }}
          >
            {" "}
            indisponível no momento{" "}
          </div>
        ) : null}
      </CardStyled>
    </React.Fragment>
  );
}

export function TextDesconto({ item }) {
  if (item.valorPrecoNormal <= item.preco) {
    return null;
  }

  return (
    <span>
      <span style={{ fontSize: 12 }}> de </span>
      <span className="desconto">{formatMoney(item.valorPrecoNormal)}</span>
      <span style={{ fontSize: 12 }}> por </span>
    </span>
  );
}

export default Produto;
