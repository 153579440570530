import { useContext, useEffect, useState } from "react";
import { Ambiente } from "../types";
import { httpCache } from "infra/axios";
import { EventoContext } from "../EventoContext";

export default function useAmbientesPorDisponibilidade() {
  const [data, setData] = useState<Ambiente[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { value } = useContext(EventoContext);

  useEffect(() => {
    (async () => {
      if (!value.dataEvento) {
        setData([]);
        return;
      }

      setIsLoading(true);

      try {
        const { data: disponibilidades } = await httpCache.get(
          "v2/eventos/mont/ambientes-by-disponibilidade",
          {
            params: {
              dataEvento: value.dataEvento,
            },
          }
        );

        setData(disponibilidades);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [value.dataEvento]);

  return {
    data,
    isLoading,
  };
}
