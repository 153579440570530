import styled from "styled-components";

export const CollapseTitle = styled.div`
  background: white;
  cursor: pointer;
  padding: 4px;
  float: left;
  width: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;

  .badge {
    margin-top: 12px;
    margin-left: 10px;
  }
`;
export const FundoCardapio = styled.div`
  background-color:${(props)=>props.color || "#fafafc"};
  overflow:hidden;
  padding-top:16px;

`;

export const GrupoItem = styled.div`
  h1 {
    margin: 0;
    margin-bottom: 32px;
    font-size: 24px;
    text-align: center;
  }

  h2 {
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 22px;
    color: #444;
  }

  h4 {
    margin-bottom: 32px;
  }
`;

export const ContainerProduto = styled.div`
  width: 100%;
  float: left;

  .react-customscroll-frame {
    padding-left: 4px;
  }

  @media all and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
  }

  @media all and (max-width: 768px) {
    .nav {
      .nav-item {
        width: 50%;
        margin-bottom: 16px;
        padding: 6px;

        a {
          width: 100%;
        }
      }
    }
  }
`;
