import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AppBar, Paper, Tab, Tabs, useMediaQuery } from "@material-ui/core";
import { Container, Button } from "reactstrap";

import Appbar from "../../../components/Appbar";
import ScrollTop from "../../../components/ScrollTop";
import Footer from "../../../components/Footer";

import TabHistorico from "./Tabs/TabHistorico";
import TabSobre from "./Tabs/TabSobre";
import TabRecompensas from "./Tabs/TabRecompensas";
import TabResgates from "./Tabs/TabResgates";
import AuthClient from "infra/auth/AuthClient";
import { LayoutContext } from "contexts/layout";
import { findFidelidadeCampanhaAtiva } from "services/fidelidade";

const Body = styled.div`
  min-height: 100vh;
  padding-top: 120px;

  @media all and (max-width: 768px) {
    & {
      padding-top: 64px;
    }
  }
`;



function FidelidadePage() {
  const [value, setValue] = useState<any>(0);
  const { layoutData, update: updateContext }: any = useContext(LayoutContext);

  const handleChange = (_ev: any, newValue: any) => {
    setValue(newValue);
  };
  const isMobile = useMediaQuery("(max-width: 460px)");
  const isUserLogged = AuthClient.isUserLogged();
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const result = await findFidelidadeCampanhaAtiva(layoutData.id);

        setData(result);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [layoutData.id]);
  return (
    <Body>
      <Appbar />

      <Container style={{ maxWidth: 768, minHeight: "75vh", marginBottom: 32 }}>
        <h3>Fidelidade</h3>
        {data?.saldoAtualConta && <h6>Saldo da conta: {data?.saldoAtualConta} pontos</h6>}

        <br />
        {!isUserLogged ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Button
              color="primary"
              onClick={() => updateContext({ openLogin: true })}
            >
              Faça Login para continuar
            </Button>
          </div>

        ) : <Paper>
          <AppBar position="static" color="default">


            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant={isMobile ? 'scrollable' : 'fullWidth'}
              scrollButtons={isMobile ? 'auto' : 'off'}
            >
              <Tab label="Sobre" />
              <Tab label="Histórico" />
              <Tab label="Recompensas" />
              <Tab label="Resgates" />
            </Tabs>

          </AppBar>

          {value === 0 ? <TabSobre data={data} loading={isLoading} layoutData={layoutData} /> : null}
          {value === 1 ? <TabHistorico /> : null}
          {value === 2 ? <TabRecompensas /> : null}
          {value === 3 ? <TabResgates /> : null}
        </Paper>}

      </Container>

      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default FidelidadePage;
