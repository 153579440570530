import React, { useState, useEffect } from "react";
import {  getAllPedidosMenu } from "services/pedido";
import { Container, Spinner, Row, Col } from "reactstrap";
import PedidoItem from "./PedidoItem";
import { Paper } from "@material-ui/core";

function ListaPedidos(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const data = await getAllPedidosMenu();

        setItems(data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);


  return (
    <Container>
      {isLoading && <Spinner />}

      <h2 style={{marginTop: 16}}>Pedidos</h2>

      <br />

      <Row>
        {items.map(item => (
          <Col sm={6}>
            <Paper>
              <PedidoItem item={item} />
            </Paper>
          </Col>
        ))}
      </Row>
      <br />
    </Container>
  );
}

export default ListaPedidos;
