import Axios from 'axios';
import { BASE_URL_API } from '../infra/config';

export function findAll() {
  return [
    {
      id: 27,
      nome: 'Acre',
      sigla: 'AC',
      paisId: 1,
      op: 0,
    },
    {
      id: 14,
      nome: 'Alagoas',
      sigla: 'AL',
      paisId: 1,
      op: 0,
    },
    {
      id: 25,
      nome: 'Amapá',
      sigla: 'AP',
      paisId: 1,
      op: 0,
    },
    {
      id: 17,
      nome: 'Amazonas',
      sigla: 'AM',
      paisId: 1,
      op: 0,
    },
    {
      id: 9,
      nome: 'Bahia',
      sigla: 'BA',
      paisId: 1,
      op: 0,
    },
    {
      id: 23,
      nome: 'Ceará',
      sigla: 'CE',
      paisId: 1,
      op: 0,
    },
    {
      id: 8,
      nome: 'Distrito Federal',
      sigla: 'DF',
      paisId: 1,
      op: 0,
    },
    {
      id: 10,
      nome: 'Espirito Santo',
      sigla: 'ES',
      paisId: 1,
      op: 0,
    },
    {
      id: 7,
      nome: 'Goiás',
      sigla: 'GO',
      paisId: 1,
      op: 0,
    },
    {
      id: 18,
      nome: 'Maranão',
      sigla: 'MA',
      paisId: 1,
      op: 0,
    },
    {
      id: 1,
      nome: 'Mato Grosso',
      sigla: 'MT',
      paisId: 1,
      op: 1,
    },
    {
      id: 2,
      nome: 'Mato Grosso do Sul',
      sigla: 'MS',
      paisId: 1,
      op: 0,
    },
    {
      id: 3,
      nome: 'Minas Gerias',
      sigla: 'MG',
      paisId: 1,
      op: 0,
    },
    {
      id: 24,
      nome: 'Para',
      sigla: 'PA',
      paisId: 1,
      op: 0,
    },
    {
      id: 21,
      nome: 'Paraiba',
      sigla: 'PB',
      paisId: 1,
      op: 0,
    },
    {
      id: 6,
      nome: 'Paraná',
      sigla: 'PR',
      paisId: 1,
      op: 0,
    },
    {
      id: 20,
      nome: 'Pernambuco',
      sigla: 'PE',
      paisId: 1,
      op: 0,
    },
    {
      id: 19,
      nome: 'Piaui',
      sigla: 'PI',
      paisId: 1,
      op: 0,
    },
    {
      id: 5,
      nome: 'Rio de Janeiro',
      sigla: 'RJ',
      paisId: 1,
      op: 0,
    },
    {
      id: 13,
      nome: 'Rio Grande do Norte',
      sigla: 'RN',
      paisId: 1,
      op: 0,
    },
    {
      id: 12,
      nome: 'Rio Grande do Sul',
      sigla: 'RS',
      paisId: 1,
      op: 0,
    },
    {
      id: 16,
      nome: 'Rondonia',
      sigla: 'RO',
      paisId: 1,
      op: 0,
    },
    {
      id: 15,
      nome: 'Roraima',
      sigla: 'RR',
      paisId: 1,
      op: 0,
    },
    {
      id: 11,
      nome: 'Santa Catarina',
      sigla: 'SC',
      paisId: 1,
      op: 0,
    },
    {
      id: 4,
      nome: 'São Paulo',
      sigla: 'SP',
      paisId: 1,
      op: 0,
    },
    {
      id: 22,
      nome: 'Sergipe',
      sigla: 'SE',
      paisId: 1,
      op: 0,
    },
    {
      id: 26,
      nome: 'Tocantis',
      sigla: 'TO',
      paisId: 1,
      op: 0,
    },
  ];
}

export async function findCidade(ufId) {
  let data = [
    {
      id: 1489,
      nome: 'Cuiabá',
      codigoIbge: '5103403',
      ordem: 1,
      ufId: 1,
    },
    {
      id: 26,
      nome: 'Acorizal',
      codigoIbge: '5100102',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 48,
      nome: 'Água Boa',
      codigoIbge: '5100201',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 131,
      nome: 'Alta Floresta',
      codigoIbge: '5100250',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 147,
      nome: 'Alto Araguaia',
      codigoIbge: '5100300',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 149,
      nome: 'Alto Boa Vista',
      codigoIbge: '5100359',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 153,
      nome: 'Alto Garças',
      codigoIbge: '5100409',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 157,
      nome: 'Alto Paraguai',
      codigoIbge: '5100508',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 166,
      nome: 'Alto Taquari',
      codigoIbge: '5100607',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 277,
      nome: 'Apiacás',
      codigoIbge: '5100805',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 313,
      nome: 'Araguaiana',
      codigoIbge: '5101001',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 315,
      nome: 'Araguainha',
      codigoIbge: '5101209',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 338,
      nome: 'Araputanga',
      codigoIbge: '5101258',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 374,
      nome: 'Arenápolis',
      codigoIbge: '5101308',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 380,
      nome: 'Aripuanã',
      codigoIbge: '5101407',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 489,
      nome: 'Barão de Melgaço',
      codigoIbge: '5101605',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 514,
      nome: 'Barra do Bugres',
      codigoIbge: '5101704',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 518,
      nome: 'Barra do Garças',
      codigoIbge: '5101803',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 682,
      nome: 'Bom Jesus do Araguaia',
      codigoIbge: '5101852',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 754,
      nome: 'Brasnorte',
      codigoIbge: '5101902',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 835,
      nome: 'Cáceres',
      codigoIbge: '5102504',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 947,
      nome: 'Campinápolis',
      codigoIbge: '5102603',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 981,
      nome: 'Campo Novo do Parecis',
      codigoIbge: '5102637',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 983,
      nome: 'Campo Verde',
      codigoIbge: '5102678',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 987,
      nome: 'Campos de Júlio',
      codigoIbge: '5102686',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1000,
      nome: 'Canabrava do Norte',
      codigoIbge: '5102694',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1006,
      nome: 'Canarana',
      codigoIbge: '5102702',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1119,
      nome: 'Carlinda',
      codigoIbge: '5102793',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1169,
      nome: 'Castanheira',
      codigoIbge: '5102850',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1246,
      nome: 'Chapada dos Guimarães',
      codigoIbge: '5103007',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1279,
      nome: 'Cláudia',
      codigoIbge: '5103056',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1289,
      nome: 'Cocalinho',
      codigoIbge: '5103106',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1300,
      nome: 'Colíder',
      codigoIbge: '5103205',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1307,
      nome: 'Colniza',
      codigoIbge: '5103254',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1321,
      nome: 'Comodoro',
      codigoIbge: '5103304',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1355,
      nome: 'Confresa',
      codigoIbge: '5103353',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1362,
      nome: 'Conquista dOeste',
      codigoIbge: '5103361',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1436,
      nome: 'Cotriguaçu',
      codigoIbge: '5103379',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1521,
      nome: 'Curvelvândia',
      codigoIbge: '5103437',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1538,
      nome: 'Denise',
      codigoIbge: '5103452',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1555,
      nome: 'Diamantino',
      codigoIbge: '5103502',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1588,
      nome: 'Dom Aquino',
      codigoIbge: '5103601',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1767,
      nome: 'Feliz Natal',
      codigoIbge: '5103700',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1784,
      nome: 'Figueirópolis dOeste',
      codigoIbge: '5103809',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1890,
      nome: 'Gaúcha do Norte',
      codigoIbge: '5103858',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1897,
      nome: 'General Carneiro',
      codigoIbge: '5103908',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 1911,
      nome: 'Glória dOeste',
      codigoIbge: '5103957',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2016,
      nome: 'Guarantã do Norte',
      codigoIbge: '5104104',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2041,
      nome: 'Guiratinga',
      codigoIbge: '5104203',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2197,
      nome: 'Indiavaí',
      codigoIbge: '5104500',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2417,
      nome: 'Itaúba',
      codigoIbge: '5104559',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2426,
      nome: 'Itiquira',
      codigoIbge: '5104609',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2472,
      nome: 'Jaciara',
      codigoIbge: '5104807',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2511,
      nome: 'Jangada',
      codigoIbge: '5104906',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2556,
      nome: 'Jauru',
      codigoIbge: '5105002',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2612,
      nome: 'Juara',
      codigoIbge: '5105101',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2624,
      nome: 'Juína',
      codigoIbge: '5105150',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2650,
      nome: 'Juruena',
      codigoIbge: '5105176',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2652,
      nome: 'Juscimeira',
      codigoIbge: '5105200',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2729,
      nome: 'Lambari dOeste',
      codigoIbge: '5105234',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2794,
      nome: 'Lucas do Rio Verde',
      codigoIbge: '5105259',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2798,
      nome: 'Luciara',
      codigoIbge: '5105309',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 2930,
      nome: 'Marcelândia',
      codigoIbge: '5105580',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3018,
      nome: 'Matupá',
      codigoIbge: '5105606',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3091,
      nome: 'Mirassol dOeste',
      codigoIbge: '5105622',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3266,
      nome: 'Nobres',
      codigoIbge: '5105903',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3270,
      nome: 'Nortelândia',
      codigoIbge: '5106000',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3277,
      nome: 'Nossa Senhora do Livramento',
      codigoIbge: '5106109',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3290,
      nome: 'Nova Bandeirantes',
      codigoIbge: '5106158',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3294,
      nome: 'Nova Brasilândia',
      codigoIbge: '5106208',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3299,
      nome: 'Nova Canaã do Norte',
      codigoIbge: '5106216',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3320,
      nome: 'Nova Guarita',
      codigoIbge: '5108808',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3331,
      nome: 'Nova Lacerda',
      codigoIbge: '5106182',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3337,
      nome: 'Nova Marilândia',
      codigoIbge: '5108857',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3338,
      nome: 'Nova Maringá',
      codigoIbge: '5108907',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3340,
      nome: 'Nova Monte Verde',
      codigoIbge: '5108956',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3341,
      nome: 'Nova Mutum',
      codigoIbge: '5106224',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3342,
      nome: 'Nova Nazaré',
      codigoIbge: '5106174',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3345,
      nome: 'Nova Olímpia',
      codigoIbge: '5106232',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3367,
      nome: 'Nova Santa Helena',
      codigoIbge: '5106190',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3376,
      nome: 'Nova Ubiratã',
      codigoIbge: '5106240',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3383,
      nome: 'Nova Xavantina',
      codigoIbge: '5106257',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3398,
      nome: 'Novo Horizonte do Norte',
      codigoIbge: '5106273',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3405,
      nome: 'Novo Mundo',
      codigoIbge: '5106265',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3413,
      nome: 'Novo Santo Antônio',
      codigoIbge: '5106315',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3414,
      nome: 'Novo São Joaquim',
      codigoIbge: '5106281',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3585,
      nome: 'Paranaíta',
      codigoIbge: '5106299',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3590,
      nome: 'Paranatinga',
      codigoIbge: '5106307',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3692,
      nome: 'Pedra Preta',
      codigoIbge: '5106372',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3722,
      nome: 'Peixoto de Azevedo',
      codigoIbge: '5106422',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3874,
      nome: 'Planalto da Serra',
      codigoIbge: '5106455',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3890,
      nome: 'Poconé',
      codigoIbge: '5106505',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3905,
      nome: 'Pontal do Araguaia',
      codigoIbge: '5106653',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3914,
      nome: 'Ponte Branca',
      codigoIbge: '5106703',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3918,
      nome: 'Pontes e Lacerda',
      codigoIbge: '5106752',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3940,
      nome: 'Porto Alegre do Norte',
      codigoIbge: '5106778',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3951,
      nome: 'Porto dos Gaúchos',
      codigoIbge: '5106802',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3952,
      nome: 'Porto Esperidião',
      codigoIbge: '5106828',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3953,
      nome: 'Porto Estrela',
      codigoIbge: '5106851',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 3985,
      nome: 'Poxoréo',
      codigoIbge: '5107008',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4033,
      nome: 'Primavera do Leste',
      codigoIbge: '5107040',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4069,
      nome: 'Querência',
      codigoIbge: '5107065',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4124,
      nome: 'Reserva do Cabaçal',
      codigoIbge: '5107156',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4161,
      nome: 'Ribeirão Cascalheira',
      codigoIbge: '5107180',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4173,
      nome: 'Ribeirãozinho',
      codigoIbge: '5107198',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4185,
      nome: 'Rio Branco',
      codigoIbge: '5107206',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4264,
      nome: 'Rondolândia',
      codigoIbge: '5107578',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4267,
      nome: 'Rondonópolis',
      codigoIbge: '5107602',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4276,
      nome: 'Rosário Oeste',
      codigoIbge: '5107701',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4319,
      nome: 'Salto do Céu',
      codigoIbge: '5107750',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4351,
      nome: 'Santa Carmem',
      codigoIbge: '5107248',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4377,
      nome: 'Santa Cruz do Xingu',
      codigoIbge: '5107743',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4458,
      nome: 'Santa Rita do Trivelato',
      codigoIbge: '5107768',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4479,
      nome: 'Santa Terezinha',
      codigoIbge: '5107776',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4522,
      nome: 'Santo Afonso',
      codigoIbge: '5107263',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4552,
      nome: 'Santo Antônio do Leste',
      codigoIbge: '5107792',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4553,
      nome: 'Santo Antônio do Leverger',
      codigoIbge: '5107800',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4625,
      nome: 'São Félix do Araguaia',
      codigoIbge: '5107859',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4779,
      nome: 'São José do Povo',
      codigoIbge: '5107297',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4780,
      nome: 'São José do Rio Claro',
      codigoIbge: '5107305',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4787,
      nome: 'São José do Xingu',
      codigoIbge: '5107354',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4793,
      nome: 'São José dos Quatro Marcos',
      codigoIbge: '5107107',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4855,
      nome: 'São Pedro da Cipa',
      codigoIbge: '5107404',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 4921,
      nome: 'Sapezal',
      codigoIbge: '5107875',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5003,
      nome: 'Serra Nova Dourada',
      codigoIbge: '5107883',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5055,
      nome: 'Sinop',
      codigoIbge: '5107909',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5082,
      nome: 'Sorriso',
      codigoIbge: '5107925',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5101,
      nome: 'Tabaporã',
      codigoIbge: '5107941',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5134,
      nome: 'Tangará da Serra',
      codigoIbge: '5107958',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5155,
      nome: 'Tapurah',
      codigoIbge: '5108006',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5211,
      nome: 'Terra Nova do Norte',
      codigoIbge: '5108055',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5216,
      nome: 'Tesouro',
      codigoIbge: '5108105',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5253,
      nome: 'Torixoréu',
      codigoIbge: '5108204',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5374,
      nome: 'União do Sul',
      codigoIbge: '5108303',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5406,
      nome: 'Vale de São Domingos',
      codigoIbge: '5108352',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5445,
      nome: 'Várzea Grande',
      codigoIbge: '5108402',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5457,
      nome: 'Vera',
      codigoIbge: '5108501',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5496,
      nome: 'Vila Bela da Santíssima Trindade',
      codigoIbge: '5105507',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 5507,
      nome: 'Vila Rica',
      codigoIbge: '5108600',
      ordem: 2,
      ufId: 1,
    },
    {
      id: 12213,
      nome: 'Conquista D Oeste',
      codigoIbge: '5103361',
      ordem: 999,
      ufId: 1,
    },
    {
      id: 12357,
      nome: 'Curvelândia',
      codigoIbge: '5103437',
      ordem: 999,
      ufId: 1,
    },
    {
      id: 12624,
      nome: 'Figueirópolis D Oeste',
      codigoIbge: '5103809',
      ordem: 999,
      ufId: 1,
    },
    {
      id: 12740,
      nome: 'Glória D Oeste',
      codigoIbge: '5103957',
      ordem: 999,
      ufId: 1,
    },
    {
      id: 13020,
      nome: 'Ipiranga do Norte',
      codigoIbge: '5104526',
      ordem: 999,
      ufId: 1,
    },
    {
      id: 13109,
      nome: 'Itanhangá',
      codigoIbge: '5104542',
      ordem: 999,
      ufId: 1,
    },
    {
      id: 13424,
      nome: 'Lambari D Oeste',
      codigoIbge: '5105234',
      ordem: 999,
      ufId: 1,
    },
    {
      id: 13717,
      nome: 'Mirassol D Oeste',
      codigoIbge: '5105622',
      ordem: 999,
      ufId: 1,
    },
    {
      id: 14555,
      nome: 'Poxoréu',
      codigoIbge: '5107008',
      ordem: 999,
      ufId: 1,
    },
  ];

  data = data.filter(x => x.ufId === ufId && !!x.codigoIbge);

  if (!data.length) {
    const response = await Axios.get(`${BASE_URL_API}/v1/api/cidades/${ufId}`);
    data = response.data;
  }

  return new Promise(async (resolve) => {
    return resolve(data);
  });
}

export async function findCidadesAtivas() {
  const { data } = await Axios.get(`${BASE_URL_API}/v1/api/empresas/cidades-ativas`);

  return data;
}