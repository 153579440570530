import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from "reactstrap";
import {
  getEnderecoByGooglePlace,
  getDetalhesEnderecoGooglePlace,
  buscarCidadesPorNome,
  validarBairroPorNome,
  requestPermissionLocation
} from "services/location";

import CircularProgress from '@material-ui/core/CircularProgress';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';

let cancel;

function ModalPesquisarEndereco({ open, onToggle, onSelected }) {
  const [error, setError] = useState(null);
  const [, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pesquisa, setPesquisa] = useState(null); 
  const [propsPlace, setPropsPlace] = useState({});

  useEffect(() => {

    (async () => {

      const { latitude, longitude } = await requestPermissionLocation();
 
      if (latitude) {
        setPropsPlace({
          autocompletionRequest: {
            bounds: [
              {
                lat: latitude,
                lng: longitude
              },
            ],
          }
        })
      }

    })();

  }, []);

  useEffect(() => {

    (() => {

      if (open) {
        setItems([]);
        setError(null);
        setPesquisa(null);
      }

    })();

  }, [open]);


  const searchGoogle = useCallback(async () => {

    setIsLoading(true);

    try {
      const data = await getEnderecoByGooglePlace(pesquisa, c => {
        cancel = c;
      });
 
      if (data.status === 'OK') {
        setItems(data.predictions);
      }

    } catch (e) {
    } finally {
      setIsLoading(false);
    }

  }, [pesquisa]);

  useEffect(() => {

    (() => {

      if (open && pesquisa) {

        if (cancel) {
          cancel();
        }

        searchGoogle();
      }

      if (!pesquisa) {
        setItems([]);
      }

    })();

  }, [pesquisa, open, searchGoogle]);

  const getPlaceDetails = async (place_id) => {

    return new Promise((resolve, reject) => {

      const request = {
        placeId: place_id,
      };

      const service = new window.google.maps.places.PlacesService(window.document.createElement('div'));

      service.getDetails(request, (place, status) => {

        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(place);
        } else {
          reject();
        }
      });
    })
  }
  const selectEndereco = useCallback(async (item) => {

    try {

      setIsLoading(true);

      const data = await getPlaceDetails(item.place_id);

      const endereco = await getDetalhesEnderecoGooglePlace(data);

      if (!endereco || !endereco.uf) {

        setIsLoading(false);
        return setError(
          'Você precisa selecionar um endereço brasileiro.',
        );
      }

      const logradouro = item.structured_formatting.main_text;
      const parts = item.structured_formatting.secondary_text.split(',');

      // Se possui tres partes, significa que tem bairro, cidade/uf e pais
      if (parts.length === 3) {

        const [bairroGoogle, cidadeEstadoUf] = parts;
        const [cidade, estadoUf] = (cidadeEstadoUf || '').split(' - ');

        endereco.nomeBairro = endereco.nomeBairro || bairroGoogle;
        endereco.nomeCidade = endereco.nomeCidade || cidade;
        endereco.uf = endereco.uf || estadoUf;
        endereco.logradouro = logradouro || endereco.logradouro;
      }

      if (endereco.nomeBairro) {
        const validarBairro = await validarBairroPorNome({
          nomeBairro: endereco.nomeBairro,
          nomeCidade: endereco.nomeCidade,
          siglaUf: (endereco.uf || '').toLowerCase(),
        });

        if (validarBairro) {
          endereco.bairroId = validarBairro.id;
          endereco.cidadeId = validarBairro.cidadeId;
        }
      } else {

        const partsCidadeUf = parts[0].split('-');

        const nomeCidade = endereco.nomeCidade || partsCidadeUf[0];
        const uf = endereco.uf || partsCidadeUf[1];

        if (nomeCidade) {
          const cidades = await buscarCidadesPorNome(nomeCidade, uf);

          if (cidades.length) {
            endereco.cidadeId = cidades[0].id;
            endereco.ufId = cidades[0].ufId;
          }
        }

        // Habiltia por que não tem bairro
        endereco.habilitaCadastro = true;
      }

      if (typeof onSelected === "function") {
        onSelected(endereco);
      }

    } catch (e) {
      setError('Não foi possível selecionar este endereço!')
    } finally {
      setIsLoading(false);
    }

  }, [onSelected]);

  return (
    <div>
      <Modal size="lg" isOpen={open} toggle={onToggle}>
        <ModalHeader toggle={onToggle}>Pesquisar endereço</ModalHeader>
        <ModalBody>

          <div style={{ marginBottom: 32 }}>

            <GooglePlacesAutocomplete
              placeholder="Procurar endereço"
              {...propsPlace}
              onSelect={(value) => {
                selectEndereco(value);
              }}
            />

          </div>


          {isLoading ? <div style={{ margin: '16px auto', display: 'table' }}> <CircularProgress /> </div> : null}

          {error && (
            <Alert color="danger">
              {error}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onToggle}>
            Cancelar
        </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalPesquisarEndereco;
