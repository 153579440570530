import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import Axios from "axios";
import { BASE_URL_API } from "infra/config";
import PagamentoParcialPix from "./PagamentoParcialPix";
import FormPagamento from "./FormPagamento";
import { formatMoney } from "infra/utils";

// Componente para exibir o comprovante de pagamento
function Comprovante({ comprovante }) {
  return (
    <div>
      <div
        style={{
          padding: "4px 8px",
          margin: "8px 0",
          borderRadius: 8,
          backgroundColor: "#4caf50",
          color: "white",
          display: "flex",
        }}
      >
        <h4>Pagamento Aprovado</h4>
      </div>
      <h4>Comprovante</h4>
      <div>
        <p>
          <strong>Valor:</strong> R${" "}
          {formatMoney(comprovante?.valorPago ?? 100)}
        </p>
        <p>
          <strong>Data e Hora:</strong>{" "}
          {/* {moment(comprovante?.dataHora).format("DD/MM/YYYY HH:mm:ss")} */}
        </p>
        <p>
          <strong>Identificador da transação:</strong>{" "}
          {comprovante?.paymentId ?? 100}
        </p>
      </div>
    </div>
  );
}

// Componente para consultar automaticamente o status
function AutoRefreshStatus({
  resultadoSolicitacao,
  saidaId,
  buscarComprovante,
  setAutoRefresh,
}) {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getStatus();
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saidaId, resultadoSolicitacao, buscarComprovante]);

  const getStatus = async () => {
    try {
      const response = await Axios.get(
        `${BASE_URL_API}/v1/pagamentos/obter-status/${resultadoSolicitacao?.id}`
      );
      const data = response.data;
      setStatus(data.flgStatus);

      if (data.flgStatus === "F" || data.flgStatus === "P") {
        setAutoRefresh(false);
      } else if (data.flgStatus === "C") {
        // Redirecione de volta para a página de acompanhamento da conta
        setAutoRefresh(false);
      }
    } catch (error) {
      console.error("Erro ao obter status:", error);
    }
  };

  return (
    <div>
      {buscarComprovante && (
        <Spinner style={{ margin: "32px auto", display: "table" }} />
      )}
      {status === "F" || status === "P" ? null : <h3>Consultando...</h3>}
    </div>
  );
}

function ModalPagamentoParcialPix({
  open,
  saidaId,
  numIdentificador,
  valorRestante,
  onCancel,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({});
  const [isCopied, setIsCopied] = useState(false);
  const [resultadoSolicitacao, setResultadoSolicitacao] = useState(undefined);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [buscarComprovante] = useState(false);
  const [comprovante, setComprovante] = useState(null);

  useEffect(() => {
    setForm({});
  }, [open]);

  const valor = form?.valor;

  const solicitarPagamentoParcial = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await Axios.post(
        `${BASE_URL_API}/v1/pagamentos/saidas/${saidaId}`,
        {
          saidaId,
          modalidadeCaixaApiId: 101,
          valorPago: valor,
        }
      );

      setResultadoSolicitacao(data);
    } catch (error) {
      console.error("Erro ao solicitar pagamento parcial:", error);
    } finally {
      setIsLoading(false);
    }
  }, [saidaId, valor]);

  const handleCurrencyInputChange = useCallback(
    (value) => {
      setForm({ ...form, valor: value });
    },
    [form]
  );

  const handleCopy = () => {
    setIsCopied(true);
  };

  const startAutoRefresh = () => {
    setAutoRefresh(true);
  };

  useEffect(() => {
    if (buscarComprovante) {
      try {
        Axios.get(
          `${BASE_URL_API}/v1/pagamentos/obter-comprovante/${resultadoSolicitacao?.id}`
        )
          .then((response) => {
            const data = response.data;
            setComprovante(data);
          })
          .catch((error) => {
            console.error("Erro ao obter comprovante:", error);
          });
      } catch (error) {
        console.error("Erro ao obter comprovante:", error);
      }
    }
  }, [buscarComprovante, resultadoSolicitacao]);

  return (
    <div>
      <Modal isOpen={open}>
        <ModalHeader>Pagamento Parcial via Pix</ModalHeader>
        <ModalBody>
          {isLoading ? (
            <Spinner style={{ margin: "32px auto", display: "table" }} />
          ) : resultadoSolicitacao !== undefined ? (
            <div>
              <PagamentoParcialPix
                qrCodeData={resultadoSolicitacao?.pix?.qrCodeData}
                isCopied={isCopied}
                onCopy={handleCopy}
              />
              {autoRefresh && (
                <AutoRefreshStatus
                  resultadoSolicitacao={resultadoSolicitacao}
                  saidaId={saidaId}
                  buscarComprovante={buscarComprovante}
                  setAutoRefresh={setAutoRefresh}
                />
              )}
              {!autoRefresh && buscarComprovante && comprovante !== null && (
                <Comprovante comprovante={comprovante} />
              )}
            </div>
          ) : (
            <FormPagamento
              value={valor}
              onChange={handleCurrencyInputChange}
              numIdentificador={numIdentificador}
              valorRestante={valorRestante}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              onCancel();
              setResultadoSolicitacao(undefined);
            }}
          >
            Voltar
          </Button>
          {comprovante === null && (
            <Button
              disabled={isLoading}
              color="primary"
              onClick={() =>
                resultadoSolicitacao !== undefined
                  ? startAutoRefresh()
                  : solicitarPagamentoParcial()
              }
            >
              {resultadoSolicitacao !== undefined
                ? "Já efetuei o pagamento"
                : "Confirmar"}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default React.memo(ModalPagamentoParcialPix);
