import Axios from "axios";
import { BASE_URL_API } from "infra/config";

export async function findHistoricoLancamento(fidelidadeContaId) {
  const { data } = await Axios.get(
    `${BASE_URL_API}/v1/api/fidelidade/hitorico-movimento/${fidelidadeContaId}`
  );
  return data;
}

export async function findHistoricoResgate(fidelidadeId) {
  const { data } = await Axios.get(
    `${BASE_URL_API}/v1/api/fidelidade/historico-resgate-premio/${fidelidadeId}`
  );
  return data;
}

export async function solicitarResgatePremio(resgate) {
  const { data } = await Axios.post(
    `${BASE_URL_API}/v1/api/fidelidade/solicitar-resgate-premio`,
    resgate
  );
  return data;
}

// QUANDO O USUARIO ESTA VISUALIZANDO A SAIDA ELE PODE RESGATAR OS PONTOS REFERENTE A VENDA
export async function resgatarPontosSaida(saidaId) {
  const { data } = await Axios.put(
    `${BASE_URL_API}/v1/api/fidelidade/resgatar-pontos-saida/${saidaId}`
  );

  return data;
}

export async function findPremiacoes(fidelidadeId) {
  const { data } = await Axios.get(
    `${BASE_URL_API}/v1/api/fidelidade/premiacoes/${fidelidadeId}`
  );
  return data;
}

export async function findFidelidadeCampanhaAtiva(empresaId) {
  const { data } = await Axios.get(
    `${BASE_URL_API}/v1/api/fidelidade/campanha-ativa/${empresaId}`
  );

  return data;
}

export async function cadastrarContaFidelidade(fidelidadeId) {
  const { data } = await Axios.post(
    `${BASE_URL_API}/v1/api/fidelidade/cadastrar-conta`,
    {
      fidelidadeId,
    }
  );
  return data;
}
