import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { getEspecialidades } from 'services/cardapio';
import { TextField } from '@material-ui/core';

export default function SelectEspecialidades({ disabled, value, onChange }) {

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    (async () => {

      try {
        setIsLoading(true);

        const data = await getEspecialidades();

        setItems(data);
      } catch (e) {

      } finally {
        setIsLoading(false);
      }

    })();
  }, []);

  return (
    <Autocomplete
      value={value}
      disabled={disabled}
      onSelect={(selected) => onChange(items.find(x => x.nome === selected.target.value)?.id ?? null)}
      options={items}
      loading={isLoading}
      fullWidth
      loadingText="Carregando..."
      getOptionLabel={(option) => option.nome}
      renderInput={(params) => <TextField disabled={disabled} {...params} size="small" fullWidth label="Especialidade" variant="outlined" />}
    />
  )
}