import React, { useState } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { cadastrarContaFidelidade, } from "services/fidelidade";
import { showError, showSuccess } from "infra/alerta";
import { Button } from "reactstrap"
export const displayTexto = (texto: string) => {
  const splites = texto.split("*");

  return (
    <div>
      {splites.map((item) => (
        <Typography>{item}</Typography>
      ))}
    </div>
  );
};

function TabSobre({ layoutData, data, loading }: any) {
  const [isLoading, setIsLoading] = useState(false)
  const handleAceitar = async () => {
    try {
      setIsLoading(true);
      await cadastrarContaFidelidade(layoutData.fidelidadeId);

      showSuccess(`Parabéns! Você se fidelizou a ${data.nome}`);
    } catch (error) {
      let message = "Não foi possível aceitar a campanha";

      // if (error.response?.data) {
      //   message = error.response?.data.details[0].errors[0];
      // }

      showError(message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box style={{ padding: 16, minHeight: 400 }}>
      {isLoading ?? loading ? (
        <div style={{ textAlign: "center", marginTop: 32 }}>
          <CircularProgress />
        </div>
      ) : <>
        {data.imagemUrl ? (
          <div style={{ marginBottom: 32, marginTop: 32 }}>
            <img src={data.imagemUrl} alt={"sobre"} style={{ width: "100%", maxWidth: 300 }} />
          </div>
        ) : null}

        <Typography style={{ margin: 4 }}>• Através do WebGula Menu, você é capaz de acompanhar e gerenciar seus pontos de fidelidade, no entanto toda a responsabilidade pela execução da premiação e o fornecimento das chaves que gera pontos é do Estabelecimento Comercial em questão.
        </Typography>
        <Typography style={{ margin: 4 }}>• Não se preocupe, é um serviço gratuito.</Typography>
        <Typography style={{ marginTop: 32 }}>{displayTexto(data.textoCliente || "")}</Typography>

        {data.pontosAdesao ? (
          <div style={{ marginTop: 32 }}>
            <Typography>
              Pontos para Adesão: {data.pontosAdesao} pontos
            </Typography>
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          {!data.flgParticipa ? (
            <Button
              disabled={isLoading}
              color="primary"
              onClick={() => handleAceitar()}
            >
              Confirmar cadastro
            </Button>
          ) : null}
        </div>
      </>
      }

    </Box>
  );
}

export default TabSobre;
