import { useContext, useEffect, useState } from "react";
import { Produto } from "../types";
import { httpCache } from "infra/axios";
import { EventoContext } from "../EventoContext";

export default function useCardapio() {
  const [data, setData] = useState<Produto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { value } = useContext(EventoContext);

  useEffect(() => {
    (async () => {
      if (!value.tipoEventoId) {
        setData([]);
        return;
      }

      setIsLoading(true);

      try {
        const { data: items } = await httpCache.get(
          "v2/eventos/mont/cardapios-by-tipoevento",
          {
            params: {
              tipoEventoId: value.tipoEventoId,
            },
          }
        );
 
        setData(items);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [value.tipoEventoId]);

  return {
    data,
    isLoading,
  };
}
