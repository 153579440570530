import React, { useCallback, useMemo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Spinner,
  Label,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import useProduto from "actions/cardapio/useProduto";
import LabelTotal from "../../../../../components/Produto/LabelTotal";

import RenderVariacaoItem from "../variacao/RenderVariacaoItem";
import RangeQuantidade from "../variacao/RangeQuantidade";
import RenderPrecoUnico from "../variacao/RenderPrecoUnico";
import RenderPrecoItem from "../variacao/RenderPrecoItem";

import { Description, ImagemStyled } from "./styles";

function ModalProduto({ open, onToggle, onSaved, menu, item }) {
  const {
    handleAdicionar,
    changeVariacao,
    handleChange,
    podeAdicionarOUAtualizarProduto,
    error,
    isLoading,
    produto,
    selecionarPreco,
  } = useProduto(open, onSaved, menu, item);

  const getTamanhoSelecionado = useMemo(() => {
    const tamanho = (produto.precos ?? []).find((x) => x.selected) || {};
    return tamanho.tamanhoId;
  }, [produto]);

  const renderLista = useCallback(
    (items) => {
      items = items || [];

      return (
        <div>
          {items.map((preco, key) => (
            <RenderPrecoItem
              onClick={() =>
                podeAdicionarOUAtualizarProduto &&
                selecionarPreco(preco, produto)
              }
              item={preco}
              key={key}
            />
          ))}
        </div>
      );
    },
    [podeAdicionarOUAtualizarProduto, selecionarPreco, produto]
  );

  return (
    <Modal size="lg" isOpen={open} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>{produto.nomeCompleto}</ModalHeader>
      <ModalBody>
        {isLoading && <Spinner />}

        <Row style={{ overflow: "hidden", marginBottom: 20 }}>
          <Col sm={12}>
            {produto.imagem ? (
              <ImagemStyled>
                <img src={produto.imagem} alt={produto.nomeCompleto} />
              </ImagemStyled>
            ) : null}

            <Description>
              <p>{produto.textoInformativo}</p>

              <div>
                <h3
                  className="preco-display"
                  style={{
                    fontWeight: "bold",
                    paddingTop: 10,
                    paddingLeft: 10,
                    float: "left",
                  }}
                >
                  {(produto.precos || []).length > 1 ? (
                    renderLista(produto.precos)
                  ) : (
                    <RenderPrecoUnico item={produto} />
                  )}
                </h3>

                {podeAdicionarOUAtualizarProduto &&
                produto.precos?.length &&
                produto.precos[0].valorPreco > 0 ? (
                  <div>
                    <div
                      className="quantidade"
                      style={{
                        float: "left",
                        marginTop: 14,
                        marginLeft: 12,
                        marginBottom: 6,
                      }}
                    >
                      <RangeQuantidade
                        min={0}
                        max={9999}
                        value={produto.quantidade}
                        onChange={(value) => handleChange("quantidade", value)}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </Description>
          </Col>
        </Row>

        <Row>
          {(produto.variacoes || []).map((variacao) => (
            <RenderVariacaoItem
              item={variacao}
              readonly={!podeAdicionarOUAtualizarProduto}
              tamanhoVariacaoProdutoId={getTamanhoSelecionado}
              onChange={(variacao) => changeVariacao(variacao)}
            />
          ))}
        </Row>

        {podeAdicionarOUAtualizarProduto ? (
          <FormGroup style={{ marginTop: 12 }}>
            <Label>Observação</Label>
            <Input
              style={{ marginBottom: 6 }}
              type="textarea"
              min={1}
              onChange={(ev) => handleChange("observacao", ev.target.value)}
              value={produto.observacao}
              label="Observação"
              placeholder="Por exemplo: Retirar cebola, tirar tomate, etc..."
            />
          </FormGroup>
        ) : null}

        <div>
          <h3 style={{ fontWeight: "bold", float: "right" }}>
            <LabelTotal produto={produto} />
          </h3>
        </div>

        {error && (
          <div
            style={{
              padding: 16,
              marginTop: 10,
              clear: "both",
              background: "rgba(255, 0, 0, 0.04)",
              color: "red",
            }}
          >
            {error}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onToggle}>
          Fechar
        </Button>
        {podeAdicionarOUAtualizarProduto ? (
          <Button
            disabled={isLoading}
            color="success"
            onClick={() => handleAdicionar()}
          >
            {produto.uuid ? "Salvar" : "Adicionar"}
          </Button>
        ) : null}
      </ModalFooter>
    </Modal>
  );
}

export default ModalProduto;
