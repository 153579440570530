import React, { useMemo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useToasts } from "react-toast-notifications";
import { formatMoney } from "infra/utils";
import CurrencyInput from 'react-currency-input';
import { calcularValorTotal, getRetirarNoLocal } from "services/cart";
import AuthClient from "infra/auth/AuthClient";

function ModalTroco({ open, forceUpdate, onClose, onOk, valorPagamento, setValorPagamento }) {
  const { addToast } = useToasts();

  const validar = () => {
    if (!valorPagamento) {
      return addToast("Informe o valor", {
        appearance: "error",
        autoDismiss: true
      });
    }

    onOk();
  };

  const value = useMemo(() => {

    if (valorPagamento) {
      return valorPagamento.toString().replace('.', ',');
    }

    return '';

  }, [valorPagamento]);


  const authData = AuthClient.getData() || {};
  const endereco = authData.endereco || { taxaEntrega: 0 };

  const valorTotal = calcularValorTotal() + ( getRetirarNoLocal() ? 0 : endereco.taxaEntrega);


  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title"> Quanto vai pagar em dinheiro?</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }}>
            O valor total do pedido é <strong>R$ {formatMoney(valorTotal)}</strong>.
            Insira no campo abaixo quanto vai pagar em dinheiro para enviarmos seu troco.
          </DialogContentText>

          <div style={{ textAlign: 'center' }}>

            <CurrencyInput
              value={value}
              decimalSeparator=","
              thousandSeparator="."
              placeholder="0,00 "
              style={{
                textAlign: "right"
              }}
              onChangeEvent={(values, val, floatValue) => {
                setValorPagamento(floatValue);
                forceUpdate();
              }}
            />

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            Cancelar
          </Button>
          <Button variant="contained" onClick={() => validar()} color="primary">
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalTroco;
