/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import AuthClient from "infra/auth/AuthClient";
import ModalTrocarTelefone from "./ModalTrocarTelefone";
import { LayoutContext } from "contexts/layout";
import { useContext } from "react";

function ModalConfirmarSMS({ open, onSaved, dadosUsuario, onCancel }) {
  const [isLoading, setIsLoading] = useState(false);
  const [autorizacaoTrocarTelefone, setAutorizacaoTrocarTelefone] =
    useState(null);
  const [telefoneTrocado, setTelefoneTrocado] = useState(null);
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [openModalTrocarTelefone, setOpenModalTrocarTelefone] = useState(false);
  const [error, setError] = useState();
  const [form, setForm] = useState({});
  const { update: forceUpdate } = useContext(LayoutContext);

  const handleConfirmarSMS = useCallback(async () => {
    if (!form.codigoSMS) return alert("Informe o código do SMS!");

    setIsLoading(true);

    try {
      if (autorizacaoTrocarTelefone) {
        await AuthClient.validarSMSTrocandoTelefone({
          novoTelefone: telefoneTrocado,
          codigoSms: form.codigoSMS,
          autorizacao: autorizacaoTrocarTelefone,
        });
      } else {
        await AuthClient.confirmarSMS(form);
      }

      setIsLoading(false);

      onSaved();

      forceUpdate();
    } catch (e) {
      setIsOpenToast(true);
      let errorText = "";

      if (typeof e === "object" && e.response) {
        errorText = e.response.message;
      }

      setError(errorText);
    } finally {
      setIsLoading(false);
    }
  }, [form, autorizacaoTrocarTelefone, onSaved, forceUpdate, telefoneTrocado]);

  const reenviarSMS = useCallback(async () => {
    setIsLoading(true);

    try {
      const telefone = dadosUsuario.telefone;

      await AuthClient.reenviarSMS({
        telefone: telefone,
      });

      setIsLoading(false);
    } catch (e) {
      setIsOpenToast(true);
      let errorText = "Houve um problema ao realizar esta operação.";

      if (typeof e === "object" && e.response) {
        errorText = e.response.message;
      }

      setError(errorText);
    } finally {
      setIsLoading(false);
    }
  }, [dadosUsuario]);

  const handleChange = useCallback(
    (prop, value) => {
      setForm({
        ...form,
        [prop]: value,
      });
    },
    [form]
  );

  return (
    <div>
      <ModalTrocarTelefone
        open={openModalTrocarTelefone}
        onCancel={onCancel}
        onSaved={(result) => {
          setAutorizacaoTrocarTelefone(result.autorizacao);
          setTelefoneTrocado(result.telefone);
          setOpenModalTrocarTelefone(false);
        }}
      />

      <Modal isOpen={open}>
        <ModalHeader>Confirmar SMS</ModalHeader>

        <ModalBody>
          <Alert color="success">
            Enviamos um código SMS, use-o para confirmar seu cadastro!
          </Alert>

          <Form>
            <FormGroup>
              <Label>Código SMS</Label>
              <Input
                type="text"
                value={form.codigoSMS}
                required
                onChange={(ev) => handleChange("codigoSMS", ev.target.value)}
                placeholder="Código SMS"
              />
            </FormGroup>

            <div>
              <a
                style={{ cursor: "pointer", color: "#007bff" }}
                onClick={() => setOpenModalTrocarTelefone(true)}
              >
                {" "}
                Trocar de telefone
              </a>
            </div>
          </Form>

          {isOpenToast && (
            <Alert color="danger">
              {error ||
                "Código ou e-mail não encontrado, solicite um novo código."}
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onCancel}>Deixar para depois</Button>{" "}
          <Button onClick={() => reenviarSMS()}>Reenviar Código</Button>
          <Button
            disabled={isLoading}
            color="primary"
            onClick={() => handleConfirmarSMS()}
          >
            Confirmar Código
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalConfirmarSMS;
