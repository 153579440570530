import styled from "styled-components";

export const Body = styled.div`
  padding-top: 90px;

  @media all and (max-width: 768px) {
    & {
      padding-top: 30px;
    }
  }
`;

export const HorizontalList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0px;

  @media (max-width: 600px) {
    .MuiButton-root {
      width: 100%;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    z-index: 100;
  }
`;
export const Card = styled.div`
  border: 1px solid #ddd;
  padding: 8px 16px;
  cursor: pointer;
  margin: 16px;
  border-radius: 8px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 2px solid #ddd;
  background-color: ${(props) => (props.isSelected ? "#f8f9fa" : "white")};
  border-color: ${(props) => (props.isSelected ? "red" : "#ddd")};
`;

export const Title = styled.h5`
  margin: 0;
  color: #666;
`;

export const Subtitle = styled.h4`
  margin: 0;
  color: #666;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-self: flex-end;
`;
