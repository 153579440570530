import React from "react";
import Appbar from "../../components/Appbar";
import styled from "styled-components";
import ScrollTop from "../../components/ScrollTop";
import Footer from "../../components/Footer";
import ListaMinhasFormasPagamento from "./components/ListaMinhasFormasPagamento";
import { Container } from "reactstrap";

const Body = styled.div`
  min-height: 100vh;
  padding-top: 90px;
`;

function MinhasFormasPagamentoPage() {
  return (
    <Body>
      <Appbar />

      <Container style={{ maxWidth: 768, paddingTop: 32 }}>
        <h2 style={{ textAlign: "center" }}>Minhas formas de pagamento</h2>

        <ListaMinhasFormasPagamento />
      </Container>

      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default MinhasFormasPagamentoPage;
