import React, { useContext } from "react";
import styled from "styled-components";
import {
  Button,
  Alert,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import { LayoutContext } from "contexts/layout";
import axios from "axios";
import { BASE_URL_API } from "infra/config";
import QuestaoObjetiva from "./QuestaoObjetiva";
import { withToastManager } from "react-toast-notifications";
import { navigate } from "infra/router/history";
import { useState } from "react";

const SliderMainRoot = styled.div`
  width: 100%;
  clear: both;
  margin-top: -50px;
  position: relative;
`;

const H2 = styled.h2`
  font-size: 22px;
`;

const H3 = styled.h3`
  font-size: 16px;
`;

const Panel = styled.div`
  padding: 16px;
  background: #efefef;
  clear: both;
  margin: 16px 0;
`;

const SliderMainImage = styled.img`
  width: 100%;
  height: 650px;

  @media screen and (max-width: 768px) {
    height: 450px;
  }

  @media screen and (max-width: 400px) {
    height: 350px;
  }
`;

const SliderMainContainer = styled.div`
  position: relative;
`;

const ContainerButton = styled.div`
  position: absolute;
  left: 50%;
  bottom: -100px;
  transform: translate(-50%);
  background: rgba(0, 0, 0, .2);
  border: 1px solid white;
  padding: 16px;
  max-width: 650px;
  width: 100%;
  z-index: 1;

  h3 {
    font-style: italic;
  }

  .inner {
    padding: 32px 16px;
    background: white;
    text-align: center; 

    p {
      margin: 6px 0;
    }

    .inner-p {
      padding: 16px;
    }
  }
`;

const ButtonSlider = styled(Button)`
  background-color: rgb(226, 57, 47);
  color: white;
  border: none;
  padding: 16px 32px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;

`;

function SliderMain() {

  const [open, setOpen] = useState(false);

  const { layoutData } = useContext(LayoutContext);

  return (
    <SliderMainRoot>
      <SliderMainContainer>
        <SliderMainImage src={layoutData.config.imagemCabecalhoUrl} />

        {layoutData.flgWGAtivoDeliverySeguranca ? (
          <ContainerButton>
            <div className="inner">
              {/* {!layoutData.flgWGAtivoDeliverySeguranca ? (
                    <ButtonSlider onClick={() => setOpen(true)}>
                      Faça uma avaliação
                    </ButtonSlider>
                  ) : null} */}

              <div>

                <h3>Delivery</h3>

                <div className="inner-p">
                  <p>Conheça Nosso Cardápio, monte seu pedido e receba em sua casa.</p>
                </div>

                <ButtonSlider onClick={() => navigate(`cardapio`)}>
                  FAZER PEDIDO
              </ButtonSlider>
              </div>
            </div>
          </ContainerButton>
        ) : null}


        <ModalAvaliacao
          empresaId={layoutData.empresaBaseId}
          open={open}
          onClose={() => setOpen(false)}
        />
      </SliderMainContainer>
    </SliderMainRoot>
  );
}

const ModalAvaliacao = withToastManager(
  class ModalAvaliacao extends React.Component {
    state = {
      loading: false,
      atual: 0,
      cliente: {},
      avaliacao: [],
      resposta: {}
    };

    async componentDidMount() {
      if (this.props.open) {
        await this.fetchData();
      }
    }

    async componentDidUpdate(prevProps) {
      if (this.props.open !== prevProps.open && this.props.open) {
        await this.fetchData();
      }
    }

    fetchData = async () => {
      const { empresaId } = this.props;

      this.setState({ loading: true });

      const responseFeedback = await axios.get(
        BASE_URL_API + "/v1/feedback/" + empresaId
      );

      const data = responseFeedback.data[0];

      const responseFeedbackQuestoes = await axios.get(
        BASE_URL_API + "/v1/feedback/" + data.id + "/questionario/detalhado"
      );

      data.questoes = responseFeedbackQuestoes.data;

      this.setState({ loading: false, avaliacao: data });
    };

    salvarResposta = async () => {
      const { resposta, avaliacao, cliente } = this.state;

      this.setState({ loading: true });

      try {
        let respostas = [];

        for (let item in resposta) {
          respostas.push(resposta[item].resposta);
        }

        await axios.post(BASE_URL_API + `/v1/feedback/`, {
          feedBackId: avaliacao.id,
          flgAnonimo: true,
          nome: cliente.nome,
          email: cliente.email,
          telefone: cliente.telefone,
          pessoaGlobalId: null,
          observacaoFinal: avaliacao.observacaoFinal,
          flgStatus: "a",
          feedBackRespostaQuestionario: respostas
        });

        // alert("Operação realizada com sucesso!");
        this.props.toastManager.add("Operação realizada com sucesso!", {
          appearance: "success",
          autoDismiss: true
        });

        this.onClose();
      } catch (e) {
        this.props.toastManager.add(
          "Houve um problema ao realizar a operação",
          {
            appearance: "error",
            autoDismiss: true
          }
        );
      } finally {
        this.setState({ loading: false });
      }
    };

    onClose = () => {
      this.setState({
        atual: 0
      });

      this.props.onClose();
    };

    render() {
      const { open } = this.props;
      const { loading, atual, cliente, avaliacao, resposta } = this.state;
      const onClose = this.onClose.bind(this);
      const setCliente = value => this.setState({ cliente: value });
      const setResposta = value => this.setState({ resposta: value });
      const setAvaliacao = value => this.setState({ avaliacao: value });
      const setAtual = value => this.setState({ atual: value });

      const salvarResposta = () => this.salvarResposta();

      let questoes = [];

      if (avaliacao && avaliacao.questoes) {
        questoes = avaliacao.questoes || [];
      }

      const total = questoes.length;

      return (
        <Modal backdrop size="lg" isOpen={open} toggle={onClose}>
          <ModalHeader toggle={onClose}>Avaliação</ModalHeader>
          <ModalBody>
            {loading && (
              <Spinner
                style={{
                  width: "3rem",
                  display: "table",
                  margin: "auto",
                  height: "3rem"
                }}
                type="grow"
              />
            )}

            {!loading ? (
              <Alert color="primary">
                Você precisa selecionar uma resposta antes de avançar.
              </Alert>
            ) : null}

            <H2>{avaliacao.nome}</H2>
            {questoes.map((questao, key) => (
              <Panel
                style={{
                  display: key === atual ? "block" : "none",
                  overflow: "hidden"
                }}
                key={key}
              >
                <H3> {questao.pergunta}</H3>

                <QuestaoObjetiva
                  questao={questao}
                  name={"questionario" + questao.id}
                  value={resposta[questao.id] && resposta[questao.id].resposta}
                  onChange={res => {
                    const _resposta = {
                      respostaId: 0,
                      opcaoId: res.id,
                      respostaTexto: res.respostaTexto,
                      questionarioId: questao.id
                    };

                    resposta[questao.id] = resposta[questao.id] || {
                      ...questao
                    };

                    resposta[questao.id].resposta = _resposta;

                    setResposta({ ...resposta });
                  }}
                />

                {key === questoes.length - 1 ? (
                  <div
                    style={{
                      marginTop: 40,
                      float: "left",
                      width: "100%",
                      clear: "both",
                      marginBottom: 20
                    }}
                  >
                    <FormGroup>
                      <Label>Observação Final:</Label>
                      <Input
                        type="textarea"
                        onChange={e => {
                          setAvaliacao({
                            ...avaliacao,
                            observacaoFinal: e.target.value
                          });
                        }}
                        value={avaliacao.observacaoFinal}
                        rows={4}
                        placeholder="Observação.."
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Seu nome:</Label>
                      <Input
                        type="text"
                        onChange={e => {
                          setCliente({
                            ...cliente,
                            nome: e.target.value
                          });
                        }}
                        value={cliente.nome}
                        placeholder="Nome..."
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Seu Telefone:</Label>
                      <Input
                        type="tel"
                        onChange={e => {
                          setCliente({
                            ...cliente,
                            telefone: e.target.value
                          });
                        }}
                        value={cliente.telefone}
                        placeholder="Telefone..."
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Seu Email:</Label>
                      <Input
                        type="email"
                        onChange={e => {
                          setCliente({
                            ...cliente,
                            email: e.target.value
                          });
                        }}
                        value={cliente.email}
                        placeholder="Email..."
                      />
                    </FormGroup>
                  </div>
                ) : null}
              </Panel>
            ))}

            {atual > 0 ? (
              <Button
                color="secondary"
                style={{ float: "left" }}
                outline
                onClick={() => {
                  setAtual(atual - 1);
                }}
              >
                Voltar
              </Button>
            ) : null}

            {atual < total - 1 ? (
              <Button
                color="primary"
                style={{ float: "right" }}
                outline
                disabled={!resposta[questoes[atual].id]}
                onClick={() => setAtual(atual + 1)}
              >
                Próxima
              </Button>
            ) : null}

            {atual === total - 1 ? (
              <Button
                color="success"
                style={{ float: "right" }}
                disabled={!resposta[questoes[atual].id]}
                onClick={() => salvarResposta()}
              >
                Salvar
              </Button>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  }
);

export default SliderMain;
