import React, { useContext } from "react";
import styled from "styled-components";
import { CardImg, CardTitle, CardSubtitle } from "reactstrap";
import { formatMoney } from "infra/utils";
import { LayoutContext } from "contexts/layout";

const CardStyled = styled.div`
  float: left;
  width: 100%;
  clear: both;
  background: white;
  min-height: 200px;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 16px 4px;
  cursor: pointer;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);

  @media all and (max-width: 768px) {
    & {
      height: auto;
    }
  }
`;

const CardBodyStyled = styled.div`
  float: left;
  flex: 1;
  padding: 8px 8px;
  .preco {
    float: left;
    font-size: 16px;

    .desconto {
      text-decoration: line-through;
    }
  }
  .button {
    float: right;
  }
`;

const CardImgStyled = styled.div`
  float: right;
  margin-right: 16px;
  img {
    border-radius: 6px;
    width: 180px;
    height: 166px;
  }

  @media all and (max-width: 768px) {
    img {
      width: 100px;
      height: 90px;
    }
  }
`;

const CardTitleStyled = styled(CardTitle)`
  & {
    padding: 8px 0;
    color: ${(props) => props.color || "#212529"};
    font-weight: bold;
    line-height: 16px;
    p {
      margin-bottom: 0;
    }
  }

  @media all and (max-width: 768px) {
    & {
      padding: 4px 0;
      /* margin-bottom: 8px !important; */
      line-height: auto;
    }
  }
`;

const TextoInfo = styled.div`
  padding: 8px 0;
  color: ${(props) => props.color || "#212529"};
`;

function Produto({ item, onClick }) {
  const { layoutData } = useContext(LayoutContext);

  return (
    <React.Fragment>
      <CardStyled onClick={onClick}>
        {item.imagem ? (
          <CardImgStyled>
            <CardImg top src={item.imagem} alt={item.nomeCompleto} />
          </CardImgStyled>
        ) : null}

        <CardBodyStyled>
          <CardTitleStyled color={layoutData.cardapioCorNomeProduto}>
            <p>{item.nomeCompleto}</p>
          </CardTitleStyled>
          {!!item.textoInformativo ? (
            <TextoInfo color={layoutData?.cardapioCorTextoInfo}>
              <p className="text-overflow">{item.textoInformativo}</p>
            </TextoInfo>
          ) : null}
          {!!item.preco && item.flgDisponivel ? (
            <CardSubtitle
              style={{
                position: "absolute",
                bottom: "10px",
              }}
            >
              <div className="preco">
                {item.flgEmFalta ? (
                  "Em Falta!"
                ) : (
                  <div>
                    <TextDesconto item={item} />
                    {item.flgPrecoVariavel ? `A partir de` : null}{" "}
                    <b>R$ {formatMoney(item.preco)}</b>
                  </div>
                )}
              </div>
            </CardSubtitle>
          ) : null}
        </CardBodyStyled>
      </CardStyled>
    </React.Fragment>
  );
}

export function TextDesconto({ item }) {
  if (item.valorPrecoNormal <= item.preco) {
    return null;
  }

  return (
    <span>
      <span style={{ fontSize: 12 }}> de </span>
      <span className="desconto">{formatMoney(item.valorPrecoNormal)}</span>
      <span style={{ fontSize: 12 }}> por </span>
    </span>
  );
}

export default Produto;
