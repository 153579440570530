import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

function SkeletonItem() {
  return (
    <div>
      <Skeleton height={40} width="20%" variant="text" />

      <Skeleton width="60%" height={40} variant="text" />
      
      <Skeleton height={40} variant="text" />
    </div>
  );
}

export default SkeletonItem;
