import React, { useContext } from "react";
import { LayoutContext } from "contexts/layout";

import LayoutDefault from "./default";
import LayoutWebGula from "./webgula";

const layouts = {
  default: LayoutDefault,
  webgula: LayoutWebGula,
};

const LayoutProviderComponent = () => {
  const { layoutData } = useContext(LayoutContext);

  const { layout, ...outerProps } = layoutData;

  if (!layout) {
    return <div />;
  }

  let ComponentLayout = layouts[layout];

  if (!ComponentLayout) {
    ComponentLayout = layouts.default;
  }

  return <ComponentLayout {...outerProps} />;
};

export default LayoutProviderComponent;
