/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from "react";
import { CardContent, Card, Typography } from "@material-ui/core";

import { LayoutContext } from "contexts/layout";
import { navigate } from "infra/router/history";
import ModalDetalhesFidelidade from "./ModalDetalhesFidelidade";

function MenuFidelidade() {
  const { layoutData } = useContext(LayoutContext);
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {openModal ? (
        <ModalDetalhesFidelidade
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      ) : null}
      <Card
        onClick={() => {
          if (!layoutData.fidelidadeFlgParticipa) {
            setOpenModal(true);
          } else {
            navigate("fidelidade", true);
          }
        }}
      >
        <CardContent
          style={{
            paddingBottom: 16,
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          {layoutData.fidelidadeFlgParticipa ? (
            <MenuFidelidadeSaldo saldo={layoutData.fidelidadeSaldoAtualConta} />
          ) : null}

          {!layoutData.fidelidadeFlgParticipa ? (
            <Typography>Conheça nosso plano de fidelidade</Typography>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
}

function MenuFidelidadeSaldo({ saldo }) {
  return (
    <div>
      <div style={{ fontSize: 12, color: "#999" }}>FIDELIDADE</div>
      <div style={{ fontWeight: "bold", fontSize: 22 }}>{saldo}</div>
      <div style={{ fontSize: 12, color: "#999" }}>pontos</div>
    </div>
  );
}

export default MenuFidelidade;
