/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import iphoneImage from "../images/iphone.png";
import appleStoreImage from "../images/FrameAppStore.png";
import googlePlayImage from "../images/FrameGooglePlay.png";

const SliderMainRoot = styled.div`
	width: 100%;
	clear: both; 
	
	position: relative;
	z-index: 0;

	.slide-container {
		width: 70%;
		margin: auto;
	}

	h3 {
		text-align: center;
	}

	.each-slide > div {
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
	}

	.each-slide span {
		padding: 20px;
		font-size: 20px;
		background: #efefef;
		text-align: center;
	}

	.each-fade {
		display: flex;
	}

	.each-fade .image-container {
		width: 75%;
		overflow: hidden;
	}

	.each-fade .image-container img {
		width: 100%;
	}

	.each-fade h2 {
		width: 25%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		background: #adceed;
	}

	@media all and (max-width: 768px) {
		.each-slide > div {
			background-size: 100%;
			background-repeat: no-repeat;
			height: 200px !important;
		}
	}
`;

const SliderMainContainer = styled.div`
	position: relative; 
`;

const BaseGradient = styled.div`
	margin-top: 80px;
	padding-top: 20px;
	height: 472px; /*calc(100vh - 100px);*/
	display: flex;
	justify-content: center;
	background: linear-gradient(360deg, #880A0A 22.25%, #E3382D 100%);
	overflow: hidden;
`;

const ImagesContainer = styled.div`  
	display: flex;
	flex: 1;
	flex-direction: row; 
	
	@media all and (max-width: 768px) {
		flex-direction: column;
		display: block;
	} 
`

const HeaderText = styled.h1` 

	font-family: Cabin;
	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	line-height: 64px; 

	color: #FFFFFF; 
	
	@media all and (max-width: 768px) {
		font-size: 24px;
		line-height: 24px; 
		text-align: center;
	}
`

const ContainerText = styled.div`
	flex: 2;
	flex-direction: column;
	display: flex;
	justify-content: center;

	@media all and (max-width: 768px) {
		display: block;
		width: 100%;
		flex: auto;
		text-align: center;

		img {
			margin-bottom: 16px;
			margin-right: 0 !important;
		}
	}
`;

const ContainerImageRight = styled.div`
	display: flex; 
	flex: 1;
	align-items: flex-end; 
	justify-content: center;

	img {
		margin-top: 48px;
	}
	@media all and (max-width: 768px) {
		display: block;
		width: 100%;
		flex: auto;
		text-align: center;

		img {
			margin-top: 0px;
			max-height: 250px
		}
	}

`;

class SliderMain extends React.PureComponent {

	oldRender = () => {
		return (
			<SliderMainRoot>
				<SliderMainContainer>
					{/* <Slideshow /> */}
					<BaseGradient>

					</BaseGradient>
				</SliderMainContainer>
			</SliderMainRoot>
		);
	};

	render() {
		return (
			<BaseGradient>
				<Container>
					<ImagesContainer>
						<ContainerText>
							<HeaderText style={{ color: 'white', margin: 16 }}>Baixe o aplicativo e tenha acesso a recursos <span style={{ color: '#F6B215' }}>exclusivos</span></HeaderText>
							<div style={{ margin: 16 }}>
								<a target="_blank" href="https://play.google.com/store/apps/details?id=com.tactonuvem.webgula">
									<img src={appleStoreImage} style={{ marginRight: 16 }} />
								</a>
								<a target="_blank" href="https://apps.apple.com/us/app/webgula/id1462601021?l=pt&ls=1">
									<img src={googlePlayImage} />
								</a>
							</div>
						</ContainerText>
						<ContainerImageRight>
							<img src={iphoneImage} />
						</ContainerImageRight>
					</ImagesContainer>
				</Container>
			</BaseGradient>
		)
	}
}

export default SliderMain;
