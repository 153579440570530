/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styled from 'styled-components';
import HeaderTopo from './components/HeaderTopo';
import Corpo from './components/Corpo';
import PerguntasFrequentes from './components/PerguntasFrequentes';

const Wrapper = styled.div` 
  margin-bottom: 32px;
`

function SejaNossoParceiroPage() {

  return (
    <Wrapper>

      <HeaderTopo />

      <Corpo />

      <PerguntasFrequentes />

    </Wrapper>
  )
}

export default SejaNossoParceiroPage;