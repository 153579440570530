import Axios from "axios";
import { BASE_URL_API } from "infra/config";

export async function realizarReserva(reserva) {
  const { data } = await Axios.post(
    `${BASE_URL_API}/v2/eventos/mont/evento-reserva`,
    reserva
  );

  return data;
}
