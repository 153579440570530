import React, { useContext } from "react";
import {
  Collapse,
  Nav,
  NavItem,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import Badge from "@material-ui/core/Badge";

import AuthClient from "infra/auth/AuthClient";
import history, { navigate } from "infra/router/history";
import { getAllCart, checkoutCart } from "services/cart";

import { LinkText } from "./styles";
import { LayoutContext } from "contexts/layout";

import { ButtonStyled } from "../../pages/components/styles";

function CollapseMenu({
  setOpenModal,
  setOpenModalPerfil,
  setOpenModalCadastro,
  setOpenModalTelefone,
  isOpen,
}) {
  const { layoutData, update: updateContext } = useContext(LayoutContext);
  const isUserLogged = AuthClient.isUserLogged();
  const authData = AuthClient.getData() || {};

  // const isPedidoMenu = !!layoutData.contaAtualMenuDto;
  const itemsDelivery = getAllCart();

  const count = itemsDelivery.length;

  const [nome] = (authData.nome || "").split(" ");

  const displayCart = isUserLogged || layoutData.flgMenuPedidoSimplesOk;

  return (
    <Collapse style={{ backgroundColor: "#FFFFFF" }} isOpen={isOpen} navbar>
      <Nav className="ml-auto" navbar>
        {(layoutData.menu || []).map((item, key) => (
          <NavItem key={key}>
            <LinkText href={item.action}>{item.text}</LinkText>
          </NavItem>
        ))}

        {isUserLogged ? (
          <div className="user-menu">
            <Row>
              <Col sm={12}>
                <div
                  style={{
                    float: "right",
                    marginLeft: 8,
                  }}
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      style={{
                        background: "transparent",
                        marginTop: -4,
                        color: "#333",
                        border: "none",
                      }}
                    >
                      <i className="fa fa-user-o" />

                      <span style={{ paddingLeft: 8 }}> Perfil </span>
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                      <div style={{ width: 220, padding: 16, marginBottom: 8 }}>
                        <strong> Olá, {nome}</strong>
                      </div>
                      <DropdownItem
                        style={{ marginBottom: 6, fontSize: ".9em" }}
                        onClick={() => setOpenModalPerfil(true)}
                      >
                        <i
                          className="fa fa-user-o"
                          style={{
                            paddingRight: 16,
                            textAlign: "center",
                            width: 40,
                          }}
                        />{" "}
                        Meu Perfil
                      </DropdownItem>
                      <DropdownItem
                        style={{ marginBottom: 6, fontSize: ".9em" }}
                        onClick={() => setOpenModalTelefone(true)}
                      >
                        <i
                          className="fa fa-phone"
                          style={{
                            paddingRight: 16,
                            textAlign: "center",
                            width: 40,
                          }}
                        />{" "}
                        Alterar telefone
                      </DropdownItem>
                      <DropdownItem
                        style={{ marginBottom: 6, fontSize: ".9em" }}
                        onClick={() =>
                          history.push(
                            `/${layoutData.tenant}/minhas-formas-pagamento`
                          )
                        }
                      >
                        <i
                          className="fa fa-money"
                          style={{
                            paddingRight: 16,
                            textAlign: "center",
                            width: 40,
                          }}
                        />{" "}
                        Formas de pagamento
                      </DropdownItem>
                      <DropdownItem
                        style={{ marginBottom: 6, fontSize: ".9em" }}
                        onClick={() => {
                          updateContext({
                            openModalEndereco: true,
                          });
                        }}
                      >
                        <i
                          className="fa fa-map-marker"
                          style={{
                            paddingRight: 16,
                            textAlign: "center",
                            width: 40,
                          }}
                        />{" "}
                        Endereços
                      </DropdownItem>
                      <DropdownItem
                        style={{ marginBottom: 6, fontSize: ".9em" }}
                        onClick={() =>
                          history.push(
                            `/${layoutData.tenant}/historico-pedidos`
                          )
                        }
                      >
                        <i
                          className="fa fa-picture-o"
                          style={{
                            paddingRight: 16,
                            textAlign: "center",
                            width: 40,
                          }}
                        />{" "}
                          Meus Pedidos - Delivery
                      </DropdownItem>
                      <DropdownItem
                        style={{ marginBottom: 6, fontSize: ".9em" }}
                        onClick={() =>
                          history.push(
                            `/${layoutData.tenant}/historico-pedidos-menu`
                          )
                        }
                      >
                        <i
                          className="fa fa-picture-o"
                          style={{
                            paddingRight: 16,
                            textAlign: "center",
                            width: 40,
                          }}
                        />{" "}
                          Meus Pedidos - Menu
                      </DropdownItem>
                      <DropdownItem
                        style={{ marginBottom: 6, fontSize: ".9em" }}
                        onClick={() => {
                          AuthClient.logout();
                          checkoutCart();
                          window.location.reload();
                        }}
                      >
                        <i
                          className="fa fa-sign-out"
                          style={{
                            paddingRight: 16,
                            textAlign: "center",
                            width: 40,
                          }}
                        />
                        Sair
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}

        {displayCart ? (
          <NavItem>
            <LinkText
              style={{ cursor: "pointer" }}
              onClick={() => navigate("carrinho")}
            >
              <Badge overlap="circle" badgeContent={count} color="secondary">
                <i
                  style={{ fontSize: "1em" }}
                  className="fa fa-shopping-cart"
                />
              </Badge>
              <span style={{ paddingLeft: 8 }}> Carrinho </span>
            </LinkText>
          </NavItem>
        ) : null}

        {!isUserLogged ? (
          <div
            className="user-menu"
            style={{ marginTop: -6, paddingBottom: 8 }}
          >
            <ButtonStyled
              float="left"
              style={{
                color: "#111",
                border: "1px solid #ccc",
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 24,
                paddingRight: 24,
              }}
              onClick={() => setOpenModalCadastro()}
            >
              Criar conta
            </ButtonStyled>

            <ButtonStyled
              float="left"
              style={{
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 24,
                paddingRight: 24,
                textTransform: "normal",
                background: layoutData.primaryColor,
              }}
              onClick={() => setOpenModal()}
            >
              Entrar
            </ButtonStyled>
          </div>
        ) : null}
      </Nav>
    </Collapse>
  );
}

export default CollapseMenu;
