import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  calcularValorTotal,
  getDesconto,
  getOrigem,
  getRetirarNoLocal,
  getTaxaEntrega,
  getVoucher,
  setDesconto,
  setTaxaEntrega,
  setVoucher,
} from "services/cart";

import { formatMoney } from "infra/utils";
import AuthClient from "infra/auth/AuthClient";

import BoxEndereco from "./BoxEndereco";
import BoxDesconto from "./BoxDesconto";
import { getTaxaEntregaEndereco } from "services/pedido";
import useCookies from "infra/hooks/useCookies";
import { LayoutContext } from "contexts/layout";

function ProdutoTotais() {
  const [voucherLocal, setVoucherLocal] = useState(null);
  const [descontoLocal, setDescontoLocal] = useState(0);
  const [taxaEntregaLocal, setTaxaEntregaLocal] = useState(0);
  const { layoutData  } = useContext(LayoutContext);

  const origem = getOrigem();
  const valorTotalProdutos = calcularValorTotal();
  const retirarNoLocal = getRetirarNoLocal();
  const { getCookie } = useCookies();
  const authData = AuthClient.getData();
  const isUserLogged = AuthClient.isUserLogged();
  const isPedidoOff =!isUserLogged && !!getCookie("idPedidoOff") && !!getCookie("chavePedidoOff") && layoutData?.wgPedidoDeliveryOFF;
  const pontoEntrega = JSON.parse(localStorage.getItem("pontoEntrega"));
  const isPontoEntrega = !!localStorage.getItem("pontoEntrega");
  const isPedidoComTaxaDeServico = layoutData?.flgPedidoMenuComissionado && origem === "menu" && !retirarNoLocal;

  const updateVoucherValues = useCallback(async () => {
    if (isPontoEntrega) {
      return;
    }

    const endereco = authData?.endereco || {};

    const regiao = await getTaxaEntregaEndereco(
      isPedidoOff ? null : endereco?.id,
      valorTotalProdutos,
      isPedidoOff ? getCookie("idPedidoOff") : null
    );
    setTaxaEntregaLocal(regiao?.taxaEntrega);
    let valorTaxaEntrega = endereco?.taxaEntrega ?? null;
    let valorDesconto = 0;

    // Desconto em reais
    if (voucherLocal?.tipoId === 1) {
      valorDesconto = voucherLocal.valor;

      // Desconto em porcentagem nos produtos
    } else if (voucherLocal?.tipoId === 2) {
      valorDesconto = (valorTotalProdutos * voucherLocal.valor) / 100;

      // Sugere o valor da taxa de entrega
    } else if (voucherLocal?.tipoId === 3) {
      valorTaxaEntrega = voucherLocal.valor;
      setTaxaEntregaLocal(valorTaxaEntrega);
    }

    setDescontoLocal(valorDesconto);
  }, [authData, isPontoEntrega, isPedidoOff, valorTotalProdutos, getCookie, voucherLocal]);

  useEffect(() => {
    setVoucherLocal(getVoucher());
  }, []);

  useEffect(() => {
    setDescontoLocal(getDesconto());
  }, []);

  useEffect(() => {
    let taxaEntrega = 0;
    if (isPontoEntrega) {
      taxaEntrega = pontoEntrega.taxaEntrega;
    } else if (origem === "menu" || retirarNoLocal) {
      taxaEntrega = 0;
    } else {
      taxaEntrega = getTaxaEntrega() || 0;
    }
    setTaxaEntregaLocal(taxaEntrega);
  }, [origem, retirarNoLocal, isPontoEntrega, pontoEntrega]);

  useEffect(() => {
    setVoucher(voucherLocal);
    setDesconto(descontoLocal);
    setTaxaEntrega(taxaEntregaLocal);
   origem === 'delivery' && updateVoucherValues();
  }, [updateVoucherValues, descontoLocal, voucherLocal, taxaEntregaLocal, origem]);

  // Calcular o valor total incluindo a taxa de serviço se aplicável
  const taxaServico = isPedidoComTaxaDeServico ? 0.10 * valorTotalProdutos : 0;

  return (
    <div style={{ margin: "16px 0", fontSize: "0.9em" }}>
      {origem === "delivery" ? (
        <>
          <BoxEndereco taxaEntrega={taxaEntregaLocal} />
          {isPedidoOff ? null : (
            <BoxDesconto voucher={voucherLocal} setVoucher={setVoucherLocal} />
          )}
        </>
      ) : null}

      {descontoLocal ? (
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ flex: 1 }}>Desconto</div>
          <div>R$ {formatMoney(descontoLocal)}</div>
        </div>
      ) : null}

      {isPedidoComTaxaDeServico ? (
        <div style={{ display: "flex", marginBottom: 8 }}>
          <div style={{ flex: 1 }}>Taxa de serviço: 10%</div>
          <div>R$ {formatMoney(taxaServico)}</div>
        </div>
      ) : null}

      <div
        style={{
          display: "flex",
          border: "1px solid #ccc",
          padding: 8,
          paddingTop: 10,
          marginBottom: 8,
        }}
      >
        <div style={{ flex: 1 }}>
          <strong style={{ fontSize: "18px" }}>Total</strong>
        </div>
        <div>
          <strong style={{ fontSize: "18px" }}>
            R${" "}
            {formatMoney(
              retirarNoLocal || origem === "menu"
                ? valorTotalProdutos - descontoLocal + taxaServico
                : valorTotalProdutos + taxaEntregaLocal - descontoLocal + taxaServico
            )}
          </strong>
        </div>
      </div>
    </div>
  );
}

export default ProdutoTotais;
