import React, { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import { LayoutContext } from "contexts/layout";
import AuthClient from "infra/auth/AuthClient";
import { getEnderecoString } from "infra/utils";
import { BodyStyled } from "./styles";
import { Badge } from "reactstrap";
import CarrinhoCard from "./CarrinhoCard";
import styled from "styled-components";
import { useState } from "react";
import ModalListaAvaliacao from "./ModalListaAvaliacao";
import ModalAvaliacao from "./ModalAvaliacao";
import { getOrigem } from "services/cart";

const ImageHeader = styled.div`
  height: 200px;
  margin-bottom: 8px;
  background: url(${(props) => props.src}) no-repeat;
  background-size: 100%;
  background-attachment: cover;
  background-position: center center;
`;

function HeaderEmpresa({ hideBackground, hideDetails, showCarrinho = true }) {
  const { layoutData } = useContext(LayoutContext);
  const [openModalAvaliacao, setOpenModalAvaliacao] = useState(false);
  const [openModalNovaAvaliacao, setOpenModalNovaAvaliacao] = useState(false);

  const isUserLogged = AuthClient.isUserLogged();

  const origem = getOrigem();

  return (
    <BodyStyled color={layoutData.primaryColor}>
      <ModalListaAvaliacao
        open={openModalAvaliacao}
        onAvaliar={() => {
          setOpenModalAvaliacao(false);
          setOpenModalNovaAvaliacao(true);
        }}
        onClose={() => setOpenModalAvaliacao(false)}
      />

      <ModalAvaliacao
        open={openModalNovaAvaliacao}
        onClose={() => setOpenModalNovaAvaliacao(false)}
      />

      <Container style={{ position: "relative" }}>
        <Row>
          <Col md={9} sm={9} xs={12}>
            <div>
              {!hideBackground ? (
                <div>
                  <ImageHeader src={layoutData.config.imagemCabecalhoUrl} />
                </div>
              ) : null}

              <div>
                <h1>{layoutData.nome} </h1>

                {layoutData.abertoDelivery ? (
                  <Badge pill color="success">
                    Aberto{" "}
                  </Badge>
                ) : (
                  <Badge pill color="secondary">
                    Fechado{" "}
                  </Badge>
                )}
              </div>
            </div>

            {!hideDetails ? (
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            ) : null}

            {!hideDetails ? (
              <div style={{ marginTop: -4 }}>
                <span
                  style={{
                    cursor: "pointer",
                    color: layoutData.primaryColor,
                    fontSize: 13,
                  }}
                  onClick={() => setOpenModalAvaliacao(true)}
                >
                  ver avaliações
                </span>
              </div>
            ) : null}

            {origem !== "menu" ? (
              <div style={{ marginTop: 8, marginBottom: 16 }}>
                <p
                  style={{ margin: 0, color: "#666" }}
                  className="mensagem-padrao"
                >
                  {layoutData.mensagemPadrao}
                </p>
              </div>
            ) : null}

            {origem !== "menu" ? (
              <p style={{ marginTop: 8, marginBottom: 16, color: "#666" }}>
                {getEnderecoString(layoutData)}
              </p>
            ) : null}

            {isUserLogged && origem !== "menu" && !hideDetails ? (
              <div>
                <ul className="lista-caracteristicas">
                  <li>{layoutData.tempoMedioEntregaNome}</li>

                  {layoutData.flgAceitaRetirarPedido ? (
                    <li> • Aceita retirar no local</li>
                  ) : null}
                </ul>
              </div>
            ) : null}
          </Col>

          {showCarrinho && (
            <Col md={3} sm={3} xs={12}>
              <CarrinhoCard />
            </Col>
          )}
        </Row>
      </Container>
    </BodyStyled>
  );
}

export default HeaderEmpresa;
