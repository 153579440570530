/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useContext } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  getEnderecosPessoa,
  setPrincipalEnderecosPessoa,
} from "services/pessoa";
import { Spinner } from "reactstrap";
import ModalCadastroEndereco from "./ModalCadastroEndereco";
import AuthClient from "infra/auth/AuthClient";
import EnderecoCard from "./EnderecoCard";
import { LayoutContext } from "contexts/layout";

function ModalEnderecos({
  open,
  onToggle,
  layoutData,
  onCallbackAfterSelectedPrincipal,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { update: forceUpdate } = useContext(LayoutContext);
  const [openModalCadastro, setOpenModalCadastro] = useState(false);
  const [items, setItems] = useState([]);
  const [itemEdit, setItemEdit] = useState({});

  const loadData = useCallback(async () => {
    setIsLoading(true);
    const authData = AuthClient.getData() || {};
    const data = await getEnderecosPessoa({
      pessoaId: authData.pessoaId,
      empresaId: layoutData.id,
    });
    setIsLoading(false);
    setItems(data);
    forceUpdate({});
  }, [forceUpdate, layoutData.id]);

  useEffect(() => {
    (() => {
      if (!open) {
        return;
      }
      loadData();
    })();
  }, [open]);

  const handleSelectPrincipal = useCallback(
    async (item) => {
      if (!item.flgAceitaEntrega) {
        return alert(
          "Este estabelecimento não aceita entrega para este endereço!"
        );
      }
      setIsLoading(true);

      try {
        await setPrincipalEnderecosPessoa(item.id);
        await loadData();
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    },
    [loadData]
  );

  const enderecoPrincipal = items.find((x) => x.flgPrincipal);

  return (
    <div>
      <ModalCadastroEndereco
        open={openModalCadastro}
        onToggle={() => {
          setItemEdit({});
          setOpenModalCadastro(!openModalCadastro);
        }}
        item={itemEdit}
        onSaved={() => {
          // setOpenModalCadastro(false);
          // setItemEdit({});
          // loadData();
          window.location.reload(); // TODO, remover esse cara e passar a atualizar o endereco do auth
        }}
      />
      <Modal size="lg" isOpen={open} toggle={onToggle}>
        <ModalHeader toggle={onToggle}>Endereço para entrega</ModalHeader>
        <ModalBody>
          <div style={{ marginBottom: 16, overflow: "hidden" }}>
            {isLoading && <Spinner color="primary" />}

            <Button
              style={{ float: "right" }}
              color="success"
              onClick={() => setOpenModalCadastro(true)}
            >
              <i className="fa fa-plus" /> Criar Novo Endereço
            </Button>
          </div>

          <div style={{ clear: "both" }}>
            {items.map((item, key) => (
              <EnderecoCard
                key={key}
                item={item}
                selected={enderecoPrincipal?.id === item.id}
                isLoading={isLoading}
                handleItemEdit={() => {
                  setItemEdit(item);
                  setOpenModalCadastro(true);
                }}
                handleSelectPrincipal={() => {
                  handleSelectPrincipal(item);

                  if (typeof onCallbackAfterSelectedPrincipal === "function") {
                    onCallbackAfterSelectedPrincipal();
                  }
                }}
              />
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onToggle}>
            Sair
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalEnderecos;
