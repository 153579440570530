import React, { useState, useEffect } from "react";
import { Container, Navbar, NavbarToggler, NavbarBrand } from "reactstrap";

import { useLayoutContext } from "contexts/layout";
import AuthClient from "infra/auth/AuthClient";

import ModalCadastro from "../../pages/components/ModalCadastro";
import ModalConfirmarSMS from "../../pages/components/ModalConfirmarSMS";
import ModalEnderecos from "../../pages/components/ModalEnderecos";
import ModalPerfil from "../../pages/components/ModalPerfil";
import ModalLogin from "../../pages/components/ModalLogin";
import ModalTelefone from "../../pages/components/ModalTelefone";

import CarrinhoMobile from "./CarrinhoMobile";
import CollapseMenu from "./CollapseMenu";

import { AppbarRoot, GlobalStyle, ImageLogo } from "./styles";

export function getLink(empresa) {
  if (empresa.tenant === "webgula") {
    return "/";
  }

  return "/" + empresa.tenant;
}

function Appbar() {
  const { layoutData, update: updateContext } = useLayoutContext();
  const [isOpen, setIsOpen] = useState(false);
  const [openModalPerfil, setOpenModalPerfil] = useState(false);
  const [dadosUsuario, setDadosUsuario] = useState({});
  const [openModalConfirmarSMS, setOpenModalConfirmarSMS] = useState(false);
  const [openModalTelefone, setOpenModalTelefone] = useState(false);
  const isUserLogged = AuthClient.isUserLogged();
  const auth = AuthClient.getData();

  const openModalEndereco = !!layoutData?.openModalEndereco;

  useEffect(() => {
    (() => {
      if (isUserLogged && auth) {
        if (!auth["telefone"]) {
          setOpenModalTelefone(true);
        }
        // } else if (auth["phone-confirmed"] === "false") {
        //   setOpenModalConfirmarSMS(true);
        // }
      }
    })();
  }, [auth, isUserLogged]);

  return (
    <AppbarRoot>
      <GlobalStyle primaryColor={layoutData.primaryColor} />

      <Navbar fixed="true" transparent="true" light={true} expand="md">
        <Container>
          <NavbarBrand href={getLink(layoutData)}>
            <ImageLogo
              title={layoutData.nome}
              alt={layoutData.nome}
              src={layoutData.logo}
            />
          </NavbarBrand>

          <CarrinhoMobile />

          <NavbarToggler onClick={() => setIsOpen(!isOpen)} />

          <CollapseMenu
            setOpenModal={() => {
              updateContext({ openLogin: true });
            }}
            setOpenModalTelefone={setOpenModalTelefone}
            setOpenModalPerfil={setOpenModalPerfil}
            setOpenModalCadastro={() => {
              updateContext({ openCadastro: true });
            }}
            setOpenModalConfirmarSMS={setOpenModalConfirmarSMS}
            isOpen={isOpen}
          />
        </Container>
      </Navbar>

      <ModalCadastro
        open={layoutData.openCadastro}
        onToggle={() => {
          updateContext({ openCadastro: !layoutData.openCadastro });
        }}
        onSaved={(dados) => {
          updateContext({ openCadastro: false });
          setDadosUsuario(dados);
          setOpenModalConfirmarSMS(true);
        }}
      />

      <ModalLogin
        open={layoutData.openLogin}
        onToggle={(cb) => {
          updateContext({ openLogin: false });
          setTimeout(cb, 200);
        }}
      />

      <ModalPerfil
        open={openModalPerfil}
        onToggle={() => setOpenModalPerfil(!openModalPerfil)}
        onSaved={() => {
          setOpenModalPerfil(false);
        }}
      />

      <ModalEnderecos
        open={openModalEndereco}
        layoutData={layoutData}
        onToggle={() => {
          updateContext({ openModalEndereco: !openModalEndereco });
        }}
      />

      <ModalConfirmarSMS
        open={openModalConfirmarSMS}
        dadosUsuario={dadosUsuario}
        onCancel={() => setOpenModalConfirmarSMS(false)}
        onSaved={() => {
          setOpenModalConfirmarSMS(false);
          window.location.reload();
        }}
      />

      <ModalTelefone
        open={openModalTelefone}
        onClose={() => setOpenModalTelefone(false)}
        onSaved={() => {
          setOpenModalTelefone(false);
          setOpenModalConfirmarSMS(true);
        }}
      />
    </AppbarRoot>
  );
}

export default Appbar;
