import React from "react";
import styled from "styled-components";
import Text from "../../../../components/Text";
import FiltroRestaurantes from "./FiltroRestaurantes";
import useRestaurantesApi from "./hooks/useRestaurantesApi";
import { Container, Col, Row, Badge } from "reactstrap";
import { CircularProgress } from "@material-ui/core";
import { Card, CardContent } from "@material-ui/core";
import SliderMain from "../../components/SliderMain";

const ListaContainer = styled.div`
  position: relative;
`;

export default function RestaurantesPage() {
  const [restaurantes, loading, filtros, setFiltro] = useRestaurantesApi();

  return (
    <>
      <SliderMain />
      <Container>
        <div
          style={{
            width: "100%",
            marginTop: 32,
            marginBottom: 32,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Text size={32} variant="fancy-title">
            Encontre seu Restaurante
          </Text>
        </div>
        <div style={{ width: "100%" }}>
          <FiltroRestaurantes filtros={filtros} setFiltro={setFiltro} />
        </div>
        {filtros?.tipoEmpresa === 1 ? (
          <div style={{ marginTop: 32, marginBottom: 32, fontSize: 18 }}>
            Mostrando restaurantes com opção de menu: cardápio para consumo no
            estabelecimento.
          </div>
        ) : (
          <div style={{ marginTop: 32, marginBottom: 32, fontSize: 18 }}>
            Mostrando restaurantes com cardápio e funcionalidades para Delivery,
            acesse o estabelecimento e faça seu pedido
          </div>
        )}

        <ListaContainer>
          {loading ? (
            <CircularProgress style={{ margin: "auto", display: "table" }} />
          ) : null}

          <Row>
            {restaurantes.map((restaurante, k) => (
              <RestauranteItem
                filtros={filtros}
                key={k}
                restaurante={restaurante}
              />
            ))}
          </Row>
        </ListaContainer>
      </Container>
    </>
  );
}

function RestauranteItem({ restaurante, filtros }) {
  const {
    nome,
    aberto,
    mensagemPadrao,
    permaLink,
    logoUrl,
    endBairro,
    endCidade,
    especialidades,
    avaliacao,
    flgDeliveryIndisponivel,
    flgMostraCardapioMenu,
  } = restaurante;

  return (
    <Col style={{ marginBottom: 32 }} sm={4} xs={12}>
      <Card style={{ height: 240 }}>
        <CardContent>
          <a
            style={{
              display: "block",
              textDecoration: "none",
              color: "inherit",
            }}
            href={`/${permaLink}`}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
              }}
            >
              <img
                src={logoUrl}
                alt={nome}
                style={{ height: 85, width: 85, borderRadius: 6 }}
              />
              <div
                style={{
                  marginLeft: 16,
                  marginBottom: 16,
                  borderLeft: "1px solid #F0F1F3",
                }}
              />
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  paddingLeft: 16,
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Text variant="h4">{nome}</Text>
                  <span style={{ paddingLeft: 12 }}>
                    {aberto ? (
                      <Badge pill color="success">
                        Aberto{" "}
                      </Badge>
                    ) : (
                      <Badge pill color="secondary">
                        Fechado{" "}
                      </Badge>
                    )}
                  </span>
                </div>

                <div style={{ display: "flex", height: 32 }}>
                  <div style={{ color: "#F6B215", display: "flex" }}>
                    <i
                      style={{ position: "relative", fontSize: 12 }}
                      className="mdi mdi-star"
                    />
                    <span style={{ marginLeft: 2, fontSize: 12 }}>
                      {avaliacao.toString().replace(".", ",")}
                    </span>
                  </div>
                  <span
                    style={{ top: -4, position: "relative", padding: "0 4px" }}
                  >
                    •
                  </span>
                  <Text
                    style={{ lineHeight: "16px", fontSize: 12 }}
                    variant="caption"
                  >
                    {endBairro} - {endCidade}{" "}
                  </Text>
                </div>
                <div>
                  <Text variant="caption">
                    {!flgDeliveryIndisponivel && filtros.tipoEmpresa === 2
                      ? "Delivery"
                      : null}{" "}
                    •{" "}
                    {flgMostraCardapioMenu && filtros.tipoEmpresa === 1
                      ? "Menu"
                      : null}
                  </Text>
                </div>
              </div>
            </div>
            {especialidades ? (
              <div
                style={{
                  display: "flex",
                  marginTop: 16,
                  flexDirection: "row",
                  flexBasis: 24,
                }}
              >
                {especialidades?.split(",").map((item, k) => (
                  <Tag key={k}>{item}</Tag>
                ))}
              </div>
            ) : null}
            <div className="text-overflow" style={{ flex: 1, marginTop: 16 }}>
              <Text variant="body2"> {mensagemPadrao} </Text>
            </div>
          </a>
        </CardContent>
      </Card>
    </Col>
  );
}

const Tag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 16px;

  height: 24px;
  margin-right: 6px;

  /* UI / 01 */

  background: #ffffff;
  /* UI / 03 */

  border: 1px solid #f0f1f3;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;
