import React, { useContext } from "react";
import { Row, Col, Container } from "reactstrap";
import { TextField, CircularProgress } from "@material-ui/core";

import useAmbientesPorDisponibilidade from "../../../hooks/useAmbientesPorDisponibilidade";
import { EventoContext } from "../../../EventoContext";
import SkeletonItem from "./SkeletonItem";
import { LayoutContext } from "contexts/layout";
import { ContainerAmbientes } from "./styles";
import AmbienteItem from "./AmbienteItem";

function Ambiente() {
  const { data, isLoading } = useAmbientesPorDisponibilidade();

  const { layoutData }: any = useContext(LayoutContext);
  const { update, value }: any = useContext(EventoContext);

  return (
    <ContainerAmbientes>
      <Container>
        <div>
          <h3>Monte sua reserva</h3>
        </div>

        <p style={{ marginTop: 16 }}>
          Primeiro selecione a data do seu evento, depois selecione um dos
          espaços disponíveis e então o cardápio desejado. Com tudo planejado,
          envie sua solicitação de reserva e aguarde confirmação.
        </p>

        <div style={{ marginBottom: 48, marginTop: 48 }}>
          <span>
            <TextField
              label="Data do evento"
              type="date"
              disabled={isLoading}
              value={value.dataEvento}
              onChange={({ target: { value } }) => update("dataEvento", value)}
              variant="outlined"
              style={{ backgroundColor: "white" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </span>
          {/* <span>
            <FormControl
              style={{
                marginLeft: 16,
                minWidth: 120,
                backgroundColor: "white",
              }}
              variant="outlined"
            >
              <InputLabel>Horário</InputLabel>
              <Select
                native
                disabled={isLoading || !value.dataEvento}
                variant="outlined"
                value={value.horario}
                onChange={({ target: { value } }) => update("horario", value)}
              >
                {[].map((horario: any) => (
                  <option value={horario}>{horario}</option>
                ))}
              </Select>
            </FormControl>
          </span> */}

          <span>
            {isLoading ? (
              <CircularProgress
                style={{ marginLeft: 12, top: 12, position: "relative" }}
                size="2em"
              />
            ) : null}
          </span>
        </div>

        <div>
          {isLoading ? (
            <Row>
              <Col md={4}>
                <SkeletonItem />
              </Col>
              <Col md={4}>
                <SkeletonItem />
              </Col>
              <Col md={4}>
                <SkeletonItem />
              </Col>
            </Row>
          ) : null}

          <Row>
            {data.map((ambiente, index) => (
              <Col md={4}>
                <AmbienteItem
                  disabled={!value.dataEvento}
                  active={value?.ambiente?.id === ambiente.id}
                  onClick={() =>
                    update(
                      "ambiente",
                      value.ambiente?.id !== ambiente.id ? ambiente : null
                    )
                  }
                  item={ambiente}
                  key={index}
                  primaryColor={layoutData.primaryColor}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </ContainerAmbientes>
  );
}

export default Ambiente;
