import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

function Mobile(props) {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {

    if (props.open) {
      setOpen(props.open);
    }

  }, [props.open]);

  useEffect(() => {

    if (!open) {
      props.onClose();
    }

  }, [open, props, props.onClose]);

  const toggleDrawer = (stateOpen) => (
    event,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event).key === 'Tab' ||
        (event).key === 'Shift')
    ) {
      return;
    }
    setOpen(stateOpen);
  };

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor={'left'}
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {[
                'Encontra seu restaurante',
                'Como funciona',
                'Seja nosso parceiro'
              ]
                .map((text, index) => (
                  <ListItem
                    button
                    key={text}
                    onClick={() => {

                      if (index === 0) {
                        history.push("/")
                      } else if (index === 1) {
                        history.push("/webgula/como-funciona")
                      } else if (index === 2) {
                        history.push("/webgula/seja-nosso-parceiro")
                      }
                    }}>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
            </List>
          </div>
        </SwipeableDrawer>
      </React.Fragment>

    </div>
  );
}

export default Mobile;
