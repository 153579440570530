/* eslint-disable react-native/no-inline-styles */
import React from "react";
import { formatMoney } from "infra/utils";

class RenderPrecoItem extends React.Component {
  render() {
    const { item, key, onClick } = this.props;

    return (
      <div
        style={{
          padding: 6,
          width: 180,
          marginBottom: 10,
          background: "#efefef",
          float: "left",
          clear: "both",
          cursor: "pointer"
        }}
        key={key}
        onClick={() => onClick && onClick(item)}
      >
        <div style={{ float: "left" }}>
          <div
            style={{ fontSize: 14, fontWeight: "normal" }}
            onClick={() => (onClick ? onClick(item) : null)}
          >
            {item.tamanhoNome}
          </div>

          <div>
            <div
              style={{ fontSize: 16 }}
              onClick={() => (onClick ? onClick(item) : null)}
            >
              R$ {formatMoney(item.valorPreco)}
            </div>
          </div>
        </div>

        {onClick && (
          <div style={{ float: "right" }}>
            {item.selected ? (
              <span
                style={{ fontSize: 14, color: "green" }}
                className="fa fa-check-circle"
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default RenderPrecoItem;
